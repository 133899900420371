import { FC, createContext, useContext, PropsWithChildren, useState } from 'react';
import { Subject, Subscriber } from 'rxjs';
import { CallBackProps as JoyrideCallbackData, EVENTS, ACTIONS } from 'react-joyride';

const TOUR_SETTINGS_LS_KEY = 'avifTourSettings';

export type TourStatus = 'enabled' | 'disabled';

export interface TourSettings {
    allHelp?: TourStatus | undefined;
    intro?: TourStatus | undefined;
    [key: string]: TourStatus | undefined;
}

const getTourSettingsFromLS = (): TourSettings => {
    const tourSettings = localStorage.getItem(TOUR_SETTINGS_LS_KEY);
    if (!tourSettings) return {};
    return JSON.parse(tourSettings);
};

const setTourSettingsToLS = (tourSettings: TourSettings) => {
    localStorage.setItem(TOUR_SETTINGS_LS_KEY, JSON.stringify(tourSettings));
};

export interface IJoyrideContext {
    onJoyrideCallback?: (data: JoyrideCallbackData) => void;
    joyrideEvents?: Subject<JoyrideCallbackData>;
    disableAllTours?: () => void;
    enableTour?: (tourId: string) => void;
    disableTour?: (tourId: string) => void;
    isTourEnabled?: (tourId: string) => boolean;
}

export const JoyrideContext = createContext<IJoyrideContext>({} as IJoyrideContext);

export const useJoyride = () => {
    return useContext(JoyrideContext);
};

export const JoyrideProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [observable, setObservable] = useState<Subject<JoyrideCallbackData>>(new Subject<JoyrideCallbackData>());
    const [tourSettings, setTourSettings] = useState<TourSettings>(getTourSettingsFromLS());

    const onJoyrideCallback = (data: JoyrideCallbackData) => {
        if (!([EVENTS.STEP_BEFORE, EVENTS.TARGET_NOT_FOUND] as string[]).includes(data.type)) return;

        observable.next(data);
    };

    return (
        <JoyrideContext.Provider
            value={{
                onJoyrideCallback,
                joyrideEvents: observable,
                disableAllTours: () => {
                    setTourSettingsToLS({ allHelp: 'disabled' });
                    setTourSettings({ allHelp: 'disabled' });
                },
                enableTour: (tourId: string) => {
                    const tourSettings = getTourSettingsFromLS();
                    tourSettings[tourId] = 'enabled';
                    setTourSettingsToLS(tourSettings);
                    setTourSettings(tourSettings);
                },
                disableTour: (tourId: string) => {
                    const tourSettings = getTourSettingsFromLS();
                    tourSettings[tourId] = 'disabled';
                    setTourSettingsToLS(tourSettings);
                    setTourSettings(tourSettings);
                },
                isTourEnabled: (tourId: string) => {
                    const tourSettings = getTourSettingsFromLS();

                    // if all help is disabled, allow only tours that are manually enabled
                    if (tourSettings.allHelp === 'disabled')
                        return tourId !== 'intro' && tourId !== 'example' && tourSettings[tourId] === 'enabled';

                    // if intro is not yet disabled, allow only intro and example tours
                    if (tourSettings.intro !== 'disabled') return tourId === 'intro' || tourId === 'example';

                    // if all help is enabled, intro is disabled, allow all tours that are not yet disabled
                    if (tourSettings[tourId] !== 'disabled') return true;

                    return false;
                },
            }}
        >
            {children}
        </JoyrideContext.Provider>
    );
};
