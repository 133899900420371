import { FunctionComponent } from 'react';
import { IDetection } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useDict } from '../../services/dictContext';

const DetectionSelect: FunctionComponent<DictionarySelectPublicProps<number, IDetection>> = (props) => {
    const { detection } = useDict();
    return (
        <DictionarySelect<number, IDetection>
            color="secondary"
            {...props}
            getDictOptions={
                detection
                    ? new Promise((resolve) => {
                          resolve(detection);
                      })
                    : undefined
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.id)}
            getOptionLabel={(option, dicts = [] as IDetection[]) =>
                dicts.find((dict) => dict.id === option)?.name.cs || `Unknown detection ${option}`
            }
        />
    );
};

export default DetectionSelect;
