import { MonochromePhotos, Clear } from '@mui/icons-material';
import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    Avatar,
} from '@mui/material';
import { FC } from 'react';
import PoppedControl from '../../../../../components/formControls/PoppedControl';
import { AvifDetectionIcon } from '../../../../../components/icons';
import { DetectionCategory } from '../../../../../schemas/enums';
import { useDict } from '../../../../../services/dictContext';
import { useFormikContext } from 'formik';
import { ObservationItemFormValues } from '../../../ObservationFormApp';

const DetectionMenuItem: FC<{ handleClose: () => void }> = ({ handleClose }) => {
    const { detection: detections } = useDict();
    const { values, setFieldValue } = useFormikContext<ObservationItemFormValues>();

    return (
        <PoppedControl
            renderIcon={() => (
                <MenuItem>
                    <ListItemIcon>
                        <MonochromePhotos fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Nastavit způsob detekce</ListItemText>
                </MenuItem>
            )}
            renderControl={(handleClose) => (
                <List
                    sx={{
                        pt: 0,
                        '& .MuiListItem-root .MuiButtonBase-root.Mui-selected .MuiAvatar-root': {
                            bgcolor: 'primary.main',
                        },
                    }}
                >
                    {detections
                        ?.filter((det) => det.category.includes(DetectionCategory.listItemInsert))
                        .map((detectionMethod) => (
                            <ListItem disableGutters key={detectionMethod.id}>
                                <ListItemButton
                                    selected={values.detectionId == detectionMethod.id}
                                    onClick={() => {
                                        handleClose();
                                        setTimeout(() => setFieldValue('detectionId', detectionMethod.id));
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AvifDetectionIcon detection={detectionMethod.label} fontSize="inherit" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={detectionMethod.name.cs} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    {!!values.detectionId && (
                        <ListItem disableGutters key={'remove'}>
                            <ListItemButton
                                onClick={() => {
                                    handleClose();
                                    setTimeout(() => setFieldValue('detectionId', null));
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'error.main' }}>
                                        <Clear fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Odebrat způsob detekce" />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            )}
            title="Vyberte způsob detekce"
            description="Vyberte způsob detekce, který nejlépe odpovídá vašemu pozorování."
            confirmButtonLabel="Pokračovat"
            onPopClose={() => handleClose()}
            disableButtons
        />
    );
};

export default DetectionMenuItem;
