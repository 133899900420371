import { Box, Alert, Snackbar, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { IChartData, IChartsFilter } from '../../../schemas/interfaces';
import { useApi } from '../../../services/apiContext';
import Plot from 'react-plotly.js';
import { feedersPrimary, feedersSecondary } from '../../../theme';
import * as csCz from 'plotly.js/lib/locales/cs';
import { format } from 'date-fns';
import { random } from 'lodash';
import Loading from '../../../components/Loading';
import { useTaxonLanguage } from '../../../services/taxonLanguageContext';

export interface ChartResultProps {
    query?: IChartsFilter;
}

const colors = [
    feedersPrimary[500],
    feedersSecondary[500],
    feedersPrimary[300],
    feedersSecondary[300],
    feedersPrimary[100],
    feedersSecondary[100],
];

const ChartResult: React.FunctionComponent<ChartResultProps> = (props) => {
    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IChartData>();
    const { getCharts } = useApi();
    const isMobile = useMediaQuery('(pointer: coarse)');
    const { currentLanguage } = useTaxonLanguage();

    useEffect(() => {
        if (!props.query) return;

        setLoading(true);
        getCharts(props.query)
            .then((response) => {
                setData({
                    ...response.data,
                    series: [...response.data.series],
                });
                setLoading(false);
            })
            .catch(onFetchError);
    }, [props.query]);

    const onFetchError = (error: any) => {
        console.error(error);
        setLoading(false);
        setError('Nepodařilo se načíst data. Zkontrolujte prosím, zda máte přístup k\xa0internetu a zkuste to znovu.');
    };

    if (loading)
        return (
            <Box className="ChartResult">
                <Loading fullPage />
            </Box>
        );

    return (
        <Box className="ChartResult">
            <Box
                sx={{
                    width: '100%',
                    borderRadius: 'var(--mui-shape-borderRadius)',
                    overflow: 'hidden',
                    p: 3,
                    boxSizing: 'border-box',
                }}
            >
                {!data && (
                    <Typography variant="subtitle1" component="h2" textAlign="center">
                        Vyberte prosím jeden či více druhů a rozsah dat pro zobrazení grafu.
                    </Typography>
                )}
                {!!data && (
                    <>
                        <Box
                            sx={{
                                height: '60vh',
                                minHeight: 300,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '& .js-plotly-plot': {
                                    width: '100%',
                                    height: '100%',
                                },
                            }}
                        >
                            <Plot
                                data={data.series.map((serie, index) => ({
                                    x: data.xaxis.categories,
                                    y: serie.data,
                                    type: 'scatter',
                                    mode: 'lines+markers',
                                    marker: { color: colors[index] },
                                    name: serie.name[currentLanguage || 'cs'] || '',
                                }))}
                                layout={{
                                    xaxis: {
                                        title: { text: `<b>${data.xaxis.title.text.cs}</b>`, standoff: 30 },
                                        type: 'category',
                                        fixedrange: true,
                                    },
                                    yaxis: { title: { text: `<b>${data.yaxis.title.text.cs}</b>` }, fixedrange: true },
                                    legend: isMobile
                                        ? {
                                              x: 0.4,
                                              y: -0.3,
                                              xanchor: 'center',
                                              orientation: 'h',
                                          }
                                        : undefined,
                                    margin: isMobile
                                        ? {
                                              l: 40,
                                              r: 0,
                                              t: 40,
                                              b: 0,
                                          }
                                        : {
                                              l: 50,
                                              r: 30,
                                              t: 40,
                                              b: 90,
                                          },
                                }}
                                config={{
                                    locales: {
                                        csCz: csCz,
                                    },
                                    locale: 'csCz',
                                    responsive: true,
                                    displayModeBar: true,
                                    displaylogo: false,
                                    modeBarButtonsToRemove: [
                                        'zoom2d',
                                        'pan2d',
                                        'select2d',
                                        'lasso2d',
                                        'zoomIn2d',
                                        'zoomOut2d',
                                        'autoScale2d',
                                        'resetScale2d',
                                    ],
                                    toImageButtonOptions: {
                                        format: 'png',
                                        filename: `avif-graf-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}`,
                                        height: 800,
                                        width: 1200,
                                    },
                                }}
                            />
                        </Box>
                        <Alert severity="info" sx={{ mt: 3, width: '100%', boxSizing: 'border-box' }}>
                            <Typography variant="body1" component="p">
                                Graf zobrazuje, v&nbsp;kolika procentech z&nbsp;celkového počtu kompletních seznamů
                                za&nbsp;dané období se druh nacházel. Vyobrazení několika let vedle sebe se může hodit
                                například pro&nbsp;odhalení zvyšujícího se či snižujícího se trendu celkové početnosti
                                druhu. Vyobrazení jednoho roku rozděleného na&nbsp;měsíce může sloužit například
                                pro&nbsp;vizualizace odletů a příletů migrujících ptáků.
                            </Typography>
                        </Alert>
                    </>
                )}
            </Box>
            <Snackbar
                open={error !== undefined}
                autoHideDuration={6000}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') return;
                    setError(undefined);
                }}
            >
                <Alert severity="error">{error}</Alert>
            </Snackbar>
        </Box>
    );
};

export default ChartResult;
