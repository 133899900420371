import { Box, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { IObservationFilter, IPredefinedFilter } from '../../schemas/interfaces';
import { IObsListsResultsProps, ObsListsResults } from './components/ObsListsResults';
import { ObsListsSearch } from './components/ObsListsSearch';
import { useLocation, useNavigate } from 'react-router-dom';
import { schema_filterParameters } from '../../schemas/schemas';
import { parseFilterToFormData, removeNullableFilterValues } from '../../services/parsers';
import SearchTour from '../../components/joyride/SearchTour';
import { useAuth } from '../../services/authenticator';
import { useUser } from '../../services/userContext';

export const ObsListsApp: React.FunctionComponent<IObsListsResultsProps> = (props: IObsListsResultsProps) => {
    const [query, setQuery] = useState<IObservationFilter>();
    const navigate = useNavigate();
    const location = useLocation();
    const resultsRef = useRef<HTMLSpanElement>(null);
    const { isLoggedIn } = useAuth();
    const { filters: predefinedFilters } = useUser();

    useEffect(() => {
        if (isLoggedIn === undefined || !predefinedFilters) return;

        const params = new URLSearchParams(location.search);

        // in case no filter is set in the URL, redirect to the default filter
        if (!params.has('filter')) {
            const defaultFilter = predefinedFilters?.items?.find((filter) => filter.id === predefinedFilters?.default);
            if (!defaultFilter) return;
            setFilter(defaultFilter.parameters);

            return;
        }

        const rawFilter = JSON.parse(params.get('filter') as string) as IObservationFilter;
        const filter = schema_filterParameters.safeParse(rawFilter);

        if (!filter.success) {
            console.error('Failed parsing URL query', filter.error);
            return;
        }

        if (isLoggedIn === false && filter.data.tags && filter.data.tags.includes('my')) {
            const filterWithoutMy = {
                ...filter.data,
                tags: filter.data.tags.length === 1 ? null : filter.data.tags.filter((tag) => tag !== 'my'),
            };
            setFilter(filterWithoutMy);
            return;
        }

        setQuery(filter.data);
    }, [location, isLoggedIn, predefinedFilters]);

    const setFilter = (newFilter: IObservationFilter) => {
        //remove all null or undefined values from the query
        const nonNullQuery = removeNullableFilterValues(newFilter);

        const newQueryString = JSON.stringify(nonNullQuery);

        if (newQueryString === JSON.stringify(query)) return;

        const params = new URLSearchParams();
        params.append('filter', newQueryString);

        navigate('?' + params.toString(), { replace: true });
    };

    const onFilterSubmit = (newQuery: IObservationFilter) => {
        setFilter(newQuery);

        setTimeout(() => {
            if (resultsRef.current) resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 300);
    };

    const onFilterSaved = (newFilter: IPredefinedFilter) => {
        setFilter(newFilter.parameters);
    };

    return (
        <Box className="ObsListsApp">
            {!props.fullScreen && (
                <Box sx={{ py: 3, bgcolor: 'primary.300' }}>
                    <Container maxWidth="lg">
                        <Typography
                            variant="h2"
                            component="h1"
                            textAlign="center"
                            sx={{ pb: 3, color: 'background.paper' }}
                        >
                            Vyhledávání
                        </Typography>
                        <Paper elevation={2} sx={{ p: 2.5 }}>
                            <ObsListsSearch
                                query={query ? parseFilterToFormData(query) : undefined}
                                onSubmit={onFilterSubmit}
                                onSaved={onFilterSaved}
                            />
                        </Paper>
                    </Container>
                </Box>
            )}
            <Box sx={props.fullScreen ? undefined : { pt: 3, pb: 6.75 }}>
                <Container
                    maxWidth="lg"
                    sx={props.fullScreen ? { paddingLeft: '0 !important', paddingRight: '0 !important' } : undefined}
                >
                    <Paper elevation={2}>
                        <Box ref={resultsRef} sx={{ position: 'absolute', marginTop: '-90px' }} component="span" />
                        <ObsListsResults query={query} tab={props.tab} fullScreen={props.fullScreen} />
                    </Paper>
                </Container>
            </Box>
            <SearchTour />
        </Box>
    );
};
