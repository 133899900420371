import { FunctionComponent } from 'react';
import { ITag } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useDict } from '../../services/dictContext';

const TagSelect: FunctionComponent<DictionarySelectPublicProps<string, ITag>> = (props) => {
    const { tags } = useDict();
    return (
        <DictionarySelect<string, ITag>
            color="secondary"
            {...props}
            getDictOptions={
                tags
                    ? new Promise((resolve) => {
                          resolve(tags);
                      })
                    : undefined
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.label)}
            getOptionLabel={(option, dicts = [] as ITag[]) =>
                dicts.find((dict) => dict.label === option)?.name.cs || `Unknown attribute ${option}`
            }
        />
    );
};

export default TagSelect;
