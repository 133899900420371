import { FC, MouseEventHandler, useState } from 'react';
import { IList, IListItem } from '../schemas/interfaces';
import { Menu, MenuItem, Typography, ListItemIcon, ListItemText, Alert, Snackbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DeleteRounded, Edit, MoreVert } from '@mui/icons-material';
import PoppedControl from '../components/formControls/PoppedControl';
import { useApi } from '../services/apiContext';
import { ListRights } from '../schemas/enums';
import ListItemReport from './ListItemReport';
import ItemDeleteDialog from '../apps/ObsItemViewApp/components/ItemDeleteDialog';

type HandleDeleteType = (toDelete: 'list' | 'item') => void;

const ItemActionsButton: FC<{ item?: IListItem; list: IList }> = ({ item, list }) => {
    const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
    const [deleted, setDeleted] = useState<'list' | 'item' | false>();
    const api = useApi();

    const navigate = useNavigate();

    const closeMenu = () => {
        setAnchorRef(null);
    };

    const handleToggle: MouseEventHandler<HTMLButtonElement> = (e) => {
        setAnchorRef(anchorRef ? null : e.currentTarget);
    };

    const handleDelete: HandleDeleteType = (toDelete) => {
        if (toDelete === 'item' && item) {
            api.deleteListItem(list.publicId, item.id)
                .then((response) => {
                    if (!response.success) throw new Error(response.errorDetail ?? '');

                    setDeleted('item');
                    setTimeout(() => {
                        navigate(`/list/${list.publicId}`);
                    }, 3000);
                })
                .catch((error) => {
                    setDeleted(false);
                    console.error(error);
                });
        } else {
            api.deleteList(list.publicId)
                .then((response) => {
                    if (!response.success) throw new Error(response.errorDetail ?? '');

                    setDeleted('list');
                    setTimeout(() => {
                        navigate(`/`);
                    }, 3000);
                })
                .catch((error) => {
                    setDeleted(false);
                    console.error(error);
                });
        }
    };

    return (
        <>
            <IconButton onClick={handleToggle}>
                <MoreVert />
            </IconButton>
            <Menu
                open={Boolean(anchorRef)}
                anchorEl={anchorRef}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
            >
                {list.rights.includes(ListRights.editItems) && [
                    <MenuItem
                        onClick={() => {
                            closeMenu();
                            if (item !== undefined) return navigate(`/item/${item.id}/edit`);
                            if (list !== undefined) return navigate(`/list/${list.publicId}/edit`);
                        }}
                        key={'edit'}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>
                            {' '}
                            <Typography variant="body1" fontWeight="bold">
                                Upravit {item !== undefined ? 'pozorování' : 'vycházku'}
                            </Typography>
                        </ListItemText>
                    </MenuItem>,
                    <ItemDeleteDialog
                        key="delete"
                        item={item}
                        list={list}
                        handleItemDelete={() => handleDelete('item')}
                        handleListDelete={() => handleDelete('list')}
                    />,
                ]}
                {/* {list.rights.includes(ListRights.reportItems) && ( */}
                {true && <ListItemReport list={list} item={item} closeMenu={closeMenu} />}
            </Menu>
            <Snackbar
                open={deleted === false}
                autoHideDuration={6000}
                onClose={(_, reason) => {
                    reason !== 'clickaway' && setDeleted(undefined);
                }}
            >
                <Alert severity="error">Smazání se nepodařilo. Zkuste to prosím znovu později.</Alert>
            </Snackbar>
            <Snackbar
                open={!!deleted}
                autoHideDuration={6000}
                onClose={(_, reason) => {
                    reason !== 'clickaway' && setDeleted(undefined);
                }}
            >
                <Alert severity="success">
                    {deleted === 'item' && (
                        <span>
                            Pozorování bylo úspěšně smazáno. Za&nbsp;pár sekund budete přesměrováni na&nbsp;vycházku.
                        </span>
                    )}
                    {deleted === 'list' && (
                        <span>Vycházka byla úspěšně smazána. Za&nbsp;pár sekund budete přesměrováni na&nbsp;úvod.</span>
                    )}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ItemActionsButton;
