import { FC, createContext, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { IPlace } from '../schemas/interfaces';
import { useApi } from './apiContext';

export interface IPlacesContext {
    places?: IPlace[];
}

export const PlacesContext = createContext<IPlacesContext>({} as IPlacesContext);

export const usePlaces = () => {
    return useContext(PlacesContext);
};

export const PlacesProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [places, setPlaces] = useState<IPlace[]>();
    const { getPlaces } = useApi();

    useEffect(() => {
        getPlaces().then(({ items }) => {
            setPlaces(items);
        });
    }, []);

    return <PlacesContext.Provider value={{ places }}>{children}</PlacesContext.Provider>;
};
