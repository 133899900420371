import { FC, useEffect, useRef, useState } from 'react';
import OpenStreetMap, {
    MapCategory,
    OpenStreetMapProps,
    customIcon,
} from '../../../../../components/map/OpenStreetMap';
import { FeatureCollection } from '../../../../../schemas/interfaces';
import { GeoJSON, useMap } from 'react-leaflet';
import { Typography, useMediaQuery } from '@mui/material';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import FitBounds from '../FitBounds';

interface GeoJsonMapProps extends OpenStreetMapProps {
    geojson: FeatureCollection;
}

const GeoJsonMap: FC<GeoJsonMapProps> = (props) => {
    const { geojson, ...osmProps } = props;
    const isMobile = useMediaQuery('(pointer: coarse)');
    const [bounds, setBounds] = useState<L.LatLngBounds | undefined>();

    useEffect(() => {
        if (!geojson) return;
        setBounds(L.geoJson(geojson).getBounds());
    }, [geojson]);

    if (!geojson) return null;

    return (
        <OpenStreetMap
            height="500px"
            defaultZoom={isMobile ? 6 : undefined}
            sx={{
                position: 'relative',
                '& .legend': {
                    bgcolor: 'background.paper',
                    p: 1,
                    border: '2px solid rgba(0,0,0,0.2)',
                    borderRadius: '5px',
                },
            }}
            mapCategory={MapCategory.SEARCH}
            enableFullscreen
            {...osmProps}
        >
            <Typography
                variant="body2"
                component="span"
                fontWeight="bold"
                sx={{
                    position: 'absolute',
                    top: '0.3em',
                    bgcolor: 'background.paper',
                    zIndex: 450,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    py: 0.25,
                    px: 1,
                    borderRadius: 'var(--mui-shape-borderRadius)',
                }}
            >
                Pozorování s&nbsp;polohou skrytou na&nbsp;úroveň kraje nejsou zobrazena.
            </Typography>
            {geojson.properties.clustering ? (
                <MarkerClusterGroup
                    chunkedLoading
                    maxClusterRadius={50}
                    spiderfyOnMaxZoom={true}
                    showCoverageOnHover={false}
                >
                    <GeoJsonLayer geojson={geojson} />
                </MarkerClusterGroup>
            ) : (
                <GeoJsonLayer geojson={geojson} />
            )}
            <GeoJsonLegend geojson={geojson} />
            {!props.center && !props.defaultZoom && bounds && <FitBounds bounds={bounds} onlyOnce />}
        </OpenStreetMap>
    );
};

export default GeoJsonMap;

const GeoJsonLayer: FC<{ geojson: FeatureCollection }> = ({ geojson }) => {
    return (
        <>
            <GeoJSON
                data={geojson}
                style={(feature) => {
                    if (!feature?.properties?.class) return {};

                    const styles = geojson.properties?.styles?.[feature?.properties?.class];

                    return styles || {};
                }}
                pointToLayer={(feature, latlng) => {
                    const featureDisplay = feature.properties?.displayAs;
                    const objectDefinition = geojson.properties?.objectTypes?.[0][featureDisplay];

                    let marker: L.Marker | L.CircleMarker | L.Circle | undefined = undefined;
                    switch (objectDefinition?.type) {
                        case 'Icon':
                            marker = L.marker(latlng, { icon: L.icon(objectDefinition.options as L.IconOptions) });
                            break;
                        case 'CircleMarker':
                            marker = L.circleMarker(latlng, objectDefinition.options);
                            break;
                        case 'Circle':
                            marker = L.circle(latlng, objectDefinition.options);
                            break;
                        default:
                            marker = L.marker(latlng, { icon: customIcon });
                    }

                    return marker;
                }}
                onEachFeature={(feature, layer) => {
                    const popup = feature.properties.popup
                        ? L.popup({ autoClose: false }).setContent(feature.properties.popup)
                        : undefined;

                    if (popup) {
                        layer.bindPopup(popup);
                        layer.on('mouseover', () => {
                            layer.openPopup();
                            layer.addEventListener('mouseout', (e) => {
                                layer.closePopup();
                            });
                        });
                        layer.on('click', () => {
                            layer.openPopup();
                            layer.removeEventListener('mouseout');
                        });
                    }
                }}
            />
        </>
    );
};

const GeoJsonLegend: FC<{ geojson: FeatureCollection }> = ({ geojson }) => {
    const initialized = useRef(false);
    const map = useMap();

    useEffect(() => {
        if (!map || !geojson.properties?.legend || initialized.current) return;
        initialized.current = true;

        const legend = new L.Control({ position: geojson.properties?.legend?.position || 'bottomright' });
        legend.onAdd = () => {
            const div = L.DomUtil.create('div', 'info legend');
            div.innerHTML = geojson.properties?.legend?.html || 'Empty legend';
            if (geojson.properties?.legend?.styles)
                Object.entries(geojson.properties?.legend?.styles).forEach(([key, value]) => {
                    div.style.setProperty(key, value as string);
                });
            return div;
        };
        legend.addTo(map);
    }, [map]);

    return null;
};
