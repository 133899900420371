import { FC, useEffect, useState } from 'react';
import Joyride, { Props as JoyrideProps, CallBackProps, ACTIONS, STATUS, EVENTS } from 'react-joyride';
import { useJoyride } from '../../services/joyrideContext';
import { useMediaQuery } from '@mui/material';
import { theme } from '../../theme';

export interface IAvifJoyrideProps extends JoyrideProps {
    tourId: string;
    something?: string;
}

const AvifJoyride: FC<IAvifJoyrideProps> = (props) => {
    const [open, setOpen] = useState<boolean>(props.run ?? false);
    const [step, setStep] = useState<number>(0);
    const { onJoyrideCallback, disableAllTours, isTourEnabled, disableTour } = useJoyride();
    const isMobile = useMediaQuery('(pointer: coarse)');
    const pureJoyrideProps: IAvifJoyrideProps = {
        ...props,
    };
    delete pureJoyrideProps.something;

    useEffect(() => {
        isTourEnabled && setOpen(isTourEnabled(props.tourId));
    }, [props.tourId, isTourEnabled, props.run]);

    const callback = (data: CallBackProps) => {
        // console.log('callback', data);
        if (STATUS.SKIPPED === data.status) {
            setOpen(false);
            setStep(0);
            disableAllTours && disableAllTours();
        } else if (data.status === STATUS.FINISHED) {
            // console.log('finished', data);
            setStep(0);
            setOpen(false);
            disableTour && disableTour(props.tourId);
        } else if (ACTIONS.CLOSE === data.action) {
            // console.log('closing', data);
            setStep(data.index);

            if (data.index === data.size - 1) {
                setOpen(false);
                disableTour && disableTour(props.tourId);
            }
        } else if (EVENTS.STEP_AFTER === data.type) {
            // console.log('step after', data, data.index + data.action === ACTIONS.PREV ? -1 : 1);
            setStep(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
        } else if (EVENTS.TARGET_NOT_FOUND === data.type) {
            // console.log('not found, closing and reopening', data);
            setOpen(false);
            setTimeout(() => setOpen(true), 100);
        }

        onJoyrideCallback && onJoyrideCallback(data);
        props.callback && props.callback(data);
    };

    if (isMobile) return null;

    return onJoyrideCallback && open ? (
        <Joyride
            showProgress
            showSkipButton
            scrollOffset={100}
            {...pureJoyrideProps}
            styles={{
                ...props.styles,
                options: {
                    zIndex: 1200,
                    primaryColor: theme.palette.secondary.main,
                    ...props.styles?.options,
                },
                overlay: {
                    height: '100% !important',
                },
            }}
            floaterProps={{
                ...pureJoyrideProps.floaterProps,
                styles: {
                    ...pureJoyrideProps.floaterProps?.styles,
                    wrapper: {
                        zIndex: 1000,
                        ...pureJoyrideProps.floaterProps?.styles?.wrapper,
                    },
                },
            }}
            locale={{
                back: 'Zpět',
                close: 'Zavřít',
                last: 'Zavřít',
                skip: 'Vypnout nápovědu',
                next: 'Další',
                open: 'Otevřít nápovědu',
                ...pureJoyrideProps.locale,
            }}
            run={open}
            stepIndex={step}
            callback={callback}
        />
    ) : null;
};

export default AvifJoyride;
