import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as BinocularsSvg } from '../assets/icons/binoculars.svg';
import { ReactComponent as MapSvg } from '../assets/icons/map.svg';
import { ReactComponent as AddStickyNoteSvg } from '../assets/icons/add_sticky_note.svg';
import { IDetection } from '../schemas/interfaces';
import { Mic, Visibility } from '@mui/icons-material';

export const AvifBinocularsIcon = (props: SvgIconProps) => (
    <SvgIcon component={BinocularsSvg} viewBox="0 0 63 51" {...props} />
);
export const AvifMapIcon = (props: SvgIconProps) => <SvgIcon component={MapSvg} viewBox="0 0 107 62" {...props} />;

export const AvifAddStickyNote = (props: SvgIconProps) => (
    <SvgIcon component={AddStickyNoteSvg} viewBox="0 0 24 24" {...props} />
);

export const AvifDetectionIcon = (props: SvgIconProps & { detection: IDetection['label'] }) => {
    const { detection, ...rest } = props;

    return (
        <>
            {detection == 'visually' && <Visibility {...rest} />}
            {detection == 'acoustically' && <Mic {...rest} />}
        </>
    );
};
