import { IProject } from '../schemas/interfaces';

// TODO: support for taxon hierarchy
export default function filterRuleByTaxonId<RuleType extends { taxon: IProject['taxon'] }>(
    rules: RuleType[],
    targetTaxonId: number,
): RuleType[] {
    return rules.filter((rule) => {
        return rule.taxon.some((taxonId) => taxonId === targetTaxonId);
    });
}
