import React, { useRef, useEffect, PropsWithChildren, FC } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { OptionsType } from '@fancyapps/ui/types/Fancybox/options';
import { Box } from '@mui/material';

export const FancyboxDefaultOptions: Partial<OptionsType> = {
    Images: {
        Panzoom: {
            maxScale: 10,
        },
    },
    Carousel: {
        infinite: false,
    },
};

interface FancyboxProps extends PropsWithChildren {
    options?: Partial<OptionsType>;
    delegate?: string;
}

const Fancybox: FC<FancyboxProps> = (props) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const delegate = props.delegate || '[data-fancybox]';
        const options: Partial<OptionsType> = {
            ...FancyboxDefaultOptions,
            ...props.options,
        };

        NativeFancybox.bind(container, delegate, options);

        return () => {
            NativeFancybox.unbind(container);
            NativeFancybox.close();
        };
    });

    return (
        <Box
            ref={containerRef}
            sx={{
                width: '100%',
            }}
        >
            {props.children}
        </Box>
    );
};

export default Fancybox;
