import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { DomEvent } from 'leaflet';
import { useMap } from 'react-leaflet';

const FullScreenMap: React.FC<{ onEnable?: () => void; onDisable?: () => void }> = (props) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const map = useMap();

    useEffect(() => {
        if (!buttonRef.current) return;
        DomEvent.disableClickPropagation(buttonRef.current);
    }, [buttonRef]);

    const handleClick = () => {
        const center = map.getCenter();

        if (isFullscreen) {
            setIsFullscreen(false);
            props.onDisable?.();
        } else {
            setIsFullscreen(true);
            props.onEnable?.();
        }

        setTimeout(() => {
            if (!map) return;
            map.invalidateSize();
            map.setView(center);
        });
    };

    return (
        <Box className="leaflet-top leaflet-left" sx={{ top: '72px', zIndex: '440 !important' }}>
            <Box
                className="leaflet-control leaflet-bar"
                sx={{ display: 'flex', flexDirection: 'column', background: '#fff' }}
            >
                <Tooltip title={isFullscreen ? 'Zmenšit mapu' : 'Zvětšit mapu na celou obrazovku'} placement="top">
                    <IconButton
                        ref={buttonRef}
                        onClick={handleClick}
                        size="small"
                        sx={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px',
                            boxSizing: 'border-box',
                            color: 'grey.600',
                        }}
                    >
                        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default FullScreenMap;
