import { AccordionDetails, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ImportFile } from './ImportFileList';
import { FC } from 'react';
import { CheckCircle, Error } from '@mui/icons-material';
import ImportListResult from './ImportListResult';

const ImportFileResult: FC<{ file: ImportFile }> = ({ file }) => {
    if (!file.response) return null;

    return (
        <AccordionDetails>
            <List
                sx={{
                    '& .MuiListItemIcon-root': {
                        justifyContent: 'center',
                        pr: 1,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <ListItem>
                    {file.status === 'success' && (
                        <>
                            <ListItemIcon sx={{ color: 'success.main' }}>
                                <CheckCircle />
                            </ListItemIcon>
                            <ListItemText primary={file.response?.message?.cs || 'Import byl úspěšný.'} />
                        </>
                    )}
                    {file.status === 'error' && (
                        <>
                            <ListItemIcon sx={{ color: 'warning.main' }}>
                                <Error />
                            </ListItemIcon>
                            <ListItemText primary={file.response?.message?.cs || 'Import selhal.'} />
                        </>
                    )}
                </ListItem>
                {file.response.results?.map((result, index) => (
                    <ImportListResult key={index} listResult={result} index={index + 1} />
                ))}
            </List>
        </AccordionDetails>
    );
};

export default ImportFileResult;
