import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { useAuth } from '../../services/authenticator';

export const LogoutApp: FC = () => {
    const [success, setSuccess] = useState<boolean>();
    const navigate = useNavigate();
    const { actions } = useAuth();

    useEffect(() => {
        if (!actions?.logout) return;

        actions
            ?.logout()
            .then((response) => {
                setSuccess(true);
                navigate('/');
            })
            .catch((error) => {
                console.log(error);
                setSuccess(false);
            });
    }, [success]);

    return (
        <>
            {success === true && (
                <Snackbar
                    open={success === true}
                    autoHideDuration={6000}
                    onClose={(_, reason) => {
                        if (reason === 'clickaway') return;
                        setSuccess(undefined);
                    }}
                >
                    <Alert severity="success">Byl jste úspěšně odhlášen.</Alert>
                </Snackbar>
            )}
            {success === false && (
                <Snackbar
                    open={success === false}
                    autoHideDuration={6000}
                    onClose={(_, reason) => {
                        if (reason === 'clickaway') return;
                        setSuccess(undefined);
                    }}
                >
                    <Alert severity="success">Odhlášení selhalo.</Alert>
                </Snackbar>
            )}
        </>
    );
};
