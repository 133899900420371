import { z } from 'zod';
import { OpenAPIRegistry, extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
export const openAPIRegistry = new OpenAPIRegistry();
extendZodWithOpenApi(z);

// --- schemata -----------------------------------------------------------------------------------

// response s uspesnosti volane akce
export const response_success = openAPIRegistry.register(
    'response_success',
    z.object({
        success: z.boolean(),
        message: z
            .object({
                cs: z.string(),
                en: z.string(),
            })
            .nullish(),
        error: z
            .object({
                cs: z.string(),
                en: z.string(),
            })
            .nullish(),
        errorDetail: z.string().nullish(),
    }),
);

// POST /token
export const request_token = openAPIRegistry.register(
    'request_login',
    z
        .object({
            username: z.string().min(1).max(32).openapi({ example: 'JosefNovak' }),
            password: z.string().min(1).max(32).openapi({ example: 'abcDEF789' }),
            saveCookie: z.boolean().default(false).nullish(),
        })
        .describe('Požadavek na přihlášení'),
);
export const response_token = openAPIRegistry.register(
    'response_login',
    response_success.extend({
        // userId: z.string().uuid(),
        access_token: z.string(),
        refresh_token: z.string(),
    }),
);

// POST /refresh
export const request_refresh = openAPIRegistry.register(
    'request_refresh',
    z
        .object({
            refresh_token: z.string().nullish(),
        })
        .describe('Požadavek na nový access_token'),
);
export const response_refresh = openAPIRegistry.register(
    'response_refresh',
    response_success.extend({
        access_token: z.string(),
        refresh_token: z.string().nullish(),
    }),
);

// POST /seznamcz
export const request_seznamcz = openAPIRegistry.register(
    'request_seznamcz',
    z
        .object({
            code: z.string().min(1).max(256),
        })
        .describe('Kód pro přihlášení'),
);

// GET /identity
export const response_identity = openAPIRegistry.register(
    'response_identity',
    z.object({
        id: z.string().uuid().openapi({ example: '7d463636-d473-4c36-8202-345fc3d93b9f' }),
        name: z.string().nullable().openapi({ example: 'Josef Novák' }),
        firstName: z.string().nullable().openapi({ example: 'Josef' }),
        lastName: z.string().nullable().openapi({ example: 'Novák' }),
        email: z.string().email().nullable().openapi({ example: 'novak@cso.cz' }),
    }),
);

// ------------------------------------------------------------------------------------------------

// Funkce pro ulozeni schema do openApi
export function definitions() {
    return openAPIRegistry.definitions;
}
