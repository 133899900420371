import { ReactElement } from 'react';
import { ITableEditorRowProps, TableEditorRow } from './TableEditorRow';
import { useMediaQuery } from '@mui/material';
import { FormikValues } from 'formik';

export type TableEditorRowFunctionalProps<T> = Omit<ITableEditorRowProps<T>, 'isMobile'>;

const TableEditorRowFunctional: <T extends FormikValues>(
    props: TableEditorRowFunctionalProps<T>,
) => ReactElement | null = (props) => {
    const isMobile = useMediaQuery('(pointer: coarse)');

    return <TableEditorRow {...props} isMobile={isMobile} />;
};

export default TableEditorRowFunctional;
