import { Box, Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { random } from 'lodash';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

const NotFound: FC<{ text: string; className: string; reportText?: boolean }> = ({ text, className, reportText }) => {
    const [birdNumber, setBirdNumber] = useState(random(1, 5, false));
    const isMobile = useMediaQuery('(pointer: coarse)');

    return (
        <Container maxWidth="lg" className={className} sx={{ pt: 5.75, pb: 6.75 }}>
            <Paper sx={{ px: 4.75, py: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>
                        <Box
                            component="img"
                            src={`/img/404-${birdNumber}.png`}
                            sx={{
                                height: '40vh',
                                maxheight: '500px',
                                width: 'auto',
                                maxWidth: '100%',
                                display: 'block',
                                margin: '0 auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            order: isMobile ? -1 : 1,
                        }}
                    >
                        <Typography variant="h2" component="h1" textAlign="center" sx={{ pb: 1.75 }}>
                            Chyba
                        </Typography>
                        <Typography variant="body1" component="p" textAlign="center">
                            {text}
                        </Typography>
                        {!!reportText && (
                            <Typography variant="caption" component="p" textAlign="center" sx={{ pt: 1.75 }}>
                                Zkontrolujte, že jste zadali webovou adresu správně. Pokud jste se na&nbsp;stránku dostali
                                pomocí odkazu, napište nám prosím podrobnosti na&nbsp;
                                <Link to="mailto:admin@birds.cz" target="_blank">
                                    admin@birds.cz
                                </Link>
                                , ať to můžeme opravit. Děkujeme.
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default NotFound;
