import { JoinLeftOutlined } from '@mui/icons-material';
import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    Avatar,
} from '@mui/material';
import { FC } from 'react';
import PoppedControl from '../../../../../components/formControls/PoppedControl';
import { useFormikContext } from 'formik';
import { ObservationItemFormValues } from '../../../ObservationFormApp';

const GroupsMenuItem: FC<{
    handleClose: () => void;
    groupsEnabled?: boolean;
    existingGroups?: number[];
    onGroupsEnabled?: () => void;
    onGroupChanged?: (group: number) => void;
}> = ({ handleClose, groupsEnabled, existingGroups, onGroupsEnabled, onGroupChanged }) => {
    const { values, setFieldValue } = useFormikContext<ObservationItemFormValues>();

    const groupsToRender = Array.from(
        { length: Math.max(...(existingGroups?.length ? existingGroups : [0])) + 1 },
        (_, i) => i + 1,
    );

    return (
        <>
            {!groupsEnabled && (
                <MenuItem
                    onClick={() => {
                        onGroupsEnabled && onGroupsEnabled();
                    }}
                >
                    <ListItemIcon>
                        <JoinLeftOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Zapnout skupiny</ListItemText>
                </MenuItem>
            )}
            {groupsEnabled && (
                <PoppedControl
                    renderIcon={() => (
                        <MenuItem>
                            <ListItemIcon>
                                <JoinLeftOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Upravit skupinu</ListItemText>
                        </MenuItem>
                    )}
                    renderControl={(handleClose) => (
                        <List
                            sx={{
                                pt: 0,
                                '& .MuiListItem-root .MuiButtonBase-root.Mui-selected .MuiAvatar-root': {
                                    bgcolor: 'primary.main',
                                },
                            }}
                        >
                            {groupsToRender.sort().map((group) => (
                                <ListItem disableGutters key={group}>
                                    <ListItemButton
                                        selected={values.group == group}
                                        onClick={() => {
                                            setFieldValue('group', group);
                                            onGroupChanged && onGroupChanged(group);
                                            handleClose();
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>{group}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={`Skupina ${group}`} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                    title="Vyberte skupinu pro zařazení pozorování"
                    confirmButtonLabel="Pokračovat"
                    onPopClose={() => handleClose()}
                    disableButtons
                />
            )}
        </>
    );
};

export default GroupsMenuItem;
