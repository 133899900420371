import { FC, createContext, useContext, PropsWithChildren, useState } from 'react';

export interface IFullwidthContext {
    fullwidth?: boolean;
    enableFullwidth?: () => void;
    disableFullwidth?: () => void;
    mapEnabled?: boolean;
    enableMap?: () => void;
    disableMap?: () => void;
}

export const FullwidthContext = createContext<IFullwidthContext>({} as IFullwidthContext);

export const useFullwidth = () => {
    return useContext(FullwidthContext);
};

export const FullwidthProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [fullwidth, setFullwidth] = useState<boolean>(false);
    const [mapEnabled, setMapEnabled] = useState<boolean>(false);

    return (
        <FullwidthContext.Provider
            value={{
                fullwidth,
                enableFullwidth: () => setFullwidth(true),
                disableFullwidth: () => setFullwidth(false),
                mapEnabled,
                enableMap: () => setMapEnabled(true),
                disableMap: () => setMapEnabled(false),
            }}
        >
            {children}
        </FullwidthContext.Provider>
    );
};
