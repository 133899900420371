import { FC, createContext, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { IPlace, IProject, ITaxon } from '../schemas/interfaces';
import { useAuth } from './authenticator';
import { useApi } from './apiContext';
import { RsGetDicts, RsGetPlaces } from '../schemas/responses';

export interface IDictionaryContext extends Partial<RsGetDicts> {
    taxons?: ITaxon[];
    places?: IPlace[];
    placeTypes?: RsGetPlaces['types'];
    projects?: IProject[];
}

export const DictionaryContext = createContext<IDictionaryContext>({} as IDictionaryContext);

export const useDict = () => {
    return useContext(DictionaryContext);
};

export const DictionaryProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [dicts, setDicts] = useState<RsGetDicts>();
    const [taxons, setTaxons] = useState<ITaxon[]>();
    const [places, setPlaces] = useState<IPlace[]>();
    const [placeTypes, setPlaceTypes] = useState<RsGetPlaces['types']>();
    const [projects, setProjects] = useState<IProject[]>();
    const { isLoggedIn } = useAuth();
    const { getDicts, getTaxons, getPlaces, getProjects } = useApi();

    useEffect(() => {
        if (isLoggedIn === undefined) return;

        getDicts().then((response) => {
            setDicts(response);
        });

        getTaxons().then((response) => {
            setTaxons(response);
        });

        getPlaces().then((response) => {
            setPlaces(response.items);
            setPlaceTypes(response.types);
        });

        getProjects().then((response) => {
            setProjects(response.projects);
        });
    }, [isLoggedIn]);

    return (
        <DictionaryContext.Provider value={{ ...dicts, taxons, places, placeTypes, projects }}>
            {children}
        </DictionaryContext.Provider>
    );
};
