import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface FormDarkBackgroundProps extends PropsWithChildren {
    disabled?: boolean;
}

const FormDarkBackground: FC<FormDarkBackgroundProps> = ({ children, disabled }) => {
    return (
        <Box
            sx={
                disabled
                    ? {}
                    : {
                          '& .MuiInputBase-root': {
                              bgcolor: 'background.paper',
                          },
                          '& .MuiInputLabel-shrink': {
                              bgcolor: 'action.selected',
                              padding: '0 0.5em',
                              borderRadius: 'var(--mui-shape-borderRadius)',
                              marginLeft: '-0.3em',
                          },
                          '& .MuiFormHelperText-root': {
                              bgcolor: 'action.selected',
                              display: 'inline-block',
                              alignSelf: 'flex-start',
                              padding: '0 1em',
                              borderRadius: 'var(--mui-shape-borderRadius)',
                          },
                          '& .MuiAutocomplete-root .MuiIconButton-root': {
                              color: { xs: '#fff', md: 'inherit' },
                          },
                      }
            }
        >
            {children}
        </Box>
    );
};

export default FormDarkBackground;
