import { any, z } from 'zod';
import { OpenAPIRegistry, extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
export const openAPIRegistry = new OpenAPIRegistry();
extendZodWithOpenApi(z);

// --- enums --------------------------------------------------------------------------------------

export const enum_observation_fields = [
    'listPublicId',
    'itemId',
    'rarity',
    'date',
    'time',
    'taxonId',
    'count',
    'ageId',
    'sexId',
    'secrecyLevel',
    'secretUntil',
    // "countString",
    'location',
    // "locationString",
    'location.coordinates',
    'location.kfme',
    'location.eea10km',
    'location.territorialUnitId',
    // "location.country",
    // "location.region",
    // "location.district",
    // "location.prague",
    // "location.municipality",
    'siteName',
    'observers',
    'observersString',
    'observersText',
    'note',
    'media',
    'media.type',
    'media.id',
    'media.mimeType',
    'media.url',
    'media.thumbnail',
    'media.note',
    'media.exif',
    'tags',
    'commentsCount',
    'lastCommented',
    'projectsString',
] as const;

export const enum_mapGrid_mode = ['eea10km', 'kfme0', 'kfme1', 'kfme2'] as const;

export const enum_analytics_dateMode = ['months', 'years'] as const;

export const enum_list_role = ['visitor', 'coobserver', 'owner', 'admin'] as const;

export const enum_list_rights = [
    'view',
    'editList',
    'editItems',
    'share',
    'addItems',
    'deleteItems',
    'changeListType',
] as const;

export const enum_comment_rights = ['view', 'edit', 'delete'] as const;

export const enum_list_type = ['complete', 'partial', 'selected'] as const;

export const enum_list_observationMode = ['area', 'point', 'line'] as const;

export const enum_listItem_tags = ['remarkable', 'uncertain', 'adviceRequested', 'ringMark', 'firstArrivals'] as const;

export const enum_tags_categories = ['observationsSearch', 'listItemInsert', 'observationsSearchLoggedIn'] as const;

export const enum_secrecy_categories = ['listInsert'] as const;

export const enum_rarity_categories = ['observationsSearch'] as const;

export const enum_sex_categories = ['observationsSearch', 'listItemInsert'] as const;

export const enum_activity_categories = ['observationsSearch', 'listItemInsert'] as const;

export const enum_detection_categories = [
    'observationsSearch',
    'listItemInsert',
    'lsdItemInsert',
    'transItemInsert',
] as const;

export const enum_sort_languages = ['cs', 'en'] as const;

export const enum_sort_orders = ['asc', 'desc'] as const;

export const enum_rarity = ['common', 'remarkable', 'uncommon', 'rare'] as const;

export const enum_mapLayer_categories = ['observationsSearch', 'listInsert'] as const;

export const enum_project_categories = [
    'observationsSearch',
    'listInsert',
    'listItemInsert',
    'listEdit',
    'listItemEdit',
] as const;

// --- schemas ------------------------------------------------------------------------------------

export const schema_language = openAPIRegistry.register(
    'schema_language',
    z
        .string()
        .trim()
        .regex(/^([a-z]{2})$/)
        .openapi({ example: 'cs' }),
);

export const schema_slug = openAPIRegistry.register(
    'schema_slug',
    z
        .string()
        .trim()
        .regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
        .openapi({ example: 'sample-slug' }),
);

export const schema_latitude = openAPIRegistry.register('schema_latitude', z.coerce.number().gte(-90).lte(90));

export const schema_longitude = openAPIRegistry.register('schema_longitude', z.coerce.number().gte(-180).lte(180));

export const schema_coordinates = openAPIRegistry.register(
    'schema_coordinates',
    z
        .object({
            latitude: schema_latitude,
            longitude: schema_longitude,
        })
        .describe('Zeměpisné souřadnice systému WGS84. Přesnost se ukládá na 6 desetinných míst, tedy 0.111 metru.')
        .openapi({
            // 50.0717164N, 14.4056719E
            // 33UVR57464677
            // 33U 457460 5546770
            // EEA (EPSG:3035, ETRS89-LAEA): X:4636212.754263129 Y:3004953.8617408206
            example: { latitude: 50.071716, longitude: 14.405671 },
        }),
);

export const schema_track = openAPIRegistry.register(
    'schema_track',
    z
        .tuple([z.number().gte(-90).lte(90), z.number().gte(-180).lte(180)])
        .array()
        .describe('Trasa (linie) uložená jako array párů souřadnic')
        .openapi({
            example: [
                [50.071716, 14.405671],
                [50.072084, 14.41037],
                [50.070013, 14.411228],
            ],
        }),
);

export const schema_coordinatesWithRadius = openAPIRegistry.register(
    'schema_coordinatesWithRadius',
    z
        .object({
            latitude: z.number().gte(-90).lte(90),
            longitude: z.number().gte(-180).lte(180),
            radius: z
                .number({
                    invalid_type_error: 'errors.list.radius.invalid',
                    required_error: 'errors.list.radius.invalid',
                })
                .gte(0, { message: 'errors.list.radius.min' })
                .lte(100, { message: 'errors.list.radius.max' }),
        })
        .describe(
            'Zeměpisné souřadnice systému WGS84 s poloměrem v km. Přesnost se ukládá na 6 desetinných míst, tedy 0.111 metru.',
        )
        .openapi({
            example: {
                latitude: 50.071716,
                longitude: 14.405671,
                radius: 5,
            },
        }),
);

export const schema_territorialUnitType = openAPIRegistry.register(
    'schema_territorialUnitType',
    z.object({
        id: z
            .number()
            .int()
            .min(0)
            .max(9)
            .describe('Vychází z NUTS/LAU: 0 - stát, 3 - kraj, 4 - okres, 6 - část obce')
            .openapi({
                example: 5,
            }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'Obec' }),
            en: z.string().trim().nullable().openapi({ example: 'Municipality' }),
        }),
        zoomCorrection: z
            .number()
            .nullish()
            .describe(
                'Hodnota korekce zoomu např. pro vyhledávání v oblasti a následný zoom mapy, kde je nějaká default hodnota a tato korekce se k ní příčíta. Hodnota může být kladná i záporná.',
            )
            .openapi({ example: -2 }),
    }),
);

export const schema_territorialUnitId = openAPIRegistry.register(
    'schema_territorialUnitId',
    z
        .number()
        .int()
        .min(1)
        .max(9999999)
        .describe(
            'Kód územních jednotek dle číselníků ČSÚ (země - ISO 3166, kraje - číselník ČSÚ 100, okresy - číselník ČSÚ 101, části obcí - číselník ČSÚ 42).',
        )
        .openapi({
            example: 6400301,
        }),
);

export const schema_areaId = openAPIRegistry.register(
    'schema_areaId',
    z.string().trim().uuid().describe('Veřejné ID oblasti').openapi({
        example: 'c26f61d6-b724-4ffc-ad3d-5b4fbe739787',
    }),
);

export const schema_territorialUnit = openAPIRegistry.register(
    'schema_territorialUnit',
    z.object({
        id: schema_territorialUnitId,
        type: z
            .number()
            .int()
            .min(0)
            .max(9)
            .describe('Vychází z NUTS/LAU: 0 - stát, 3 - kraj, 4 - okres, 5 - obec, 6 - část obce')
            .openapi({
                example: 5,
            }),
        parentId: z.number().int().min(1).max(9999999).nullable().describe('ID nadřazeného území').openapi({
            example: 40291,
        }),
        name: z.string().trim().openapi({ example: 'Jablonec u Českého Krumlova [CK]' }),
        en: z
            .string()
            .trim()
            .nullish()
            .describe('Anglický název v případě států a krajů.')
            .openapi({ example: 'Zlín Region' }),
        latitude: z.number().gte(-90).lte(90).nullish(),
        longitude: z.number().gte(-180).lte(180).nullish(),
        maxDistance: z
            .number()
            .nullish()
            .describe(
                'Hodnota max. vzdálenosti v metrech mezi středem obce a zadaným bodem, např. pro vojenské újezdy nestačí standardní limit 5000 metrů.',
            )
            .openapi({ example: 20000 }),
        zoomCorrection: z
            .number()
            .nullish()
            .describe(
                'Hodnota korekce zoomu např. pro vyhledávání v oblasti a následný zoom mapy, kde je nějaká default hodnota a tato korekce se k ní příčíta. Hodnota může být kladná i záporná.',
            )
            .openapi({ example: -2 }),
    }),
);

export const schema_location = openAPIRegistry.register(
    'schema_location',
    z.object({
        coordinates: schema_coordinates.nullish(),
        coordinatesSetByUser: z
            .boolean()
            .nullish()
            .describe('Ukazatel, zda souřadnice nastavil uživatel nebo byly spočítány ze středu obce.')
            .openapi({
                example: true,
            }),
        kfme: z
            .string()
            .trim()
            .regex(/^\d\d\d\d[abcd]?[abcd]?$/)
            .nullish()
            .describe('Síťové pole Kartierung der Flora Mitteleuropas (KFME).')
            .openapi({
                example: '5952ad',
            }),
        eea10km: z
            .string()
            .trim()
            .regex(/^10kmE\d\d\dN\d\d\d$/)
            .nullish()
            .describe(
                'Síťové pole používané EEA (Evropská agentura pro životní prostředí). 10 km síť vycházející ze souřadnicového systému EPSG:3035 (ETRS89-LAEA).',
            )
            .openapi({
                example: '10kmE463N300',
            }),
        territorialUnitId: schema_territorialUnitId,
    }),
);

export const schema_mapSquareCodeKfme = openAPIRegistry.register(
    'schema_mapSquareCodeKfme',
    z
        .string()
        .trim()
        .regex(/^\d\d\d\d[abcd]?[abcd]?$/)
        .nullish()
        .describe('Kód mapového čtverce - Síťové pole Kartierung der Flora Mitteleuropas (KFME).')
        .openapi({
            example: '5952ad',
        }),
);

export const schema_mapSquareCodeEea = openAPIRegistry.register(
    'schema_mapSquareCodeEea',
    z
        .string()
        .trim()
        .regex(/^(10kmE\d\d\dN\d\d\d)|(50kmE\d{1,4}N\d{1,4})|(100kmE\d\dN\d\d)$/)
        .nullish()
        .describe('Kód mapového čtverce - Síťové pole používané EEA (Evropská agentura pro životní prostředí).')
        .openapi({
            example: '10kmE445N302',
        }),

    // Prozatim vynechano: (1kmE\d\d\d\dN\d\d\d\d)|
);

export const schema_taxon = openAPIRegistry.register(
    'schema_taxon',
    z.object({
        id: z.number().int().min(0).max(99999).openapi({ example: 311 }),
        classId: z.number().int().min(0).max(99999).nullish().openapi({ example: 254 }),
        orderId: z.number().int().min(0).max(99999).nullish().openapi({ example: 293 }),
        familyId: z.number().int().min(0).max(99999).nullish().openapi({ example: 309 }),
        genusId: z.number().int().min(0).max(99999).nullish().openapi({ example: 310 }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'čáp černý' }),
            la: z.string().trim().nullable().openapi({ example: 'Ciconia nigra' }),
            en: z.string().trim().nullable().openapi({ example: 'Black Stork' }),
            // sk: z.string().trim().nullable().openapi({ example: 'bocian čierny' }),
        }),
        treeOrder: z.number().int().min(0).nullish().openapi({ example: 315000 }),
        rarity: z
            .array(
                z.object({
                    territory: schema_territorialUnitId,
                    period: z.array(
                        z.object({
                            from: z
                                .string()
                                .trim()
                                .regex(/^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
                                .describe('Od (mm-dd)')
                                .openapi({
                                    example: '01-01',
                                }),
                            to: z
                                .string()
                                .trim()
                                .regex(/^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
                                .describe('Do (mm-dd)')
                                .openapi({
                                    example: '12-31',
                                }),
                            min: z
                                .number()
                                .int()
                                .gte(1)
                                .describe('Minimální počet, od tohoto čísla je taxon považován za vzácný')
                                .openapi({
                                    example: 1,
                                }),
                            level: z
                                .number()
                                .int()
                                .gte(1)
                                .lte(100)
                                .describe(
                                    "Úroveň vzácnost (obvyklé, zajímavé, neobvyklé, vzácné). Hodnotu 'zajímavé' přiděluje uživatel. Číselné hodnoty: 10 obvyklé, 30 zajímavé, 50 neobvyklé druhem, 70 neobvyklé počtem, 90 vzácné.",
                                )
                                .openapi({
                                    example: 50,
                                }),
                        }),
                    ),
                }),
            )
            .nullable(),
    }),
);

export const schema_rarity_attr = openAPIRegistry.register(
    'schema_rarity_attr',
    z.object({
        level: z.number().int().min(1).max(100).openapi({ example: 90 }),
        label: z.string().trim().openapi({ example: 'rare' }),
        name: z.object({
            cs: z.string().trim().openapi({ example: 'vzácné' }),
            en: z.string().trim().openapi({ example: 'rare' }),
        }),
        category: z
            .enum(enum_sex_categories)
            .array()
            .describe(
                "Kategorie vyznačuje pro jaké použití je položka navržena. 'observationsSearch' - ve vyhledávání, 'listItemInsert' - při vkládání položky do seznamu.",
            )
            .openapi({
                example: ['observationsSearch'],
            }),
    }),
);

export const schema_sex = openAPIRegistry.register(
    'schema_sex',
    z.object({
        id: z.number().int().min(0).max(99999).openapi({ example: 1 }),
        label: z.string().trim().nullable().describe('Odborné označení pohlaví').openapi({ example: 'M' }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'samec' }),
            en: z.string().trim().nullable().openapi({ example: 'male' }),
        }),
        category: z
            .enum(enum_sex_categories)
            .array()
            .describe(
                "Kategorie vyznačuje pro jaké použití je položka navržena. 'observationsSearch' - ve vyhledávání.",
            )
            .openapi({
                example: ['observationsSearch'],
            }),
    }),
);

export const schema_detection = openAPIRegistry.register(
    'schema_detection',
    z.object({
        id: z.number().int().min(1).max(100).openapi({ example: 1 }),
        label: z.string().trim().nullable().describe('Textové označení').openapi({ example: 'visually' }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'Vizuálně' }),
            en: z.string().trim().nullable().openapi({ example: 'Visually' }),
        }),
        category: z
            .enum(enum_detection_categories)
            .array()
            .describe('Kategorie vyznačuje pro jaké použití je položka navržena.')
            .openapi({
                example: ['observationsSearch'],
            }),
    }),
);

export const schema_age = openAPIRegistry.register(
    'schema_age',
    z.object({
        id: z.number().int().min(0).max(99999).openapi({ example: 320 }),
        parents: z.number().int().min(0).max(99999).array().describe('Nadřazené úrovně').openapi({ example: [] }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'ad. v p.š.' }),
            en: z.string().trim().nullable().openapi({ example: 'ad. v p.š.' }),
        }),
        hint: z
            .object({
                cs: z.string().trim().nullable().openapi({
                    example:
                        'Pozorování zpívajícího samce (samců) nebo zaslechnutí hlasových projevů souvisejících s hnízděním v hnízdním období.',
                }),
                en: z.string().trim().nullable().openapi({
                    example:
                        'Pozorování zpívajícího samce (samců) nebo zaslechnutí hlasových projevů souvisejících s hnízděním v hnízdním období.',
                }),
            })
            .describe('Vysvětlení zadávaného výrazu'),
        expression: z
            .object({
                cs: z.string().trim().nullable().openapi({ example: 'ad. {sex} v p.š.' }),
                en: z.string().trim().nullable().openapi({ example: 'ad. {sex} v p.š.' }),
            })
            .describe("Výraz pro zobrazení v tabulce, kdy {sex} nahradí uloženým pohlavím, např. 'ad. M v p.š.'"),
    }),
);

export const schema_tags = openAPIRegistry.register(
    'schema_tags',
    z.object({
        label: z.string().trim().openapi({ example: 'my' }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'Moje pozorování' }),
            en: z.string().trim().nullable().openapi({ example: 'My observations' }),
        }),
        category: z
            .enum(enum_tags_categories)
            .array()
            .describe(
                "Kategorie vyznačuje pro jaké použití je položka navržena. 'observationsSearch' - ve vyhledávání, 'observationsSearchLoggedIn' - ve vyhledávání přihlášeným uživatelem, 'listItemInsert' - při vkládání položky do seznamu.",
            )
            .openapi({
                example: ['observationsSearch'],
            }),
    }),
);

export const schema_secrecy = openAPIRegistry.register(
    'schema_secrecy',
    z.object({
        level: z.number().int().min(0).max(100).openapi({ example: 0 }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'bez utajení lokality' }),
            en: z.string().trim().nullable().openapi({ example: 'location displayed' }),
        }),
        category: z
            .enum(enum_secrecy_categories)
            .array()
            .describe(
                "Kategorie vyznačuje pro jaké použití je položka navržena. 'listInsert' - vytváření nového seznamu.",
            )
            .openapi({
                example: ['listInsert'],
            }),
    }),
);

export const schema_activity = openAPIRegistry.register(
    'schema_activity',
    z.object({
        id: z.number().int().min(0).max(99999).openapi({ example: 2 }),
        parents: z
            .number()
            .int()
            .min(0)
            .max(99999)
            .array()
            .describe('Nadřazené úrovně')
            .openapi({ example: [20] }),
        name: z.object({
            cs: z.string().trim().nullable().openapi({ example: 'A2 zpěv' }),
            en: z.string().trim().nullable().openapi({ example: 'A2 zpěv' }),
        }),
        hint: z
            .object({
                cs: z.string().trim().nullable().openapi({
                    example:
                        'Pozorování zpívajícího samce (samců) nebo zaslechnutí hlasových projevů souvisejících s hnízděním v hnízdním období.',
                }),
                en: z.string().trim().nullable().openapi({
                    example:
                        'Pozorování zpívajícího samce (samců) nebo zaslechnutí hlasových projevů souvisejících s hnízděním v hnízdním období.',
                }),
            })
            .describe('Vysvětlení zadávaného výrazu'),
        category: z
            .enum(enum_activity_categories)
            .array()
            .describe(
                "Kategorie vyznačuje pro jaké použití je položka navržena. 'observationsSearch' - ve vyhledávání, 'listItemInsert' - při vkládání položky do seznamu.",
            )
            .openapi({
                example: ['observationsSearch'],
            }),
    }),
);

export const schema_mediaId = openAPIRegistry.register(
    'schema_mediaId',
    z.string().trim().uuid().describe('Identifikátor přílohy, uuid.').openapi({
        example: 'c26f61d6-b724-4ffc-ad3d-5b4fbe739787',
    }),
);

export const schema_media = openAPIRegistry.register(
    'schema_media',
    z.object({
        type: z.enum(['photo']).openapi({
            example: 'photo',
        }),
        id: schema_mediaId,
        mimeType: z.enum(['image/jpeg']).openapi({
            example: 'image/jpeg',
        }),
        url: z.string().trim().url().openapi({
            example: 'https://data.cso.cz/2023/p/c26f61d6-b724-4ffc-ad3d-5b4fbe739787.jpg',
        }),
        thumbnail: z.string().trim().url().openapi({
            example: 'https://data.cso.cz/2023/p/c26f61d6-b724-4ffc-ad3d-5b4fbe739787-t.jpg',
        }),
        note: z.string().trim().nullable().openapi({
            example: 'Pohled na hejno od hráze.',
        }),
        exif: z.string().trim().nullable().openapi({
            example:
                '{"CameraMake":"Canon","CameraModel":"D90","LensMake":"Sigma","LensModel":"150-600 mm f/5,0-6,3 DG OS HSM C","ISO":"3000","F":"10","Time":"1/1000"}',
        }),
    }),

    // Chybne se zobrazuje v docs
    // schema_mediaSimple.extend({
    //     note: z.string().trim().nullable().openapi({
    //         example: "Pohled na hejno od hráze.",
    //     }),
    //     exif: z.string().trim().nullable().openapi({
    //         example: "{\"CameraMake\":\"Canon\",\"CameraModel\":\"D90\",\"LensMake\":\"Sigma\",\"LensModel\":\"150-600 mm f/5,0-6,3 DG OS HSM C\",\"ISO\":\"3000\",\"F\":\"10\",\"Time\":\"1/1000\"}",
    //     }),
    // })
);

export const schema_rarity = openAPIRegistry.register(
    'schema_rarity',
    z.enum(enum_rarity).describe('Atribut pozorování - běžné / zajímavé / neobvyklé / raritní').openapi({
        example: 'remarkable',
    }),
);

export const schema_count = openAPIRegistry.register(
    'schema_count',
    z
        .string()
        .trim()
        .regex(
            /^(?:([0-9]{1,7})|((?:cca) ?[0-9]{1,7})|((?:(?:min\.?)|>) ?[0-9]{1,7})|((?:(?:max\.?)|<) ?[0-9]{1,7})|([0-9]{1,7} ?[-–] ?[0-9]{1,7}))$/,
            { message: 'errors.item.count.invalid' },
        )
        .describe("Možné hodnoty: '35', 'cca 40', 'min 12', 'max 11', '>5', '<10', '12-25'")
        .openapi({
            example: '35',
        }),
);

export const schema_ageId = openAPIRegistry.register(
    'schema_ageId',
    z.number().int().describe('ID věku').openapi({
        example: 300,
    }),
);

export const schema_sexId = openAPIRegistry.register(
    'schema_sexId',
    z.number().int().describe('ID pohlaví').openapi({
        example: 2,
    }),
);

export const schema_listItemId = openAPIRegistry.register(
    'schema_listItemId',
    z.coerce
        .number()
        .int()
        .gte(1)
        .lte(999999999)
        .describe('Identifikátor položky seznamu. Celé číslo 1 - 999999999.')
        .openapi({
            example: 10038403,
        }),
);

export const schema_userPublicId = openAPIRegistry.register(
    'schema_userPublicId',
    z.string().trim().uuid().describe('Identifikátor uživatele/pozorovatele, ve formátu UUID.').openapi({
        example: 'b783552c-53b0-4ae4-a78f-8b8e3fef7984',
    }),
);

export const schema_observer = openAPIRegistry.register(
    'schema_observer',
    z.object({
        publicId: schema_userPublicId,
        name: z.string().trim().nullish().describe('Jméno pozorovatele.').openapi({
            example: 'Mirek Dušín',
        }),
        anonymous: z
            .boolean()
            .default(false)
            .describe('Ukazatel, zda bylo jméno uživatele a veřejné ID skryto na základě jeho nastavení.')
            .openapi({
                example: false,
            }),
        // rights: z.enum(["owner", "edit", "coobserver"]).openapi({
        //     example: "owner",
        //     description: "Práva uživatele k listu - owner (měnit vše, sdílet, smazat list), edit (přidávat na list, upravovat), coobserver (jen sebe vymazat ze sdílení listu)",
        // }),
    }),
);

export const schema_observation_map = openAPIRegistry.register(
    'schema_observation_map',
    z
        .object({
            id: z.string().trim().length(6).openapi({ example: '4562aa' }),
            intensity: z.number().int().gte(0).lte(100).openapi({ example: 50 }),
            //text: z.string().trim().openapi({ example: "4562aa\n64 pozorování" }),
            count: z.number().int().gte(0).lte(9999999).openapi({ example: 141 }),
            level: z.string().trim().length(1).openapi({ example: 'A' }),
        })
        .describe('Schéma pro výpis hodnot na jednom čtverci.'),
);

export const schema_monthAndDay = openAPIRegistry.register(
    'schema_monthAndDay',
    z
        .string()
        .trim()
        .regex(/^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
        .openapi({ example: '12-31' }),
);

export const schema_year = openAPIRegistry.register(
    'schema_year',
    z
        .string()
        .trim()
        .regex(/^([12]\d\d\d)$/)
        .openapi({ example: '2022' }),
);

export const schema_date = openAPIRegistry.register(
    'schema_date',
    z
        .string()
        .trim()
        .regex(/^(\d\d\d\d)-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
        .openapi({ example: '2022-12-10' }),
);

export const schema_datesRange = openAPIRegistry.register(
    'schema_datesRange',
    schema_date
        .array()
        .length(2)
        .openapi({ example: ['2022-12-10', '2023-01-10'] }),
);

export const schema_user = openAPIRegistry.register(
    'schema_user',
    z.object({
        publicId: schema_userPublicId,
        name: z.string().trim().nullish().describe('Jméno uživatele.').openapi({
            example: 'Mirek Dušín',
        }),
        anonymous: z
            .boolean()
            .default(false)
            .describe('Ukazatel, zda bylo jméno uživatele a veřejné ID skryto na základě jeho nastavení.')
            .openapi({
                example: false,
            }),
        messagingAllowed: z.boolean().describe('Ukazatel, zda je možné uživateli poslat zprávu.').openapi({
            example: true,
        }),
        bio: z.string().trim().nullish().openapi({
            example:
                'Tincidunt bibendum diam neque quis eros. Duis placerat sodales dapibus. Pellentesque id purus a felis volutpat tincidunt et eget augue. Nulla porta malesuada massa, id congue nisl tincidunt a. Phasellus tempor urna pharetra risus dapibus, et suscipit urna elementum. Phasellus ornare orci nec lorem condimentum ultricies. Duis aliquam ligula ut mi rutrum varius. Donec semper velit ac enim convallis consectetur.',
        }),
        registrationDate: z.coerce.date().describe('Datum registrace.').openapi({
            example: '2019-01-21',
        }),
    }),
);

export const schema_userWithStatistics = openAPIRegistry.register(
    'schema_userWithStatistics',
    schema_user.extend({
        statistics: z.object({
            years: z
                .object({
                    year: schema_year.describe('Rok').openapi({
                        example: '2021',
                    }),
                    speciesCount: z.number().int().describe('Celkový počet pozorovaných druhů').openapi({
                        example: 135,
                    }),
                    listCount: z.number().int().describe('Celkový počet seznamů').openapi({
                        example: 2516,
                    }),
                    listCompleteCount: z.number().int().describe('Celkový počet kompletních seznamů').openapi({
                        example: 1535,
                    }),
                    listItemCount: z.number().int().describe('Celkový počet pozorovaní').openapi({
                        example: 21520,
                    }),
                })
                .array(),
            yearsSummary: z
                .object({
                    speciesCount: z.number().int().describe('Celkový počet pozorovaných druhů').openapi({
                        example: 135,
                    }),
                    listCount: z.number().int().describe('Celkový počet seznamů').openapi({
                        example: 2516,
                    }),
                    listCompleteCount: z.number().int().describe('Celkový počet kompletních seznamů').openapi({
                        example: 1535,
                    }),
                    listItemCount: z.number().int().describe('Celkový počet pozorovaní').openapi({
                        example: 21520,
                    }),
                })
                .describe('Celkový souhrn pozorování v letech'),
            species: z
                .object({
                    date: z.coerce.date(),
                    taxonId: z.number().int().min(0).max(99999).openapi({ example: 311 }),
                    listItemId: schema_listItemId.nullish(),
                    territorialUnitId: schema_territorialUnitId.nullish(),
                    secrecyLevel: z.number().int().min(0).max(100),
                })
                .describe('Druhy pozorované uživatelem, datum prvního pozorování a lokalita')
                .array(),
            districts: z
                .object({
                    territorialUnitId: schema_territorialUnitId,
                    intensity: z.number().int().min(0).max(100).openapi({ example: 50 }),
                })
                .describe(
                    'Okresy, ve kterých uživatel má zadaná pozorování. Intenzita (0-100 %) vyjadřuje aktivitu v daných okresech',
                )
                .array(),
        }),
        lastMedia: schema_media
            .extend({
                listItemId: schema_listItemId,
            })
            .describe(
                'Posledních n příloh uložených uživatelem. Položka je rozšířena o id položky seznamu, aby bylo možné se z fotky dostat na pozorování',
            )
            .array(),
    }),
);

export const schema_filterParameters = openAPIRegistry.register(
    'schema_filterParameters',
    z
        .object({
            taxonId: z
                .number()
                .int()
                .min(0)
                .max(99999)
                .array()
                .min(1)
                .nullish()
                .openapi({ example: [311] }),
            count: schema_count.nullish(),
            activity: z
                .number()
                .int()
                .min(0)
                .max(9999)
                .array()
                .min(1)
                .nullish()
                .openapi({ example: [13] }),
            age: z
                .number()
                .int()
                .min(0)
                .max(9999)
                .array()
                .min(1)
                .nullish()
                .openapi({ example: [300] }),
            sex: z
                .number()
                .int()
                .min(0)
                .max(9999)
                .array()
                .min(1)
                .nullish()
                .openapi({ example: [1, 2] }),
            detection: z
                .number()
                .int()
                .min(1)
                .max(99)
                .array()
                .min(1)
                .nullish()
                .openapi({ example: [1, 2] }),
            note: z.string().trim().max(64).nullish().openapi({ example: 'leucismus' }),
            observer: z.string().trim().nullish(),
            tags: z
                .string()
                .trim()
                .array()
                .min(1)
                .nullish()
                .describe('Vyhledávání na základě tagů')
                .openapi({ example: ['my', 'ringMark'] }),
            ringMark: z.string().trim().nullish().openapi({ example: '6411' }),
            minRarityLevel: z
                .number()
                .int()
                .min(0)
                .max(100)
                .nullish()
                .describe('Minimální hodnota vzácnosti [0,100], viz /codebook/attributes a pole rarity')
                .openapi({ example: 30 }),
            // rarity: schema_rarity.array().nullish().openapi({ example: ["rare"] }),
            projects: z
                .array(z.number().int())
                .min(1)
                .nullish()
                .openapi({ example: [3] }),
            territorialUnitId: schema_territorialUnitId.array().nullish(),
            siteName: z.string().trim().nullish().openapi({ example: 'Vrbenské rybníky' }),
            siteId: z.string().trim().uuid().nullish(),
            coordinatesWithRadius: schema_coordinatesWithRadius.nullish(),
            areas: schema_areaId.array().nullish().describe('Seznam oblastí, ve kterých se má vyhledávat'),
            mapSquares: z
                .union([schema_mapSquareCodeKfme, schema_mapSquareCodeEea])
                .array()
                .nullish()
                .describe('Vyhledávání ve čtvercích mapovacích sítí KFME a EEA')
                .openapi({
                    example: ['7165ab', '10kmE470N301'],
                }),
            dateNDays: z
                .number()
                .int('errors.integer.invalid')
                .min(1, 'errors.list.date.relative.min')
                .max(9999, 'errors.list.date.relative.max')
                .nullish()
                .describe('Vyhledávání za posledních n dní')
                .openapi({
                    example: 90,
                }),
            dateRange: z
                .object({
                    start: schema_date.nullish(),
                    end: schema_date.nullish(),
                })
                .nullish()
                .describe('Vyhledávání v rozsahu datumů. Počáteční nebo koncový datum nemusí být zadán.')
                .openapi({
                    example: {
                        end: '2022-12-31',
                    },
                }),
            dateSeason: z
                .object({
                    start: schema_monthAndDay,
                    end: schema_monthAndDay,
                    yearStart: schema_year.nullish(),
                    yearEnd: schema_year.nullish(),
                })
                .nullish()
                .describe('Vyhledávání v rozsahu datumů a sezón. Počáteční nebo koncový rok nemusí být zadán.')
                .openapi({
                    example: {
                        start: '05-01',
                        end: '10-01',
                        yearEnd: '2002',
                    },
                }),

            // monthAndDayRange: schema_monthAndDay.array().length(2).nullish().openapi({ example: ["12-10", "01-15"], description: "Vyhledávání v rozsahu dne ve formátu [MM-DD, MM-DD]" }),
            // datesRange: schema_datesRange.array().nullish().openapi({ example: [["2022-12-10", "2023-01-15"], ["2021-12-10", "2022-01-15"]], description: "Vyhledávání mezi dvěma daty" }),
        })
        .describe('Schéma pro parametry filtru, ať už uloženého, tak pro součást dotazu'),
);

export const schema_filter = openAPIRegistry.register(
    'schema_filter',
    z.object({
        id: z.string().trim().openapi({ example: 'rarity.uncommon' }),
        type: z.enum(['predefined', 'userDefined']).openapi({
            example: 'predefined',
        }),
        name: z.object({
            cs: z.string().trim().openapi({ example: 'Neobvyklá pozorování' }),
            en: z.string().trim().openapi({ example: 'Uncommon observations' }),
        }),
        parameters: schema_filterParameters,
    }),
);

export const schema_listPublicId = openAPIRegistry.register(
    'schema_listPublicId',
    z
        .string()
        .trim()
        .regex(/^[a-zA-Z0-9]{6}$/)
        .describe('Identifikátor seznamu. 6 náhodných znaků - malá a velká písmena, čísla.')
        .openapi({
            example: '7a7B81',
        }),
);

export const schema_commentId = openAPIRegistry.register(
    'schema_commentId',
    z.coerce
        .number()
        .int()
        .gte(1)
        .lte(999999999)
        .describe('Identifikátor komentáře položky seznamu. Celé číslo 1 - 999999999.')
        .openapi({
            example: 5645664,
        }),
);

export const schema_comment = openAPIRegistry.register(
    'schema_comment',
    z.object({
        id: schema_commentId,
        parentId: schema_commentId.nullish().describe('Číselné ID nadřazeného komentáře').openapi({
            example: 64525,
        }),
        rights: z
            .enum(enum_comment_rights)
            .array()
            .default(['view'])
            .describe('Volba práv u komentáře')
            .openapi({
                example: ['view'],
            }),
        date: z.coerce.date(),
        updated: z.coerce.date().nullish().describe('Datum poslední úpravy komentáře'),
        deleted: z.coerce.date().nullish().describe('Datum smazání komentáře, subject je null, text je prázný'),
        author: z.object({
            publicId: schema_userPublicId,
            name: z.string().trim().describe('Jméno autora komentáře').openapi({
                example: 'Mirek Dušín',
            }),
        }),
        subject: z.string().trim().nullish(),
        text: z.string(),
    }),
);

export const schema_projectDataAnswer = openAPIRegistry.register(
    'schema_projectDataAnswer',
    z.object({
        questionId: z.string().trim().describe('Textové ID otázky').openapi({ example: 'habitat' }),
        name: z
            .object({
                cs: z.string().trim().openapi({ example: 'Prostředí' }),
                en: z.string().trim().openapi({ example: 'Habitat' }),
            })
            .nullish()
            .describe('Otázka'),
        value: z
            .any()
            .describe('Zvolená odpověď z možností nebo textová odpověď (pro editaci)')
            .openapi({ example: '1' }),
        answer: z
            .object({
                cs: z.string().trim().openapi({ example: 'řepka' }),
                en: z.string().trim().openapi({ example: 'řepka' }),
            })
            .nullish()
            .describe('Odpověď (pro zobrazení)'),
    }),
);

export const schema_projectData = openAPIRegistry.register(
    'schema_projectData',
    z.object({
        projectId: z
            .number()
            .int()
            .min(1)
            .max(9999)
            .describe('ID projektu z číselníku (projects)')
            .openapi({ example: 1 }),
        name: z
            .object({
                cs: z.string().trim().openapi({ example: 'Čejka' }),
                en: z.string().trim().openapi({ example: 'Čejka (Lapwing)' }),
            })
            .nullish(),
        data: schema_projectDataAnswer.array(),
    }),
);

export const schema_time = openAPIRegistry.register(
    'schema_time',
    z
        .string()
        .trim()
        .regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/)
        .describe('Čas')
        .openapi({
            example: '10:30',
        }),
);

export const schema_listItem = openAPIRegistry.register(
    'schema_listItem',
    z.object({
        id: z.number().int(),
        taxonId: z.number({ required_error: 'errors.item.taxonId.required' }).int().describe('ID druhu').openapi({
            example: 311,
        }),
        group: z.number().int().min(1).max(999).default(1).nullish().describe('Číslo skupiny').openapi({
            example: 1,
        }),
        time: schema_time.nullish(),
        coordinates: schema_coordinates.nullish().describe('Souřadnice objektu'),
        observersCoordinates: schema_coordinates.nullish().describe('Souřadnice pozorovatele'),
        accuracy: z.number().gte(0).lte(99999).nullish().describe('Přesnost souřadnic v metrech'),
        count: schema_count.nullish(),
        ageId: schema_ageId.nullish(),
        sexId: schema_sexId.nullish(),
        activityId: z.number().int().nullish().describe('ID aktivity').openapi({
            example: 2,
        }),
        rarity: schema_rarity,
        detectionId: z
            .number()
            .int()
            .min(1)
            .max(255)
            .nullish()
            .describe('Způsob detekce z číselníku (attributes/detection)')
            .openapi({ example: 1 }),
        ringMark: z.string().trim().max(512).nullish().describe('Kroužek, značka, límec').openapi({
            example: 'Z313',
        }),
        note: z.string().trim().nullish().describe('Poznámka k pozorování').openapi({
            example: 'Poraněné křídlo',
        }),
        tags: z
            .enum(enum_listItem_tags)
            .array()
            .nullish()
            .describe('Tagy z číselníku, z kategorie listItemInsert')
            .openapi({
                example: ['remarkable', 'adviceRequested'],
            }),
        media: z.array(schema_media).max(3),
        commentsCount: z
            .number()
            .int()
            .min(0)
            .max(999)
            .nullish()
            .describe('Počet komentářů u položky')
            .openapi({ example: 0 }),
        lastCommented: z.coerce
            .date()
            .min(new Date('1900-01-01'))
            .nullish()
            .describe('Datum posledního komentáře')
            .openapi({
                example: '2023-03-21T09:31:45.000Z',
            }),
        comments: schema_comment.array().nullish(),
        commentsLocked: z
            .boolean()
            .nullish()
            .describe('Uzamčení komentářů u položky, komentáře pak nelze přidávat, upravovat ani mazat')
            .openapi({
                example: false,
            }),
        projects: z
            .number()
            .int()
            .array()
            .nullish()
            .describe('Výčet projektů')
            .openapi({
                example: [1],
            }),
        projectsString: z.string().trim().nullish().describe('Výčet projektů jako String').openapi({
            example: 'LSD',
        }),
        projectsData: schema_projectData
            .array()
            .nullish()
            .describe(
                'Data uložená k projektům na úrovni položky seznamu. Může se jednat i o neaktivní projekty, proto vazba ID nemusí být v číselníku projektů.',
            ),
    }),
);

export const schema_listItem_input = openAPIRegistry.register(
    'schema_listItem_input',
    schema_listItem
        .omit({
            id: true,
            media: true,
            rarity: true,
            comments: true,
            lastCommented: true,
            commentsCount: true,
            commentsLocked: true,
            projects: true,
            projectsString: true,
        })
        .extend({
            media: schema_mediaId
                .array()
                .nullish()
                .describe(
                    'UUID uložených fotek (videí, zvuků). Pořadí v poli určuje pořadí pro uložení a následné vypsání',
                )
                .openapi({
                    example: ['bc95dafc-44d7-48c4-9437-b2496d78f8ba', '92e7316e-fdb9-4b1c-8732-33a40dd15f08'],
                }),
        }),
);

export const schema_listMetadata = openAPIRegistry.register(
    'schema_listMetadata',
    z.object({
        id: z.number().int(),
        publicId: schema_listPublicId,
        created: z.coerce.date().describe('Datum vytvoření').openapi({
            example: '2023-12-31',
        }),
        createdBy: z.number().int().describe('ID uživatele'),
        updated: z.coerce.date().nullish().describe('Datum úprav').openapi({
            example: '2023-12-31',
        }),
        updatedBy: z.number().int().nullish().describe('ID uživatele'),
        lockedSince: z.coerce.date().describe('Datum, od kdy je seznam uzamčen a není možné ho editovat').openapi({
            example: '2023-12-31',
        }),
        author: z.number().int().describe('ID autora'),
        date: z.coerce.date().describe('Datum pozorování').openapi({
            example: '2023-12-31',
        }),
        listType: z.string().nullish().describe('Typ seznamu'),
    }),
);

export const schema_commentMetadata = openAPIRegistry.register(
    'schema_commentMetadata',
    z.object({
        listId: z.number().int(),
        listPublicId: schema_listPublicId,
        listItemId: schema_listItemId,
        commentsLocked: z
            .boolean()
            .describe('Uzamčení komentářů u položky, komentáře pak nelze přidávat, upravovat ani mazat')
            .openapi({
                example: false,
            }),
        responseToId: z.number().int().nullish().describe('ID komentáře, na který je odpovědí'),
        created: z.coerce.date().describe('Datum vytvoření').openapi({
            example: '2023-12-31',
        }),
        createdBy: z.number().int().describe('ID uživatele'),
        updated: z.coerce.date().nullish().describe('Datum úprav').openapi({
            example: '2023-12-31',
        }),
        updatedBy: z.number().int().nullish().describe('ID uživatele'),
        deleted: z.coerce.date().nullish().describe('Datum smazání').openapi({
            example: '2023-12-31',
        }),
        deletedBy: z.number().int().nullish().describe('ID uživatele'),
    }),
);

export const schema_list = openAPIRegistry.register(
    'schema_list',
    z.object({
        publicId: schema_listPublicId,
        type: z.enum(enum_list_type).default('partial').describe('Typ seznamu').openapi({
            example: 'partial',
        }),
        selectedSpecies: z
            .number()
            .int()
            .array()
            .nullish()
            .describe("Pouze pro typ 'selected' - Seznam ID druhů, pro které je seznam kompletní")
            .openapi({
                example: [826, 827, 828],
            }),
        observationMode: z.enum(enum_list_observationMode).default('area').describe('Typ seznamu').openapi({
            example: 'area',
        }),
        role: z.enum(enum_list_role).default('visitor').describe('Volba práv u seznamu').openapi({
            example: 'visitor',
        }),
        rights: z
            .enum(enum_list_rights)
            .array()
            .default(['view'])
            .describe('Volba práv u seznamu')
            .openapi({
                example: ['view'],
            }),
        passwordSharing: z
            .string()
            .trim()
            .length(16)
            .nullish()
            .describe(
                'Heslo pro sdílení seznamu s přáteli. Všichni, kteří mají toto heslo se mohou přidat jako spolupozorovatelé, ale nemohou seznam editovat.',
            )
            .openapi({
                example: '490f0d25-a0f4-49f0-937e-b6bfa6a5a5f2',
            }),
        lockedSince: z.coerce
            .date()
            .nullish()
            .describe('Datum, od kdy je seznam uzamčen a není možné ho editovat.')
            .openapi({
                example: '2023-12-31',
            }),
        // date: z.string().trim().pipe(z.coerce.date().min(new Date("1900-01-01")).max(new Date()))
        //  .describe("Datum")
        //  .openapi({
        //      example: "2023-03-21",
        //  }),
        // date: z.coerce.date().min(new Date("1900-01-01")).max(new Date())
        //  .describe("Datum")
        //  .openapi({
        //      example: "2023-03-21",
        //  }),
        date: z
            .union([z.string(), z.date()], {
                errorMap: (error, ctx) => {
                    return {
                        message:
                            error.code === 'invalid_union'
                                ? 'errors.list.date.required'
                                : error.message ?? 'Invalid value',
                    };
                },
            })
            .pipe(z.coerce.date().min(new Date('1900-01-01'), { message: 'errors.list.date.min' }))
            // .union([z.number(), z.string(), z.date()])
            // .refine((val) => val >= new Date(), {
            //  message: "dateFromFuture"
            // })
            .describe('Datum')
            .openapi({
                example: '2023-03-21',
            }),
        timeStart: schema_time.describe('Čas od').nullish(),
        timeEnd: schema_time.describe('Čas do').nullish(),
        // visibility: z.enum(["visible", "district", "region", "hidden"]).default("visible").openapi({
        //     example: "visible",
        //     description: "Volba viditelnosti seznamu: viditelné / jen okres / jen kraj / skryté",
        // }),
        secrecyLevel: z
            .number()
            .int()
            .min(0)
            .max(100)
            .default(0)
            .nullish()
            .describe(
                'Volba viditelnosti seznamu (viditelné / jen okres / jen kraj / skryté), číselné hodnoty v /codebook/attributes',
            )
            .openapi({
                example: 0,
            }),
        secretUntil: z.coerce
            .date()
            .nullish()
            .describe('Datum, do kdy je seznam skrýtý. Úroveň utajení určuje volba secrecyLevel')
            .openapi({
                example: '2035-12-31',
            }),
        hideObservers: z.coerce
            .boolean()
            .default(false)
            .describe('Skrýt všechny pozorovatele. U seznamu bude zobrazeno, že pozorovatel je anonymní.')
            .openapi({
                example: false,
            }),
        projects: z
            .number()
            .int()
            .array()
            .nullish()
            .describe('Výčet projektů')
            .openapi({
                example: [1],
            }),
        projectsString: z.string().trim().nullish().describe('Výčet projektů jako String').openapi({
            example: 'LSD',
        }),
        projectsData: schema_projectData
            .array()
            .nullish()
            .describe(
                'Data uložená k projektům na úrovni seznamu. Může se jednat i o neaktivní projekty, proto vazba ID nemusí být v číselníku projektů.',
            ),
        location: schema_location,
        track: schema_track.nullish(),
        plannedTrack: schema_track.nullish(),
        observers: z.array(schema_observer).nullish(),
        observersNumber: z
            .number()
            .int('errors.integer.invalid')
            .min(1, 'errors.list.observersNumber.min')
            .max(100, 'errors.list.observersNumber.max')
            .default(1)
            .nullish()
            .describe(
                'Počet pozorovatelů uvedený autorem seznamu. Nemusí se jednat o všechny uvedené jmenovitě, ale počet by měl vyjadřovat kolik lidí se pozorování účastnilo.',
            )
            .openapi({
                example: 5,
            }),
        observersText: z
            .string()
            .trim()
            .max(512)
            .nullish()
            .describe(
                'Textová část seznamu pozorovatelů. Slouží pro doplnění dalších pozorovatelů bez vazby na uživatele.',
            )
            .openapi({
                example: 'účastníci festivalu Vítání ptačího jara',
            }),
        distanceCovered: z
            .number()
            .int('errors.integer.invalid')
            .min(0)
            .max(50000, { message: 'errors.list.distanceCovered.tooBig' })
            .nullish()
            .describe('Přibližná ušlá vzdálenost v metrech.')
            .openapi({
                example: 1500,
            }),
        note: z.string().trim().nullish().describe('Souhrnná poznámka k seznamu.').openapi({
            example: 'Vycházka v rámci akce Vítání ptačího jara',
        }),
        siteName: z.string().trim().max(128).nullish().describe('Název lokality').openapi({
            example: 'PP Luční rybník',
        }),
        siteNote: z.string().trim().nullish().describe('Poznámka k lokalitě.').openapi({
            example: 'Rybník Luční čerstvě vypuštěn, v lese probíhá těžba.',
        }),
        siteId: z.string().trim().uuid().nullish().describe('ID lokality'),
        speciesCount: z.number().int().min(0).max(9999).describe('Počet pozorovaných druhů'),
        individualsCount: z.number().int().min(0).max(99999999).describe('Počet pozorovaných jedinců'),
        individualsCountString: z.string().trim().describe('Počet pozorovaných jedinců, textová forma'),
        photosCount: z.number().int().min(0).max(9999).describe('Počet fotografií u všech položek ze seznamu'),
        items: schema_listItem.array(),
    }),
);

export const schema_listWithoutItems = openAPIRegistry.register(
    'schema_listWithoutItems',
    schema_list
        .omit({
            track: true,
            plannedTrack: true,
            items: true,
        })
        .extend({
            observersString: z.string().trim().nullish().describe('Všichni pozorovatelé jako String'),
            media: z.array(schema_media),
        }),
);

export const schema_list_input = openAPIRegistry.register(
    'schema_list_input',
    schema_list
        .omit({
            publicId: true,
            passwordSharing: true,
            observers: true,
            items: true,
            role: true,
            rights: true,
            lockedSince: true,
            location: true,
            speciesCount: true,
            individualsCount: true,
            individualsCountString: true,
            photosCount: true,
            projects: true,
            projectsString: true,
        })
        .extend({
            municipalityPartId: z
                .number({
                    required_error: 'errors.list.municipalityPartId.required',
                    invalid_type_error: 'errors.list.municipalityPartId.required',
                })
                .int()
                .describe('Kód obce')
                .openapi({
                    example: 6400301,
                }),
            coordinates: schema_coordinates.nullish(),
            items: schema_listItem_input.array(),
            source: z.string().trim().max(16).nullish().describe('Název aplikace').openapi({
                example: 'web',
            }),
            sourceVersion: z.string().trim().max(16).nullish().describe('Verze aplikace').openapi({
                example: '1.1.6',
            }),
        }),
);

export const schema_observation = openAPIRegistry.register(
    'schema_observation',
    z
        .object({
            listPublicId: schema_listPublicId.nullish(),
            role: z.enum(enum_list_role).default('visitor').describe('Volba práv u seznamu').openapi({
                example: 'visitor',
            }),
            rights: z
                .enum(enum_list_rights)
                .array()
                .default(['view'])
                .describe('Volba práv u seznamu')
                .openapi({
                    example: ['view'],
                }),
            itemId: schema_listItemId.nullish(),
            rarity: schema_rarity.nullish(),
            date: z.coerce
                .date()
                .min(new Date('1900-01-01'))
                .nullish() /*.max(new Date())*/
                .describe('Datum')
                .openapi({
                    example: '2023-03-21',
                }),
            time: schema_time.nullish(),
            taxonId: z.number().int().min(0).max(99999).openapi({ example: 311 }),
            count: schema_count.nullish(),
            ageId: schema_ageId.nullish(),
            sexId: schema_sexId.nullish(),
            secrecyLevel: z
                .number()
                .int()
                .min(0)
                .max(100)
                .default(0)
                .nullish()
                .describe(
                    'Volba viditelnosti seznamu (viditelné / jen okres / jen kraj / skryté), číselné hodnoty v /codebook/attributes',
                )
                .openapi({
                    example: 0,
                }),
            secretUntil: z.coerce
                .date()
                .nullish()
                .describe('Datum, do kdy je seznam skrýtý. Úroveň utajení určuje volba secrecyLevel')
                .openapi({
                    example: '2035-12-31',
                }),
            location: schema_location.nullish(),
            siteName: z.string().trim().max(128).nullish().describe('Název lokality').openapi({
                example: 'PP Luční rybník',
            }),
            projects: z
                .number()
                .int()
                .array()
                .nullish()
                .describe('Výčet projektů')
                .openapi({
                    example: [1],
                }),
            projectsString: z.string().trim().nullish().describe('Výčet projektů jako String').openapi({
                example: 'LSD',
            }),
            observers: z.array(schema_observer).nullish(),
            observersString: z.string().trim().nullish().describe('Všichni pozorovatelé jako String'),
            observersText: z
                .string()
                .trim()
                .nullish()
                .describe(
                    'Textová část seznamu pozorovatelů. Slouží pro doplnění dalších pozorovatelů bez vazby na uživatele.',
                )
                .openapi({
                    example: 'účastníci festivalu Vítání ptačího jara',
                }),
            note: z.string().trim().nullish().openapi({ example: 'Odečten kroužek X54.' }),
            media: z.array(schema_media).max(3).nullish(),
            tags: z
                .enum(enum_listItem_tags)
                .array()
                .nullish()
                .describe('Tagy z číselníku, z kategorie listItemInsert')
                .openapi({
                    example: ['remarkable', 'adviceRequested'],
                }),
            commentsCount: z
                .number()
                .int()
                .min(0)
                .max(999)
                .nullish()
                .describe('Počet komentářů u položky')
                .openapi({ example: 0 }),
            lastCommented: z.coerce
                .date()
                .min(new Date('1900-01-01'))
                .nullish()
                .describe('Datum posledního komentáře')
                .openapi({
                    example: '2023-03-21T09:31:45.000Z',
                }),
        })
        .describe('Schéma pro výpis jednoho pozorování.'),
);

export const schema_project_field = openAPIRegistry.register(
    'schema_project_field',
    z.object({
        questionId: z.string().trim().describe('Textové ID otázky').openapi({
            example: 'habitat',
        }),
        required: z.boolean().describe('Vyžadovaná hodnota'),
        name: z
            .object({
                cs: z.string().openapi({ example: 'Prostředí' }),
                en: z.string().openapi({ example: 'Habitat' }),
            })
            .describe('Otázka')
            .nullish(),
        hint: z
            .object({
                cs: z
                    .string()
                    .openapi({ example: 'Class aptent taciti sociosqu ad litora torquent per conubia nostr.' }),
                en: z
                    .string()
                    .openapi({ example: 'Phasellus hendrerit nisi vel nisl vestibulum sollicitudin a vel augue.' }),
            })
            .nullish(),
        errorMessage: z
            .object({
                cs: z.string().openapi({ example: 'Litora torquent per conubia nostr.' }),
                en: z.string().openapi({ example: 'Vestibulum sollicitudin a vel augue.' }),
            })
            .nullish(),
        type: z
            .enum(['string', 'enum', 'number', 'text'])
            .describe('Datový typ odpovědi')
            .openapi({
                example: 'string',
            })
            .nullish(),
        regex: z.string().describe('Pokud je odpověď zadaná, musí splňovat regex pravidla').nullish(),
        entries: z
            .object({
                value: z.string().trim().describe('Textové ID možnosti (enum)').openapi({
                    example: 'řepka',
                }),
                text: z
                    .object({
                        cs: z.string().trim().nullable().openapi({ example: 'řepka' }),
                        en: z.string().trim().nullable().openapi({ example: 'canola' }),
                    })
                    .describe('Odpověď'),
            })
            .array()
            .nullish()
            .describe("Možné odpovědi v případě typu 'enum'"),
    }),
);

export const schema_project = openAPIRegistry.register(
    'schema_project',
    z
        .object({
            id: z.number().int().min(0).max(999).openapi({ example: 1 }),
            activationSlug: schema_slug
                .nullish()
                .describe('Slug, který slouží k aktivaci projektu. Funguje pro kategorie Insert.'),
            category: z
                .enum(enum_project_categories)
                .array()
                .describe(
                    "Kategorie vyznačuje pro jaké použití je položka navržena. 'listItemInsert' - přidání položky do seznamu.",
                )
                .openapi({
                    example: ['listItemInsert'],
                }),
            taxon: z
                .number()
                .int()
                .min(0)
                .max(99999)
                .array()
                .openapi({ example: [487] }),
            territory: z
                .number()
                .int()
                .array()
                .describe('ID lokalit, pro které je tento projekt aktivní')
                .openapi({
                    example: [203],
                }),
            period: z
                .object({
                    from: z
                        .string()
                        .trim()
                        .regex(/^(\d\d\d\d-)?(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
                        .describe('Od (mm-dd) nebo (yyyy-mm-dd)')
                        .openapi({
                            example: '01-01',
                        }),
                    to: z
                        .string()
                        .trim()
                        .regex(/^(\d\d\d\d-)?(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
                        .describe('Do (mm-dd) nebo (yyyy-mm-dd)')
                        .openapi({
                            example: '12-31',
                        }),
                })
                .array(),
            name: z.object({
                cs: z.string().trim().nullable().openapi({ example: 'Čejka' }),
                en: z.string().trim().nullable().openapi({ example: 'Čejka (Lapwing)' }),
            }),
            description: z.object({
                cs: z
                    .string()
                    .trim()
                    .nullable()
                    .openapi({ example: 'Class aptent taciti sociosqu ad litora torquent per conubia nostr.' }),
                en: z
                    .string()
                    .trim()
                    .nullable()
                    .openapi({ example: 'Phasellus hendrerit nisi vel nisl vestibulum sollicitudin a vel augue.' }),
            }),
            entryQuestion: z
                .object({
                    cs: z.string().trim().nullable().openapi({ example: 'Chcete vložit informace k projektu ...?' }),
                    en: z.string().trim().nullable().openapi({ example: 'Chcete vložit informace k projektu ...?' }),
                })
                .nullish(),
            url: z.string().trim().url().describe('Adresa projektu'),
            itemFields: schema_project_field.array(),
            listFields: schema_project_field.array(),
            customForm: z
                .string()
                .nullish()
                .describe('Specifický formulář pro zadávaní na webu')
                .openapi({ example: 'lsdTransForm' }),
            autoOpenCustomForm: z.boolean().describe('Otevřít formulář po kliknutí').openapi({ example: false }),
        })
        .describe('Schéma pro definici jednoho projektu (pro sběr dat v rámci Avifu'),
);

export const schema_projectInfo = openAPIRegistry.register(
    'schema_projectInfo',
    z
        .object({
            id: z.number().int().min(0).max(999).openapi({ example: 1 }),
            name: z.string().trim().nullable().openapi({ example: 'Čejka' }),
            description: z
                .string()
                .trim()
                .nullable()
                .openapi({ example: 'Class aptent taciti sociosqu ad litora torquent per conubia nostr.' }),
            thumbnail: z.string().trim().url().describe('Adresa náhledového obrázku'),
            photoCopyright: z.string().trim().describe('Copyright k fotce'),
            url: z.string().trim().url().describe('Adresa projektu'),
        })
        .describe('Informace o projektu (pro webové zobrazení)'),
);

export const schema_newsArticle = openAPIRegistry.register(
    'schema_newsArticle',
    z.object({
        id: z.string().trim().describe('ID (slug) článku').openapi({
            example: 'duis-placerat-sodales',
        }),
        thumbnail: z.string().trim().url().describe('Adresa náhledového obrázku'),
        image: z.string().trim().url().describe('Adresa obrázku v hlavičce stránky'),
        title: z.string().trim().openapi({
            example: 'Duis placerat sodales',
        }),
        date: z.coerce.date().openapi({
            example: '2023-02-25',
        }),
        author: z.string().trim().openapi({
            example: 'Bořivoj Vrabec',
        }),
        sample: z.string().trim().nullish().describe('Ukázka textu, bez HTML').openapi({
            example:
                'Tincidunt bibendum diam neque quis eros. Duis placerat sodales dapibus. Pellentesque id purus a felis volutpat tincidunt et eget augue.',
        }),
        content: z.string().trim().nullish().describe('HTML obsah článku').openapi({
            example:
                '<p>Tincidunt bibendum diam neque quis eros. Duis placerat sodales dapibus. Pellentesque id purus a felis volutpat tincidunt et eget augue. Nulla porta malesuada massa, id congue nisl tincidunt a. Phasellus tempor urna pharetra risus dapibus, et suscipit urna elementum. Phasellus ornare orci nec lorem condimentum ultricies. Duis aliquam ligula ut mi rutrum varius. Donec semper velit ac enim convallis consectetur.</p>',
        }),
    }),
);

export const schema_newsArticleSummary = openAPIRegistry.register(
    'schema_newsArticleSummary',
    schema_newsArticle.omit({
        content: true,
        image: true,
    }),
);

export const schema_htmlArticle = openAPIRegistry.register(
    'schema_htmlArticle',
    z.object({
        id: schema_slug,
        title: z.string().trim().nullish().openapi({
            example: 'Pellentesque felis',
        }),
        content: z.string().trim().nullish().openapi({
            example:
                '<p>Tincidunt bibendum diam neque quis eros. Duis placerat sodales dapibus. Pellentesque id purus a felis volutpat tincidunt et eget augue. Nulla porta malesuada massa, id congue nisl tincidunt a. Phasellus tempor urna pharetra risus dapibus, et suscipit urna elementum. Phasellus ornare orci nec lorem condimentum ultricies. Duis aliquam ligula ut mi rutrum varius. Donec semper velit ac enim convallis consectetur.</p>',
        }),
    }),
);

export const schema_sort = openAPIRegistry.register(
    'schema_sort',
    z.object({
        field: z.string().trim().openapi({
            example: 'date',
        }),
        language: z.enum(enum_sort_languages).default('cs').openapi({
            example: 'cs',
        }),
        order: z.enum(enum_sort_orders).default('asc').openapi({
            example: 'asc',
        }),
    }),
);

export const schema_chartData = openAPIRegistry.register(
    'schema_chartData',
    z.object({
        series: z
            .object({
                name: z.object({
                    cs: z.string().trim().nullish().openapi({ example: 'vrabec polní' }),
                    en: z.string().trim().nullish().openapi({ example: 'Eurasian tree sparrow' }),
                    la: z.string().trim().nullish().openapi({ example: 'Passer montanus' }),
                }),
                data: z.union([z.null(), z.number()]).array(),
            })
            .array(),
        xaxis: z.object({
            title: z.object({
                text: z.object({
                    cs: z.string().trim().openapi({ example: 'Měsíc' }),
                    en: z.string().trim().openapi({ example: 'Month' }),
                }),
            }),
            categories: z.string().trim().array(),
        }),
        yaxis: z.object({
            title: z.object({
                text: z.object({
                    cs: z.string().trim().openapi({ example: 'Počet' }),
                    en: z.string().trim().openapi({ example: 'Count' }),
                }),
            }),
            min: z.number().nullish(),
            max: z.number().nullish(),
        }),
    }),
);

export const schema_geojsonFeature = openAPIRegistry.register(
    'schema_geojsonFeature',
    z.object({
        type: z.literal('Feature'),
        id: z.string().nullish(),
        properties: z.any().nullish(),
        // properties: z
        //     .intersection(
        //         z.object({
        //             displayAs: z.string().nullish(),
        //             popup: z.string().nullish(),
        //             class: z.string().nullish(),
        //         }),
        //         z.any()
        //     )
        //     .nullish(),
        geometry: z.object({
            type: z.enum(['Point', 'LineString', 'Polygon']),
            coordinates: z.number().array().or(z.number().array().array()).or(z.number().array().array().array()),
        }),
    }),
);

export const schema_geojsonFeatureCollection = openAPIRegistry.register(
    'schema_geojsonFeatureCollection',
    z.object({
        type: z.literal('FeatureCollection'),
        name: z.string().nullish(),
        properties: z.any().nullish(),
        features: schema_geojsonFeature.array(),
    }),
);

export const schema_mapLayer = openAPIRegistry.register(
    'schema_mapLayer',
    z
        .object({
            type: z.enum(['tls', 'wms', 'wmts', 'geojson']),
            label: z
                .string()
                .trim()
                .regex(/^[a-zA-Z0-9]*$/)
                .describe('Krátké označení vrstvy ([a-zA-Z0-9]'),
            category: z
                .enum(enum_mapLayer_categories)
                .array()
                .describe(
                    "Kategorie vyznačuje pro jaké použití je vrstva navržena. 'observationsSearch' - ve vyhledávání, 'listInsert' - při vkládání seznamu (včetně položek).",
                )
                .openapi({
                    example: ['observationsSearch'],
                }),
            url: z.string().trim().describe('Adresa mapových dílců').url(),
            name: z
                .object({
                    cs: z.string(),
                    en: z.string().trim(),
                })
                .describe('Název mapy pro zobrazení ve vyběru map'),
            logo: z
                .object({
                    link: z.string().trim().describe('Adresa odkazu').url(),
                    url: z.string().trim().describe('Adresa obrázku').url(),
                })
                .nullish()
                .describe('Logo poskytovatele (vyžadováno některými poskytovateli map'),
            options: z.any().nullish().describe('Nastavení vstvy dle specifikace leaflet - TileLayer, GridLayer'),
        })
        .describe('Mapová vrstva pro leaflet'),
);

export const schema_area = openAPIRegistry.register(
    'schema_area',
    z
        .object({
            type: z.enum(['predefined', 'userDefined']).openapi({
                example: 'predefined',
            }),
            publicId: schema_areaId,
            name: z
                .object({
                    cs: z.string(),
                    en: z.string().trim(),
                })
                .describe('Název oblasti'),
        })
        .describe('Oblast pro vyhledávání'),
);

export const schema_searchMapType = openAPIRegistry.register(
    'schema_searchMapType',
    z
        .object({
            id: z.string().describe('ID zobrazení'),
            name: z
                .object({
                    cs: z.string(),
                    en: z.string(),
                })
                .describe('Název zobrazení'),
            mode: z.string().describe('Režim zobrazení - posílá se jako parametr na endpoint vyhledávání'),
        })
        .describe('Typ zobrazení v mapě'),
);

// --- request / response -------------------------------------------------------------------------

// Zakladni request na strankovani
export const request_page = openAPIRegistry.register(
    'request_page',
    z
        .object({
            page: z.number().int().min(1).max(99999).default(1).openapi({ example: 1 }),
            pageSize: z.number().int().min(1).max(500).default(100).openapi({ example: 100 }),
        })
        .describe('Požadavek pro výpis položek (obecné základní schéma).'),
);

// response pro pouziti behem vyvoje
export const response_todo = openAPIRegistry.register(
    'response_todo',
    z.object({
        done: z.boolean().default(false),
    }),
);

// response s uspesnosti volane akce
export const response_success = openAPIRegistry.register(
    'response_success',
    z.object({
        success: z.boolean(),
        message: z
            .object({
                cs: z.string(),
                en: z.string(),
            })
            .nullish(),
        error: z
            .object({
                cs: z.string(),
                en: z.string(),
            })
            .nullish(),
        errorDetail: z.string().trim().nullish(),
    }),
);

// response s uspesnosti volane akce a uuid vytvoreneho objektu
export const response_success_uuid = openAPIRegistry.register(
    'response_success_uuid',
    response_success.extend({
        uuid: z.string().trim().uuid().describe('Identifikátor položky, ve formátu UUID.').openapi({
            example: '7a7c8177-0f82-44ad-be15-8921860f2699',
        }),
    }),
);

// GET /search/filters
export const response_observations_filters = openAPIRegistry.register(
    'response_observations_filters',
    z
        .object({
            default: z.string().trim().openapi({ example: 'rarity.remarkable' }),
            filters: schema_filter.array(),
        })
        .describe('Odpověď na požadavek výpisu seznamu možných dotazů v Základním režimu vyhledávání.'),
);

// POST /count
export const request_count = openAPIRegistry.register(
    'request_count',
    z
        .object({
            filter: schema_filterParameters,
        })
        .describe('Požadavek na počet pozorování'),
);
export const response_count = openAPIRegistry.register(
    'response_count',
    z
        .object({
            count: z.number().int().openapi({ example: 1354 }),
        })
        .describe('Odpověď na požadavek počtu pozorování'),
);

// POST /search
export const request_observations = openAPIRegistry.register(
    'request_observations',
    request_page
        .extend({
            sort: schema_sort.array().nullish(),
            // simplified: z.boolean().nullish().openapi({ description: "Volba, zda má být výstup zjednodušený, tedy pro Základní vyhledávání" }),
            fields: z
                .enum(enum_observation_fields)
                .array()
                .nullable()
                .describe('Volba klíčů pro výpis. V případě null vrátí všechna dostupná pole.')
                .openapi({
                    example: ['date', 'taxonId', 'location'],
                }),
            filter: schema_filterParameters,
        })
        .describe('Požadavek vyhledávání pozorování'),
);
export const response_observations = openAPIRegistry.register(
    'response_observations',
    z
        .object({
            page: z.number().int().openapi({ example: 1 }),
            pageCount: z.number().int().openapi({ example: 32 }),
            itemsTotalCount: z.number().int().openapi({ example: 4261535 }),
            items: schema_observation.array(),
        })
        .describe('Odpověď vyhledávání pozorování.'),
);

// POST /search/map
export const request_observations_map = openAPIRegistry.register(
    'request_observations_map',
    request_observations
        .omit({
            page: true,
            pageSize: true,
            fields: true,
            sort: true,
        })
        .extend({
            mode: z.string().describe('Režim výstupu').default('markers'),
        })
        .describe('Požadavek vyhledávání pozorování'),
);

// POST /search/map/points
export const request_observations_mapPoints = openAPIRegistry.register(
    'request_observations_mapPoints',
    request_observations
        .omit({
            page: true,
            pageSize: true,
            fields: true,
            sort: true,
        })
        .describe('Požadavek vyhledávání pozorování'),
);
export const response_observations_mapPoints = openAPIRegistry.register(
    'response_observations_mapPoints',
    z
        .object({
            items: schema_observation.array(),
        })
        .describe('Odpověď vyhledávání pozorování.'),
);

// POST /search/map/grid
export const request_observations_mapGrid = openAPIRegistry.register(
    'request_observations_mapGrid',
    request_observations
        .omit({
            page: true,
            pageSize: true,
            fields: true,
        })
        .extend({
            grid: z.enum(enum_mapGrid_mode).describe('Síťové pole').openapi({
                example: 'kfme0',
            }),
        })
        .describe('Požadavek vyhledávání pozorování'),
);
export const response_observations_mapGrid = openAPIRegistry.register(
    'response_observations_mapGrid',
    z
        .object({
            grid: z.enum(enum_mapGrid_mode).describe('Síťové pole').openapi({
                example: 'kfme0',
            }),
            squares: z.array(schema_observation_map),
        })
        .describe('Odpověď vyhledávání pozorování.'),
);

// POST /search/lists
export const request_observations_lists = openAPIRegistry.register(
    'request_observations_lists',
    request_observations
        .omit({
            fields: true,
        })
        .extend({
            sort: schema_sort.array(),
        })
        .describe('Požadavek vyhledávání v seznamech'),
);
export const response_observations_lists = openAPIRegistry.register(
    'response_observations_lists',
    z
        .object({
            page: z.number().int().openapi({ example: 1 }),
            pageCount: z.number().int().openapi({ example: 32 }),
            itemsTotalCount: z.number().int().openapi({ example: 4261535 }),
            items: schema_listWithoutItems.array(),
        })
        .describe('Odpověď vyhledávání v seznamech'),
);

// POST /search/media
export const request_observations_media = openAPIRegistry.register(
    'request_observations_media',
    request_observations
        // .omit({
        //  fields: true,
        // })
        .extend({
            type: z
                .enum(['photo'])
                .array()
                .describe('Typy přílohy')
                .openapi({
                    example: ['photo'],
                }),
            sort: schema_sort.array(),
        })
        .describe('Požadavek vyhledávání příloh (fotek)'),
);
export const response_observations_media = openAPIRegistry.register(
    'response_observations_media',
    z
        .object({
            page: z.number().int().openapi({ example: 1 }),
            pageCount: z.number().int().openapi({ example: 32 }),
            itemsTotalCount: z.number().int().openapi({ example: 4261535 }),
            items: schema_observation.array(),
        })
        .describe('Odpověď vyhledávání příloh (fotek)'),
);

// POST /search/export
export const request_observations_export = openAPIRegistry.register(
    'request_observations_export',
    request_observations
        .omit({
            fields: true,
            page: true,
            pageSize: true,
        })
        .extend({
            language: schema_language,
            email: z.string().trim().email().describe('Email žadatele'),
            purpose: z.string().trim().describe('Účel exportu').openapi({
                example: 'Osobní použití',
            }),
            purposeDescription: z.string().trim().describe('Popis účelu').nullish().openapi({
                example: 'Připravuji dlouhodobý monitoring ptactva na lokalitě Vrbenské rybníky.',
            }),
            // mode: z.enum(enum_export_mode).describe('Typ exportu').openapi({
            //     example: 'observations',
            // }),
            format: z.enum(['csv']).describe('Formát souboru exportu').openapi({
                example: 'csv',
            }),
        })
        .describe('Požadavek na export dat'),
);
export const response_observations_export = openAPIRegistry.register(
    'response_observations_export',
    response_success
        .extend({
            error: z.string().trim().nullish(),
            itemsTotalCount: z.number().int().openapi({ example: 4261535 }),
            url: z.string().trim().url().describe('Adresa exportovaného souboru'),
        })
        .describe('Odpověď na export dat'),
);

// POST /search
export const request_analytics_countDate = openAPIRegistry.register(
    'request_analytics_countDate',
    z
        .object({
            taxonId: z
                .number()
                .int()
                .min(0)
                .max(99999)
                .array()
                .min(1, { message: 'errors.charts.taxonId.min' })
                .max(3, { message: 'errors.charts.taxonId.max' })
                .openapi({ example: [853, 805] }),
            dateRange: z
                .object({
                    start: schema_date,
                    end: schema_date,
                })
                .describe('Vyhledávání v rozsahu datumů.')
                .openapi({
                    example: {
                        start: '2023-01-01',
                        end: '2023-12-31',
                    },
                }),
            dateMode: z.enum(enum_analytics_dateMode).describe('Režim pro osu X - měsíce nebo roky.').openapi({
                example: 'months',
            }),
        })
        .describe('Požadavek na grafové zobrazení početnosti v období'),
);
export const response_analytics_countDate = openAPIRegistry.register(
    'response_analytics_countDate',
    z
        .object({
            data: schema_chartData,
        })
        .describe('Odpověď na grafové zobrazení početnosti v období'),
);

// POST /list
export const request_list = openAPIRegistry.register('request_list', schema_list_input);
export const response_list = openAPIRegistry.register(
    'response_list',
    response_success.extend({
        data: schema_list,
    }),
);

// GET /list/{listPublicId}
// GET /list/byItem/{listItemId}
// request: schema_listPublicId
export const response_list_id = openAPIRegistry.register('response_list_id', schema_list);

// POST /list/{listPublicId}/item/{listItemId}/comment/{commentId}
export const request_list_item_comment = openAPIRegistry.register(
    'request_list_item_comment',
    schema_comment.pick({
        subject: true,
        text: true,
    }),
);
export const response_list_item_comment = openAPIRegistry.register(
    'response_list_item_comment',
    response_success.extend({
        comment: schema_comment,
    }),
);

// POST /list/{listPublicId}/report/{listItemId}
export const request_list_report = openAPIRegistry.register(
    'request_list_report',
    z.object({
        reason: z.string().trim().min(1).max(1024),
    }),
);

// PUT /list/{listPublicId}
export const request_listWithoutItems = openAPIRegistry.register(
    'request_listWithoutItems',
    schema_list_input.omit({
        items: true,
    }),
);

// Post /import
export const response_import = openAPIRegistry.register(
    'response_import',
    response_success.extend({
        filename: z.string().nullish(),
        results: response_success
            .extend({
                listPublicId: schema_listPublicId.nullish(),
            })
            .array(),
    }),
);

// POST /media
// PUT /media/{uuid} (uprava poznamky)
export const response_media = openAPIRegistry.register(
    'response_media',
    response_success.extend({
        data: schema_media,
    }),
);

// GET /media/{uuid}
export const response_media_id = openAPIRegistry.register('response_media_id', schema_media);

// PUT /media/{uuid} (uprava poznamky)
export const request_media_note = openAPIRegistry.register(
    'request_note',
    z.object({
        note: z.string().trim().nullable(),
    }),
);

// GET /user
export const response_user = openAPIRegistry.register(
    'request_user',
    z.object({
        settings: z.object({
            language: schema_language.default('cs'),
            taxonLanguage: schema_language.default('cs'),
        }),
        filters: z.object({
            default: z.string().trim().openapi({ example: 'rarity.remarkable' }),
            items: schema_filter.array(),
        }),
        maps: z.object({
            default: z.string().trim().openapi({ example: 'osmBASE' }),
            baseLayers: schema_mapLayer.array(),
            overlays: schema_mapLayer.array(),
        }),
        areas: schema_area.array(),
        search: z.object({
            mapTypes: z.object({
                default: z.string().trim().openapi({ example: 'markers' }),
                items: schema_searchMapType.array(),
            }),
        }),
    }),
);

// GET /user/profile
export const response_user_profile = openAPIRegistry.register('response_user_profile', schema_user);

// POST /user/profile
export const request_user_profile = openAPIRegistry.register(
    'request_user_profile',
    schema_user.pick({
        name: true,
        bio: true,
        anonymous: true,
    }),
);

// GET /user/profile/{userId}
export const response_user_profile_id = openAPIRegistry.register('response_user_profile_id', schema_userWithStatistics);

// POST /user/message/{userId}
export const request_user_message_id = openAPIRegistry.register(
    'request_user_message_id',
    z.object({
        subject: z.string().trim().describe('Předmět zprávy').openapi({
            example: 'Proin ut arcu',
        }),
        message: z.string().trim().describe('Text zprávy').openapi({
            example:
                'Maecenas et facilisis sapien. Proin ut arcu risus. Nunc enim lectus, dignissim a interdum et, varius ut est. Donec a ex non ligula fermentum imperdiet. Suspendisse vestibulum convallis nibh non viverra.',
        }),
    }),
);

// POST /user/filter
export const request_user_filter = openAPIRegistry.register(
    'request_user_filter',
    z.object({
        name: z.string().trim().max(128).describe('Název filtru').openapi({
            example: 'Sovy v JHC za posledních 14 dní',
        }),
        parameters: schema_filterParameters,
    }),
);

// GET /web/about/{language}
export const response_web_about_language = openAPIRegistry.register(
    'response_web_about_language',
    schema_htmlArticle.array(),
);

// GET /web/help/{language}
export const response_web_help_language = openAPIRegistry.register(
    'response_web_help_language',
    schema_htmlArticle.array(),
);

// GET /web/faq/{language}
export const response_web_faq_language = openAPIRegistry.register(
    'response_web_faq_language',
    schema_htmlArticle.array(),
);

// GET /web/projects/{language}
export const response_web_projects_language = openAPIRegistry.register(
    'response_web_projects_language',
    schema_projectInfo.array(),
);

// GET /web/news/{language}
export const response_web_news_language = openAPIRegistry.register(
    'response_web_news_language',
    schema_newsArticleSummary.array(),
);

// GET /web/news/{language}/{id}
export const response_web_news_language_id = openAPIRegistry.register(
    'response_web_news_language_id',
    schema_newsArticle,
);

// --- codebook -----------------------------------------------------------------------------------

export const response_codebook_taxons = openAPIRegistry.register('response_codebook_taxons', schema_taxon.array());

export const response_codebook_attributes = openAPIRegistry.register(
    'response_codebook_attributes',
    z.object({
        rarity: schema_rarity_attr.array(),
        secrecy: schema_secrecy.array(),
        tags: schema_tags.array(),
        age: schema_age.array(),
        sex: schema_sex.array(),
        activity: schema_activity.array(),
        detection: schema_detection.array(),
    }),
);

export const response_codebook_territorialunits = openAPIRegistry.register(
    'response_codebook_territorialunits',
    z.object({
        types: schema_territorialUnitType.array(),
        items: schema_territorialUnit.array(),
    }),
);

export const response_codebook_projects = openAPIRegistry.register(
    'response_codebook_projects',
    z.object({
        projects: schema_project.array(),
    }),
);

// ------------------------------------------------------------------------------------------------

// Funkce pro ulozeni schema do openApi
export function definitions() {
    return openAPIRegistry.definitions;
}
