import { SxProps } from '@mui/material';

const horizontalScrollShadowStyle: any = {
    '& .MuiDataGrid-virtualScrollerContent': {
        backgroundColor: 'rgba(255,255,255,0.5)',
        minHeight: '35vh !important',
    },
    '& .MuiDataGrid-virtualScroller': {
        backgroundImage:
            'linear-gradient(to left, transparent 0%, white 75%),\
            linear-gradient(to right, transparent 0%, white 75%),\
            radial-gradient(100% 100% at left center, rgba(26,48,80,.75) 0%, rgba(255,255,255,0) 85%),\
            radial-gradient(100% 100% at right center, rgba(26,48,80,.75) 0%, rgba(255,255,255,0) 85%)',
        backgroundPosition: 'left center, right center, left center, right center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundSize: 'calc(40px * 4) 100%, calc(40px * 4) 100%, 40px 100%, 40px 100%',
        backgroundAttachment: 'local, local, scroll, scroll',
    },
};

export default horizontalScrollShadowStyle;
