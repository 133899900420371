import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { IComment } from '../../schemas/interfaces';
import { Formik } from 'formik';
import { schema_comment } from '../../schemas/schemas';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import DirtyPrompt from '../formControls/DirtyPrompt';

export interface CommentEditorProps {
    comment?: IComment;
    onSubmit: (comment: CommentEditorValues) => Promise<void>;
    onClose?: () => void;
    title: string;
    submitButtonLabel: string;
    cancelButtonLabel?: string;
    defaultSubject?: string;
    id?: string;
}

export type CommentEditorValues = Pick<IComment, 'text' | 'subject'>;

export const commentEditorSchema = schema_comment.pick({ text: true, subject: true });

const CommentEditor: FunctionComponent<CommentEditorProps> = (props) => {
    const { submitButtonLabel, cancelButtonLabel, defaultSubject, onSubmit, title, onClose, comment } = props;

    const initialValues: CommentEditorValues = {
        text: comment?.text || '',
        subject: comment?.subject || defaultSubject,
    };

    const handleFormSubmit = (values: CommentEditorValues) => {
        onSubmit(values)
            .then(() => console.log('submitted'))
            .catch((e) => console.log('error', e));
    };

    return (
        <Card variant="outlined" id={props.id ?? undefined}>
            <CardContent>
                <Formik<CommentEditorValues>
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                    validationSchema={toFormikValidationSchema(commentEditorSchema)}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                            <DirtyPrompt />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h4">
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Předmět"
                                        value={values.subject || ''}
                                        // this fixed the problem with field throwing "Required" error when empty
                                        // because the zod schema consider empty string as invalid value for a nullable field
                                        onChange={(event) => setFieldValue('subject', event.target.value || null)}
                                        onBlur={handleBlur}
                                        name="subject"
                                        required={
                                            !(
                                                commentEditorSchema.shape.subject.isOptional() ||
                                                commentEditorSchema.shape.subject.isNullable()
                                            )
                                        }
                                        error={touched.subject && !!errors.subject}
                                        helperText={touched.subject && errors.subject && 'Předmět je povinný.'}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Text"
                                        value={values.text || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="text"
                                        multiline
                                        required={
                                            !(
                                                commentEditorSchema.shape.text.isOptional() ||
                                                commentEditorSchema.shape.text.isNullable()
                                            )
                                        }
                                        error={touched.text && !!errors.text}
                                        helperText={touched.text && errors.text && 'Text je povinný.'}
                                        fullWidth
                                        minRows={4}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ float: 'right' }}
                                        onClick={() => handleSubmit()}
                                    >
                                        {submitButtonLabel}
                                    </Button>
                                    <Button
                                        variant="text"
                                        sx={{ float: 'right', marginRight: '1rem' }}
                                        onClick={onClose}
                                    >
                                        {cancelButtonLabel || 'Zrušit'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};

export default CommentEditor;
