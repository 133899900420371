import { FC } from 'react';
import { ImportListResult } from './ImportListResult';
import { ListItemText } from '@mui/material';

const ImportListError: FC<{ result: ImportListResult }> = ({ result }) => {
    if (result.success) return null;

    return (
        <ListItemText primary={result.message?.cs || 'Import vycházky selhal.'} secondary={result.error?.cs || ' '} />
    );
};

export default ImportListError;
