import {
    Button,
    Container,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import FullScreenImageBackground from '../../components/FullScreenImageBackground';
import Loading from '../../components/Loading';
import { useApi } from '../../services/apiContext';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

interface RegisterAppState {
    loading: boolean;
    success?: boolean;
}

const RegisterApp: FunctionComponent = () => {
    const [state, setState] = useState<RegisterAppState>({ loading: false });
    const api = useApi();

    const onSubmit = () => {
        setState({ ...state, loading: true, success: undefined });

        api._mock_registerUser().then((result) => {
            setState({ ...state, loading: false, success: result });
        });
    };

    return (
        <FullScreenImageBackground imageUrl="https://picsum.photos/1920/1080">
            <Container
                maxWidth="sm"
                sx={{ bgcolor: 'background.paper', padding: '2rem', borderRadius: 1, opacity: 0.9 }}
                className="RegisterApp"
            >
                <Grid container spacing={2}>
                    {state.success !== true && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h1">
                                    Registrace
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Jméno" id="name" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Příjmení" id="surname" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Email" id="email" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Uživatelské jméno" id="username" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Heslo" id="password" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Potvrzení hesla" id="passwordConfirm" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox required />} label="Souhlasím s&nbsp;podmínkami" />
                            </Grid>
                            <Grid item xs={12} display="flex">
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmit}
                                    loading={state.loading}
                                >
                                    Registrovat
                                </LoadingButton>
                            </Grid>
                        </>
                    )}
                    {state.success === true && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h1">
                                    Registrace proběhla úspěšně
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Váš uživatelský účet je připraven. Nyní se můžete přihlásit.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" component={RouterLink} to="/login?username=test">
                                    Přihlásit se
                                </Button>
                            </Grid>
                        </>
                    )}
                    {state.success === false && (
                        <Snackbar
                            open={state.success === false}
                            autoHideDuration={6000}
                            onClose={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setState({ ...state, success: undefined });
                            }}
                        >
                            <Alert severity="error">Registrace se nezdařila. Zkuste to prosím později.</Alert>
                        </Snackbar>
                    )}
                </Grid>
            </Container>
        </FullScreenImageBackground>
    );
};

export default RegisterApp;
