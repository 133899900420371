import { Grid, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import linkifyHtml from 'linkify-html';

const DefinitionListItem: FC<{
    label: string | JSX.Element;
    value?: string | number | null;
    formattedValue?: JSX.Element;
    htmlValue?: string;
    emptyValue?: string | JSX.Element;
    displayEmptyOnMobile?: boolean;
}> = (props) => {
    const valueIsEmpty = (props.value === null || props.value === undefined) && !props.htmlValue;
    const isMobile = useMediaQuery('(pointer: coarse)');

    return valueIsEmpty && isMobile && !props.displayEmptyOnMobile ? null : (
        <>
            <Grid item xs={5} component="dt" sx={{ bgcolor: 'action.selected' }}>
                <Typography component="span" variant="body1" fontWeight="bold">
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={7} component="dd">
                {props.htmlValue ? (
                    <Typography
                        component="span"
                        variant="body1"
                        dangerouslySetInnerHTML={props.htmlValue ? { __html: linkifyHtml(props.htmlValue) } : undefined}
                    />
                ) : (
                    <Typography
                        component="span"
                        variant="body1"
                        sx={{
                            '& em': {
                                letterSpacing: '0.3px',
                                opacity: 0.5,
                            },
                        }}
                    >
                        {!valueIsEmpty && (props.formattedValue || props.value)}
                        {valueIsEmpty && <em>{props.emptyValue || 'nezadáno'}</em>}
                    </Typography>
                )}
            </Grid>
        </>
    );
};

export default DefinitionListItem;
