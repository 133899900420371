import {
    Box,
    Grid,
    TextField,
    TextFieldProps,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FormikProps } from 'formik';
import { IObsListSearchFormValues } from '../ObsListsSearch';
import { parseInt } from 'lodash';
import translateErrorMessage from '../../../../services/errorMessages';
import { TooltipMobile } from '../../../../components/Tooltip';
import { useJoyride } from '../../../../services/joyrideContext';
import { useEffect } from 'react';
import NumberField from '../../../../components/formControls/NumberField';

export enum EDateMode {
    Seasonal = 'seasonal',
    Range = 'range',
    Relative = 'relative',
}

interface ObsListsSearchDateProps {
    formikProps: FormikProps<IObsListSearchFormValues>;
}

const ObsListsSearchDate: React.FunctionComponent<ObsListsSearchDateProps> = (props) => {
    const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = props.formikProps;
    const isMobile = useMediaQuery('(pointer: coarse)');
    const { joyrideEvents } = useJoyride();

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            if (data.step.target === '.tour-advancedsearch-4') handleModeChange(undefined, EDateMode.Relative);
            if (data.step.target === '.tour-advancedsearch-5') handleModeChange(undefined, EDateMode.Range);
            if (data.step.target === '.tour-advancedsearch-6') handleModeChange(undefined, EDateMode.Seasonal);
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents]);

    const handleModeChange = (e: React.MouseEvent<HTMLElement> | undefined, value: EDateMode) => {
        if (!value) return;

        props.formikProps.setTouched({
            ...props.formikProps.touched,
            _dateStart: false,
            _dateEnd: false,
            _yearStart: false,
            _yearEnd: false,
            dateNDays: false,
        });
        props.formikProps.setValues({
            ...props.formikProps.values,
            _dateMode: value,
            _dateStart: undefined,
            _dateEnd: undefined,
            _yearStart: undefined,
            _yearEnd: undefined,
            dateNDays: undefined,
            dateRange: undefined,
            dateSeason: undefined,
        });
    };

    return (
        <Box className="ObsListsSearchDate tour-advancedsearch-4 tour-advancedsearch-5 tour-advancedsearch-6">
            <Grid container spacing={1.75}>
                <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="subtitle2" textTransform="uppercase" fontWeight="bold">
                        Datum:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                    <ToggleButtonGroup
                        value={values._dateMode}
                        onChange={handleModeChange}
                        id="_dateMode"
                        exclusive
                        aria-label="vyberte jak chcete zadat datum"
                        fullWidth
                        size="small"
                    >
                        <TooltipMobile title="Umožňuje zadat relativní rozsah jako počet dní od&nbsp;aktuálního data">
                            <ToggleButton value={EDateMode.Relative} selected={values._dateMode == EDateMode.Relative}>
                                Relativní
                            </ToggleButton>
                        </TooltipMobile>
                        <TooltipMobile title="Umožňuje zadat rozsah ve&nbsp;formátu od/do">
                            <ToggleButton value={EDateMode.Range} selected={values._dateMode == EDateMode.Range}>
                                Rozsah
                            </ToggleButton>
                        </TooltipMobile>
                        <TooltipMobile title="Umožňuje zadat rozsah a k&nbsp;němu vybrat roky">
                            <ToggleButton value={EDateMode.Seasonal} selected={values._dateMode == EDateMode.Seasonal}>
                                Sezónní
                            </ToggleButton>
                        </TooltipMobile>
                    </ToggleButtonGroup>
                </Grid>
                {values._dateMode == EDateMode.Relative && (
                    <Grid item xs={12}>
                        <NumberField
                            fullWidth
                            name="dateNDays"
                            id="dateNDays"
                            value={values.dateNDays || ''}
                            className={values.dateNDays ? 'nonEmpty' : ''}
                            onChange={(e) => {
                                setFieldValue(
                                    'dateNDays',
                                    isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value),
                                );
                            }}
                            onBlur={handleBlur}
                            label="Posledních N dní od&nbsp;dnešního data"
                            error={touched.dateNDays && !!errors.dateNDays}
                            helperText={touched.dateNDays && translateErrorMessage(errors.dateNDays)}
                            color="secondary"
                            min={1}
                            step={1}
                            max={9999}
                        />
                    </Grid>
                )}
                {values._dateMode != EDateMode.Relative && (
                    <>
                        <Grid item xs={12} md={6}>
                            <DatePicker<Date>
                                label="Datum od"
                                views={
                                    values._dateMode == EDateMode.Range ? ['year', 'month', 'day'] : ['month', 'day']
                                }
                                {...(values._dateMode == EDateMode.Seasonal
                                    ? {
                                          format: 'dd.MM.',
                                      }
                                    : {})}
                                value={values._dateStart || null}
                                disableFuture={values._dateMode == EDateMode.Range}
                                onChange={(value) => {
                                    setFieldValue('_dateStart', value, true);
                                    setFieldTouched('_dateStart', true, false);
                                }}
                                slotProps={{
                                    textField: {
                                        name: '_dateStart',
                                        id: '_dateStart',
                                        fullWidth: true,
                                        className: values._dateStart ? ' nonEmpty' : '',
                                        onBlur: handleBlur,
                                        error: touched._dateStart && !!errors._dateStart,
                                        helperText: touched._dateStart && errors._dateStart,
                                        color: 'secondary',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker<Date>
                                label="Datum do"
                                views={
                                    values._dateMode == EDateMode.Range ? ['year', 'month', 'day'] : ['month', 'day']
                                }
                                {...(values._dateMode == EDateMode.Seasonal
                                    ? {
                                          format: 'dd.MM.',
                                      }
                                    : {})}
                                value={values._dateEnd || null}
                                disableFuture={values._dateMode == EDateMode.Range}
                                onChange={(value) => {
                                    setFieldValue('_dateEnd', value, true);
                                    setFieldTouched('_dateEnd', true, false);
                                }}
                                slotProps={{
                                    textField: {
                                        name: '_dateEnd',
                                        id: '_dateEnd',
                                        fullWidth: true,
                                        className: values._dateEnd ? ' nonEmpty' : '',
                                        onBlur: handleBlur,
                                        error: touched._dateEnd && !!errors._dateEnd,
                                        helperText: touched._dateEnd && errors._dateEnd,
                                        color: 'secondary',
                                    },
                                }}
                            />
                        </Grid>
                    </>
                )}
                {values._dateMode == EDateMode.Seasonal && (
                    <>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label="Sezóna od"
                                views={['year']}
                                openTo="year"
                                maxDate={new Date()}
                                disableFuture
                                value={values._yearStart || null}
                                onChange={(value) => setFieldValue('_yearStart', value)}
                                slotProps={{
                                    textField: {
                                        name: '_yearStart',
                                        id: '_yearStart',
                                        fullWidth: true,
                                        className: values._yearStart ? ' nonEmpty' : '',
                                        onBlur: handleBlur,
                                        error: touched._yearStart && !!errors._yearStart,
                                        helperText: touched._yearStart && errors._yearStart,
                                        color: 'secondary',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label="Sezóna do"
                                views={['year']}
                                openTo="year"
                                maxDate={new Date()}
                                disableFuture
                                value={values._yearEnd || null}
                                onChange={(value) => setFieldValue('_yearEnd', value)}
                                slotProps={{
                                    textField: {
                                        name: '_yearEnd',
                                        id: '_yearEnd',
                                        fullWidth: true,
                                        className: values._yearEnd ? ' nonEmpty' : '',
                                        onBlur: handleBlur,
                                        error: touched._yearEnd && !!errors._yearEnd,
                                        helperText: touched._yearEnd && errors._yearEnd,
                                        color: 'secondary',
                                    },
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default ObsListsSearchDate;
