import { useFormikContext } from 'formik';
import { FC } from 'react';
import { TextField } from '@mui/material';
import { ObservationItemFormValues } from '../../../ObservationFormApp';

const RingMarkDialog: FC<{ label?: string; controlTag?: boolean }> = ({ label, controlTag }) => {
    const { handleBlur, touched, values, errors, setFieldValue } = useFormikContext<ObservationItemFormValues>();

    return (
        <TextField
            onKeyDown={(e) => {
                e.stopPropagation();
            }}
            onChange={(e) => {
                const value = e.target.value;

                if (!value) {
                    if (controlTag)
                        setFieldValue(
                            'tags',
                            (values.tags || []).filter((tag) => tag !== 'ringMark'),
                        );

                    setFieldValue('ringMark', null);
                    return;
                }

                if (controlTag)
                    setFieldValue('tags', [...(values.tags || []).filter((t) => t !== 'ringMark'), 'ringMark']);

                setFieldValue('ringMark', value);
            }}
            onBlur={handleBlur}
            label={label ?? 'Číslo kroužku'}
            name="ringMark"
            id="ringMark"
            value={values.ringMark || ''}
            fullWidth
            sx={{ mt: 2 }}
            error={!!errors.ringMark && !!touched.ringMark}
            helperText={!!errors.ringMark && !!touched.ringMark && errors.ringMark}
        />
    );
};

export default RingMarkDialog;
