import { Box } from '@mui/material';
import { FunctionComponent } from 'react';

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number | string;
    value: number | string;
    className?: string;
}

const TabPanel: FunctionComponent<TabPanelProps> = (props) => {
    const { children, value, index, className } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{ position: 'relative' }}
            className={className}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
};

export default TabPanel;
