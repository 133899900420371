import { Color } from '@mui/material';
import { createTheme, experimental_extendTheme, ThemeOptions } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import { csCZ as pickers_csCZ } from '@mui/x-date-pickers/locales';
import { csCZ as dataGrid_csCZ } from '@mui/x-data-grid/locales';
import { csCZ as mui_csCZ } from '@mui/material/locale';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const feedersPrimary = {
    50: '#e6e9ed',
    100: '#dae5ef',
    200: '#8d98a8',
    300: '#5e7694',
    400: '#3c4f6a',
    500: '#1a3050',
    600: '#172b49',
    700: '#132440',
    800: '#0f1e37',
    900: '#081327',
    A100: '#6393ff',
    A200: '#306fff',
    A400: '#004dfc',
    A700: '#0045e3',
    main: '#1a3050',
    light: '#5e7694',
    dark: '#081327',
    contrastText: '#fff',
} as Color;

export const feedersSecondary = {
    50: '#ffead1',
    100: '#fdd5c0',
    200: '#fbba97',
    300: '#f99e6d',
    400: '#f8894d',
    500: '#f7742e',
    600: '#f66c29',
    700: '#f56123',
    800: '#f3571d',
    900: '#f14412',
    A100: '#ffffff',
    A200: '#ffefeb',
    A400: '#ffc6b8',
    A700: '#ffb19f',
    main: '#f7742e',
    light: '#f99e6d',
    dark: '#de5912',
    contrastText: '#fff',
} as Color;

export const feedersPalette = createPalette({
    primary: feedersPrimary,
    secondary: feedersSecondary,
    text: {
        primary: '#3a3a3a',
        secondary: '#6d6d6d',
    },
    action: {
        active: '#5e7694',
        selected: '#f3f5f6',
    },
});

const themeOptions: ThemeOptions = {
    palette: feedersPalette,
    typography: {
        fontFamily: '"Open Sans", "Roboto", sans-serif',
        body1: {
            fontSize: '0.875rem',
        },
        body2: {
            fontSize: '0.75rem',
        },
        button: {
            fontSize: '0.875rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '1.875rem',
            fontWeight: 700,
        },
        h3: {
            fontSize: '1.375rem',
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        caption: {
            fontSize: '0.8125rem',
        },
    },
    components: {
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    '& .MuiToggleButton-root': {
                        fontWeight: 'bold',
                        fontSize: '0.875rem',
                        '&.Mui-selected': {
                            backgroundColor: feedersPrimary[100],
                        },
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': { height: '6px' },
                    '& .MuiTab-root:not(.Mui-disabled)': { opacity: 1 },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: 'secondary',
            },
            styleOverrides: {
                root: {
                    overflow: 'visible',
                    '& .Mui-checked': {
                        color: feedersSecondary[500],
                    },
                    '& .Mui-checked + .MuiSwitch-track': {
                        backgroundColor: feedersSecondary[500],
                    },
                    '&:focus': {
                        backgroundColor: 'rgba(247, 116, 46, 0.2)',
                        boxShadow: `0 0 0 4px rgba(247, 116, 46, 0.2)`,
                    },
                },
                switchBase: {
                    '&.Mui-focusVisible': {
                        backgroundColor: 'rgba(247, 116, 46, 0.2)',
                        boxShadow: `0 0 0 4px rgba(247, 116, 46, 0.2)`,
                    },
                },
                track: {
                    '&:focus': {
                        backgroundColor: feedersSecondary[500],
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiTextField: {
            defaultProps: {
                color: 'secondary',
            },
            styleOverrides: {
                root: {
                    '&.nonEmpty:not(.Mui-error)': {
                        '& .MuiInputBase-root:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                            borderColor: feedersSecondary[500],
                            borderWidth: '1px',
                        },
                        '& + .MuiInputLabel-root, & .MuiInputBase-root:not(.Mui-error) .MuiInputLabel-root, & .MuiInputLabel-root:not(.Mui-error)':
                            {
                                color: feedersSecondary[500],
                            },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.nonEmpty:not(.Mui-error)': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: feedersSecondary[500],
                            borderWidth: '1px',
                        },
                        '& + .MuiInputLabel-root, .MuiInputLabel-root': {
                            color: feedersSecondary[500],
                        },
                    },
                    '&.Mui-error': {
                        backgroundColor: '#fce9e9',
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                color: 'secondary',
            },
            styleOverrides: {
                icon: {
                    width: '1.5em',
                    height: '1.5em',
                    top: 'calc(50% - 0.75em)',
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                color: 'secondary',
            },
            styleOverrides: {
                popupIndicator: {
                    marginTop: '-0.25em',
                    '& .MuiSvgIcon-root': {
                        width: '1.5em',
                        height: '1.5em',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    fontSize: '0.8125rem',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiMenuItem-root.Mui-selected[color="secondary"]': {
                        backgroundColor: feedersSecondary[50],
                    },
                    '&.MuiButton-containedPrimary:hover': {
                        backgroundColor: feedersSecondary[500],
                    },
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: '0.875rem',
                    display: 'inline-block',
                    borderRadius: '3px',
                    padding: '0.2rem 1rem',
                    backgroundColor: feedersPrimary[50],
                },
            },
        },
    },
};

export const theme = createTheme(themeOptions, mui_csCZ, pickers_csCZ, dataGrid_csCZ);

export const experimentalTheme = experimental_extendTheme({
    colorSchemes: {
        light: {
            palette: theme.palette,
        },
    },
    components: theme.components,
    typography: theme.typography,
});
