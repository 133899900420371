import { FC } from 'react';
import UndefinedDictValue from './UndefinedDictValue';
import { locationFormatter } from '../../services/dataFormatters';
import { useDict } from '../../services/dictContext';

const PlaceFromDict: FC<{ siteName?: string | null; placeId?: number | null }> = ({ siteName, placeId }) => {
    const { places } = useDict();

    if (!placeId) return <UndefinedDictValue />;

    if (!places) return <>Loading...</>;

    return <>{locationFormatter(siteName, placeId, places)}</>;
};

export default PlaceFromDict;
