import { ContentPasteSearch, ListAltOutlined, Receipt } from '@mui/icons-material';
import { Typography, IconButton, Tooltip, Box } from '@mui/material';
import { IMedia } from '../../schemas/interfaces';
import { AvifBinocularsIcon } from '../icons';

const ImageCaption: React.FunctionComponent<{
    image: IMedia;
    hideIcons?: boolean;
    caption?: JSX.Element | string;
    onObservationClick?: () => void;
    onListClick?: () => void;
    taxonName?: string;
    transparent?: boolean;
}> = (props) => {
    if (props.hideIcons && !props.caption && !props.image.note) return null;

    return (
        <Box
            className="ImageCaption"
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                padding: '0.2rem 0.5rem',
                backgroundColor: props.transparent ? 'transparent' : 'rgba(255, 255, 255, 0.8)',
                color: 'black',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                fontStyle: props.taxonName === 'latin' ? 'italic' : 'normal',
                ...(props.transparent && {
                    '& .MuiButtonBase-root': {
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                    },
                }),
            }}
        >
            <Typography variant="caption" component="div" sx={{ flexGrow: 1, maxWidth: `calc(100% - 76px)}` }}>
                {props.caption ?? props.image.note ?? ''}
            </Typography>
            {!props.hideIcons && (
                <Box
                    sx={{
                        marginLeft: 'auto',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '0.5rem',
                    }}
                >
                    {props.onObservationClick && (
                        <Tooltip title="Zobrazit pozorování">
                            <IconButton
                                aria-label="options"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.onObservationClick && props.onObservationClick();
                                }}
                            >
                                <AvifBinocularsIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {props.onListClick && (
                        <Tooltip title="Zobrazit vycházku">
                            <IconButton
                                aria-label="options"
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.onListClick && props.onListClick();
                                }}
                            >
                                <ListAltOutlined fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ImageCaption;
