import { Fragment, FunctionComponent } from 'react';
import { IListItemProject } from '../../../schemas/interfaces';
import { TypographyProps, Typography, Divider, Grid, SxProps } from '@mui/material';
import DefinitionListItem from '../../../components/DefinitionListItem';
import linkifyHtml from 'linkify-html';

const ItemProjectDefinitionList: FunctionComponent<{
    project: IListItemProject;
    headlineProps?: TypographyProps<any>;
    sx?: SxProps;
}> = ({ project, headlineProps, sx }) => {
    const { name, data } = project;

    return (
        <Grid
            container
            component="dl"
            spacing={1}
            sx={{
                '& div.MuiGrid-root:last-child': { display: 'none' },
                '& .MuiGrid-item': {
                    py: 1,
                },
                '& .MuiGrid-item:not(:last-of-type)': {
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                },
                '& dt.MuiGrid-item': {
                    bgcolor: 'action.selected',
                },
                marginBottom: 0,
                ...sx,
            }}
            className="ItemProjectDefinitionList"
        >
            {data.map((pair) => (
                <DefinitionListItem
                    key={pair.questionId}
                    label={pair.name?.cs ?? ''}
                    htmlValue={linkifyHtml(String(pair.answer?.cs ?? ''), { target: '_blank' })}
                />
            ))}
        </Grid>
    );
};

export default ItemProjectDefinitionList;
