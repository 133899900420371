import React, { useEffect } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { MyLocation as MyLocationIcon } from '@mui/icons-material';
import { DomEvent } from 'leaflet';
interface GeolocationProps {
    onChange: (coordinates: { latitude: number; longitude: number }) => void;
}

const Geolocation: React.FC<GeolocationProps> = ({ onChange }) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const handleGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    onChange({ latitude, longitude });
                },
                (error) => {
                    console.error('Error obtaining geolocation', error);
                },
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleGeolocation();
        }
    };

    useEffect(() => {
        if (!buttonRef.current) return;
        DomEvent.disableClickPropagation(buttonRef.current);
    }, [buttonRef]);

    return (
        <Box className="leaflet-top leaflet-right" sx={{ top: '38px', zIndex: '440 !important' }}>
            <Box
                className="leaflet-control leaflet-bar"
                sx={{ display: 'flex', flexDirection: 'column', background: '#fff' }}
            >
                <Tooltip title="Použít aktuální polohu" placement="top">
                    <IconButton
                        ref={buttonRef}
                        onClick={handleGeolocation}
                        tabIndex={0}
                        onKeyDown={handleKeyPress}
                        size="small"
                        sx={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '6px',
                            boxSizing: 'border-box',
                            color: 'grey.500',
                            fontSize: '1.2rem',
                        }}
                    >
                        <MyLocationIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default Geolocation;
