import { FC, useEffect } from 'react';

const FormikObserver: FC<{ onChange: (values: any) => void; values: any; debounce?: number }> = ({
    onChange,
    values,
}) => {
    useEffect(() => {
        onChange(values);
    }, [values]);

    return null;
};

export default FormikObserver;
