import { Button, Card, CardContent, CardMedia, Container, Grid, Typography, Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IProjectInfo } from '../../schemas/interfaces';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useApi } from '../../services/apiContext';

const ProjectsApp: FC = () => {
    const [projects, setProjects] = useState<IProjectInfo[]>();
    const { getProjectsInfo } = useApi();

    useEffect(() => {
        getProjectsInfo()
            .then((response) => setProjects(response))
            .catch(console.error);
    }, []);

    return (
        <Container className="ProjectsApp" sx={{ pt: 5.75, pb: 6.75 }}>
            <Typography variant="h2" component="h1" textAlign="center" sx={{ pb: 3.75 }}>
                Projekty
            </Typography>
            <Typography
                variant="body1"
                component="p"
                textAlign="center"
                sx={{ pb: 3.75, width: '80%', margin: '0 auto' }}
            >
                Součástí hlavní faunistické databáze Avif, na&nbsp;které se právě nacházíte, je i&nbsp;několik
                specializovaných projektů, zaměřených na&nbsp;konkrétní tématiku. Na&nbsp;této stránce naleznete jejich
                přehled a odkazy na&nbsp;webové stránky konkrétních projektů s&nbsp;více informacemi. Vložené údaje
                v&nbsp;těchto projektech se zároveň zobrazují jako každá jiná pozorování v&nbsp;databázi, což umožňuje
                jejich využití nejen v&nbsp;rámci konkrétního projektu, ale také k&nbsp;mnohým dalším účelům
                v&nbsp;ochraně a výzkumu ptactva. Všechny projekty sdílejí společný seznam uživatelů, všechny jsou
                přístupné pomocí stejného uživatelského jména a hesla. Výjimkou je projekt Ptačí hodinka, jehož data se
                prozatím ukládají do&nbsp;samostatné databáze.
            </Typography>
            {!projects && <Loading fullPage />}
            {projects && !projects.length && (
                <Typography variant="body1" component="p">
                    Aktuálně zde nejsou žádné projekty.
                </Typography>
            )}
            {projects && projects.length && (
                <>
                    <Grid container spacing={2.25}>
                        {projects.map((project) => (
                            <Grid item xs={12} md={6} lg={4} key={project.id}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                    variant="outlined"
                                >
                                    <CardMedia
                                        image={project.thumbnail}
                                        title={project.name || ''}
                                        sx={{ height: 200, width: '100%' }}
                                    />
                                    <CardContent
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <Typography variant="h3" component="h2" sx={{ pb: 1 }}>
                                            {project.name}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="p"
                                            sx={{
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 6,
                                                mb: 2.25,
                                            }}
                                        >
                                            {project.description}
                                        </Typography>
                                        <Box sx={{ marginTop: 'auto' }}>
                                            <Link
                                                to={project.url}
                                                target="_blank"
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                <Button size="small" color="secondary" variant="contained">
                                                    Přejít na&nbsp;web projektu
                                                </Button>
                                            </Link>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ textAlign: 'center', pt: 3.75 }}>
                        <Typography
                            variant="caption"
                            component="p"
                            textAlign="center"
                            sx={{
                                bgcolor: 'primary.50',
                                py: 0.5,
                                px: 1,
                                borderRadius: 'var(--mui-shape-borderRadius)',
                                display: 'inline-block',
                            }}
                        >
                            Autoři fotografií: Monitoring čejky chocholaté - Petr Šaj, Ostatní projekty - archiv ČSO.
                            Grafické zpracování: Jiří Kaláček.
                        </Typography>
                    </Box>
                </>
            )}
        </Container>
    );
};

export default ProjectsApp;
