import { FunctionComponent } from 'react';
import { IRarity } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useDict } from '../../services/dictContext';

const RaritySelect: FunctionComponent<DictionarySelectPublicProps<number, IRarity>> = (props) => {
    const { rarity: rarities } = useDict();

    return (
        <DictionarySelect<number, IRarity>
            color="secondary"
            {...props}
            getDictOptions={
                rarities
                    ? new Promise((resolve) => {
                          resolve(rarities);
                      })
                    : undefined
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.level)}
            getOptionLabel={(option, dicts = [] as IRarity[]) =>
                dicts.find((dict) => dict.level === option)?.name.cs || `Unknown attribute ${option}`
            }
        />
    );
};

export default RaritySelect;
