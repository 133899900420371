import { FacebookRounded, Instagram, Twitter, YouTube } from '@mui/icons-material';
import { Box, Button, Container, Divider, Grid, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const AppFooter: FC = () => {
    return (
        <Box className="AppFooter" sx={{ bgcolor: 'background.paper', py: 4 }}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Link to="/about" style={{ textDecoration: 'none' }}>
                            <Button>O webu</Button>
                        </Link>
                        <br />
                        <Link to="/help" style={{ textDecoration: 'none' }}>
                            <Button>Nápověda</Button>
                        </Link>
                        <br />
                        <Box sx={{ display: 'flex', gap: '0 0.25rem', flexWrap: 'wrap' }}>
                            <Link to="https://www.facebook.com/birdlife.cz" target="_blank">
                                <IconButton size="large">
                                    <FacebookRounded fontSize="inherit" />
                                </IconButton>
                            </Link>
                            <Link to="https://twitter.com/birdlifecz" target="_blank">
                                <IconButton size="large">
                                    <Twitter fontSize="inherit" />
                                </IconButton>
                            </Link>
                            <Link to="https://www.youtube.com/channel/UCJIbX7rHEasNGgpkmP5YyGw" target="_blank">
                                <IconButton size="large">
                                    <YouTube fontSize="inherit" />
                                </IconButton>
                            </Link>
                            <Link to="https://www.instagram.com/birdlife_cz/" target="_blank">
                                <IconButton size="large">
                                    <Instagram fontSize="inherit" />
                                </IconButton>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography variant="caption" component="p">
                            Faunistická databáze Avif je určena všem zájemcům o&nbsp;ptactvo z&nbsp;řad široké
                            veřejnosti, amatérských ornitologů, birdwatcherů i vědců a slouží ke&nbsp;sdílení a
                            uchovávání záznamů o&nbsp;pozorování všech druhů ptáků na&nbsp;území České republiky. Slouží
                            nejen ke&nbsp;sdílení zajímavých a neobvyklých pozorování, ale také ke&nbsp;sběru
                            kompletních seznamů všech pozorovaných druhů pro&nbsp;pozdější vědecké využití.
                        </Typography>
                        <Link to="https://www.darujme.cz/avif" style={{ textDecoration: 'none' }} target="_blank">
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 1.75,
                                    px: 4,
                                    bgcolor: 'primary.400',
                                    '&:hover, &:active, &:focus, &.Mui-focusVisible': {
                                        bgcolor: 'primary.main',
                                    },
                                }}
                            >
                                Přispěj na&nbsp;rozvoj Avifu
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                                gap: '1rem',
                                justifyContent: 'flex-start',
                                '& a': {
                                    display: 'inline-flex',
                                },
                                '& img': {
                                    display: 'block',
                                    height: '100%',
                                    maxHeight: { xs: '50px', md: '100px' },
                                    width: { xs: 'auto', md: '100%' },
                                    maxWidth: '100%',
                                },
                            }}
                        >
                            <Link
                                to="https://play.google.com/store/apps/details?id=cz.birdlife.avifmobile"
                                target="_blank"
                            >
                                <Box
                                    component="img"
                                    src="/img/google-play-badge-v2.png"
                                    title="Stahujte mobilní aplikaci Avif na&nbsp;Google Play"
                                    alt="Stahujte mobilní aplikaci Avif na&nbsp;Google Play"
                                />
                            </Link>
                            <Link to="https://apps.apple.com/cz/app/avif/id6478004907" target="_blank">
                                <Box
                                    component="img"
                                    src="/img/app-store-badge.png"
                                    title="Stahujte mobilní aplikaci Avif na&nbsp;App Store"
                                    alt="Stahujte mobilní aplikaci Avif na&nbsp;App Store"
                                />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{
                            display: 'flex',
                            flexWrap: {
                                xs: 'wrap',
                                md: 'nowrap',
                            },
                            gap: '1.75rem',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            '& img': {
                                display: 'block',
                                maxHeight: '54px',
                                maxWidth: '100%',
                            },
                        }}
                    >
                        <Link to="https://www.birdlife.cz/" target="_blank">
                            <img src="/img/footer-logo-cso-black.png" alt="Logo ČSO" />
                        </Link>
                        <Link to="https://www.birdlife.org/" target="_blank">
                            <img src="/img/footer-logo-birdlife-black.png" alt="Logo Birdlife" />
                        </Link>
                        <Link to="https://www.birdlife.cz/kam-za-vtakmi/" target="_blank">
                            <img src="/img/avif-logo-interreg.png" alt="Avif byl podpořen z&nbsp;programu Interreg" />
                        </Link>
                        <Link to="https://www.birdlife.cz/kam-za-vtakmi/" target="_blank">
                            <img
                                src="/img/avif-logo-eu.png"
                                alt="Avif byl podpořen z&nbsp;programu Spoločně bez&nbsp;hranic"
                                style={{ maxHeight: '60px' }}
                            />
                        </Link>
                        <Link to="https://www.eon.cz/domacnosti/" target="_blank">
                            <img
                                src="/img/avif-logo-egd-v2.png"
                                alt="Avif byl podpořen z&nbsp;programu egg"
                                style={{ maxHeight: '45px' }}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="caption" textAlign="right" component="p">
                            &copy; Česká společnost ornitologická, 2002–{format(new Date(), 'yyyy')}
                            <br />
                            Všechna práva vyhrazena.
                            <br />
                            Verze aplikace: {process.env.REACT_APP_VERSION}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AppFooter;
