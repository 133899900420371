import { FC } from 'react';
import { Polyline, Tooltip } from 'react-leaflet';
import { ICoordinates } from '../../schemas/interfaces';
import { feedersPalette } from '../../theme';

const ObservationTracks: FC<{ track?: ICoordinates[] | null; plannedTrack?: ICoordinates[] | null }> = ({
    track,
    plannedTrack,
}) => {
    return (
        <>
            {track && track.length && (
                <Polyline positions={track} pathOptions={{ color: 'transparent', weight: 30 }}>
                    <Polyline positions={track} pathOptions={{ color: feedersPalette.success.main, weight: 3 }} />
                    <Tooltip content="Zaznamenaná trasa" sticky direction="top"></Tooltip>
                </Polyline>
            )}
            {plannedTrack && plannedTrack.length && (
                <Polyline positions={plannedTrack} pathOptions={{ color: 'transparent', weight: 30 }}>
                    <Polyline
                        positions={plannedTrack}
                        pathOptions={{ color: feedersPalette.warning.main, weight: 3 }}
                    />
                    <Tooltip content="Plánovaná trasa" sticky direction="top"></Tooltip>
                </Polyline>
            )}
        </>
    );
};

export default ObservationTracks;
