import { TableCell, TableRow, Typography, Box, Tooltip, useMediaQuery } from '@mui/material';
import { ObservationFormStepSummaryComponent, ObservationItemFormValues } from '../ObservationFormApp';
import { TableEditor } from '../../../components/tableEditor/TableEditor';
import TaxonFromDict from '../../../components/dicts/TaxonFromDict';
import SexFromDict from '../../../components/dicts/SexFromDict';
import AgeFromDict from '../../../components/dicts/AgeFromDict';
import ActivityFromDict from '../../../components/dicts/ActivityFromDict';
import UndefinedDictValue from '../../../components/dicts/UndefinedDictValue';
import TableIcons from '../../../components/TableIcons';
import { IMedia } from '../../../schemas/interfaces';
import { ListItemAction } from '../../../schemas/enums';
import equal from 'fast-deep-equal';
import { normalizeObjectForComparison } from '../../../services/helpers';
import { useEffect, useState } from 'react';
import { Add, Delete, Edit } from '@mui/icons-material';
import { TooltipMobile } from '../../../components/Tooltip';
import TaxonLanguageSwitcher from '../../../components/formControls/TaxonLanguageSwitcher';

const ObsItemsFormSummary: ObservationFormStepSummaryComponent = (props) => {
    const items = props.data?.items || [];
    const isMobile = useMediaQuery('(pointer: coarse)');

    return (
        <Box
            className="ObsItemsFormSummary"
            sx={{
                py: 2,
                ...(isMobile
                    ? {
                          '& .MuiTableContainer-root': {
                              backgroundImage:
                                  '\
                                linear-gradient(to left, transparent 0%, white 75%),\
                                linear-gradient(to right, transparent 0%, white 75%),\
                                radial-gradient(100% 100% at left center, rgba(26,48,80,.75) 0%, rgba(255,255,255,0) 85%),\
                                radial-gradient(100% 100% at right center, rgba(26,48,80,.75) 0%, rgba(255,255,255,0) 85%)',
                              backgroundPosition: 'left center, right center, left center, right center',
                              backgroundRepeat: 'no-repeat',
                              backgroundColor: 'white',
                              backgroundSize: 'calc(40px * 4) 100%, calc(40px * 4) 100%, 40px 100%, 40px 100%',
                              backgroundAttachment: 'local, local, scroll, scroll',
                          },
                          '& .MuiTable-root': {
                              backgroundColor: 'rgba(255,255,255,0)',
                          },
                      }
                    : {}),
            }}
        >
            <TableEditor<ObservationItemFormValues>
                columnsNumber={7}
                formInitialValues={{} as ObservationItemFormValues}
                items={items}
                enableRevert={false}
                floatingButtons={false}
                hidePagination
                disableRowHighlight
                pageSize={10000}
                disableAlerts
                disableButtons={['create', 'update', 'duplicate', 'revert', 'edit', 'revertAll', 'save']}
                hideActionsColumn
                getRowClassname={(item) => (props.new ? '' : `action-${item._Action || 'none'}`)}
                itemRendererView={(item) => {
                    const action = item.data._Action;
                    return (
                        <>
                            <TableCell
                                sx={{
                                    '& .MuiSvgIcon-root': { position: 'relative', top: '3px' },
                                }}
                            >
                                {!props.new && action === ListItemAction.Delete && (
                                    <TooltipMobile title="Pozorování bude smazáno." placement="right">
                                        <Delete color="inherit" />
                                    </TooltipMobile>
                                )}
                                {!props.new && action === ListItemAction.Create && (
                                    <TooltipMobile
                                        title="Nové pozorování bude vytvořeno a přiřazeno k&nbsp;této vycházce."
                                        placement="right"
                                    >
                                        <Add color="inherit" />
                                    </TooltipMobile>
                                )}
                                {!props.new && action === ListItemAction.Update && (
                                    <TooltipMobile title="Pozorování bude upraveno." placement="right">
                                        <Edit color="inherit" />
                                    </TooltipMobile>
                                )}
                            </TableCell>
                            <TableCell>
                                <TaxonFromDict taxonId={item.data.taxonId} />
                            </TableCell>
                            <TableCell>{item.data.count || <UndefinedDictValue />}</TableCell>
                            <TableCell>
                                <SexFromDict sexId={item.data.sexId} />
                            </TableCell>
                            <TableCell>
                                <AgeFromDict ageId={item.data.ageId} />
                            </TableCell>
                            <TableCell>
                                <ActivityFromDict activityId={item.data.activityId} />
                            </TableCell>
                            <TableCell>
                                <TableIcons
                                    media={item.data._Media || []}
                                    note={item.data.note}
                                    coordinates={item.data.coordinates}
                                />
                            </TableCell>
                        </>
                    );
                }}
                tableProps={{
                    sx: {
                        '& .MuiTableRow-root': {
                            bgcolor: { xs: '#ffffffa0', md: '#fff' },
                            '&.MuiTableRow-hover:hover': {
                                backgroundColor: '#f5f5f5a0',
                            },
                            '&.action-create': {
                                bgcolor: { xs: '#4caf50a0', md: 'success.light' },
                                '&:hover': {
                                    bgcolor: '#4caf50e0',
                                },
                                // color: 'background.paper',
                            },
                            '&.action-update': {
                                bgcolor: { xs: '#ed6c02a0', md: 'warning.light' },
                                '&:hover': {
                                    bgcolor: '#ed6c02e0',
                                },
                                // color: 'background.paper',
                            },
                            '&.action-delete': {
                                bgcolor: { xs: '#c62828a0', md: 'error.light' },
                                '&:hover': {
                                    bgcolor: '#c62828e0',
                                },
                                // color: 'background.paper',
                            },
                        },
                        '& .MuiTableRow-root .MuiTableCell-root': {
                            color: 'inherit',
                            px: 1.75,
                        },
                        '& .MuiTableBody-root .MuiTableCell-root': {
                            height: '30px',
                        },
                    },
                }}
                footerRenderer={() => {
                    return (
                        <TableRow>
                            <TableCell
                                colSpan={7}
                                sx={{
                                    '&.MuiTableCell-sizeSmall': {
                                        paddingTop: { xs: '20px', md: 1 },
                                        borderTop: { xs: 'none', md: '1.75em solid transparent' },
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '0.2rem 2rem',
                                        alignContent: 'flex-start',
                                        py: 1,
                                        mt: -1.75,
                                    }}
                                >
                                    {!props.new && (
                                        <>
                                            <Typography variant="body1">
                                                Nová pozorování:&nbsp;
                                                <strong>
                                                    {items.filter((i) => i._Action === ListItemAction.Create).length}
                                                </strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                Změněná pozorování:&nbsp;
                                                <strong>
                                                    {items.filter((i) => i._Action === ListItemAction.Update).length}
                                                </strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                Smazaná pozorování:&nbsp;
                                                <strong>
                                                    {items.filter((i) => i._Action === ListItemAction.Delete).length}
                                                </strong>
                                            </Typography>
                                        </>
                                    )}
                                    {props.new && (
                                        <>
                                            <Typography variant="body1">
                                                Celkem pozorování:&nbsp;
                                                <strong>
                                                    {items.filter((i) => i._Action !== ListItemAction.Delete).length}
                                                </strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                Unikátních druhů:&nbsp;
                                                <strong>
                                                    {
                                                        [
                                                            ...new Set(
                                                                items
                                                                    .filter((i) => i._Action !== ListItemAction.Delete)
                                                                    .map((item) => item.taxonId),
                                                            ),
                                                        ].length
                                                    }
                                                </strong>
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </TableCell>
                        </TableRow>
                    );
                }}
                itemRendererEdit={() => <></>}
                headerRenderer={(items) => {
                    return (
                        <>
                            {[
                                ['', '5%', 50, undefined, null],
                                ['Druh', '25%', 200, undefined, <TaxonLanguageSwitcher />],
                                ['Počet', '10%', 100, undefined, null],
                                ['Pohlaví', '10%', 100, undefined, null],
                                ['Věk', '10%', 100, undefined, null],
                                ['Aktivita', '20%', 100, undefined, null],
                                [' ', '20%', 150, undefined, null],
                            ].map(([label, width, minWidth, center, icon]) => (
                                <TableCell
                                    width={width as string}
                                    align={center as 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined}
                                    key={label as string}
                                    sx={{ minWidth: minWidth as string }}
                                >
                                    {label}
                                    {icon}
                                </TableCell>
                            ))}
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default ObsItemsFormSummary;
