import { Typography } from '@mui/material';
import { FC } from 'react';

const HtmlContent: FC<{ content?: string | null }> = ({ content }) => {
    return (
        <Typography
            variant="body1"
            component="div"
            sx={{
                '& table td, & table th': { border: '1px solid var(--mui-palette-divider)', padding: '0.2rem 0.7rem' },
                '& table': { borderCollapse: 'collapse' },
                '& p, & ul, & ol': { marginTop: '0' },
                '& *:last-child': { marginBottom: '0' },
            }}
            dangerouslySetInnerHTML={{ __html: content ?? '' }}
        />
    );
};

export default HtmlContent;
