import { FormikErrors } from 'formik';
import { IObsListSearchFormValues } from '../apps/ObsListsApp/components/ObsListsSearch';
import { EDateMode } from '../apps/ObsListsApp/components/search/ObsListsSearchDate';
import { IChartsFilter } from '../schemas/interfaces';

export const obsListSearchValidationFunction: (
    values: IObsListSearchFormValues,
) => FormikErrors<IObsListSearchFormValues> = (values) => {
    const errors: FormikErrors<IObsListSearchFormValues> = {};

    // in seasonal mode, _dateStart and _dateEnd are required when any of them is set
    if (
        values._dateMode === EDateMode.Seasonal &&
        (values._dateStart || values._dateEnd || values._yearStart || values._yearEnd)
    ) {
        if (!values._dateEnd) errors._dateEnd = `Vyplňte konec sezóny.`;
        if (!values._dateStart) errors._dateStart = `Vyplňte začátek sezóny.`;
    }

    // _dateStart must be before _dateEnd
    if (
        values._dateMode === EDateMode.Range &&
        values._dateEnd &&
        values._dateStart &&
        values._dateStart > values._dateEnd
    )
        errors._dateEnd = errors._dateStart = `Začátek intervalu nesmí být před jeho koncem.`;

    // _yearStart must be before _yearEnd
    if (values._yearEnd && values._yearStart && values._yearStart > values._yearEnd)
        errors._yearEnd = errors._yearStart = `Začátek intervalu nesmí být před jeho koncem.`;

    return errors;
};

export const chartFilterValidationFunction: (values: IChartsFilter) => FormikErrors<IChartsFilter> = (values) => {
    const errors: FormikErrors<IChartsFilter> = {};

    if (
        values.dateRange &&
        values.dateRange.start &&
        values.dateRange.end &&
        values.dateRange.start > values.dateRange.end
    ) {
        errors.dateRange = {};
        errors.dateRange.start = errors.dateRange.end = `Začátek intervalu nesmí být před jeho koncem.`;
    }

    return errors;
};
