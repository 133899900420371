import { FC } from 'react';
import AvifJoyride from './AvifJoyride';
import { Link } from 'react-router-dom';

const CreateListSummaryTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            continuous
            steps={[
                {
                    target: '.tour-createlistsummary-1',
                    title: 'Odeslání vycházky',
                    content: (
                        <>
                            Ve&nbsp;třetím kroku vkládání můžete zadané údaje zkontrolovat. V&nbsp;případě, že je nutné
                            udělat změny, můžete se jednoduše vrátit na&nbsp;předchozí kroky tlačítkem zpět.
                            V&nbsp;případě, že je všechno v&nbsp;pořádku, klikněte na&nbsp;tlačítko "Odeslat vycházku
                            do&nbsp;databáze".
                        </>
                    ),
                },
                {
                    target: '.tour-createlistsummary-2',
                    disableBeacon: true,
                    title: 'A máme hotovo!',
                    placement: 'center',
                    content: (
                        <>
                            Po&nbsp;úspěšném odeslání se zobrazí text potvrzující, že jste vycházku odeslali. Děkujeme a
                            přejeme příjemné chvíle s&nbsp;ptáky! V&nbsp;případě problémů nás neváhejte kontaktovat
                            na&nbsp;
                            <Link to="mailto:admin@birdlife.cz" target="_blank">
                                admin@birdlife.cz
                            </Link>
                            .
                        </>
                    ),
                },
            ]}
            tourId="createlistsummary"
        />
    );
};

export default CreateListSummaryTour;
