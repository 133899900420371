import { Email } from '@mui/icons-material';
import { Button, Grid, Typography, TextField, Box, Alert, Snackbar } from '@mui/material';
import { Formik, Form } from 'formik';
import { FC, useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import PoppedControl from '../../../components/formControls/PoppedControl';
import { IUserMessage } from '../../../schemas/interfaces';
import { request_user_message_id } from '../../../schemas/schemas';
import { useApi } from '../../../services/apiContext';
import Loading from '../../../components/Loading';
import { LoadingButton } from '@mui/lab';

const formInitialValues: IUserMessage = { subject: '', message: '' };

const MessageUser: FC<{ recipientId: string; recipientName?: string }> = ({ recipientId, recipientName }) => {
    const [state, setState] = useState<{ success?: boolean; loading: boolean }>({ success: undefined, loading: false });
    const api = useApi();

    const handleFormSubmit = (values: IUserMessage, handleClose: () => void) => {
        setState({ ...state, loading: true });
        api.userSendMessage(recipientId, values)
            .then((response) => {
                setState({ ...state, success: response.success, loading: false });
                handleClose();
            })
            .catch((err) => {
                console.error(err);
                setState({ ...state, success: false, loading: false });
            });
    };

    return (
        <Box className="MessageUser">
            <PoppedControl
                renderIcon={() => (
                    <Button variant="contained" color="secondary" sx={{ letterSpacing: '1.5px' }}>
                        <Email />
                        &nbsp;Poslat vzkaz
                    </Button>
                )}
                renderControl={(handleClose) => (
                    <>
                        <Formik<IUserMessage>
                            onSubmit={(values) => handleFormSubmit(values, handleClose)}
                            initialValues={formInitialValues}
                            validationSchema={toFormikValidationSchema(request_user_message_id)}
                        >
                            {({ values, handleBlur, handleChange, touched, errors }) => {
                                return (
                                    <Form>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    maxRows={1}
                                                    value={values.subject}
                                                    label="Nadpis"
                                                    name="subject"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors.subject && touched.subject}
                                                    helperText={errors.subject}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    multiline
                                                    minRows={3}
                                                    maxRows={6}
                                                    value={values.message}
                                                    name={`message`}
                                                    label="Text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={!!errors.message && touched.message}
                                                    helperText={errors.message}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'flex-end',
                                                    gap: '1rem',
                                                }}
                                            >
                                                <Button onClick={() => handleClose()}>Zrušit</Button>
                                                <LoadingButton
                                                    variant={state.loading ? 'outlined' : 'contained'}
                                                    type="submit"
                                                    loading={state.loading}
                                                >
                                                    Odeslat příspěvek
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </>
                )}
                disableButtons
                title={`Poslat vzkaz uživateli ${recipientName}`}
                disableClickaway={true}
            />
            {state.success === true && (
                <Snackbar
                    open={state.success === true}
                    autoHideDuration={6000}
                    onClose={(event, reason) => {
                        if (reason === 'clickaway') return;
                        setState({ ...state, success: undefined });
                    }}
                >
                    <Alert severity="success">Zpráva byla úspěšně odeslána!</Alert>
                </Snackbar>
            )}
            {state.success === false && (
                <Snackbar
                    open={state.success === false}
                    autoHideDuration={6000}
                    onClose={(event, reason) => {
                        if (reason === 'clickaway') return;
                        setState({ ...state, success: undefined });
                    }}
                >
                    <Alert severity="error">Zprávu se nepodařilo odeslat.</Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default MessageUser;
