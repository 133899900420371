import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './authenticator';
import { useApi } from './apiContext';
import { IUser } from '../schemas/interfaces';

export interface IUserContext {
    filters?: IUser['filters'];
    maps?: IUser['maps'];
    areas?: IUser['areas'];
    search?: IUser['search'];
    refetchUser?: () => Promise<IUser>;
}

export const UserContext = createContext<IUserContext>({});

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [user, setUser] = useState<IUser>();
    const { isLoggedIn } = useAuth();
    const { getUser } = useApi();

    useEffect(() => {
        if (isLoggedIn === undefined) return;

        getUser().then((user) => {
            setUser(user);
        });
    }, [isLoggedIn]);

    const refetchUser = async () => {
        const user = await getUser();
        setUser(user);
        return user;
    };

    return <UserContext.Provider value={{ ...user, refetchUser }}>{children}</UserContext.Provider>;
};
