import { FC } from 'react';
import AvifJoyride from './AvifJoyride';

const CreateItemTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            continuous
            showProgress={false}
            steps={[
                {
                    target: '.tour-createitem-1',
                    title: 'Výběr pozorovaných druhů',
                    content: (
                        <>
                            Druhým krokem vkládání vycházky jsou údaje o&nbsp;tom, co jsme na&nbsp;vycházce viděli.
                            Nejprve zaznamenáme, o&nbsp;jaký se jednalo druh – toto je současně jediný povinný údaj
                            k&nbsp;vyplnění v&nbsp;této části zadávání. To, zda vyplníte další detaily je zcela
                            na&nbsp;vás.
                        </>
                    ),
                },
            ]}
            tourId="createitem"
        />
    );
};

export default CreateItemTour;
