import { FunctionComponent } from 'react';
import { ISex } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useDict } from '../../services/dictContext';

/**
 * Implementation of DictionarySelect for sex dictionary.
 */
const SexSelect: FunctionComponent<DictionarySelectPublicProps<number, ISex>> = (props) => {
    const { sex } = useDict();

    return (
        <DictionarySelect<number, ISex>
            color="secondary"
            {...props}
            getDictOptions={
                sex
                    ? new Promise((resolve) => {
                          resolve(sex);
                      })
                    : undefined
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.id)}
            getOptionLabel={(option, dicts = [] as ISex[]) =>
                dicts.find((dict) => dict.id === option)?.name.cs || `Unknown sex ${option}`
            }
        />
    );
};

export default SexSelect;
