import { FC, useEffect, useMemo, useState, useRef } from 'react';
import { Marker, MarkerProps, Polyline, Tooltip, useMap } from 'react-leaflet';
import { Box, Typography } from '@mui/material';
import { LatLng } from 'leaflet';
import { Subject } from 'rxjs';
import ObservationTracks from '../../../../components/map/ObservationTracks';
import ObservationWithObserver from '../../../../components/map/ObservationWithObserver';
import OpenStreetMap, { MapCategory } from '../../../../components/map/OpenStreetMap';
import { ExternalChangeEvent } from '../../../../components/tableEditor/TableEditorRow';
import { ICoordinates } from '../../../../schemas/interfaces';
import { parseCoordinate } from '../../../../services/parsers';
import ObservationsFitBounds from '../../../ObsListsApp/components/tabs/ObservationsFitBounds';
import { ObservationFormValues, ObservationItemFormValues } from '../../ObservationFormApp';
import { useFullwidth } from '../FullwidthContext';

const ObsItemsFormMap: FC<{
    list?: ObservationFormValues;
    items: ObservationItemFormValues[];
    onItemClick?: (item: ObservationItemFormValues, index: number) => void;
    onItemMoved?: (item: ObservationItemFormValues, index: number, coordinates: ICoordinates) => void;
    selectedIndex?: number;
    changeNotifier?: Subject<ExternalChangeEvent>;
}> = (props) => {
    const [mapCenter, setMapCenter] = useState<ICoordinates>();

    useEffect(() => {
        if (props.list?.coordinates) setMapCenter([props.list.coordinates.latitude, props.list.coordinates.longitude]);
        else setMapCenter(undefined);
    }, [props.list?.coordinates]);

    if (!props.list) return null;

    return (
        <Box
            sx={{
                maxHeight: '100%',
                height: 'calc(100vh - 60px - 40px)',
                position: 'sticky',
                top: '80px',
                pb: '50px',
            }}
        >
            <OpenStreetMap
                center={mapCenter} //TODO: || municipality coordinates
                defaultZoom={15}
                minHeight="300px"
                maxHeight="100%"
                height="100%"
                mapCategory={MapCategory.INSERT}
            >
                <ResetDimensionsOnFullwidthChange />
                <ObservationTracks track={props.list.track} plannedTrack={props.list.plannedTrack} />
                {!!props.items && !!props.items.length && (
                    <ObservationsFitBounds
                        observations={props.items.map((item) => ({
                            location: { coordinates: item.coordinates },
                        }))}
                        onlyOnce
                    />
                )}
                {props.items.map((item, index) => {
                    if (!item.coordinates) return null;

                    const active = props.selectedIndex === index;

                    return (
                        <ObservationWithObserver
                            key={index}
                            observer={
                                item.observersCoordinates
                                    ? [item.observersCoordinates.latitude, item.observersCoordinates.longitude]
                                    : undefined
                            }
                            observation={[item.coordinates.latitude, item.coordinates.longitude]}
                            taxonId={item.taxonId}
                            observationId={item.id}
                            enableHoverHighlight={true}
                            highlighted={active}
                            onClick={() => props.onItemClick?.(item, index)}
                            onObservationMoved={(coordinates) => props.onItemMoved?.(item, index, coordinates)}
                        />
                        // <ClickableMarker
                        //     position={[item.coordinates.latitude, item.coordinates.longitude]} // done
                        //     icon={active ? customIcon : customIconDimmed} // done
                        //     key={index} // done
                        //     onClick={() => props.onItemClick?.(item, index)}
                        //     onPositionChange={(coordinates) => props.onItemMoved?.(item, index, coordinates)}
                        //     draggable={active}
                        //     changeNotifier={props.changeNotifier}
                        //     index={index}
                        // >
                        //     <Tooltip direction="bottom" offset={[0, 0]}>
                        //         <TaxonFromDict taxonId={item.taxonId} />
                        //         {!!item.ageId ||
                        //             item.sexId ||
                        //             (!!item.count && (
                        //                 <>
                        //                     , <CountFromDict ageId={item.ageId} sexId={item.sexId} count={item.count} />
                        //                 </>
                        //             ))}
                        //     </Tooltip>
                        // </ClickableMarker>
                    );
                })}
            </OpenStreetMap>
            <Typography
                variant="body2"
                component="span"
                fontWeight="bold"
                sx={{
                    position: 'absolute',
                    top: '0.7em',
                    bgcolor: 'action.selected',
                    zIndex: 450,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    py: 0.25,
                    px: 1,
                    borderRadius: 'var(--mui-shape-borderRadius)',
                    textAlign: 'center',
                    width: 'calc(100% - 160px)',
                }}
            >
                {props.selectedIndex === undefined &&
                    'Pozorování můžete vybrat kliknutím do\x0amapy nebo na\x0ařádek v\x0atabulce.'}
                {props.selectedIndex !== undefined &&
                    'Nyní můžete jednoduše změnit souřadnice pozorování přetažením bodu v\x0amapě.'}
            </Typography>
        </Box>
    );
};

export default ObsItemsFormMap;

const ClickableMarker: FC<
    MarkerProps & {
        onClick?: () => void;
        onPositionChange?: (coordinates: ICoordinates) => void;
        position: ICoordinates;
        changeNotifier?: Subject<ExternalChangeEvent>;
        index: number;
    }
> = (props) => {
    const [position, setPosition] = useState<[number?, number?]>(props.position || [undefined, undefined]);
    const markerRef = useRef(null);

    useEffect(() => {
        props.changeNotifier?.subscribe((event) => {
            if (event.index !== props.index) return;

            // handle coordinates removal
            if (event.fieldName === 'coordinates' && !event.fieldValue) return setPosition([undefined, undefined]);

            // handle full set event (e.g. click in map)
            if (event.fieldName === 'coordinates' && event.fieldValue)
                setPosition([event.fieldValue.latitude as number, event.fieldValue.longitude as number]);

            // also accepts partial update events, so when filled manually, we keep the partial values
            if (event.fieldName === 'coordinates.latitude') setPosition([event.fieldValue as number, position[1]]);
            if (event.fieldName === 'coordinates.longitude') setPosition([position[0], event.fieldValue as number]);
        });
    }, [position]);

    // useEffect(() => {
    //     setPosition(props.position);
    // }, [props.position]);

    const changePosition = (newPosition: LatLng) => {
        const coordinates: ICoordinates = [
            parseCoordinate(newPosition.lat) as number,
            parseCoordinate(newPosition.lng) as number,
        ];
        setPosition(coordinates);
        props.onPositionChange?.(coordinates);
    };

    const eventHandlers = useMemo(
        () => ({
            click: () => {
                props.onClick?.();
            },
            dragend: () => {
                const marker = markerRef.current;
                if (marker != null) changePosition((marker as any).getLatLng());
            },
        }),
        [props.onClick],
    );

    const { onClick, position: propsPosition, ...markerProps } = props;

    // only renders when both latitude and longitude are numeric
    if (!position || position[0] === undefined || position[1] === undefined) return null;

    return (
        <Marker {...markerProps} position={position as ICoordinates} eventHandlers={eventHandlers} ref={markerRef} />
    );
};

const ResetDimensionsOnFullwidthChange: FC = () => {
    const { fullwidth, mapEnabled } = useFullwidth();
    const map = useMap();

    useEffect(() => {
        if (!map) return;

        map.invalidateSize();
    }, [fullwidth, mapEnabled]);

    return null;
};
