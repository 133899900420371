import { FunctionComponent } from 'react';
import DictionaryAutocomplete, { DictionaryAutocompletePublicProps } from './DictionaryAutocomplete';
import { IPlace } from '../../schemas/interfaces';
import { useDict } from '../../services/dictContext';

interface PlacesAutocompleteProps extends DictionaryAutocompletePublicProps<IPlace, number> {
    enableGrouping?: boolean;
}

/**
 * Implementation of DictionarySelect for age dictionary.
 */
const PlacesAutocomplete: FunctionComponent<PlacesAutocompleteProps> = (props) => {
    const { places } = useDict();

    return (
        <DictionaryAutocomplete<IPlace, number>
            {...props}
            getDictOptions={
                places
                    ? new Promise((resolve) => {
                          resolve(
                              places.sort((a, b) => {
                                  if (a.type === b.type) return a.name.localeCompare(b.name);
                                  return a.type - b.type;
                              }),
                          );
                      })
                    : undefined
            }
            getOptionLabel={(place) => {
                return place.name;
            }}
            getOptionById={(id, dicts) => dicts.find((dict) => dict.id === id)}
            AutocompleteProps={{
                color: 'secondary',
            }}
            stringifyOption={(place: IPlace) => {
                const name = place.name;
                const nameParts = name
                    .split(' - ')
                    .map((part) => part.trim().replaceAll('[', '').replaceAll(']', '').split(' '))
                    .flat();

                return `${nameParts.join('|')}|${name}`;
            }}
            placeholder="Vyberte ze&nbsp;seznamu"
            noResultsText="Musíte vybrat jednu z&nbsp;nabízených položek. Upravte dotaz."
            groupBy={
                props.enableGrouping
                    ? (place) => {
                          switch (place.type) {
                              case 0:
                                  return 'Státy';
                              case 3:
                                  return 'Kraje';
                              case 4:
                                  return 'Okresy';
                              case 5:
                                  return 'Obce';
                              case 6:
                                  return 'Části obcí';
                              default:
                                  return `Nezařazeno (${place.type})`;
                          }
                      }
                    : undefined
            }
        />
    );
};

export default PlacesAutocomplete;
