import { Box, CircularProgress, CircularProgressProps, SxProps } from '@mui/material';
import { FunctionComponent } from 'react';

interface LoadingProps {
    fullWidth?: boolean;
    fullPage?: boolean;
    inline?: boolean;
    boxSx?: SxProps;
    progressProps?: CircularProgressProps;
}

const Loading: FunctionComponent<LoadingProps> = (props) => {
    const sx: SxProps = {
        boxSizing: 'border-box',
        display: 'block',
        ...(props.inline ? { display: 'inline-flex' } : {}),
        ...(props.fullWidth
            ? { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', pt: 5.75, pb: 6.75 }
            : {}),
        ...(props.fullPage
            ? { width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }
            : {}),
        ...props.boxSx,
    };

    const progressProps: CircularProgressProps = {
        color: 'secondary',
        thickness: 4,
        size: 40,
        ...props.progressProps,
    };

    return (
        <Box sx={sx} component="span">
            <CircularProgress {...progressProps} />
        </Box>
    );
};

export default Loading;
