import { FC, useRef, useState } from 'react';
import { IComment } from '../../schemas/interfaces';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import CommentEditor, { CommentEditorValues } from './CommentEditor';
import Comment from './Comment';
import { useApi } from '../../services/apiContext';
import { useAuth } from '../../services/authenticator';
import { CommentRights } from '../../schemas/enums';

export const CommentsThread: FC<{
    comments: IComment[];
    listId: string;
    itemId: number;
    defaultSubject?: string;
    locked?: boolean;
}> = (props) => {
    const [state, setState] = useState<{
        addingComment: boolean;
        comments: IComment[];
        snackbarContent?: string;
        snackbarSeverity?: 'success' | 'info' | 'warning' | 'error';
        forceCloseForms: boolean;
    }>({
        addingComment: false,
        comments: props.comments,
        forceCloseForms: false,
    });
    const { listId, itemId } = props;
    const { comments, snackbarContent, addingComment } = state;
    const newCommentRef = useRef<HTMLElement>(null);
    const { isLoggedIn } = useAuth();
    const api = useApi();

    const onNewComment = (comment: CommentEditorValues, parentId?: number) => {
        return new Promise<void>((resolve, reject) => {
            if (!isLoggedIn) return reject(new Error('User is not logged in.'));
            if (props.locked) return reject(new Error('Comments are locked.'));

            // try {
            return api
                .postItemComment(listId, itemId, comment.text, comment.subject, parentId)
                .then((response) => {
                    if (!response.success)
                        throw new Error(`Server returned success=false and message=${response.message?.cs}`);

                    const newComments: IComment[] = [...state.comments, response.comment];

                    setState((state) => ({
                        ...state,
                        addingComment: false,
                        comments: newComments,
                    }));

                    return resolve();
                })
                .catch((err) => {
                    setState((state) => ({
                        ...state,
                        snackbarContent: 'Nepodařilo se přidat komentář. Zkuste to prosím později.',
                        snackbarSeverity: 'error',
                    }));
                    return reject(err);
                });
        });
    };

    const onCommentEdited = (comment: IComment) => {
        return new Promise<void>((resolve, reject) => {
            if (!isLoggedIn) return reject(new Error('User is not logged in.'));
            if (props.locked) return reject(new Error('Comments are locked.'));
            if (!comment.rights.includes(CommentRights.edit))
                return reject(new Error('User does not have edit rights.'));

            return api
                .updateItemComment(listId, itemId, comment.id, comment.text, comment.subject || '')
                .then((response) => {
                    if (!response.success)
                        throw new Error(`Server returned success=false and message=${response.message?.cs}`);

                    const newComments: IComment[] = [...state.comments];
                    const commentIndex = newComments.findIndex((c) => c.id === comment.id);
                    newComments[commentIndex] = response.comment;

                    setState((state) => ({
                        ...state,
                        comments: newComments,
                        snackbarContent: 'Komentář byl upraven.',
                        snackbarSeverity: 'success',
                    }));

                    return resolve();
                })
                .catch((err) => {
                    setState((state) => ({
                        ...state,
                        snackbarContent: 'Nepodařilo se upravit komentář. Zkuste to prosím později.',
                        snackbarSeverity: 'error',
                    }));
                    return reject(err);
                });
        });
    };

    const onCommentDeleted = (comment: IComment) => {
        return new Promise<void>((resolve, reject) => {
            if (!isLoggedIn) return reject(new Error('User is not logged in.'));
            if (props.locked) return reject(new Error('Comments are locked.'));
            if (!comment.rights.includes(CommentRights.delete))
                return reject(new Error('User does not have delete rights.'));

            return api
                .deleteItemComment(listId, itemId, comment.id)
                .then((response) => {
                    if (!response.success)
                        throw new Error(`Server returned success=false and message=${response.message?.cs}`);

                    const newComments: IComment[] = [...state.comments];
                    const commentIndex = newComments.findIndex((c) => c.id === comment.id);

                    newComments[commentIndex] = { ...comment, deleted: new Date(), text: '', subject: null };

                    setState((state) => ({
                        ...state,
                        comments: newComments,
                        snackbarContent: 'Komentář byl smazán.',
                        snackbarSeverity: 'success',
                    }));

                    return resolve();
                })
                .catch((err) => {
                    setState((state) => ({
                        ...state,
                        snackbarContent: 'Nepodařilo se smazat komentář. Zkuste to prosím později.',
                        snackbarSeverity: 'error',
                    }));
                    return reject(err);
                });
        });
    };

    const onCommentFormOpen = () => {
        setState((state) => ({ ...state, forceCloseForms: true, addingComment: false }));

        setTimeout(() => {
            setState((state) => ({ ...state, forceCloseForms: false }));
        });
    };

    const onAddCommentClick = () => {
        setState((state) => ({ ...state, addingComment: true, forceCloseForms: true }));

        setTimeout(() => {
            setState((state) => ({ ...state, forceCloseForms: false }));
        });
    };

    return (
        <Box className="CommentsThread">
            {comments && comments.length > 3 && isLoggedIn && !props.locked && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setState({ ...state, addingComment: true });
                        newCommentRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    sx={{ mb: 1.75 }}
                >
                    Vložit komentář
                </Button>
            )}
            {(comments || [])
                .filter((comment) => comment.parentId == null)
                .map((comment) => (
                    <Comment
                        comment={comment}
                        key={comment.id}
                        allComments={comments}
                        replies={(comments || []).filter(
                            (reply) => reply.parentId == comment.id && reply.id != comment.id,
                        )}
                        onReplyAdded={onNewComment}
                        onCommentEdited={onCommentEdited}
                        onCommentDeleted={onCommentDeleted}
                        commentLevel={0}
                        onOpen={onCommentFormOpen}
                        forceCloseForms={state.forceCloseForms}
                        repliesAllowed={isLoggedIn && !props.locked}
                    />
                ))}
            {!addingComment && isLoggedIn && !props.locked && (
                <Button variant="contained" color="secondary" onClick={onAddCommentClick}>
                    Vložit komentář
                </Button>
            )}
            <Box ref={newCommentRef}>
                {addingComment && (
                    <CommentEditor
                        defaultSubject={props.defaultSubject}
                        title="Přidat nový komentář"
                        submitButtonLabel="Přidat komentář"
                        onSubmit={onNewComment}
                        onClose={() => setState({ ...state, addingComment: false })}
                    />
                )}
            </Box>
            {!!props.locked && (
                <Typography variant="body2" component="p">
                    Komentáře byly uzamčeny a nelze vkládat nové, editovat nebo mazat staré.
                </Typography>
            )}
            <Snackbar
                open={snackbarContent !== undefined}
                autoHideDuration={6000}
                onClose={(_, reason) => {
                    if (reason === 'clickaway') return;
                    setState({ ...state, snackbarContent: undefined, snackbarSeverity: undefined });
                }}
            >
                <Alert
                    severity={state.snackbarSeverity ?? 'error'}
                    onClose={() => setState({ ...state, snackbarContent: undefined })}
                >
                    {snackbarContent}
                </Alert>
            </Snackbar>
        </Box>
    );
};
