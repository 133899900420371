import { Box, Fab, Tooltip, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import AppHeader from './AppHeader';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import AppFooter from './AppFooter';
import { Add } from '@mui/icons-material';
import ScrollToTop from './ScrollToTop';
import { useAuth } from '../services/authenticator';
import IntroTour from './joyride/IntroTour';

const AppContent: FC = () => {
    const isMobile = useMediaQuery('(pointer: coarse)');
    const { isLoggedIn } = useAuth();
    const { pathname } = useLocation();

    if (pathname.includes('/iframe')) {
        return (
            <Box className="AppContent EmbeddedApp" sx={{ width: '100%' }}>
                <Outlet />
            </Box>
        );
    }

    const fab = (
        <Fab color="secondary" aria-label="add new observation" sx={{ cursor: 'pointer' }}>
            <Add />
        </Fab>
    );

    return (
        <Box className="AppContent" sx={{ width: '100%' }}>
            <IntroTour />
            <AppHeader />
            <Box
                className="AppContent__body"
                sx={{
                    paddingTop: isMobile ? '56px' : '64px',
                    bgcolor: 'primary.50',
                    minHeight: '100vh',
                    boxSizing: 'border-box',
                    backgroundImage: 'url(/img/background-rotated.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom left',
                    backgroundSize: 'auto 60vh',
                    backgroundAttachment: 'fixed',
                }}
            >
                <ScrollToTop />
                <Outlet />
            </Box>
            <AppFooter />
            {isLoggedIn && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: '2rem',
                        right: '2rem',
                        zIndex: 450,
                        '& a.active .MuiFab-root': { display: 'none' },
                    }}
                >
                    <Tooltip title={'Vložit pozorování'} placement="left">
                        <Box>{isLoggedIn && <NavLink to="/create">{fab}</NavLink>}</Box>
                    </Tooltip>
                </Box>
            )}
        </Box>
    );
};

export default AppContent;
