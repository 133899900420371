import { Grid, Card, CardContent, Typography, Tooltip, Box, Button, useMediaQuery } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import HtmlContent from './HtmlContent';
import CopyToClipboard from '../map/CopyToClipboard';
import { Link, useLocation } from 'react-router-dom';
import { Link as LinkIcon } from '@mui/icons-material';
import Loading from '../Loading';
import { useJoyride } from '../../services/joyrideContext';
import { useAuth } from '../../services/authenticator';

const ContentSectionCards: FC<{
    sections?: { title?: string | null; content?: string | null; id?: string | null }[];
    noSectionsText?: string;
}> = ({ sections, noSectionsText }) => {
    const location = useLocation();
    const sectionRef = useRef<HTMLDivElement>(null);
    const { enableTour } = useJoyride();
    const isMobile = useMediaQuery('(pointer: coarse)');
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        scrollToSection();
    }, [location.hash, sectionRef.current]);

    const scrollToSection = () => {
        const hash = location.hash.substring(1);
        const element = document.getElementById(hash);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Grid container spacing={3}>
            {!sections && (
                <Grid item xs={12}>
                    <Loading fullPage />
                </Grid>
            )}
            {sections && !sections.length && (
                <Grid item xs={12}>
                    <Typography variant="body1" component="p">
                        {noSectionsText ?? 'Aktuálně zde nejsou žádné sekce.'}
                    </Typography>
                </Grid>
            )}
            {sections &&
                sections.map((section) => {
                    const sectionUrl =
                        window.location.href.substring(
                            0,
                            window.location.href.indexOf('#') === -1 ? undefined : window.location.href.indexOf('#'),
                        ) +
                        '#' +
                        section.id;

                    return (
                        <Grid item xs={12} key={section.id} ref={sectionRef}>
                            <Card elevation={2}>
                                <CardContent
                                    sx={{
                                        '& .CopyToClipboard': { display: 'none' },
                                        '&:hover .CopyToClipboard': { display: 'inline-block' },
                                        '&:last-child': { pb: 3.75 },
                                        px: 3,
                                        pt: 3.25,
                                        pb: 3.75,
                                        position: 'relative',
                                    }}
                                >
                                    <Box
                                        id={section.id ?? ''}
                                        sx={{
                                            position: 'absolute',
                                            visibility: 'hidden',
                                            marginTop: '-90px',
                                        }}
                                    />
                                    {section.title && (
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            lineHeight="2em"
                                            sx={{
                                                pb: 1,
                                            }}
                                        >
                                            {section.title}
                                            <Tooltip title="Kopírovat odkaz na&nbsp;tuto sekci" placement="right">
                                                <Link to={sectionUrl}>
                                                    <CopyToClipboard
                                                        label=""
                                                        value={sectionUrl}
                                                        onlyIcon
                                                        customIcon={
                                                            <LinkIcon
                                                                fontSize="inherit"
                                                                sx={{ transform: 'rotate(45deg)' }}
                                                            />
                                                        }
                                                        typographyProps={{
                                                            sx: { position: 'relative', top: '-5px', ml: 1 },
                                                        }}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        </Typography>
                                    )}
                                    {section.content && <HtmlContent content={section.content} />}
                                    {section.id === 'vkladani-pozorovani' && !isMobile && isLoggedIn && (
                                        <Link to="/create">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                sx={{
                                                    position: 'absolute',
                                                    right: '1.5rem',
                                                    top: '1.75rem',
                                                }}
                                                onClick={() => {
                                                    if (!enableTour) return;

                                                    enableTour('createlist');
                                                    enableTour('createitem');
                                                    enableTour('createitemdetails');
                                                    enableTour('createlistsummary');
                                                }}
                                            >
                                                Spustit interaktivní nápovědu
                                            </Button>
                                        </Link>
                                    )}
                                    {section.id === 'filtrovani-pozorovani' && !isMobile && (
                                        <Link to="/search">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                sx={{
                                                    position: 'absolute',
                                                    right: '1.5rem',
                                                    top: '1.75rem',
                                                }}
                                                onClick={() => {
                                                    if (!enableTour) return;

                                                    enableTour('search');
                                                    enableTour('advancedsearch');
                                                }}
                                            >
                                                Spustit interaktivní nápovědu
                                            </Button>
                                        </Link>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
        </Grid>
    );
};

export default ContentSectionCards;
