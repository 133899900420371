import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import ClearableTextField, { ClearableTextFieldProps } from './ClearableTextField';

export type NumberFieldProps = ClearableTextFieldProps & {
    min?: number;
    max?: number;
    step?: number;
    onEmptyOrInvalid?: any;
    /** Formik's handleChange doesn't work with this event, use setFieldValue instead. */
    onChange?: TextFieldProps['onChange'];
};

const NumberField: FC<NumberFieldProps> = (props) => {
    const { onChange, min, max, step, onEmptyOrInvalid, ...propsWithoutOverrides } = props;

    return (
        <ClearableTextField
            type="number"
            inputProps={{
                min: props.min,
                step: props.step,
                max: props.max,
                inputMode: 'numeric',
                lang: 'cs',
            }}
            onKeyDown={(e) => {
                const charCode = e.key;

                if (charCode.length > 1 || !!e.ctrlKey || !!e.altKey) return;

                if (!charCode.match(/^[0-9.,]$/)) e.preventDefault();
            }}
            onChange={(e) => {
                const value = parseFloat(e.target.value);

                props.onChange &&
                    props.onChange({
                        ...e,
                        target: {
                            ...e.target,
                            value: isNaN(value)
                                ? props.onEmptyOrInvalid === undefined
                                    ? undefined
                                    : props.onEmptyOrInvalid
                                : value,
                        },
                    });
            }}
            {...propsWithoutOverrides}
        />
    );
};

export default NumberField;
