import { FunctionComponent, useContext } from 'react';
import LoginApp from './LoginApp/LoginApp';
import { AuthContext } from '../services/authenticator';

interface HomeAppProps {
    login?: boolean;
}

const HomeApp: FunctionComponent<HomeAppProps> = (props) => {
    return (
        <div>
            <h1>Home</h1>
        </div>
    );
};

export default HomeApp;
