import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IHelpSection, IFaqSection } from '../../schemas/interfaces';
import { useApi } from '../../services/apiContext';
import { ArrowDropDown } from '@mui/icons-material';
import ContentSectionCards from '../../components/contentPages/ContentSectionCards';
import HtmlContent from '../../components/contentPages/HtmlContent';

const HelpApp: FC = () => {
    const [state, setState] = useState<{ helpSections?: IHelpSection[]; faqSections?: IFaqSection[] }>({});
    const api = useApi();

    useEffect(() => {
        api.getHelpSections()
            .then((helpSections) => setState((s) => ({ ...s, helpSections })))
            .catch(console.error);
        api.getFaqSections()
            .then((faqSections) => setState((s) => ({ ...s, faqSections })))
            .catch(console.error);
    }, []);

    return (
        <Container className="HelpApp" maxWidth="lg" sx={{ pt: 5.75, pb: 6.75 }}>
            <Typography variant="h2" component="h1" textAlign="center" sx={{ pb: 3.75 }}>
                Nápověda
            </Typography>
            <ContentSectionCards sections={state.helpSections} />
            {state.faqSections && state.faqSections.length > 0 && (
                <Box id="faq">
                    <Typography variant="h2" component="h2" textAlign="center" sx={{ pb: 1.75, pt: 5.75 }}>
                        Často kladené dotazy
                    </Typography>
                    {state.faqSections.map((section) => (
                        <Accordion key={section.id} sx={{ my: 2 }}>
                            <AccordionSummary expandIcon={<ArrowDropDown fontSize="large" />}>
                                <Typography variant="body1" component="h3" fontWeight="bold">
                                    {section.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <HtmlContent content={section.content} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            )}
        </Container>
    );
};

export default HelpApp;
