import {
    Box,
    Button,
    Grid,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { IChartsFilter, schema_chartsFilter } from '../../../schemas/interfaces';
import SpeciesAutocomplete from '../../../components/formControls/SpeciesAutocomplete';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Clear, Search } from '@mui/icons-material';
import PoppedControl from '../../../components/formControls/PoppedControl';
import { DatePicker } from '@mui/x-date-pickers';
import { AnalyticsDateMode } from '../../../schemas/enums';
import translateErrorMessage from '../../../services/errorMessages';
import { endOfMonth, format, isValid, parse, startOfMonth } from 'date-fns';
import { chartFilterValidationFunction } from '../../../services/validators';

export interface ChartFilterProps {
    query?: IChartsFilter;
    onSubmit: (query: IChartsFilter) => void;
}

// all fields should be set here but since validation is not necessary for all fields,
// we can skip them
const defaultValues: IChartsFilter = {
    dateMode: 'months',
    dateRange: {
        start: null,
        end: null,
    },
    taxonId: [],
} as unknown as IChartsFilter;

const ChartFilter: React.FunctionComponent<ChartFilterProps> = (props) => {
    const isMobile = useMediaQuery('(pointer: coarse)');

    const onSubmit = (values: IChartsFilter, helpers: FormikHelpers<IChartsFilter>) => {
        const parsedValues = schema_chartsFilter.safeParse(values);

        if (!parsedValues.success) {
            console.error(parsedValues.error);
        } else {
            props.onSubmit(parsedValues.data as IChartsFilter);
        }

        helpers.setSubmitting(false);
    };

    const dateToString = (input: Date | null | undefined, end?: boolean) => {
        if (!input || !isValid(input)) return null;

        const date = end ? endOfMonth(input) : startOfMonth(input);

        // maximum date is today
        return format(date > new Date() ? new Date() : date, 'yyyy-MM-dd');
    };

    const stringToDate = (input: string | null | undefined) => {
        if (!input) return null;

        const date = parse(input, 'yyyy-MM-dd', new Date());

        return isValid(date) ? date : null;
    };

    return (
        <div className="ObsListsSearch">
            <Formik<IChartsFilter>
                initialValues={{ ...defaultValues, ...props.query }}
                enableReinitialize
                validationSchema={toFormikValidationSchema(schema_chartsFilter)}
                validate={chartFilterValidationFunction}
                onSubmit={onSubmit}
            >
                {(formikProps: FormikProps<IChartsFilter>) => {
                    const { values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched } =
                        formikProps;

                    return (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Grid container spacing={1.75}>
                                <Grid item xs={12} md={6}>
                                    <SpeciesAutocomplete
                                        multiple
                                        name="taxonId"
                                        onChange={(value) => {
                                            formikProps.setFieldValue('taxonId', value);
                                        }}
                                        optionsFilter={(taxons) => taxons.filter((taxon) => taxon.genusId)}
                                        onBlur={handleBlur}
                                        value={values.taxonId || []}
                                        label="Vyberte jeden až tři druhy"
                                        noResultsText="Tomuto dotazu neodpovídá žádný druh. Prosím vyberte z&nbsp;nabízených druhů."
                                        AutocompleteProps={{
                                            limitTags: 5,
                                        }}
                                        TextFieldProps={{
                                            error: touched.taxonId && !!errors.taxonId,
                                            helperText:
                                                touched.taxonId && translateErrorMessage(errors.taxonId as string),
                                        }}
                                        placeholder={(values.taxonId ?? []).length >= 3 ? ' ' : undefined}
                                        highlightWhenFilled
                                        disableNonBirdSpecies
                                        enableLanguageSwitch
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <DatePicker<Date>
                                        label="Datum od"
                                        value={stringToDate(values.dateRange?.start)}
                                        disableFuture
                                        onChange={async (value) => {
                                            await setFieldValue('dateRange.start', dateToString(value), false);
                                            setFieldTouched('dateRange.start', true, true);
                                        }}
                                        maxDate={stringToDate(values.dateRange?.end) ?? undefined}
                                        minDate={new Date(2010, 2, 1)}
                                        views={['year', 'month']}
                                        slotProps={{
                                            textField: {
                                                name: 'dateRange.start',
                                                id: 'dateRange.start',
                                                fullWidth: true,
                                                className: values.dateRange?.start ? ' nonEmpty' : '',
                                                onBlur: handleBlur,
                                                error: touched.dateRange?.start && !!errors.dateRange?.start,
                                                helperText:
                                                    touched.dateRange?.start &&
                                                    translateErrorMessage(errors.dateRange?.start),
                                                color: 'secondary',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <DatePicker<Date>
                                        label="Datum do"
                                        value={stringToDate(values.dateRange?.end)}
                                        disableFuture
                                        onChange={async (value) => {
                                            await setFieldValue('dateRange.end', dateToString(value, true), false);
                                            setFieldTouched('dateRange.end', true, true);
                                        }}
                                        views={['year', 'month']}
                                        minDate={stringToDate(values.dateRange?.start) ?? new Date(2010, 2, 1)}
                                        slotProps={{
                                            textField: {
                                                name: 'dateRange.end',
                                                id: 'dateRange.end',
                                                fullWidth: true,
                                                className: values.dateRange?.end ? ' nonEmpty' : '',
                                                onBlur: handleBlur,
                                                error: touched.dateRange?.end && !!errors.dateRange?.end,
                                                helperText:
                                                    touched.dateRange?.end &&
                                                    translateErrorMessage(errors.dateRange?.end),
                                                color: 'secondary',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: 2.5,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="subtitle1">Granularita grafu:</Typography>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <ToggleButtonGroup
                                            value={values.dateMode}
                                            exclusive
                                            onChange={(_, newValue) => {
                                                if (!newValue) return;

                                                setFieldValue('dateMode', newValue);
                                            }}
                                            fullWidth
                                            sx={{ height: '53px' }}
                                        >
                                            <ToggleButton value={AnalyticsDateMode.months}>Měsíce</ToggleButton>
                                            <ToggleButton value={AnalyticsDateMode.years}>Roky</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: 1.75,
                                        order: isMobile ? 1 : undefined,
                                    }}
                                >
                                    <PoppedControl
                                        renderIcon={() => (
                                            <Tooltip title="Vyčistit filtr" placement="bottom">
                                                <IconButton
                                                    color="primary"
                                                    sx={{
                                                        height: '53px',
                                                        width: '53px',
                                                    }}
                                                >
                                                    <Clear fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        renderControl={() => null}
                                        onConfirmed={() => {
                                            formikProps.setValues({ ...defaultValues });
                                        }}
                                        title="Opravdu si přejete vyčistit celý filtr?"
                                        confirmButtonLabel="Ano"
                                        cancelButtonLabel="Zpět"
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ flexGrow: 1, height: '53px' }}
                                        onClick={() => handleSubmit()}
                                        startIcon={<Search fontSize="large" />}
                                    >
                                        Vyhledat
                                    </Button>
                                </Grid>
                                {/* <Grid>
                                    <Box>
                                        <code>{JSON.stringify(errors)}</code>
                                    </Box>
                                    <Box>
                                        <code>{JSON.stringify(touched)}</code>
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ChartFilter;
