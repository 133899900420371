import { FC } from 'react';
import AvifJoyride from './AvifJoyride';
import { Link } from 'react-router-dom';

const CreateItemDetailsTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            continuous
            steps={[
                {
                    target: '.tour-createitemdetails-1',
                    title: 'Druh',
                    content: (
                        <>
                            V&nbsp;případě, že si druhem nejste jistí, máte dvě možnosti: buď zadat druh jako neurčen,
                            což učiníte tak, že napíšete do&nbsp;pole druh slovo "neurčen". Nebo požádejte o&nbsp;
                            <b>"pomoc s&nbsp;určením"</b> ostatní uživatele Avifu (více informací v&nbsp;dalším kroku).
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-2',
                    disableCloseOnEsc: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    disableScrolling: true,
                    title: 'Pomoc s určením',
                    content: (
                        <>
                            V&nbsp;tomto případě do&nbsp;pole "Druh" napište, který druh by to podle vás mohl být, a
                            následně ho označte políčkem <b>"Prosím o&nbsp;pomoc s&nbsp;určením"</b>, které se skrývá
                            v&nbsp;nabídce <b>"Označit pozorování jako"</b>. Více o&nbsp;pomoci s&nbsp;určením se
                            dozvíte v&nbsp;<Link to="/help#faq">nápovědě</Link>. Pozorování k&nbsp;dourčení prosím
                            doplňte i fotkami a poznámkou obsahující více informací (popis pozorovaného ptáka, lokality
                            atd.).
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-3',
                    disableCloseOnEsc: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    disableScrolling: true,
                    title: 'Označit pozorování jako',
                    content: (
                        <>
                            Kromě pomoci s&nbsp;určením je možné označit pozorování jako <b>zajímavé</b> (např. běžný
                            druh hnízdící na&nbsp;zajímavém místě), <b>nejisté</b> (např. prolétla sova,
                            na&nbsp;99&nbsp;% to byla pustovka, ale nejsem si tím úplně jist) či polem{' '}
                            <b>první přílety</b> (označuje přílety tažných ptáků na&nbsp;lokalitu).
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-4',
                    disableCloseOnEsc: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    disableScrolling: true,
                    title: 'Kroužek/značka a řazení do skupin',
                    content: (
                        <>
                            Pod&nbsp;třemi tečkami na&nbsp;konci řádku se navíc skrývá možnost zadat{' '}
                            <b>kroužek/značku</b> či <b>rozřadit pozorování ve&nbsp;vycházce do&nbsp;skupin</b> (které
                            slouží např. pro&nbsp;zadávání kachních rodinek).
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-6',
                    title: 'Počet',
                    content: (
                        <>
                            Pro&nbsp;každé pozorování je možné vyplnit důležité doprovodné informace. Počet lze zadávat
                            ve&nbsp;formátech: 5, min. 5, max 5, &gt;5, 5–10, cca 10.
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-7',
                    title: 'Pohlaví',
                    content: (
                        <>
                            V&nbsp;tomto poli je možné určit pohlaví. Pokud jste během vycházky zaznamenali jedince
                            stejného druhu různého pohlaví, můžete je zadat jako pár (pokud jím byli). V&nbsp;tomto
                            případě ale pozor na&nbsp;počet! Kombinace počet = 2, pohlaví = pár ve&nbsp;výsledku znamená
                            4 jedinci. Pokud vidíte jeden pár, zadejte počet = 1, pohlaví = pár. Pokud zaznamenáte
                            jedince stejného druhu s&nbsp;různým pohlavím, kteří ale netvořili pár, zadejte je
                            do&nbsp;různých řádků. Kategorii samice/mládě lze využít u&nbsp;druhů, u&nbsp;kterých je
                            nevybarvený mladý samec velmi obtížně rozeznatelný od&nbsp;samice, např. u&nbsp;rehka
                            domácího.
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-8',
                    title: 'Věk',
                    content: (
                        <>
                            Pokud jste si na&nbsp;základě relevantních určovacích znaků jisti, o&nbsp;jak starého
                            jedince se jedná, zadejte příslušnou věkovou kategorii.
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-9',
                    title: 'Aktivita',
                    content: (
                        <>
                            Vyberte nejvyšší kategorii z&nbsp;Hnízdního atlasu či nejdetailnější kategorii,
                            do&nbsp;které pozorování spadá.
                            <br /> PŘÍKLAD 1: Pár v&nbsp;hnízdní době ve&nbsp;vhodném prostředí, tento pár má hnízdo
                            s&nbsp;vejci. Zadejme <b>hnízdo s&nbsp;vejci</b> jako nejvyšší možnou kategorii
                            z&nbsp;hnízdního atlasu.
                            <br /> PŘÍKLAD 2: Vidíme káni sedět na&nbsp;sloupu vedení, posléze uvidíme, jak narazila
                            do&nbsp;drátů a zabila se. Zadejme <b>mrtvý pod&nbsp;dráty vedení</b>. To, že spadá i
                            do&nbsp;kategorie <b>sedí na drátech el. vedení</b>, není pro&nbsp;situaci zas až tak
                            podstatné.
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-10',
                    title: 'Poznámka',
                    content: (
                        <>
                            Poznámka slouží k&nbsp;slovnímu popsání detailů pozorování. Využijete ji především u&nbsp;
                            <b>vzácných, neobvyklých či zajímavých pozorování</b> a dále také u&nbsp;pozorování
                            označených jako <b>pomoc s&nbsp;určením</b>.
                        </>
                    ),
                },
                {
                    target: '.tour-createitemdetails-11',
                    title: 'Fotografie',
                    content: <>Ke&nbsp;každému pozorování můžete nahrát až tři fotografie.</>,
                },
                {
                    target: '.tour-createitemdetails-12',
                    title: 'GPS souřadnice',
                    content: <>V&nbsp;rámci vycházky můžete zadat souřadnice jednotlivých pozorování.</>,
                },
                {
                    target: '.tour-createitemdetails-13',
                    title: 'Pokračovat k odeslání vycházky',
                    content: (
                        <>
                            Po&nbsp;zadání pozorovaných druhů a vybraných detailů klikněte na&nbsp;tlačítko
                            "Pokračovat".
                        </>
                    ),
                },
            ]}
            tourId="createitemdetails"
        />
    );
};

export default CreateItemDetailsTour;
