import { FC, useState } from 'react';
import AvifJoyride from './AvifJoyride';
import { CallBackProps } from 'react-joyride';

const AdvancedSearchTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            continuous
            steps={[
                {
                    target: '.tour-advancedsearch-1',
                    title: 'Pokročilý filtr',
                    placementBeacon: 'top',
                    content: (
                        <>
                            V&nbsp;pokročilém filtru máte možnost filtrovat konkrétní druhy, lokalitu, časové období či
                            kombinaci těchto parametrů.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-2',
                    title: 'Druhy',
                    content: (
                        <>
                            Kromě konkrétních druhů či jejich kombinací je možné vyhledávat i celé skupiny (např. sovy).
                            Ikonkou tlapky potom aktivujete vyhledávání mezi neptačími druhy (např. netopýři).
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-3',
                    title: 'Rarita pozorování',
                    content: (
                        <>
                            V&nbsp;tomto poli lze zvolit raritu pozorování, která vás zajímá. Tyto možnosti jsou totožné
                            s&nbsp;možnosti v&nbsp;základním filtru s&nbsp;rozdílem, že je lze kombinovat s&nbsp;dalšími
                            parametry filtru.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-4',
                    placementBeacon: 'top',
                    title: 'Datum pozorování: relativní',
                    content: (
                        <>
                            Pozorování lze filtrovat podle data, a to třemi způsoby. Pokud vás zajímají nejnovější
                            pozorování, využijte možnost zadat datum jako <b>relativní</b>. Tímto způsobem si
                            vyfiltrujete pozorování dle počtu dní od&nbsp;současného data.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-5',
                    placementBeacon: 'top',
                    title: 'Datum pozorování: rozsah',
                    content: (
                        <>
                            Pro&nbsp;filtrování konkrétního období použijte možnost <b>rozsah</b>.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-6',
                    placementBeacon: 'top',
                    title: 'Datum pozorování: sezónní',
                    content: (
                        <>
                            Pro&nbsp;filtrování konkrétního období sezónně - např. duben-červen v&nbsp;letech 2020–2023,
                            použijte možnost <b>sezónní</b>.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-7',
                    placementBeacon: 'top',
                    title: 'Místo pozorování: oblast v mapě',
                    content: (
                        <>
                            Stejně jako datum i místo pozorování lze vyfiltrovat několika způsoby. Nejjednodušší
                            možností je zadat místo <b>kliknutím do&nbsp;mapy</b>. Následně je nutné definovat okruh
                            v&nbsp;okolí bodu, v&nbsp;jakém má filtr pozorování hledat.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-8',
                    placementBeacon: 'top',
                    title: 'Místo pozorování: Obec/okres/kraj',
                    content: (
                        <>
                            Nechybí ale ani možnost <b>manuálně zadat lokalitu</b> vybráním z&nbsp;nabídky obcí, okresů
                            a krajů. Tato možnost vám přijde vhod, pokud vás zajímá několik obcí či krajů, které nelze
                            v&nbsp;mapě společně vybrat.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-9',
                    placementBeacon: 'top',
                    title: 'Detaily záznamu',
                    content: (
                        <>
                            Záznamy lze filtrovat dle několika detailů: například počtu, pohlaví, věku či aktivity.
                            Definice jednotlivých detailů pozorování je blíže komentována v&nbsp;sekci nápovědy věnované
                            vkládání vycházky.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-10',
                    placementBeacon: 'bottom',
                    title: 'Vyčistit filtr',
                    content: (
                        <>
                            Všechna kritéria můžete také kdykoliv vyresetovat a začít nastavovat od&nbsp;znova kliknutím
                            na&nbsp;toto tlačítko.
                        </>
                    ),
                },
                {
                    target: '.tour-advancedsearch-11',
                    placementBeacon: 'bottom',
                    title: 'Vyhledat',
                    content: (
                        <>
                            Po&nbsp;navolení filtru jednoduše stiskněte tlačítko <b>vyhledat</b>.
                        </>
                    ),
                },
            ]}
            tourId="advancedsearch"
        />
    );
};

export default AdvancedSearchTour;
