import React, { ForwardedRef, forwardRef, memo } from 'react';
import { SnackbarContent } from 'notistack';
import { Alert } from '@mui/material';

const CustomSnackbar = forwardRef(
    (
        props: {
            message: string;
            variant: 'success' | 'info' | 'warning' | 'error' | undefined;
            component?: React.ReactNode;
            onClose?: () => void;
        },
        forwardedRef: ForwardedRef<HTMLDivElement>,
    ) => {
        const { message, variant, component, onClose } = props;

        return (
            <SnackbarContent ref={forwardedRef}>
                <Alert elevation={1} onClose={onClose} severity={variant ?? 'info'}>
                    {component ? component : message}
                </Alert>
            </SnackbarContent>
        );
    },
);

export default CustomSnackbar;
