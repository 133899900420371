import { createContext, useContext, FC, PropsWithChildren, Context } from 'react';
import { useAuth } from './authenticator';
import apiFactory, { ApiType } from './api';
import { useState, useEffect } from 'react';

export const ApiContext: Context<ApiType> = createContext<ApiType>({} as ApiType);

export const useApi = () => useContext(ApiContext);

export const ApiProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const auth = useAuth();
    const [api, setApi] = useState<ApiType>(apiFactory(auth));

    useEffect(() => {
        if (auth.isLoggedIn === undefined) return;

        setApi(apiFactory(auth));
    }, [auth.isLoggedIn]);

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

// export default {};
