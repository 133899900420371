import { FC, useState } from 'react';
import PoppedControl from '../../../../components/formControls/PoppedControl';
import { Delete } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { useApi } from '../../../../services/apiContext';
import { IPredefinedFilter } from '../../../../schemas/interfaces';
import { enqueueSnackbar } from 'notistack';
import { useUser } from '../../../../services/userContext';

const UserFilterDelete: FC<{
    onDeleted?: (filter: IPredefinedFilter) => void;
    filter: IPredefinedFilter;
    buttonProps?: IconButtonProps;
}> = ({ onDeleted, filter, buttonProps }) => {
    const { deleteUserDefinedFilter } = useApi();
    const { refetchUser } = useUser();
    const [loading, setLoading] = useState(false);

    return (
        <PoppedControl
            renderIcon={() => (
                <Tooltip title="Smazat uložený filtr">
                    <IconButton
                        color="error"
                        sx={{
                            height: '53px',
                            width: '53px',
                            ...buttonProps?.sx,
                        }}
                        {...buttonProps}
                    >
                        <Delete fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
            renderControl={() => null}
            onConfirmed={async () => {
                setLoading(true);
                await deleteUserDefinedFilter(filter.id)
                    .then((response) => {
                        if (!response.success) throw new Error('Failed to delete filter');

                        return refetchUser?.()
                            .then(() => {
                                enqueueSnackbar('Filtr byl úspěšně smazán.', { variant: 'success' });
                                onDeleted?.(filter);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        enqueueSnackbar('Nepodařilo se smazat filtr.', { variant: 'error' });
                        console.error(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }}
            title={`Opravdu si přejete smazat filtr "${filter.name.cs}"?`}
            confirmButtonLabel="Ano"
            cancelButtonLabel="Zpět"
            confirmButtonLoading={loading}
        />
    );
};

export default UserFilterDelete;
