import { FC } from 'react';
import AvifJoyride from './AvifJoyride';

const SearchTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            continuous
            steps={[
                {
                    target: '.tour-search-1',
                    title: 'Předdefinované filtry',
                    content: (
                        <>
                            Předdefinované filtry slouží k&nbsp;rychlému vyhledání pozorování, která jsou považována
                            za&nbsp;raritní. Pro&nbsp;přihlášené uživatele se zde nachází také filtr vlastních
                            pozorování.
                        </>
                    ),
                },
                {
                    target: '.tour-search-2',
                    title: 'Pokročilý filtr',
                    content: (
                        <>
                            V&nbsp;pokročilém filtru máte možnost filtrovat dle mnoha dalších kritérií. Nápověda
                            k&nbsp;pokročilému filtru se zobrazí jakmile poprvé kliknete na&nbsp;toto tlačítko.
                        </>
                    ),
                },
                {
                    target: '.tour-search-3',
                    title: 'Výstupy filtrování',
                    placementBeacon: 'top',
                    content: (
                        <>
                            Výstupem základního i pokročilého filtru jsou:{' '}
                            <b>tabulka pozorování, tabulka vycházek, galerie a mapa</b>.
                        </>
                    ),
                },
                {
                    target: '.tour-search-4',
                    placementBeacon: 'top',
                    title: 'Pozorování',
                    content: (
                        <>
                            V&nbsp;této tabulce naleznete všechna pozorování odpovídající vámi nastavenému filtru.
                            Kliknutím na&nbsp;řádek můžete přejít na&nbsp;detail pozorování.
                        </>
                    ),
                },
                {
                    target: '.tour-search-5',
                    title: 'Vycházky',
                    placementBeacon: 'top',
                    content: (
                        <>
                            V&nbsp;tabulce vycházky naleznete všechny vycházky, které odpovídají vámi nastavenému
                            filtru. Kliknutím na&nbsp;řádek můžete přejít na&nbsp;detail vycházky.
                        </>
                    ),
                },
                {
                    target: '.tour-search-6',
                    placementBeacon: 'top',
                    title: 'Galerie',
                    content: (
                        <>
                            Galerie slouží pro&nbsp;pohodlné <b>prohlížení fotografií</b> odpovídajících vámi
                            nastavenému filtru.
                        </>
                    ),
                },
                {
                    target: '.tour-search-7',
                    placementBeacon: 'top',
                    title: 'Nastavení galerie',
                    disableScrolling: true,
                    spotlightClicks: true,
                    content: (
                        <>
                            Speciální funkcí galerie je i{' '}
                            <b>přepínání názvů z&nbsp;češtiny do&nbsp;latiny či úplné skrytí názvů druhů</b>. Tyto
                            funkce mohou sloužit k&nbsp;procvičování vašich určovacích schopností.
                        </>
                    ),
                },
                {
                    target: '.tour-search-8',
                    placementBeacon: 'top',
                    title: 'Mapy',
                    content: (
                        <>
                            Posledním výstupem filtrování je <b>vyobrazení pozorování na&nbsp;mapě</b>. Kliknutím
                            na&nbsp;bod na&nbsp;mapě lze rozkliknout detail pozorování, které se pod&nbsp;tímto bodem
                            nachází.
                        </>
                    ),
                },
            ]}
            tourId="search"
        />
    );
};

export default SearchTour;
