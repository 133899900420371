import { FunctionComponent } from 'react';
import { IActivity } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useDict } from '../../services/dictContext';

/**
 * Implementation of DictionarySelect for activity dictionary.
 */
const ActivitySelect: FunctionComponent<DictionarySelectPublicProps<number, IActivity>> = (props) => {
    const { activity: activities } = useDict();

    return (
        <DictionarySelect<number, IActivity>
            color="secondary"
            {...props}
            getDictOptions={
                activities
                    ? new Promise((resolve) => {
                          resolve(activities.sort((a, b) => a.id - b.id));
                      })
                    : undefined
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.id)}
            getOptionLabel={(option, dicts = [] as IActivity[]) =>
                dicts.find((dict) => dict.id === option)?.name.cs || `Unknown activity ${option}`
            }
            getSubcategoryLevel={(option, dicts) => {
                if (!dicts) return 0;

                const activity = dicts.find((dict) => dict.id === option);

                if (!activity) return 0;

                return activity.parents.length;
            }}
        />
    );
};

export default ActivitySelect;
