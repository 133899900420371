import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const DirtyPrompt: FC = () => {
    const formik = useFormikContext();

    useEffect(() => {
        if (formik.dirty && !formik.isSubmitting) {
            window.onbeforeunload = (e) => {
                if (!formik.dirty || formik.isSubmitting) return null;

                const message = 'you sure?';

                (e || window.event).returnValue = message;

                return message;
            };
        } else {
            window.onbeforeunload = null;
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [formik.dirty]);

    return null;
};

export default DirtyPrompt;
