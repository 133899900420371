import { Edit, Delete, Undo, Send, CopyAll } from '@mui/icons-material';
import { TableRow, TableCell, Typography, IconButton, Tooltip } from '@mui/material';
import { Formik, Form, FormikHelpers, FormikProps, FormikValues, FormikErrors } from 'formik';
import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import { TableEditorItem } from './TableEditor';
import FormikObserver from '../FormikObserver';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';

export interface ExternalChangeEvent {
    index: number;
    fieldName: string;
    fieldValue: any;
}

export interface ITableEditorRowPublicProps<T> {
    /** @var columnsNumber Number of columns in the table defined by user (do not include the ones added by this component). */
    columnsNumber: number;
    /** @var itemRendererView Function to render an item in view mode. */
    itemRendererView: (item: TableEditorItem<T>) => React.ReactNode;
    /** @var itemRendererEdit Function to render an item in edit mode. */
    itemRendererEdit: (
        item: TableEditorItem<T>,
        props: FormikProps<T>,
        formId: string,
        index: number,
    ) => React.ReactNode;
    /** @var itemRendererSubmenu Function to render a submenu for an item in edit mode. */
    itemRendererSubmenu?: (
        item: TableEditorItem<T>,
        props: FormikProps<T>,
        actions: ITableEditorRowActions,
        formId: string,
    ) => React.ReactNode;
    /** @var formInitialValues Formik empty object that must contain all item's fields to initialize form correctly. */
    formInitialValues: T;
    /** @var validationSchema Zod / Yup validation schema to validate form values. */
    validationSchema?: any;
    /** @var enableRevert If true, the user can revert changes made to an item. */
    enableRevert?: boolean;
    /** @var disableRowHighlight Disables highlighting of modified rows. */
    disableRowHighlight?: boolean;
    /** @var submitOnChange If true, form will trigger submit event on any input change. */
    submitOnChange?: boolean;
    /** @var onValidationValidationError Callback function triggered when form is submitted with errors in it. */
    onValidationError?: (item: TableEditorItem<T>, errors: FormikErrors<T>) => void;
    /** @var disableButtons Array of buttons to disable. */
    disableButtons?: ('create' | 'update' | 'delete' | 'duplicate' | 'revert' | 'edit' | 'revertAll' | 'save')[];
    /** @var hideActionsColumn If true, the actions column will be hidden. */
    hideActionsColumn?: boolean;
    /** @var hideActionsColumnForEmpty If true, the actions column will be hidden for the row which creates a new entry. */
    hideActionsColumnForEmpty?: boolean;
    /** @var triggerSubmit Props trigger that triggers submit of each form in the editor when set to true. */
    triggerSubmit?: boolean;
    /** @var getRowClassname Function to get a classname for a row. */
    getRowClassname?: (item: T, index: number) => string;
    /** @var onRowClick: Function to be triggered when any row is clicked. */
    onRowClick?: (item: T, index: number) => void;
    /** @var externalChanges Subject to listen to for events that should modify the row from the outside world */
    externalChanges?: Subject<ExternalChangeEvent>;
    /** @var selectedItemIndex Index of the selected row */
    selectedItemIndex?: number;
}

export interface ITableEditorRowProps<T> extends ITableEditorRowPublicProps<T> {
    index: number;
    onCreate?: (item: TableEditorItem<T>) => Promise<void>;
    onUpdate?: (item: TableEditorItem<T>) => Promise<void>;
    onDelete?: (item: TableEditorItem<T>) => Promise<void>;
    onEnableEdit?: (item: TableEditorItem<T>) => Promise<void>;
    onRevert?: (item: TableEditorItem<T>) => Promise<void>;
    onDuplicate?: (item: TableEditorItem<T>) => Promise<void>;
    item: TableEditorItem<T>;
    /** @var validationFunction Custom validation function to validate form values. */
    validationFunction?: (values: T) => FormikErrors<T> | undefined;
    /** @var onRowChange Callback function triggered when form values change. */
    onChange?: (values: T) => void;
    isMobile: boolean;
}

export interface ITableEditorRowActions {
    createItem?: () => void;
    updateItem?: () => void;
    revertItem?: () => void;
    deleteItem?: () => void;
    duplicateItem?: () => void;
    enableEditMode?: () => void;
}

export interface ITableEditorRowState {
    uuid: string;
}

export class TableEditorRow<T extends FormikValues> extends React.Component<
    ITableEditorRowProps<T>,
    ITableEditorRowState
> {
    constructor(props: ITableEditorRowProps<T>) {
        super(props);

        this.state = {
            uuid: v4().slice(0, 8),
        };
    }

    editClick() {
        if (this.props.onEnableEdit) this.props.onEnableEdit(this.props.item);
    }

    deleteClick() {
        if (this.props.onDelete) this.props.onDelete(this.props.item);
    }

    onCreate(item: TableEditorItem<T>, resetForm: FormikHelpers<T>['resetForm']) {
        if (this.props.onCreate) this.props.onCreate(item);
        resetForm({ values: this.props.formInitialValues });
    }

    onUpdate(item: TableEditorItem<T>) {
        if (this.props.onUpdate) this.props.onUpdate(item);
    }

    handleSubmit(values: T, resetForm: FormikHelpers<T>['resetForm']) {
        const newItem = { ...this.props.item };
        newItem.data = values;

        // TODO: this is not an ideal way to determine whether we are updating or creating a new item
        if (this.props.item.empty) {
            this.onCreate(newItem, resetForm);
            resetForm({ values: this.props.formInitialValues, touched: {} });
            this.setState({ uuid: v4().slice(0, 8) });
        } else if (this.props.onUpdate) {
            this.onUpdate(newItem);
            resetForm({ touched: {} });
        }
    }

    handleRevert(props: FormikProps<T>) {
        if (this.props.onRevert) this.props.onRevert(this.props.item);
        props.resetForm({ values: this.props.item.originalData });
    }

    handleValuesChange = debounce((formikProps: FormikProps<T>, values: T, item: TableEditorItem<T>) => {
        if (this.props.onValidationError) {
            formikProps.validateForm().then((errors) => {
                if (Object.keys(errors).length > 0) {
                    this.props.onValidationError && this.props.onValidationError(item, errors);
                } else {
                    this.props.onChange && this.props.onChange(values);
                }
            });
        }
    }, 300);

    render() {
        const {
            onDelete,
            onCreate,
            onUpdate,
            onDuplicate,
            formInitialValues,
            validationFunction,
            validationSchema,
            item,
            enableRevert,
            disableRowHighlight,
            submitOnChange,
            onValidationError,
            hideActionsColumn,
            hideActionsColumnForEmpty,
            onChange,
            index,
        } = this.props;
        const { uuid } = this.state;

        // number of columns provided by user
        // + 2 action columns (mobile on the left, desktop on the right side)
        // + 1 hidden column for the form
        const columnsNumber = this.props.columnsNumber + (hideActionsColumn ? 2 : 0) + 1;

        const renderView =
            !item.editing && !(this.props.selectedItemIndex !== undefined && index === this.props.selectedItemIndex);

        //this is here to persist the information that this item is in edit mode
        if (!renderView) this.props.item.editing = true;

        if (item.deleted) return undefined;

        const viewActionsCell = (
            <>
                {(onCreate || onUpdate) && !item.empty && !this.props.disableButtons?.includes('edit') && (
                    <Tooltip title="Upravit">
                        <IconButton onClick={() => this.editClick()} color="primary">
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
                {onDelete && !item.empty && !this.props.disableButtons?.includes('delete') && (
                    <Tooltip title="Odstranit">
                        <IconButton onClick={() => this.deleteClick()} sx={{ marginLeft: '10px' }}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                )}
            </>
        );

        return (
            <>
                {item.empty && (
                    <TableRow
                        key={`new-header`}
                        className="no-border header expanded"
                        sx={{ bgcolor: { xs: '#5e7694b3', md: 'primary.300' } }}
                    >
                        <TableCell
                            colSpan={columnsNumber}
                            sx={{
                                borderBottom: 'none',
                                paddingTop: '1em',
                            }}
                        >
                            <Typography variant="body1" color="white" fontWeight="bold">
                                Přidejte pozorovaný druh
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
                <Tooltip
                    title={
                        !item.empty && this.props.validationFunction && this.props.validationFunction(item.data)
                            ? Object.values(this.props.validationFunction(item.data) || {}).join(' ')
                            : undefined
                    }
                >
                    <TableRow
                        hover={!item.empty}
                        selected={item.modified && !disableRowHighlight}
                        className={[
                            !renderView ? 'expanded' : '',
                            item.empty ? 'no-border' : '',
                            !item.empty && this.props.validationFunction && this.props.validationFunction(item.data)
                                ? 'invalid'
                                : '',
                            this.props.selectedItemIndex !== undefined && this.props.selectedItemIndex === index
                                ? 'Avif--selected'
                                : '',
                            this.props.getRowClassname?.(item.modifiedData, this.props.index) || '',
                        ].join(' ')}
                        sx={{
                            position: 'relative',
                            overflow: 'hidden',
                            bgcolor: item.empty
                                ? { xs: '#5e7694b3', md: 'primary.300' }
                                : { xs: '#ffffffa0', md: 'transparent' },
                            // fixes for iOS & Safari to make the row *really* the parent for all inner absolute elements
                            zIndex: 0,
                            transform: 'translate(0, 0) scale(1)',
                            willChange: 'transform, z-index',
                        }}
                        onClick={() => this.props.onRowClick?.(item.modifiedData, this.props.index)}
                    >
                        {renderView ? (
                            <>
                                {!hideActionsColumn && (
                                    <TableCell
                                        sx={{
                                            maxWidth: 'calc(2 * 40px + 10px)',
                                            width: 'calc(2 * 40px + 10px)',
                                            textAlign: 'center',
                                            display: {
                                                xs: 'table-cell',
                                                md: 'none',
                                            },
                                        }}
                                    >
                                        {viewActionsCell}
                                    </TableCell>
                                )}
                                <TableCell sx={{ display: 'none' }}></TableCell>
                                {this.props.itemRendererView(item)}
                                {!hideActionsColumn && (
                                    <TableCell
                                        sx={{
                                            maxWidth: 'calc(2 * 40px + 10px)',
                                            width: 'calc(2 * 40px + 10px)',
                                            textAlign: 'right',
                                            display: {
                                                xs: 'none',
                                                md: 'table-cell',
                                            },
                                        }}
                                    >
                                        {viewActionsCell}
                                    </TableCell>
                                )}
                            </>
                        ) : (
                            <Formik<T>
                                onSubmit={(values, helpers) => this.handleSubmit(values, helpers.resetForm)}
                                validationSchema={validationSchema}
                                validate={validationFunction}
                                enableReinitialize
                                initialValues={{ ...formInitialValues, ...item.data }}
                            >
                                {(fProps) => {
                                    const formikProps = { ...fProps };

                                    useEffect(() => {
                                        if (!this.props.triggerSubmit || this.props.item.empty) return;

                                        formikProps.submitForm();
                                    }, [this.props.triggerSubmit]);

                                    useEffect(() => {
                                        if (!this.props.externalChanges) return;

                                        this.props.externalChanges.subscribe((change) => {
                                            if (change.index == this.props.index) {
                                                formikProps.setFieldValue(change.fieldName, change.fieldValue, false);
                                            }
                                        });
                                    }, [this.props.externalChanges]);

                                    if (submitOnChange) {
                                        formikProps.handleChange = (e: Parameters<typeof fProps.handleChange>[0]) => {
                                            fProps.handleChange(e);

                                            setTimeout(() => {
                                                formikProps.validateForm().then((errors) => {
                                                    if (Object.keys(errors).length > 0)
                                                        return onValidationError && onValidationError(item, errors);
                                                    else fProps.submitForm();
                                                });
                                            });
                                        };
                                        formikProps.setFieldValue = (field, value, shouldValidate = true) => {
                                            fProps.setFieldValue(field, value, shouldValidate);

                                            if (!shouldValidate) return;

                                            setTimeout(() => {
                                                formikProps.validateForm().then((errors) => {
                                                    if (Object.keys(errors).length > 0)
                                                        return onValidationError && onValidationError(item, errors);
                                                    else fProps.submitForm();
                                                });
                                            });
                                        };
                                        formikProps.setValues = (values, shouldValidate) => {
                                            fProps.setValues(values, shouldValidate);

                                            if (!shouldValidate) return;

                                            setTimeout(() => {
                                                formikProps.validateForm().then((errors) => {
                                                    if (Object.keys(errors).length > 0)
                                                        return onValidationError && onValidationError(item, errors);
                                                    else fProps.submitForm();
                                                });
                                            });
                                        };
                                    }

                                    const actions: ITableEditorRowActions = {
                                        createItem: () => this.handleSubmit(formikProps.values, formikProps.resetForm),
                                        updateItem: () => this.handleSubmit(formikProps.values, formikProps.resetForm),
                                        revertItem: () => this.handleRevert(formikProps),
                                        deleteItem: this.props.onDelete ? () => this.deleteClick() : undefined,
                                        duplicateItem: this.props.onDuplicate
                                            ? () => this.props.onDuplicate?.(item)
                                            : undefined,
                                        enableEditMode: () => this.editClick(),
                                    };

                                    const editActionsCell = (
                                        <>
                                            {!!enableRevert &&
                                                item.modified &&
                                                !this.props.disableButtons?.includes('revert') && (
                                                    <Tooltip title="Revert changes">
                                                        <IconButton onClick={actions.revertItem} color="primary">
                                                            <Undo />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            {onCreate &&
                                                item.empty &&
                                                !this.props.disableButtons?.includes('create') && (
                                                    <Tooltip title="Create">
                                                        <span>
                                                            <IconButton
                                                                onClick={actions.createItem}
                                                                color={formikProps.isValid ? 'primary' : undefined}
                                                                disabled={!formikProps.isValid}
                                                            >
                                                                <Send />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            {onUpdate &&
                                                !item.empty &&
                                                !this.props.disableButtons?.includes('update') && (
                                                    <Tooltip title="Update">
                                                        <span>
                                                            <IconButton
                                                                onClick={actions.updateItem}
                                                                color="primary"
                                                                disabled={
                                                                    !formikProps.isValid ||
                                                                    Object.keys(formikProps.touched).length == 0
                                                                }
                                                            >
                                                                <Send />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            {onDuplicate &&
                                                !item.empty &&
                                                !this.props.disableButtons?.includes('duplicate') && (
                                                    <Tooltip title="Duplicate">
                                                        <IconButton
                                                            onClick={actions.duplicateItem}
                                                            sx={{ marginLeft: '10px' }}
                                                        >
                                                            <CopyAll />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            {onDelete &&
                                                !item.empty &&
                                                !this.props.disableButtons?.includes('delete') && (
                                                    <Tooltip title="Odstranit">
                                                        <IconButton
                                                            onClick={actions.deleteItem}
                                                            sx={{ marginLeft: '10px' }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            {this.props.itemRendererSubmenu &&
                                                this.props.itemRendererSubmenu(item, formikProps, actions, uuid)}
                                        </>
                                    );

                                    const displayActionsColumn =
                                        !hideActionsColumn && !(hideActionsColumnForEmpty && item.empty);

                                    return (
                                        <>
                                            {this.props.onChange && (
                                                <FormikObserver
                                                    values={formikProps.values}
                                                    onChange={(values) =>
                                                        this.handleValuesChange(formikProps, values, item)
                                                    }
                                                />
                                            )}
                                            <TableCell sx={{ display: 'none' }}>
                                                <Form
                                                    noValidate
                                                    id={uuid}
                                                    onSubmit={(e) => {
                                                        return formikProps.isValid
                                                            ? formikProps.handleSubmit(e)
                                                            : onValidationError &&
                                                                  onValidationError(item, formikProps.errors);
                                                    }}
                                                />
                                            </TableCell>
                                            {displayActionsColumn && (
                                                <TableCell
                                                    sx={{
                                                        textAlign: 'left',
                                                        display: { xs: 'table-cell', md: 'none' },
                                                    }}
                                                    className="actions"
                                                >
                                                    {editActionsCell}
                                                </TableCell>
                                            )}
                                            {this.props.itemRendererEdit(item, formikProps, uuid, index)}
                                            {displayActionsColumn && (
                                                <TableCell
                                                    sx={{
                                                        textAlign: 'right',
                                                        display: { xs: 'none', md: 'table-cell' },
                                                    }}
                                                    className="actions"
                                                >
                                                    {editActionsCell}
                                                </TableCell>
                                            )}
                                        </>
                                    );
                                }}
                            </Formik>
                        )}
                    </TableRow>
                </Tooltip>
            </>
        );
    }
}
