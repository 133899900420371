import { FC, useCallback, useState, useEffect } from 'react';
import OpenStreetMap, { MapCategory, OSMOnClickLocationMarker, customIcon } from './OpenStreetMap';
import { Marker, Polyline } from 'react-leaflet';
import { Box, Fab, Tooltip } from '@mui/material';
import { UndoRounded } from '@mui/icons-material';
import * as L from 'leaflet';
import { ICoordinates, ITrack } from '../../schemas/interfaces';
import { round } from 'lodash';
import { distanceTwoPoints } from '../../services/helpers';

const PathMap: FC<{
    coordinates?: ITrack | null;
    onChange?: (coordinates: ITrack, distance?: number) => void;
    height?: string;
    minHeight?: string;
    center?: ICoordinates;
    zoom?: number;
}> = (props) => {
    const [coordinates, setCoordinates] = useState<ITrack>();
    const onChangeCallback = useCallback(() => {
        if (!coordinates) return;

        props.onChange && props.onChange(coordinates, distance(coordinates));
    }, [coordinates, props.onChange]);

    useEffect(() => {
        if (props.coordinates) setCoordinates(props.coordinates);
    }, []);

    useEffect(() => {
        if (!coordinates) return;

        onChangeCallback();
    }, [coordinates]);

    const appendCoordinate = (latitude: number, longitude: number) => {
        setCoordinates((coordinates) => [...(coordinates || []), [round(latitude, 6), round(longitude, 6)]]);
    };

    const undoCoordinate = () => {
        if (!coordinates) return;

        setCoordinates(coordinates.slice(0, -1));
    };

    const distance = (coordinates: ITrack) => {
        if (coordinates.length < 2) return undefined;

        return coordinates.reduce((acc, curr, index) => {
            if (index === 0) return acc;
            return acc + distanceTwoPoints(coordinates[index - 1], curr);
        }, 0);
    };

    return (
        <Box
            className="PathMap"
            sx={{ position: 'relative', borderRadius: 'var(--mui-shape-borderRadius)', overflow: 'hidden' }}
        >
            <OpenStreetMap
                height={props.height ?? '225px'}
                minHeight={props.minHeight ?? '100px'}
                center={props.center}
                defaultZoom={props.zoom}
                mapCategory={MapCategory.INSERT}
            >
                {coordinates &&
                    coordinates.map((coordinate, index) => (
                        <Marker position={coordinate} icon={customIcon} key={index}></Marker>
                    ))}
                <OSMOnClickLocationMarker
                    onClick={(latitude, longitude) => {
                        appendCoordinate(latitude, longitude);
                    }}
                />
                {coordinates && coordinates.length > 0 && (
                    <Polyline positions={coordinates} pathOptions={{ color: '#5E7694' }} />
                )}
            </OpenStreetMap>
            {coordinates && coordinates.length > 0 && (
                <Tooltip title="Odstranit poslední bod">
                    <Fab
                        onClick={undoCoordinate}
                        size="small"
                        sx={{ position: 'absolute', right: '1em', bottom: '1em' }}
                        color="primary"
                    >
                        <UndoRounded />
                    </Fab>
                </Tooltip>
            )}
        </Box>
    );
};

export default PathMap;
