import { TableRow, TableCell, Box, Typography } from '@mui/material';
import { FC } from 'react';
import { TableEditorItem } from '../../../../components/tableEditor/TableEditor';
import { ObservationItemFormValues } from '../../ObservationFormApp';

const Footer: FC<{ items: TableEditorItem<ObservationItemFormValues>[] }> = ({ items }) => {
    return (
        <TableRow sx={{ bgcolor: '#ffffffa0' }}>
            <TableCell colSpan={10} sx={{ borderBottom: 'none', pl: 1.75 }}>
                <Box sx={{ display: 'flex', gap: '2rem', alignContent: 'flex-start', py: 1 }}>
                    <Typography variant="body1">
                        Celkem pozorování:&nbsp;
                        <strong>
                            {items.filter((i) => !i.deleted && i.modifiedData.taxonId !== undefined).length}
                        </strong>
                    </Typography>
                    <Typography variant="body1">
                        Unikátních druhů:&nbsp;
                        <strong>
                            {
                                [
                                    ...new Set(
                                        items.filter((i) => !i.deleted).map((item) => item.modifiedData.taxonId),
                                    ),
                                ].filter((id) => id !== undefined).length
                            }
                        </strong>
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default Footer;
