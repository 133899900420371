import { FC } from 'react';
import AvifJoyride from './AvifJoyride';

const MenuTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            tourId="menu"
            continuous
            floaterProps={{
                styles: {
                    wrapper: {
                        zIndex: 1100,
                    },
                },
            }}
            steps={[
                {
                    target: '.tour-menu-1',
                    title: 'Přihlášení',
                    content: (
                        <>
                            Zde se můžete přihlásit do&nbsp;<b>vašeho uživatelského účtu</b> nebo se nově
                            do&nbsp;databáze <b>zaregistrovat</b>.
                        </>
                    ),
                },
                {
                    target: '.tour-menu-2',
                    title: 'Vyhledávání',
                    content: (
                        <>
                            Zde naleznete <b>výpis</b> aktuálních neutajených <b>pozorování</b>, které do&nbsp;Avifu
                            uživatelé přidali. Součástí této stránky je i filtrování a export dat.
                        </>
                    ),
                },
                {
                    target: '.tour-menu-3',
                    title: 'Vložení pozorování',
                    content: (
                        <>
                            Přihlášení vám umožní <b>vložit vlastní pozorování</b>. Zároveň lze pro&nbsp;vložení
                            pozorování využít tlačítko + vpravo dole.
                        </>
                    ),
                },
            ]}
        />
    );
};

export default MenuTour;
