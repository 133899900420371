import { FC } from 'react';
import { IProject } from '../../../../schemas/interfaces';
import LsdTransForm from './custom/LsdTransForm';
import { FormikContextType } from 'formik';
import { ObservationItemFormValues } from '../../ObservationFormApp';

export interface CustomProjectFormProps {
    project: IProject;
    baseFormContext: FormikContextType<ObservationItemFormValues>;
}

const CustomProjectForm: FC<CustomProjectFormProps> = (props) => {
    if (props.project.customForm === 'lsdTransForm') return <LsdTransForm {...props} />;

    return null;
};

export default CustomProjectForm;
