import { Box, Grid, Link, SxProps, Typography, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IList, IPlace } from '../schemas/interfaces';
import SecrecyLock from './dicts/SecrecyLock';
import DefinitionListItem from './DefinitionListItem';
import PlaceFromDict from './dicts/PlaceFromDict';
import CopyToClipboard from './map/CopyToClipboard';

const ObsListDefinitionList: FunctionComponent<{
    list: IList;
    hideColumns?: (keyof IList | 'square')[];
    sx?: SxProps;
    dense?: boolean;
}> = ({ list, hideColumns, sx, dense }) => {
    const {
        date,
        timeStart,
        timeEnd,
        observers,
        observersText,
        note,
        siteNote,
        siteName,
        speciesCount,
        individualsCountString,
    } = list;
    const hide = hideColumns || [];
    const territorialUnitId = list.location?.territorialUnitId;

    return (
        <Grid
            container
            component="dl"
            spacing={dense ? 0.5 : 1}
            sx={{
                '& div.MuiGrid-root:last-child': { display: 'none' },
                '& .MuiGrid-item': {
                    py: dense ? 0.5 : 1,
                },
                '& .MuiGrid-item:not(:last-of-type)': {
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                },
                '& dt.MuiGrid-item': {
                    bgcolor: 'action.selected',
                },
                '& em': {
                    letterSpacing: '0.3px',
                    opacity: 0.5,
                },
                marginBottom: 0,
                ...sx,
            }}
        >
            {hide.indexOf('date') === -1 && (
                <DefinitionListItem label="Datum" value={format(new Date(date), 'd. M. yyyy')} />
            )}
            {hide.indexOf('timeStart') === -1 && hide.indexOf('timeEnd') === -1 && (
                <DefinitionListItem
                    label="Čas vycházky"
                    formattedValue={
                        <>
                            {!!timeStart && !!timeEnd && (
                                <>
                                    {timeStart}&ndash;{timeEnd}
                                </>
                            )}
                            {!!timeStart && !timeEnd && <>{timeStart}</>}
                            {!timeStart && !timeEnd && <em>nezadáno</em>}
                        </>
                    }
                    // needs to be supplied but we implement the logic in the formattedValue prop
                    // thus passin a value that will pass all internal checks
                    value={'true'}
                />
            )}
            {hide.indexOf('observers') === -1 && (
                <>
                    <DefinitionListItem
                        label={observersText ? 'Pozorovatelé' : 'Pozorovatel/ka'}
                        formattedValue={
                            <>
                                {!!observers && observers.length > 0 && (
                                    <Link component={RouterLink} to={`/profile/${observers[0].publicId}`}>
                                        {observers[0].name}
                                    </Link>
                                )}
                                {hide.indexOf('observersText') === -1 && observersText && ', ' + observersText}
                                {!hide.includes('observersText') && !observers && !observersText && (
                                    <>
                                        <em>pozorovatel/ka skryt/a</em>
                                        <SecrecyLock
                                            secrecyLevel={90}
                                            TooltipProps={{ title: 'Pozorovatel/ka skryl/a svoji identitu.' }}
                                            SvgIconProps={{ sx: { top: '3px', position: 'relative' } }}
                                        />
                                    </>
                                )}
                            </>
                        }
                        value="true"
                    />
                </>
            )}
            {hide.indexOf('location') === -1 && (
                <DefinitionListItem
                    label="Lokalita"
                    formattedValue={
                        <>
                            <PlaceFromDict placeId={territorialUnitId} siteName={siteName} />
                            <SecrecyLock
                                secrecyLevel={list.secrecyLevel}
                                secretUntil={list.secretUntil}
                                SvgIconProps={{ sx: { top: '3px', position: 'relative' } }}
                            />
                        </>
                    }
                    value="true"
                />
            )}
            {hide.indexOf('speciesCount') === -1 && <DefinitionListItem label="Počet druhů" value={speciesCount} />}
            {hide.indexOf('individualsCountString') === -1 && (
                <DefinitionListItem label="Počet jedinců" value={individualsCountString} />
            )}
            {hide.indexOf('note') === -1 && (
                <DefinitionListItem label="Poznámka k&nbsp;vycházce" htmlValue={note?.replaceAll('\n', '<br />')} />
            )}
            {hide.indexOf('siteNote') === -1 && (
                <DefinitionListItem label="Poznámka k&nbsp;lokalitě" htmlValue={siteNote?.replaceAll('\n', '<br />')} />
            )}
            {hide.indexOf('square') === -1 && (!!list.location.eea10km || !!list.location.kfme) && (
                <DefinitionListItem
                    label={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1 }}>
                            <Typography variant="body1" component="p" fontWeight="bold">
                                Čtverce
                            </Typography>
                            {useMediaQuery('(min-width: 400px)') && (
                                <Typography variant="caption" component="p" textAlign="right">
                                    {!!list.location.eea10km && (
                                        <Typography display="block" component="span">
                                            EEA10KM
                                        </Typography>
                                    )}
                                    {!!list.location.kfme && (
                                        <Typography display="block" component="span">
                                            KFME
                                        </Typography>
                                    )}
                                </Typography>
                            )}
                        </Box>
                    }
                    formattedValue={
                        <>
                            {!!list.location.eea10km && (
                                <CopyToClipboard
                                    value={`${list.location.eea10km}`}
                                    typographyProps={{ display: 'block' }}
                                    iconButtonProps={{ sx: { position: 'absolute', mt: '-3px' } }}
                                />
                            )}
                            {!!list.location.kfme && (
                                <CopyToClipboard
                                    value={`${list.location.kfme}`}
                                    typographyProps={{ display: 'block' }}
                                    iconButtonProps={{ sx: { position: 'absolute', mt: '-3px' } }}
                                />
                            )}
                        </>
                    }
                    value="true"
                />
            )}
        </Grid>
    );
};

export default ObsListDefinitionList;
