import { Delete, Edit, Reply, Link } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import CommentEditor, { CommentEditorValues } from './CommentEditor';
import { IComment } from '../../schemas/interfaces';
import { useAuth } from '../../services/authenticator';
import { CommentRights } from '../../schemas/enums';
import { useLocation } from 'react-router-dom';
import CopyToClipboard from '../map/CopyToClipboard';
import linkifyHtml from 'linkify-html';

export interface CommentProps {
    comment: IComment;
    commentLevel: number;
    replies?: IComment[];
    allComments: IComment[];
    onReplyAdded: (comment: CommentEditorValues, parentId: number) => Promise<void>;
    onCommentEdited?: (comment: IComment) => Promise<void>;
    onCommentDeleted?: (comment: IComment) => Promise<void>;
    onOpen: () => void;
    forceCloseForms: boolean;
    repliesAllowed?: boolean;
}

export interface CommentState {
    isEditing: boolean;
    isReplying: boolean;
    menuAnchorEl?: HTMLButtonElement;
    liked: boolean;
    skipForceCloseForms: boolean;
}

const Comment: FunctionComponent<CommentProps> = (props) => {
    const { subject, text, id, date, updated, deleted } = props.comment;
    const { name: author, publicId: authorId } = props.comment.author;
    const { allComments } = props;
    const [state, setState] = useState<CommentState>({
        isEditing: false,
        isReplying: false,
        liked: false,
        skipForceCloseForms: false,
    });
    const { identity } = useAuth();
    const location = useLocation();
    const isMobile = useMediaQuery('(pointer: coarse)');
    const replyFormRef = useRef<HTMLElement>(null);
    const commentRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (location.hash !== `#comment-${id}`) return;

        setTimeout(() => {
            commentRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }, [location.hash, commentRef.current]);

    useEffect(() => {
        if (!props.forceCloseForms) return;

        if (state.skipForceCloseForms) setState((state) => ({ ...state, skipForceCloseForms: false }));
        else setState({ ...state, isEditing: false, isReplying: false });
    }, [props.forceCloseForms]);

    const onReplyClick = () => {
        if (!props.repliesAllowed) throw new Error('Replies are not allowed.');

        setState({ ...state, isReplying: true, skipForceCloseForms: true });
        setTimeout(() => {
            replyFormRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 300);
        props.onOpen();
    };

    const onEditClick = () => {
        !!props.onCommentEdited && setState({ ...state, isEditing: true });
    };

    const { isEditing, isReplying, menuAnchorEl } = state;
    const replies = allComments.filter((c) => c.parentId == id);

    return (
        <Card
            id={`comment-${id}`}
            sx={{
                position: 'relative',
                marginBottom: '1rem',
                backgroundColor: deleted ? 'rgba(233, 235, 236, 1)' : 'rgba(243, 245, 246, 0.4)',
                ...(identity?.id && identity.id === authorId
                    ? { borderLeft: '5px solid', borderLeftColor: 'primary.main' }
                    : isMobile
                    ? { borderLeft: '5px solid', borderLeftColor: 'grey.600' }
                    : undefined),
                '@keyframes pulse': {
                    '0%': {
                        backgroundColor: 'rgba(243, 245, 246, 0.4)',
                    },
                    '50%': {
                        bgcolor: 'primary.200',
                    },
                    '100%': {
                        backgroundColor: 'rgba(243, 245, 246, 0.4)',
                    },
                },
                ...(location.hash == `#comment-${id}`
                    ? { animation: 'pulse 1.5s 3', animationDelay: '300ms' }
                    : undefined),
            }}
        >
            <Box ref={commentRef} sx={{ position: 'absolute', top: '-100px' }} />
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                        {author
                            .split(' ')
                            .map((name) => name[0])
                            .join('')}
                    </Avatar>
                }
                action={
                    <Tooltip title="Kopírovat odkaz na&nbsp;komentář" placement="right">
                        <Box>
                            <CopyToClipboard
                                label=""
                                value={window.location.href.replace(window.location.hash, '') + '#comment-' + id}
                                onlyIcon
                                customIcon={<Link fontSize="inherit" sx={{ transform: 'rotate(45deg)' }} />}
                            />
                        </Box>
                    </Tooltip>
                }
                // action={
                //     <Tooltip title="Další možnosti">
                //         <IconButton
                //             aria-label="Další možnosti"
                //             onClick={(e) => setState({ ...state, menuAnchorEl: e.currentTarget })}
                //         >
                //             <MoreVert />
                //         </IconButton>
                //     </Tooltip>
                // }
                title={author}
                subheader={
                    <>
                        {format(date, 'd. M. yyyy H:mm')}
                        {updated && <>, upraveno {format(updated, 'd. M. yyyy H:mm')}</>}
                    </>
                }
            />
            {/* <Menu
                id={`comment-${id}-menu`}
                anchorEl={menuAnchorEl}
                open={open}
                onClose={() => setState({ ...state, menuAnchorEl: undefined })}
            >
                <MenuItem onClick={() => setState({ ...state, menuAnchorEl: undefined, isEditing: true })}>
                    Upravit komentář
                </MenuItem>
                <MenuItem onClick={() => setState({ ...state, menuAnchorEl: undefined })}>
                    Označit jako nevhodné
                </MenuItem>
            </Menu> */}
            <CardContent>
                {isEditing && (
                    <CommentEditor
                        comment={props.comment}
                        title={'Upravit komentář'}
                        onSubmit={(newComment) => {
                            console.log('editComment', newComment);
                            return (
                                (props.onCommentEdited &&
                                    props
                                        .onCommentEdited({ ...props.comment, ...newComment })
                                        .then(() => setState((state) => ({ ...state, isEditing: false })))) ??
                                Promise.reject()
                            );
                        }}
                        onClose={() => setState({ ...state, isEditing: false })}
                        submitButtonLabel="Uložit změny"
                    />
                )}
                {deleted && (
                    <Typography variant="body1" fontWeight="bold">
                        Tento komentář byl smazán.
                    </Typography>
                )}
                {!isEditing && !deleted && (
                    <>
                        <Typography variant="h6" component="h3" dangerouslySetInnerHTML={{ __html: subject || '' }} />
                        <Typography
                            variant="body2"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: linkifyHtml(text.replaceAll('\n', '<br />')) }}
                            sx={{
                                '& a': {
                                    color: 'inherit',
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        textDecoration: 'none',
                                    },
                                },
                            }}
                        />
                    </>
                )}
            </CardContent>
            {!isEditing && !deleted && (
                <CardActions
                    sx={{
                        flexWrap: 'wrap',
                        gap: 1,
                    }}
                    disableSpacing
                >
                    {/* <Tooltip title="Označit jako líbí se">
                        <IconButton
                            aria-label="Označit jako líbí se"
                            onClick={() => setState({ ...state, liked: true })}
                            color={liked ? 'error' : 'default'}
                        >
                            <Favorite />
                        </IconButton>
                    </Tooltip> */}
                    {props.repliesAllowed && (
                        <Button variant="outlined" onClick={onReplyClick} startIcon={<Reply />}>
                            Odpovědět
                        </Button>
                    )}
                    {props.comment.rights.includes(CommentRights.edit) && (
                        <Button variant="text" onClick={onEditClick} startIcon={<Edit />}>
                            Upravit
                        </Button>
                    )}
                    {props.comment.rights.includes(CommentRights.delete) && (
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => !!props.onCommentDeleted && props.onCommentDeleted(props.comment)}
                            startIcon={<Delete />}
                        >
                            Smazat
                        </Button>
                    )}
                </CardActions>
            )}
            {(replies.length > 0 || isReplying) && (
                <CardContent sx={isMobile ? { pl: 0.5, pr: 1 } : { pl: 3 }}>
                    {replies
                        .filter((c) => c.parentId == id)
                        .map((reply) => (
                            <Comment
                                key={reply.id}
                                comment={reply}
                                allComments={allComments}
                                onReplyAdded={props.onReplyAdded}
                                onCommentEdited={props.onCommentEdited}
                                onCommentDeleted={props.onCommentDeleted}
                                commentLevel={props.commentLevel + 1}
                                onOpen={props.onOpen}
                                forceCloseForms={props.forceCloseForms}
                                repliesAllowed={props.repliesAllowed}
                            />
                        ))}
                    {isReplying && (
                        <>
                            <Box ref={replyFormRef} />
                            <CommentEditor
                                defaultSubject={subject ? 'Re: ' + subject : ''}
                                title={'Odpovězte uživateli ' + author}
                                submitButtonLabel="Odeslat"
                                onSubmit={(newComment) => {
                                    return props
                                        .onReplyAdded(newComment, id)
                                        .then(() => setState({ ...state, isReplying: false }));
                                }}
                                onClose={() => setState({ ...state, isReplying: false })}
                            />
                        </>
                    )}
                </CardContent>
            )}
        </Card>
    );
};

export default Comment;
