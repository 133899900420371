import { FlagRounded } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Typography, TextField, Box, Button } from '@mui/material';
import { Formik, Form } from 'formik';
import PoppedControl from './formControls/PoppedControl';
import { IList, IListItem } from '../schemas/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useApi } from '../services/apiContext';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { request_list_report } from '../schemas/schemas';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import translateErrorMessage from '../services/errorMessages';

export interface ListItemReportProps {
    item?: IListItem;
    list: IList;
    closeMenu?: () => void;
}

export interface ListItemReportFormValues {
    reason: string;
}

const ListItemReport: React.FC<ListItemReportProps> = ({ item, list, closeMenu }) => {
    const [isItem, setIsItem] = useState<boolean | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const api = useApi();

    useEffect(() => {
        setIsItem(item !== undefined);
    }, [item]);

    const handleReport = useMemo(
        () => (values: ListItemReportFormValues, closeForm: () => void) => {
            if (!api) return;

            setLoading(true);

            const reportFunc = item
                ? () => api.postListItemReport(list.publicId, item.id, values)
                : () => api.postListReport(list.publicId, values);

            reportFunc()
                .then((response) => {
                    if (!response.success) throw new Error(response.errorDetail ?? '');

                    closeForm();
                    closeMenu && closeMenu();
                    enqueueSnackbar(
                        'Pozorování bylo úspěšně nahlášeno. Administrátoři databáze váš požadavek vyhodnotí.',
                        { variant: 'success' },
                    );
                })
                .catch((error) => {
                    console.error(error);
                    enqueueSnackbar(
                        'Pozorování se nepodařilo nahlásit. Zkuste to prosím znovu později případně kontaktujte administrátora databáze.',
                        {
                            variant: 'error',
                        },
                    );
                })
                .finally(() => setLoading(false));
        },
        [closeMenu, item, list, api],
    );

    if (isItem === undefined) {
        return null;
    }

    return (
        <PoppedControl
            renderIcon={() => (
                <MenuItem onClick={() => closeMenu && closeMenu()}>
                    <ListItemIcon>
                        <FlagRounded />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="body1" fontWeight="bold">
                            Nahlásit {isItem ? 'pozorování' : 'vycházku'}
                        </Typography>
                    </ListItemText>
                </MenuItem>
            )}
            renderControl={(closeForm) => (
                <Formik<ListItemReportFormValues>
                    initialValues={{ reason: '' }}
                    validationSchema={toFormikValidationSchema(request_list_report)}
                    onSubmit={(values) => {
                        handleReport(values, closeForm);
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                name="reason"
                                label="Popis porušení pravidel"
                                value={values.reason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                multiline
                                minRows={3}
                                maxRows={5}
                                sx={{ mt: 1.75 }}
                                error={!!errors.reason}
                                helperText={!!errors.reason && translateErrorMessage(errors.reason)}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 1.75,
                                    marginTop: 1.75,
                                }}
                            >
                                <Button onClick={() => closeForm()} color="error">
                                    Zrušit
                                </Button>
                                <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
                                    Nahlásit
                                </LoadingButton>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
            title={`Opravdu chcete nahlásit ${isItem ? 'pozorování' : 'vycházku'}?`}
            description={`Zde můžete ${
                isItem ? 'pozorování' : 'vycházku'
            } nahlásit, pokud si myslíte, že obsahuje nevhodný obsah nebo jinak narušuje kvalitu databáze. Připojte důvod, proč si myslíte, že by mělo být nahlášeno.`}
            disableButtons
        />
    );
};

export default ListItemReport;
