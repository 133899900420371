import { Container, Grid, Typography, Badge } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { INewsArticleSummary } from '../../schemas/interfaces';
import { useApi } from '../../services/apiContext';
import { parseISO } from 'date-fns';
import Loading from '../../components/Loading';
import { NEWS_LAST_VISIT_LS_KEY } from '../../components/AppHeaderNews';
import NewsCard from './NewsCard';

const NewsApp: FC = () => {
    const [articles, setArticles] = useState<INewsArticleSummary[]>();
    // last visit date before the current visit
    // this is used to display badges on the news that were published since the last visit
    // this needs to be set before the localStorage is updated
    const [lastVisit, setLastVisit] = useState<Date>();
    const api = useApi();

    useEffect(() => {
        const lastVisitString = window.localStorage.getItem(NEWS_LAST_VISIT_LS_KEY);
        if (lastVisitString && parseISO(lastVisitString)) setLastVisit(parseISO(lastVisitString));

        api.getAllNewsArticles()
            .then((articles) => {
                setArticles(articles);

                window.localStorage.setItem(NEWS_LAST_VISIT_LS_KEY, new Date().toISOString());
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    return (
        <Container maxWidth="lg" className="NewsApp" sx={{ pt: 5.75, pb: 6.75 }}>
            <Typography variant="h2" component="h1" textAlign="center" sx={{ pb: 3.75 }} fontWeight="bold">
                Novinky
            </Typography>
            {!articles && <Loading fullPage />}
            {articles && !articles.length && (
                <Typography variant="body1" component="p">
                    Aktuálně zde nejsou žádné novinky.
                </Typography>
            )}
            {articles && articles.length && (
                <Grid container spacing={2.25}>
                    {articles.map((article) => (
                        <Grid item xs={12} md={6} lg={4} key={article.id}>
                            {lastVisit && article.date > lastVisit ? (
                                <Badge
                                    badgeContent="Nové"
                                    color="error"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            right: '30px',
                                            top: '20px',
                                        },
                                    }}
                                >
                                    <NewsCard article={article} />
                                </Badge>
                            ) : (
                                <NewsCard article={article} />
                            )}
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
};

export default NewsApp;
