import { Box, SxProps, Typography } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import Loading from '../Loading';
import FullHeightContent from './FullHeightContent';

const LongLoadingOverlay: FC<{
    defaultText?: string;
    longLoadingText?: string;
    longLoadingTimeout?: number;
    boxSx?: SxProps;
}> = ({ defaultText, longLoadingText, longLoadingTimeout, boxSx }) => {
    const [showLongLoading, setShowLongLoading] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShowLongLoading(true), longLoadingTimeout ?? 20 * 1000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <FullHeightContent boxSx={boxSx}>
            <Loading />
            <Typography
                variant="subtitle2"
                component="p"
                sx={{
                    width: '500px',
                    maxWidth: '80vw',
                    display: 'inline-block',
                }}
            >
                {!showLongLoading && (defaultText ?? 'Probíhá načítání, prosím vydržte.')}
                {showLongLoading &&
                    (longLoadingText ??
                        `Výsledky filtru se nedaří načíst. Zkuste prosím aktualizovat stránku. Pokud se i poté ` +
                            `setkáte se stejným problémem, ozvěte se nám na admin@birds.cz, ať to můžeme zkusit opravit. Děkujeme!`)}
            </Typography>
        </FullHeightContent>
    );
};

export default LongLoadingOverlay;
