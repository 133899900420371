const scrollToFirstError = () => {
    setTimeout(() => {
        const errorElements = document.querySelectorAll('.Mui-error');

        if (errorElements.length > 0) {
            errorElements[0].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    });
};

export default scrollToFirstError;
