import { IAge, IPlace, ISex, ITaxon } from '../schemas/interfaces';

/**
 *
 * @param sexId ID of the observed sex
 * @param ageId ID of the observed age
 * @param count Observed count
 * @param sexDict Sex dictionary
 * @param ageDict Age dictionary
 * @returns Formatted count string as a combination of sex, age and count
 */
export const countFormatter = (
    sexId?: number | null,
    ageId?: number | null,
    count?: number | string | null,
    sexDict?: ISex[],
    ageDict?: IAge[],
) => {
    if (!sexDict || !ageDict) return 'Loading...';

    const sex = sexDict.find((s) => s.id === sexId);
    const age = ageDict.find((a) => a.id === ageId);
    const countWithSpace = count ? count + ' ' : '';

    if (!age && !sex && !count) return '';
    if (age) return countWithSpace + (age.expression.cs?.replace('{sex}', sex?.label || '') || age.name.cs || '');
    if (sex) return countWithSpace + (sex.label || '');

    return countWithSpace;
};

/**
 * Format location of an observation or a list into a string
 *
 * @param siteName Name of the locality provided by user
 * @param territorialUnitId ID of the municipality provided by user
 * @param placesDict Dictionary of municipalities (can be undefined when it is not loaded yet)
 * @returns Formatted location string which either consists of both locality and municipality or just either of them
 */
export const locationFormatter = (
    siteName?: string | null,
    territorialUnitId?: number | null,
    placesDict?: IPlace[],
) => {
    if (!placesDict) return 'Loading...';

    const municipality = placesDict.find((t) => t.id === territorialUnitId);
    const municipalityName = municipality?.name;

    return siteName && municipalityName ? `${siteName}, ${municipalityName}` : siteName || municipalityName;
};
