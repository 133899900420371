import { Card, CardContent, Container, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useApi } from '../../services/apiContext';
import { IAboutSection } from '../../schemas/interfaces';
import ContentSectionCards from '../../components/contentPages/ContentSectionCards';

const AboutApp: FC = () => {
    const [state, setState] = useState<{ sections?: IAboutSection[] }>({});
    const api = useApi();

    useEffect(() => {
        api.getAboutSections()
            .then((sections) => setState((s) => ({ ...s, sections })))
            .catch(console.error);
    }, []);

    return (
        <Container className="AboutApp" sx={{ pt: 5.75, pb: 6.75 }}>
            <Typography variant="h2" component="h1" textAlign="center" sx={{ pb: 3.75 }}>
                O databázi
            </Typography>
            <ContentSectionCards sections={state.sections} />
        </Container>
    );
};

export default AboutApp;
