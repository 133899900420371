import { Box, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';

const FloatingTabs: FC<{ children: JSX.Element; contentElement: HTMLDivElement | null; includeHeader?: boolean }> = ({
    children,
    contentElement,
    includeHeader,
}) => {
    const [tabsFixed, setTabsFixed] = useState(false);
    const [tabsHidden, setTabsHidden] = useState(false);
    const isMobile = useMediaQuery('(pointer: coarse)');

    useEffect(() => {
        const onScroll = () => {
            if (!contentElement) return;

            const threshold = contentElement.offsetTop - (isMobile ? 56 : 64) - 24;
            const bottomThreshold =
                contentElement.offsetTop + contentElement.offsetHeight - 56 - 24 - 54 - (includeHeader ? 56 : 0) - 52;

            if (window.scrollY > bottomThreshold && tabsFixed && !tabsHidden)
                return requestAnimationFrame(() => {
                    setTabsHidden(true);
                });
            if (window.scrollY > threshold && window.scrollY <= bottomThreshold && (!tabsFixed || tabsHidden))
                return requestAnimationFrame(() => {
                    setTabsHidden(false);
                    setTabsFixed(true);
                });
            if (window.scrollY <= threshold && (tabsFixed || tabsHidden))
                return requestAnimationFrame(() => {
                    setTabsHidden(false);
                    setTabsFixed(false);
                });
        };

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [tabsFixed, tabsHidden, contentElement, includeHeader]);

    return (
        <Box
            className={['FloatingTabs', tabsFixed ? 'fixed' : ''].join(' ')}
            sx={{
                width: '100%',
                borderRadius: 'var(--mui-shape-borderRadius)',
                overflow: 'hidden',
                '&.fixed': {
                    paddingTop: `calc(54px + ${includeHeader ? '56px' : '0px'})`,
                },
                visibility: tabsHidden ? 'hidden' : 'visible',
                '&.fixed .tabsWrapper': {
                    position: 'fixed',
                    zIndex: 1000,
                    width: '100%',
                    maxWidth: {
                        xs: 'calc(100vw - 2 * 16px + 4px)',
                        sm: 'calc(100vw - 2 * 24px + 4px)',
                        lg: 'calc(1200px - 2 * 24px + 4px)',
                    },
                    top: {
                        xs: '56px',
                        sm: '64px',
                    },
                    padding: '24px 2px 0 2px',
                    bgcolor: 'primary.50',
                    marginLeft: '-2px',
                    boxSizing: 'border-box',
                },
                '&.fixed + .MuiBox-root .MuiDataGrid-columnHeaders, \
                &.fixed + .MuiBox-root + .MuiBox-root .MuiDataGrid-columnHeaders': {
                    position: 'fixed',
                    zIndex: 1001,
                    width: '100%',
                    maxWidth: {
                        xs: 'calc(100vw - 2 * 16px)',
                        sm: 'calc(100vw - 2 * 24px)',
                        lg: 'calc(1200px - 2 * 24px)',
                    },
                    top: {
                        xs: 'calc(56px + 54px + 24px)',
                        sm: 'calc(64px + 54px + 24px)',
                    },
                    bgcolor: 'var(--mui-palette-background-paper)',
                    visibility: tabsHidden ? 'hidden' : 'visible',
                },
            }}
        >
            <Box className="tabsWrapper">{children}</Box>
        </Box>
    );
};

export default FloatingTabs;
