import { Save, Undo } from '@mui/icons-material';
import {
    Alert,
    Button,
    CircularProgress,
    Fab,
    lighten,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableProps,
    TableRow,
    Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormikErrors, FormikValues } from 'formik';
import React from 'react';
import { theme } from '../../theme';
import { ITableEditorRowPublicProps, TableEditorRow } from './TableEditorRow';
import { default as equal } from 'fast-deep-equal';
import TableEditorDraggable from './TableEditorDraggable';
import TableEditorRowFunctional, { TableEditorRowFunctionalProps } from './TableEditorRowFunctional';

export interface TableEditorItem<T> {
    /** @var data Item's data. */
    data: T;
    /** @var editing If true, the item is in edit mode. */
    editing?: boolean;
    /** @var deleted If true, the item has been deleted from the table. */
    deleted?: boolean;
    /** @var originalData Original item's data to be used in case changes are reverted. */
    originalData: T;
    /** @var empty If true, the item respresents an empty item (form used to create a new item). */
    empty?: boolean;
    /** @var modified If true, the item has been modified. */
    modified?: boolean;
    /** @var new If true, the item has been not been saved yet. */
    new?: boolean;
    /** @var modifiedData Data after any not submitted change */
    modifiedData: T;
}

export interface TableEditorState<T extends FormikValues> extends React.ComponentState {
    items: TableEditorItem<T>[];
    page: number;
    pagePerRow: number;
    loading: boolean;
    success?: boolean;
    changes: T[];
    deletions: T[];
    errors: (FormikErrors<T> | undefined)[];
}

export interface TableEditorProps<T extends FormikValues> extends ITableEditorRowPublicProps<T> {
    /** @var items Array of items that can be edited. */
    items: T[];
    /** @var tableOptions Props for the MUI's Table component. */
    tableProps?: TableProps;
    /** @var headerRenderer Function to render the header of the table. */
    headerRenderer: (items: T[]) => JSX.Element;
    /** @var footerRenderer Function to render the table footer. */
    footerRenderer?: (items: TableEditorItem<T>[]) => React.ReactNode;
    /** @var onUpdate Function to be triggered when an existing item is modified. */
    onUpdate?: (item: T, originalItem: T, index: number) => Promise<T>;
    /** @var onCreate Function to be triggered when a new item is created. */
    onCreate?: (item: T) => Promise<T>;
    /** @var onDelete Function to be triggered when an item is deleted. */
    onDelete?: (item: T, index: number) => Promise<T>;
    /** @var onDuplicate Function to be triggered when an item is duplicated. */
    onDuplicate?: (item: T) => Promise<T>;
    /** @var onSave Function to be triggered when the user clicks the save button, so he/she wants to submit all changes. */
    onSave?: (modifications: T[], deleted: T[], allItems: TableEditorItem<T>[]) => Promise<void>;
    /** @var onErrors Function to be triggered when the user tries to save invalid changes. */
    onErrors?(item: T, errors: FormikErrors<T>): void;
    /** @var onErrorsCleared Function to be triggered when all errors were resolved. */
    onErrorsCleared?: () => void;
    /** @var floatingButtons If true, buttons to revert & save changes will be displayed as FAB. Useful for table editors with many rows. */
    floatingButtons?: boolean;
    /** @var forceEdit If true, all items will be in edit mode by default. */
    forceEdit?: boolean;
    /** @var successMessage Message to be displayed when the user saves changes successfuly. */
    successMessage?: string;
    /** @var errorMessage Message to be displayed when the saving of changes fails. */
    errorMessage?: string;
    /** @var loadingMessage Message to be displayed when the saving of changes is in progress. */
    loadingMessage?: string;
    /** @var modifiedMessage Message to be displayed when the user modifies any item. */
    modifiedMessage?: string;
    /** @var invalidMessage Message to be displayed when the user tries to save changes with invalid items. */
    invalidMessage?: string;
    /** @var validationFunction Custom validation function to validate form values. */
    validationFunction?: (
        values: T,
        item: TableEditorItem<T>,
        allItems: TableEditorItem<T>[],
    ) => FormikErrors<T> | undefined;
    /** @var itemsComparator Function to compare items. Return true if items are equal (duplicates) or false otherwise. */
    itemsComparator?: (item1: T, item2: T) => boolean;
    /** @var pageSize Number of items to be displayed in a page. */
    pageSize?: number;
    /** @var hidePagination Hides pagination elements. Make sure to set proper pageSize, since this only hides the controls, doesn't disable pagination functionality. */
    hidePagination?: boolean;
    /** @var disableSnackbarMessages Hides all snackbar messages and alerts (e.g when a row is updated). */
    disableAlerts?: boolean;
    /** @var onValuesChange Function to be triggered when the user changes any value in the table. */
    onValuesChange?: (values: T[]) => void;
}

export class TableEditor<T extends FormikValues> extends React.Component<TableEditorProps<T>, TableEditorState<T>> {
    constructor(props: TableEditorProps<T>) {
        super(props);

        this.state = {
            items: this.getWrappedItems(props.items, props.forceEdit),
            page: 0,
            pagePerRow: props.pageSize || 20,
            loading: false,
            changes: [],
            deletions: [],
            errors: [],
        };
    }

    getWrappedItems(rawItems: T[], forceEdit = false, allNew = false) {
        return rawItems.map((item) => ({
            data: item,
            editing: forceEdit,
            deleted: false,
            originalData: { ...item },
            empty: false,
            modified: allNew,
            new: allNew,
            modifiedData: { ...item },
        })) as TableEditorItem<T>[];
    }

    componentDidUpdate(prevProps: TableEditorProps<T>): void {
        if (!equal(prevProps.items, this.props.items)) {
            this.setState({
                items: this.getWrappedItems(this.props.items, this.props.forceEdit),
                changes: [],
                deletions: [],
                page: 0,
            });
        }

        if (!equal(prevProps.triggerSubmit, this.props.triggerSubmit) && this.props.triggerSubmit) {
            setTimeout(() => {
                console.log('trigger submit table');
                this.handleSave();
            });
        }
    }

    async handleEnableEdit(item: TableEditorItem<T>, index: number) {
        const items = this.state.items;
        items[index] = {
            ...item,
            editing: true,
        };
        this.setState({ ...this.state, items });
    }

    async handleDelete(item: TableEditorItem<T>, index: number) {
        console.log('table handleDelete');
        const updateState = () => {
            const items = this.state.items;
            items[index] = {
                ...item,
                deleted: true,
                modified: true,
            };
            this.setState(
                {
                    ...this.state,
                    items,
                    changes: this.state.changes.filter((change) => !this._areItemsEqual(change, item.data)),
                    //if a new item is removed, it should not be added to the deletions array (because it has not been saved yet)
                    deletions: [...this.state.deletions, ...(!item.new ? [item.data] : [])],
                    success: this.props.onSave ? undefined : true,
                    page:
                        this.state.page * this.state.pagePerRow >= this._getItemsLength()
                            ? this.state.page - 1
                            : this.state.page,
                    loading: false,
                },
                () => {
                    this.props.submitOnChange && this.props.onSave && this.handleSave();
                    this.props.onValuesChange &&
                        this.props.onValuesChange(
                            this.state.items.filter((item) => !item.deleted).map((item) => item.modifiedData),
                        );
                },
            );
        };

        if (!this.props.onSave) this.setState({ ...this.state, loading: true });

        if (!this.props.onDelete) return updateState();

        return this.props
            .onDelete(item.data, index)
            .then(() => {
                return updateState();
            })
            .catch(() => {
                this.setState({ ...this.state, loading: false, success: this.props.onSave ? undefined : false });
            });
    }

    async handleCreate(item: TableEditorItem<T>) {
        console.log('table handleCreate');
        const updateState = (newItem: T) => {
            console.log('create updatestate', newItem);
            const items = this.state.items;
            items.push({
                ...item,
                data: newItem,
                originalData: { ...newItem },
                modifiedData: { ...newItem },
                editing: true,
                modified: true,
                empty: false,
            });
            this.setState(
                {
                    ...this.state,
                    items,
                    changes: [...this.state.changes, newItem],
                    deletions: this.state.deletions.filter((deletion) => !this._areItemsEqual(deletion, newItem)),
                    //go to last page
                    page: Math.floor((this._getItemsLength() - 1) / this.state.pagePerRow),
                    loading: false,
                    success: this.props.onSave ? undefined : true,
                },
                () => {
                    this.props.submitOnChange && this.props.onSave && this.handleSave();
                    this.props.onValuesChange &&
                        this.props.onValuesChange(
                            this.state.items.filter((item) => !item.deleted).map((item) => item.modifiedData),
                        );
                },
            );
        };

        if (!this.props.onSave) this.setState({ ...this.state, loading: true });

        if (!this.props.onCreate) return updateState(item.data);

        return this.props
            .onCreate(item.data)
            .then((newItem) => {
                return updateState(newItem);
            })
            .catch(() => {
                this.setState({ ...this.state, loading: false, success: this.props.onSave ? undefined : false });
            });
    }

    async handleDuplicate(item: TableEditorItem<T>) {
        console.log('table handleDuplicate');
        const updateState = (newItem: T) => {
            const newItems = [
                ...this.state.items,
                {
                    data: newItem,
                    originalData: newItem,
                    modifiedData: newItem,
                    editing: true,
                    modified: false,
                    empty: false,
                    new: true,
                },
            ];
            this.setState(
                {
                    ...this.state,
                    items: newItems,
                    changes: [...this.state.changes, newItem],
                    loading: false,
                    success: this.props.onSave ? undefined : true,
                },
                () => {
                    this.props.submitOnChange && this.props.onSave && this.handleSave();
                    this.props.onValuesChange &&
                        this.props.onValuesChange(
                            this.state.items.filter((item) => !item.deleted).map((item) => item.modifiedData),
                        );
                },
            );
        };

        if (!this.props.onDuplicate) return updateState(item.data);

        return this.props
            .onDuplicate(item.modifiedData)
            .then((newItem) => {
                return updateState(newItem);
            })
            .catch(() => {
                this.setState({ ...this.state, loading: false });
            });
    }

    async handleUpdate(item: TableEditorItem<T>, index: number) {
        this.handleErrorClear(index);
        const updateState = (updatedItem: T) => {
            const items = this.state.items;
            items[index] = {
                ...item,
                data: updatedItem,
                originalData: item.originalData,
                modifiedData: updatedItem,
                modified: true,
            };
            this.setState(
                {
                    ...this.state,
                    items,
                    changes: [
                        ...this.state.changes.filter((change) => !this._areItemsEqual(change, updatedItem)),
                        updatedItem,
                    ],
                    deletions: this.state.deletions.filter((deletion) => !this._areItemsEqual(deletion, updatedItem)),
                    loading: false,
                    success: this.props.onSave ? undefined : true,
                },
                () => {
                    this.props.submitOnChange && this.props.onSave && this.handleSave();
                    this.props.onValuesChange &&
                        this.props.onValuesChange(
                            this.state.items.filter((item) => !item.deleted).map((item) => item.modifiedData),
                        );
                },
            );
        };

        if (!this.props.onSave) this.setState({ ...this.state, loading: true });

        if (!this.props.onUpdate) return updateState(item.modifiedData);

        return this.props
            .onUpdate(item.modifiedData, item.originalData, index)
            .then((updatedItem) => {
                return updateState(updatedItem);
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                    success: this.props.onSave ? undefined : false,
                });
            });
    }

    handleSave() {
        if (!this.props.onSave) return;

        this.setState({
            ...this.state,
            loading: true,
        });

        console.log('handleSave', this.state.changes, this.state.deletions);

        this.props
            .onSave(this.state.changes, this.state.deletions, this.state.items)
            .then(() => {
                this.setState({
                    ...this.state,
                    items: this.getWrappedItems(this.props.items, this.props.forceEdit),
                    loading: false,
                    success: true,
                });
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    loading: false,
                    success: false,
                });
            });
    }

    async handleRevert(item: TableEditorItem<T>, index: number) {
        this.handleErrorClear(index);
        this.setState((state) => {
            const items = state.items;
            items[index] = {
                ...item,
                data: item.originalData,
                modifiedData: item.originalData,
                modified: false,
                deleted: false,
            };
            return {
                items,
                changes: [],
                deletions: [],
            };
        });
    }

    handleRevertAll() {
        this.setState((state) => {
            const items = state.items;
            return {
                ...state,
                items: items
                    .filter((item) => !item.new || item.empty)
                    .map((item) => ({
                        ...item,
                        data: item.originalData,
                        modified: false,
                        deleted: false,
                    })),
                page: 0,
                errors: [],
            };
        });
    }

    handleError(item: TableEditorItem<T>, index: number, errors: FormikErrors<T>) {
        this.props.onErrors && this.props.onErrors(item.data, errors);

        this.setState((state) => {
            state.errors[index] = errors;
            return { ...state, errors: state.errors };
        });
    }

    handleErrorClear(index: number) {
        this.setState((state) => {
            const errors = [...state.errors];
            errors[index] = undefined;

            if (errors.filter((value) => !!value).length === 0)
                this.props.onErrorsCleared && this.props.onErrorsCleared();

            return { ...state, errors };
        });
    }

    handleValuesChange(values: T, index: number) {
        this.handleErrorClear(index);

        const items = this.state.items;
        items[index] = {
            ...items[index],
            modifiedData: values,
        };

        this.setState(
            {
                ...this.state,
                items,
            },
            () => {
                this.props.onValuesChange &&
                    this.props.onValuesChange(
                        this.state.items.filter((item) => !item.deleted).map((item) => item.modifiedData),
                    );
            },
        );
    }

    handleChangePage(event: unknown, newPage: number) {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            pagePerRow: parseInt(event.target.value, 10),
            page: 0,
        });
    }

    _areItemsEqual(item1: T, item2: T) {
        if (this.props.itemsComparator) return this.props.itemsComparator(item1, item2);

        return equal(item1, item2);
    }

    _isItemValid(item: TableEditorItem<T>): boolean {
        if (this.props.validationFunction) return !this.props.validationFunction(item.data, item, this.state.items);

        return true;
    }

    _hasInvalidItems(): boolean {
        return this.state.items.reduce((acc, item) => {
            return !this._isItemValid(item) || acc;
        }, false);
    }

    _hasModifiedItems() {
        return this.state.items.some((item) => item.modified);
    }

    _submitEnabled(allowInvalid = false) {
        return this._hasModifiedItems() && (allowInvalid || !this._hasInvalidItems());
    }

    _getItemsLength() {
        return this.state.items.filter((item) => !item.deleted).length;
    }

    _saveTooltipText(always = true): string | undefined {
        if (this._submitEnabled()) return always ? 'Save changes' : undefined;

        if (this._hasInvalidItems()) return 'Cannot save changes because of invalid items';

        return 'No changes to save';
    }

    _revertTooltipText(always = true): string | undefined {
        if (this._submitEnabled(true)) return always ? 'Revert all changes' : undefined;

        return 'No changes made yet';
    }

    /** Returns range of items that should be displayed on given page with regards to
     * deleted items. Deleteted items are ignored and only visible items are counted.
     *
     * @param page Number of page
     * @param perPage Number of items per page
     * @param items All items
     * @returns Tuple of start and end index of items that should be displayed on given page
     */
    _getPageRange(page: number, perPage: number, items: TableEditorItem<T>[]): [number, number] {
        const start = page > 0 ? this._getPageRange(page - 1, perPage, items)[1] + 1 : 0;
        let end = 0;

        items.reduce((acc, item, index) => {
            if (index < start) return acc;

            if (acc.length < perPage && !item.deleted) {
                acc.push(item);
                end = index;
                return acc;
            }

            return acc;
        }, [] as TableEditorItem<T>[]);

        return [start, end];
    }

    handleSnackbarClose(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ success: undefined });
    }

    render() {
        const { items, loading, success } = this.state;
        const {
            items: originalItems,
            tableProps,
            floatingButtons,
            successMessage,
            errorMessage,
            loadingMessage,
            modifiedMessage,
            invalidMessage,
            footerRenderer,
            disableAlerts: disableSnackbarMessages,
            columnsNumber,
        } = this.props;

        const emptyItem = {
            data: {} as T,
            originalData: {} as T,
            modifiedData: {} as T,
            editing: true,
            modified: false,
            empty: true,
            new: true,
        };

        const pageBoundaries = this._getPageRange(this.state.page, this.state.pagePerRow, items);
        const validationFunctionFactory = (item: TableEditorItem<T>) => (values: T) =>
            this.props.validationFunction ? this.props.validationFunction(values, item, items) : undefined;

        // number of columns provided by user
        // + 2 action columns (mobile on the left, desktop on the right side)
        // + 1 hidden column for the form
        const totalColumnsNumber = columnsNumber + (this.props.hideActionsColumn ? 0 : 2) + 1;

        return (
            <div className="TableEditor">
                {!disableSnackbarMessages && (
                    <>
                        {
                            <Snackbar open={loading}>
                                <Alert severity="info" iconMapping={{ info: <CircularProgress size={18} /> }}>
                                    {loadingMessage || 'Saving changes...'}
                                </Alert>
                            </Snackbar>
                        }
                        {
                            <Snackbar
                                open={success === true}
                                autoHideDuration={6000}
                                onClose={this.handleSnackbarClose.bind(this)}
                            >
                                <Alert severity="success">{successMessage || 'Changes were successfully saved.'}</Alert>
                            </Snackbar>
                        }
                        {
                            <Snackbar
                                open={success === false}
                                autoHideDuration={6000}
                                onClose={this.handleSnackbarClose.bind(this)}
                            >
                                <Alert severity="error">
                                    {errorMessage || 'An error occured while saving the changes.'}
                                </Alert>
                            </Snackbar>
                        }
                        {this._hasModifiedItems() && (
                            <Alert
                                severity="warning"
                                sx={{ width: '100%', marginBottom: '1em', boxSizing: 'border-box' }}
                            >
                                {modifiedMessage ||
                                    'There are some modified items. Make sure to save the changes before leaving the page.'}
                            </Alert>
                        )}
                        {this._hasInvalidItems() && (
                            <Alert
                                severity="error"
                                sx={{ width: '100%', marginBottom: '1em', boxSizing: 'border-box' }}
                            >
                                {invalidMessage ||
                                    'There are some invalid items. Review the table and resolve the errors before submitting.'}
                            </Alert>
                        )}
                    </>
                )}
                <TableEditorDraggable />
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                    }}
                >
                    <Table
                        size="small"
                        {...tableProps}
                        sx={{
                            ...tableProps?.sx,
                            '& .MuiTableCell-root': {
                                wordBreak: 'break-word',
                            },
                            '& .MuiTableHead-root .MuiTableCell-root': {
                                fontWeight: 800,
                            },
                            '& .MuiTableCell-sizeSmall': {
                                padding: '6px',
                            },
                            '& .MuiTableRow-root.no-border .MuiTableCell-root': {
                                borderBottom: 'none',
                            },
                            '& .MuiTableRow-root.invalid .MuiTableCell-root': {
                                backgroundColor: lighten(theme.palette.error.main, 0.8),
                            },
                            '& .MuiTableRow-root.bottom-space .MuiTableCell-root': {
                                paddingBottom: '48px',
                            },
                            '& .MuiTableRow-root.expanded .MuiTableCell-root': {
                                paddingTop: '16px',
                                paddingBottom: '16px',
                            },
                            '& .MuiTableRow-root.header .MuiTableCell-root': {
                                paddingTop: '0.8em',
                                paddingBottom: '0.4em',
                            },
                            '& .MuiTableRow-root .MuiTableCell-root:nth-of-type(3)': {
                                paddingLeft: '14px',
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow
                                className="expanded"
                                sx={{
                                    display: items.length < 1 ? 'none' : undefined,
                                    bgcolor: { xs: '#ffffffa0', md: 'transparent' },
                                }}
                            >
                                <TableCell sx={{ display: 'none' }}>Hidden</TableCell>
                                {!this.props.hideActionsColumn && (
                                    <TableCell
                                        sx={{
                                            minWidth: 'calc(40px + 10px)',
                                            width: 'calc(40px + 10px)',
                                            textAlign: 'center',
                                            display: {
                                                xs: 'table-cell',
                                                md: 'none',
                                            },
                                        }}
                                    >
                                        Akce
                                    </TableCell>
                                )}
                                {this.props.headerRenderer(originalItems)}
                                {!this.props.hideActionsColumn && (
                                    <TableCell
                                        sx={{
                                            minWidth: 'calc(40px + 10px)',
                                            width: 'calc(40px + 10px)',
                                            textAlign: 'center',
                                            display: {
                                                xs: 'none',
                                                md: 'table-cell',
                                            },
                                        }}
                                    >
                                        Akce
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.slice(pageBoundaries[0], pageBoundaries[1] + 1).map((item, pageIndex) => {
                                const index = this.state.page * this.state.pagePerRow + pageIndex;

                                return (
                                    <TableEditorRowFunctional
                                        {...(this.props as ITableEditorRowPublicProps<T>)}
                                        item={item}
                                        key={index}
                                        index={index}
                                        onEnableEdit={() => this.handleEnableEdit(item, index)}
                                        onUpdate={(newItem) => this.handleUpdate(newItem, index)}
                                        onDelete={
                                            this.props.onDelete ? () => this.handleDelete(item, index) : undefined
                                        }
                                        onDuplicate={
                                            this.props.onDuplicate ? () => this.handleDuplicate(item) : undefined
                                        }
                                        onRevert={(item) => this.handleRevert(item, index)}
                                        onChange={(values) => this.handleValuesChange(values, index)}
                                        disableButtons={this.props.disableButtons}
                                        validationFunction={validationFunctionFactory(item)}
                                        onValidationError={(item, errors) => this.handleError(item, index, errors)}
                                    />
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            {!this.props.hidePagination && (
                                <TableRow className="no-border bottom-space">
                                    <TableCell colSpan={totalColumnsNumber}>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 20, 50]}
                                            component="div"
                                            count={this._getItemsLength()}
                                            rowsPerPage={this.state.pagePerRow}
                                            page={this.state.page}
                                            onPageChange={this.handleChangePage.bind(this)}
                                            onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {this.props.onCreate && (
                                <TableEditorRowFunctional
                                    {...(this.props as ITableEditorRowPublicProps<T>)}
                                    item={emptyItem}
                                    index={-1}
                                    key="new-item"
                                    onCreate={(item) => this.handleCreate(item)}
                                    validationFunction={validationFunctionFactory(emptyItem)}
                                />
                            )}
                            {!!footerRenderer && footerRenderer(items)}
                            {!floatingButtons &&
                                (!this.props.disableButtons?.includes('revertAll') ||
                                    !this.props.disableButtons?.includes('save')) && (
                                    <TableRow className="no-border">
                                        <TableCell
                                            colSpan={totalColumnsNumber}
                                            sx={{ textAlign: 'right', paddingRight: 0 }}
                                        >
                                            {!this.props.disableButtons?.includes('revertAll') && (
                                                <Tooltip title={this._revertTooltipText(false)}>
                                                    <span>
                                                        <Button
                                                            onClick={() => this.handleRevertAll()}
                                                            disabled={!this._submitEnabled(true)}
                                                            variant="outlined"
                                                            sx={{ marginRight: '1em' }}
                                                        >
                                                            Revert all changes
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                            {!this.props.disableButtons?.includes('save') && (
                                                <Tooltip title={this._saveTooltipText(false)}>
                                                    <span>
                                                        <Button
                                                            onClick={() => this.handleSave()}
                                                            disabled={!this._submitEnabled()}
                                                            color="primary"
                                                            variant="contained"
                                                        >
                                                            Save changes
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                        </TableFooter>
                    </Table>
                </TableContainer>
                {floatingButtons && (
                    <>
                        {!this.props.disableButtons?.includes('revertAll') && (
                            <Tooltip placement="left" title={this._revertTooltipText(true)}>
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        bottom: 'calc(1em + 56px + 1em)',
                                        right: 'calc(1em + 4px)',
                                        zIndex: 450,
                                    }}
                                >
                                    <Fab
                                        color="secondary"
                                        size="medium"
                                        aria-label="revert changes"
                                        onClick={() => this.handleRevertAll()}
                                        disabled={!this._submitEnabled(true)}
                                    >
                                        <Undo />
                                    </Fab>
                                </Box>
                            </Tooltip>
                        )}
                        {!this.props.disableButtons?.includes('save') && (
                            <Tooltip placement="left" title={this._saveTooltipText(true)}>
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        bottom: '1em',
                                        right: '1em',
                                        zIndex: 450,
                                    }}
                                >
                                    <Fab
                                        color="primary"
                                        aria-label="submit changes"
                                        onClick={() => this.handleSave()}
                                        disabled={!this._submitEnabled()}
                                    >
                                        <Save />
                                    </Fab>
                                </Box>
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        );
    }
}
