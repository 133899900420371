import { Help } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import { FC } from 'react';
import TaxonLanguageSwitcher from '../../../../components/formControls/TaxonLanguageSwitcher';
import { TooltipMobile } from '../../../../components/Tooltip';
import { ObservationItemFormValues } from '../../ObservationFormApp';

const Header: FC<{ items: ObservationItemFormValues[] }> = ({ items }) => {
    return (
        <>
            {[
                ['Druh', '30%', 200, false, <TaxonLanguageSwitcher />],
                [
                    'Počet',
                    '10%',
                    100,
                    false,
                    <TooltipMobile title="Formát: 5; min&nbsp;5; max&nbsp;5; >5; <5; 5-10, cca&nbsp;10.">
                        <Help fontSize="small" />
                    </TooltipMobile>,
                ],
                [
                    'Pohlaví',
                    '12.5%',
                    100,
                    false,
                    <TooltipMobile
                        title="V&nbsp;případě zadání páru pozor na&nbsp;počet! Kombinace počet = 2, pohlaví = pár ve&nbsp;výsledku 
                znamená 4 jedince. Pokud vidíte jeden pár, zadejte počet = 1, pohlaví = pár. Pokud zaznamenáte jedince stejného 
                druhu s&nbsp;různým pohlavím, kteří ale netvořili pár, zadejte je do&nbsp;různých řádků."
                    >
                        <Help fontSize="small" />
                    </TooltipMobile>,
                ],
                [
                    'Věk',
                    '10%',
                    100,
                    false,
                    <TooltipMobile title="V&nbsp;případě pozorování jedinců stejného druhu různého stáří, zadejte druh do&nbsp;více řádků.">
                        <Help fontSize="small" />
                    </TooltipMobile>,
                ],
                [
                    'Aktivita',
                    '17.5%',
                    100,
                    false,
                    <TooltipMobile title="Při zadání hnízdní kategorie zadávejte vždy tu nejvyšší možnou.">
                        <Help fontSize="small" />
                    </TooltipMobile>,
                ],
                ['Pozn.', '5%', 50, true, undefined],
                ['Foto', '5%', 50, true, undefined],
                ['GPS', '5%', 50, true, undefined],
            ].map(([label, width, minWidth, center, icon]) => (
                <TableCell
                    width={width as string}
                    align={center ? 'center' : undefined}
                    key={label as string}
                    sx={{ minWidth: minWidth as number }}
                >
                    {label} {icon}
                </TableCell>
            ))}
        </>
    );
};

export default Header;
