import React, { useState, useEffect } from 'react';
import IObsListsResultsComponentProps from '../../../../interfaces/IObsListsResultsComponentProps';
import { useApi } from '../../../../services/apiContext';
import { FeatureCollection } from '../../../../schemas/interfaces';
import { Grid, Typography } from '@mui/material';
import { useAuth } from '../../../../services/authenticator';
import useDeepCompareEffect from 'use-deep-compare-effect';
import LongLoadingOverlay from '../../../../components/dataGrid/LongLoadingOverlay';
import MapTypeSelect from './map/MapTypeSelect';
import { useUser } from '../../../../services/userContext';
import GeoJsonMap from './map/GeojsonMap';
import { placesDictionaryProvider } from '../../../../services/dictProviders';
import { OSM_DEFAULT_ZOOM } from '../../../../components/map/OpenStreetMap';

const ObsItemsMap: React.FunctionComponent<Required<IObsListsResultsComponentProps>> = (props) => {
    const [geojson, setGeojson] = useState<FeatureCollection | null | undefined>();
    const [mapMode, setMapMode] = useState<string>();
    const [territorialUnitCenter, setTerritorialUnitCenter] = useState<[number, number] | undefined>();
    const [territorialUnitZoom, setTerritorialUnitZoom] = useState<number | undefined>();
    const { getObservationsMapNew } = useApi();
    const { search } = useUser();
    const { isLoggedIn } = useAuth();

    useDeepCompareEffect(() => {
        if (!props.query || isLoggedIn === undefined || !mapMode) return;

        setGeojson(undefined);
        getObservationsMapNew({
            filter: props.query,
            mode: mapMode,
        })
            .then((response) => {
                setGeojson(response);
            })
            .catch((e) => {
                setGeojson(null);
                props.onFetchError && props.onFetchError(e);
            });
    }, [props.query, isLoggedIn, mapMode]);

    useEffect(() => {
        if (!search?.mapTypes.default) return;
        setMapMode(search.mapTypes.default);
    }, [search]);

    useEffect(() => {
        if (!props.query.territorialUnitId || props.query.territorialUnitId.length !== 1) {
            // console.log('unsetting zoom base on territory');
            setTerritorialUnitCenter(undefined);
            setTerritorialUnitZoom(undefined);
            return;
        }

        placesDictionaryProvider.onFirstValue().then((places) => {
            const id = props.query.territorialUnitId?.[0];
            const place = places.items.find((place) => place.id === id);
            const placeType = places.types.find((type) => type.id === place?.type);

            // console.log('setting zoom based on territory', id, place, placeType);

            if (!id || !place || !placeType || !place.latitude || !place.longitude) return;

            const zoom = OSM_DEFAULT_ZOOM + (placeType.zoomCorrection ?? 0);

            // console.log('setting zoom based on territory to', [place.latitude, place.longitude], zoom);
            setTerritorialUnitCenter([place.latitude, place.longitude]);
            setTerritorialUnitZoom(zoom);
        });
    }, [props.query.territorialUnitId]);

    return (
        <Grid container className="ObsItemsMap" sx={{ px: 2.25, py: 4 }} spacing={2}>
            <Grid item xs={12}>
                <MapTypeSelect
                    fullWidth
                    value={mapMode}
                    multiple={false}
                    onChange={(event) => {
                        if (!event.target.value) return;

                        setMapMode(event.target.value as string);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                {geojson === undefined && <LongLoadingOverlay />}
                {geojson === null && (
                    <>
                        <Typography variant="subtitle1" component="h2" textAlign="center">
                            Kritériím vyhledávání neodpovídají žádná pozorování.
                        </Typography>
                        <Typography variant="body2" component="p" textAlign="center">
                            Pozorování s&nbsp;polohou skrytou na&nbsp;úroveň kraje nejsou v&nbsp;mapě zobrazena.
                        </Typography>
                    </>
                )}
                {!!geojson && (
                    <GeoJsonMap
                        geojson={geojson}
                        height="500px"
                        center={territorialUnitCenter}
                        defaultZoom={territorialUnitZoom}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default ObsItemsMap;
