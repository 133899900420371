import { LockRounded } from '@mui/icons-material';
import { SvgIconProps, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';
import { TooltipMobile } from '../Tooltip';
import { format } from 'date-fns';

const SecrecyLock: FC<{
    secrecyLevel: number | null | undefined;
    secretUntil?: Date | null;
    TooltipProps?: Partial<TooltipProps>;
    SvgIconProps?: Partial<SvgIconProps>;
}> = ({ secrecyLevel, secretUntil, TooltipProps, SvgIconProps }) => {
    if (!secrecyLevel) return null;

    let title =
        secrecyLevel >= 100
            ? `Pozorování je kompetně skryto`
            : secrecyLevel >= 90
            ? `Místo pozorování je skryto`
            : `Místo pozorování je utajeno na\xa0úroveň ${secrecyLevel >= 60 ? 'kraje' : 'okresu'}`;

    if (secretUntil) title += ' do\xa0' + format(secretUntil, 'd.\xa0M.\xa0y');
    else title += '.';

    return (
        <TooltipMobile {...TooltipProps} title={TooltipProps?.title ?? title}>
            <LockRounded fontSize="inherit" {...SvgIconProps} sx={{ ml: 1, ...SvgIconProps?.sx }} />
        </TooltipMobile>
    );
};

export default SecrecyLock;
