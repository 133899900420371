const ERROR_KEY_VALUE_IDENTIFIER = '.value=';

const czech: Map<string, string> = new Map([
    ['errors.required', 'Toto pole je povinné.'],
    ['errors.integer.invalid', 'Vložte prosím celé číslo.'],
    ['Required', 'Toto pole je povinné.'],
    ['Expected string, received null', 'Toto pole je povinné.'],
    ['Expected number, received string', 'Prosím, zadejte celé číslo.'],
    ['errors.list.date.required', 'Prosím, zadejte datum pozorování.'],
    ['errors.list.date.future', 'Datum pozorování nemůže být v\xa0budoucnosti.'],
    ['errors.list.date.min', 'Není možné zadávat pozorování starší než\xa01.1.1900.'],
    ['errors.list.date.relative.min', 'Prosím zadejte celé číslo >= 1.'],
    ['errors.list.date.relative.max', 'Prosím zadejte celé číslo < 10 000.'],
    ['errors.list.timeStart.required', 'Pro kompletní seznam je povinné zadat začátek vycházky.'],
    ['errors.list.timeEnd.required', 'Pro kompletní seznam je povinné zadat konec vycházky.'],
    ['errors.list.timeStart.future', 'Začátek vycházky nemůže být v\xa0budoucnosti.'],
    ['errors.list.timeEnd.future', 'Konec vycházky nemůže být v\xa0budoucnosti.'],
    [
        'errors.list.timeStart.requiredWithEnd',
        'Pokud je vyplněn čas konce vycházky, vyplňte prosím i čas začátku vycházky.',
    ],
    ['errors.list.municipalityPartId.required', 'Zadejte prosím místo pozorování.'],
    ['errors.list.coordinates.required', 'Prosím, pro zadání bodu klikněte do\xa0mapy.'],
    ['errors.list.coordinates.oneEmpty', 'Pro zadání souřadnic je vyžadována zeměpisná šířka i délka.'],
    ['errors.list.coordinates.tooFar', 'Prosím, zadejte souřadnice v\xa0okruhu %value%\xa0km od\xa0středu obce.'],
    ['errors.list.track.required', 'Prosím, pro\xa0zadání linie vyberte alespoň dva body na\xa0mapě.'],
    ['errors.list.track.tooFar', 'Prosím, zadejte souřadnice v\xa0okruhu %value%\xa0km od\xa0středu obce.'],
    ['errors.list.distanceCovered.tooBig', 'Prosím, zadejte vzdálenost kratší než 50\xa0km.'],
    ['errors.list.radius.invalid', 'Prosím, zadejte poloměr v\xa0jednotkách km.'],
    ['errors.list.radius.min', 'Prosím, zadejte poloměr větší nebo roven 1\xa0km.'],
    ['errors.list.radius.max', 'Prosím, zadejte poloměr menší nebo roven 100\xa0km.'],
    ['errors.list.secrecyLevel.empty', 'Prosím, vyberte úroveň utajení.'],
    ['errors.list.secretUntil.empty', 'Prosím, vyberte datum konce utajení.'],
    ['errors.list.observersNumber.min', 'Prosím, zadejte počet pozorovatelů větší nebo roven 1.'],
    ['errors.list.observersNumber.max', 'Prosím, zadejte počet pozorovatelů menší než 100.'],
    ['errors.item.taxonId.required', 'Prosím, vyberte druh.'],
    [
        'errors.item.count.invalid',
        'Prosím, zadejte ve\xa0formátu: 5; min\xa05; max\xa05; >5; <5; 5-10; cca\xa010. Zadejte maximálně pěticiferné číslo.',
    ],
    ['errors.item.coordinates.tooFar', 'Prosím, zadejte souřadnice v\xa0okruhu %value%\xa0km od\xa0vycházky.'],
    ['errors.item.coordinates.oneEmpty', 'Pro zadání souřadnic je vyžadována zeměpisná šířka i délka.'],
    ['errors.export.consent.required', 'Pro export dat je nutné potvrdit prohlášení.'],
    ['errors.login.username.empty', 'Prosím, zadejte uživatelské jméno.'],
    ['errors.login.password.empty', 'Prosím, zadejte heslo.'],
    ['errors.charts.taxonId.min', 'Prosím, vyberte alespoň 1 druh, který chcete zobrazit.'],
    ['errors.charts.taxonId.max', 'Prosím, vyberte maximálně 3 druhy, které chcete zobrazit.'],
]);

const translateErrorMessage = (messageKey: string | undefined): string | undefined => {
    if (!messageKey) return undefined;

    if (messageKey.indexOf(ERROR_KEY_VALUE_IDENTIFIER) > -1) {
        const key = messageKey.split(ERROR_KEY_VALUE_IDENTIFIER)[0];
        const value = messageKey.split(ERROR_KEY_VALUE_IDENTIFIER)[1];

        const translation = czech.get(key);

        if (!translation) return messageKey;

        return translation.replace('%value%', value);
    }

    return czech.get(messageKey) ?? messageKey;
};

export default translateErrorMessage;
