import { FC } from 'react';
import UndefinedDictValue from './UndefinedDictValue';
import { useDict } from '../../services/dictContext';

const SexFromDict: FC<{ sexId?: number | null }> = ({ sexId }) => {
    const { sex: sexes } = useDict();

    if (!sexId) return <UndefinedDictValue />;

    if (!sexes) return <>Loading...</>;

    const sex = sexes.find((sex) => sex.id === sexId);

    if (!sex) return <>Unknown sex</>;

    return <>{sex.name.cs || 'Missing sex label'}</>;
};

export default SexFromDict;
