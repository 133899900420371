import { LocationOnRounded, AddLocationAltRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import PoppedControl from '../../../../../components/formControls/PoppedControl';
import CoordinatesMap from '../../../../../components/map/CoordinatesMap';
import { OSM_POINT_REQUIRED_ZOOM, MapCategory } from '../../../../../components/map/OpenStreetMap';
import { ICoordinates } from '../../../../../schemas/interfaces';
import { ObservationFormValues, ObservationItemFormValues } from '../../../ObservationFormApp';
import { Subject } from 'rxjs';
import { ExternalChangeEvent } from '../../../../../components/tableEditor/TableEditorRow';

const CoordinatesDialog: FC<{
    initialValues?: Partial<ObservationFormValues>;
    formChanges?: Subject<ExternalChangeEvent>;
    index: number;
}> = ({ initialValues, formChanges, index }) => {
    const formikProps = useFormikContext<ObservationItemFormValues>();
    const { values, errors } = formikProps;

    return (
        <PoppedControl
            renderIcon={() => (
                <Tooltip title="Přidat GPS souřadnice pozorování">
                    <IconButton aria-label="Přidat GPS souřadnice" color={values.coordinates ? 'secondary' : undefined}>
                        {values.coordinates ? <LocationOnRounded /> : <AddLocationAltRounded />}
                    </IconButton>
                </Tooltip>
            )}
            renderControl={() => {
                const listCoordinates =
                    initialValues?.coordinates &&
                    initialValues?.coordinates.latitude &&
                    initialValues?.coordinates.longitude
                        ? ([initialValues.coordinates.latitude, initialValues.coordinates.longitude] as ICoordinates)
                        : undefined;

                const listTrackCoordinates =
                    initialValues?.track && initialValues.track[0] && initialValues.track.at(-1)
                        ? ([
                              (initialValues.track[0][0] + (initialValues.track.at(-1) as ICoordinates)[0]) / 2,
                              (initialValues.track[0][1] + (initialValues.track.at(-1) as ICoordinates)[1]) / 2,
                          ] as ICoordinates)
                        : undefined;

                const municipalityCoordinates =
                    initialValues?._Municipality?.latitude && initialValues?._Municipality?.longitude
                        ? ([
                              initialValues._Municipality.latitude,
                              initialValues._Municipality.longitude,
                          ] as ICoordinates)
                        : undefined;

                const itemCoordinates =
                    values.coordinates?.latitude && values.coordinates?.longitude
                        ? ([values.coordinates.latitude, values.coordinates.longitude] as ICoordinates)
                        : undefined;

                return (
                    <CoordinatesMap
                        coordsValues={{
                            latitude: values.coordinates?.latitude,
                            longitude: values.coordinates?.longitude,
                        }}
                        center={itemCoordinates ?? listTrackCoordinates ?? listCoordinates ?? municipalityCoordinates}
                        zoom={OSM_POINT_REQUIRED_ZOOM}
                        formikProps={formikProps}
                        inputNamePrefix="coordinates."
                        disableRadius
                        requiredZoom={OSM_POINT_REQUIRED_ZOOM}
                        backgroundCoords={
                            initialValues?.track ??
                            (initialValues?.coordinates &&
                            initialValues?.coordinates.latitude &&
                            initialValues?.coordinates.longitude
                                ? [[initialValues.coordinates.latitude, initialValues.coordinates.longitude]]
                                : undefined)
                        }
                        mapCategory={MapCategory.INSERT}
                        onFieldChange={(field, value) => {
                            formChanges?.next({
                                index,
                                fieldName: field,
                                fieldValue: value,
                            });
                        }}
                    />
                );
            }}
            title="GPS souřadnice pozorování"
            confirmButtonLabel="Uložit"
            confirmButtonDisabled={!!errors.coordinates}
            disableClickaway
        />
    );
};

export default CoordinatesDialog;
