import { Avatar, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { FC, useState } from 'react';
import { useAuth } from '../services/authenticator';
import { ImportExportRounded, LogoutRounded, PersonRounded } from '@mui/icons-material';
import Loading from './Loading';

const AppHeaderUser: FC = () => {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [logoutLoading, setLogoutLoading] = useState(false);
    const auth = useAuth();

    const settings = [
        { title: 'Můj profil', path: `/profile/${auth.identity?.id}`, icon: <PersonRounded /> },
        {
            title: 'Import dat',
            path: `/import`,
            icon: <ImportExportRounded />,
        },
    ];

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setLogoutLoading(false);
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        if (!auth.actions?.logout) return;

        setLogoutLoading(true);

        auth.actions
            .logout()
            .catch((error) => {
                console.log(error);
            })
            .finally(() => handleCloseUserMenu());
    };

    if (!auth.isLoggedIn || !auth.identity) return null;

    const initials =
        auth.identity.firstName && auth.identity.lastName
            ? `${auth.identity.firstName[0]}${auth.identity.lastName[0]}`
            : 'AA';

    return (
        <>
            <Tooltip title={`${auth.identity.firstName} ${auth.identity.lastName}`}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>{initials}</Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting, index) => (
                    <Link to={setting.path} style={{ textDecoration: 'none' }} key={index}>
                        <MenuItem onClick={handleCloseUserMenu}>
                            <ListItemIcon>{setting.icon}</ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" component="span" fontWeight="bold" color="primary">
                                    {setting.title}
                                </Typography>
                            </ListItemText>
                        </MenuItem>
                    </Link>
                ))}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        {logoutLoading ? (
                            <Loading inline progressProps={{ size: 20, thickness: 4 }} />
                        ) : (
                            <LogoutRounded />
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="body1" component="span" fontWeight="bold" color="primary">
                            Odhlásit se
                        </Typography>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default AppHeaderUser;
