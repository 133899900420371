import { Card, CardContent, CardMedia, Container, Typography, Box, Grid, Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { INewsArticle, INewsArticleSummary } from '../../schemas/interfaces';
import { format, parse } from 'date-fns';
import { useApi } from '../../services/apiContext';
import HtmlContent from '../../components/contentPages/HtmlContent';
import Loading from '../../components/Loading';

const NewsViewApp: FC = () => {
    const params = useParams();
    const [state, setState] = useState<{
        id?: string;
        language?: string;
        article?: INewsArticle;
        otherArticles?: INewsArticleSummary[];
        notFound: boolean;
    }>({
        language: params.language as string,
        notFound: false,
    });
    const api = useApi();

    useEffect(() => {
        setState((s) => ({ ...s, id: params.id as string }));
    }, [params.id]);

    useEffect(() => {
        if (!state.id || !state.language) return;

        api.getNewsArticleById(state.id, state.language)
            .then((article) => setState((s) => ({ ...s, article })))
            .catch((e) => {
                console.error(e);
                setState((s) => ({ ...s, notFound: true }));
            });

        api.getAllNewsArticles(state.language)
            .then((articles) => setState((s) => ({ ...s, otherArticles: articles.filter((a) => a.id !== state.id) })))
            .catch((e) => console.error(e));
    }, [state.id]);

    return (
        <Container maxWidth="lg" className="NewsViewApp" sx={{ pt: 5.75, pb: 6.75 }}>
            {!state.article && !state.notFound && <Loading fullPage />}
            {state.notFound && <h1>Novinka nebyla nalezena</h1>}
            {state.article && (
                <Grid container spacing={1.125}>
                    <Grid item xs={12} md={8}>
                        {state.article && (
                            <Card variant="outlined" component="article">
                                <Box sx={{ position: 'relative' }}>
                                    <CardMedia
                                        image={state.article.image}
                                        title={state.article.title}
                                        sx={{ height: 400, width: '100%' }}
                                    />
                                    {!!state.article.author && (
                                        <Typography
                                            sx={{
                                                textAlign: 'right',
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                color: 'white',
                                                padding: '0.3rem 0.8rem',
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Autor/ka fotky: {state.article.author}
                                        </Typography>
                                    )}
                                </Box>
                                <CardContent>
                                    <Typography variant="h2" component="h1" sx={{ pb: 2 }}>
                                        {state.article.title}
                                    </Typography>
                                    <Typography variant="body2" component="p" sx={{ pb: 2 }}>
                                        Publikováno {format(state.article.date, 'd. M. yyyy')}
                                    </Typography>
                                    <HtmlContent content={state.article.content} />
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{ px: 2.5, py: 3 }}>
                            <Typography variant="h3" component="h2" sx={{ pb: 1.75 }}>
                                Další novinky
                            </Typography>
                            {!state.otherArticles && <Loading fullPage />}
                            {state.otherArticles && !state.otherArticles.length && (
                                <Typography variant="body1" component="p">
                                    Aktuálně zde nejsou žádné novinky.
                                </Typography>
                            )}
                            {state.otherArticles && !!state.otherArticles.length && (
                                <Grid container spacing={1.125}>
                                    {state.otherArticles.map((articleSummary) => (
                                        <Grid container item xs={12} key={articleSummary.id} spacing={1.125}>
                                            <Grid item xs={4}>
                                                <img
                                                    src={articleSummary.thumbnail}
                                                    alt={articleSummary.title}
                                                    style={{ width: '100%', display: 'block' }}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Link
                                                    to={`/news/${state.language}/${articleSummary.id}`}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component="h3"
                                                        sx={{ pb: 0.5 }}
                                                        color="primary"
                                                        fontWeight="bold"
                                                    >
                                                        {articleSummary.title}
                                                    </Typography>
                                                </Link>
                                                <Typography variant="caption" component="p">
                                                    Publikováno {format(articleSummary.date, 'd. M. yyyy')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default NewsViewApp;
