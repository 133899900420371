export default function filterRuleBySlug<RuleType extends { activationSlug?: string | null }>(
    rules: RuleType[],
    urlSlugs?: string[] | null,
): RuleType[] {
    return rules.filter((subject) => {
        if (!subject.activationSlug) return true;
        if (!urlSlugs || !urlSlugs.length) return false;
        return urlSlugs.includes(subject.activationSlug);
    });
}
