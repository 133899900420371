import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import * as z from 'zod';
import { IObsListSearchFormValues } from '../ObsListsSearch';
import { schema_mapSquareCodeEea, schema_mapSquareCodeKfme } from '../../../../schemas/schemas';

const squareSchema = z.union([schema_mapSquareCodeKfme, schema_mapSquareCodeEea]);

const SquaresAutocomplete: FC<Record<string, never>> = () => {
    const { values, handleChange, handleBlur, errors, touched, setFieldValue, setFieldTouched } =
        useFormikContext<IObsListSearchFormValues>();

    const setValues = useMemo(
        () => (values: string[]) => {
            if (values.length === 0) return setFieldValue('mapSquares', null);

            setFieldValue('mapSquares', values);
            setFieldTouched('mapSquares', true);
        },
        [setFieldValue, setFieldTouched],
    );

    return (
        <FormControl fullWidth error={!!errors.mapSquares && touched.mapSquares}>
            <Autocomplete<{ inputValue: string; title: string }, true>
                id="mapSquares"
                multiple
                options={[]}
                value={((values.mapSquares || []).filter((square) => square) as string[]).map((square) => ({
                    inputValue: square,
                    title: square,
                }))}
                onBlur={handleBlur}
                renderInput={(params) => <TextField {...params} label="Čtverce" />}
                selectOnFocus
                clearOnBlur
                autoHighlight
                handleHomeEndKeys
                noOptionsText="Toto není platný kód čtverce."
                onChange={(_, newValue) => {
                    setValues(newValue.map((option) => option.inputValue));
                }}
                filterOptions={(options, params) => {
                    const { inputValue } = params;
                    const val = inputValue;

                    if (!val || !squareSchema.safeParse(val).success) return [];

                    return [
                        {
                            inputValue: val,
                            title: `Přidat čtverec ${val}`,
                        },
                    ];
                }}
                getOptionLabel={(option) => option.title}
            />
            {!!errors.mapSquares && touched.mapSquares ? (
                <FormHelperText error>{errors.mapSquares}</FormHelperText>
            ) : (
                <FormHelperText>
                    Zadejte kód čtverce systému EEA10KM (např.&nbsp;10kmE445N302), nebo KFME (např.&nbsp;5952ad). Každý
                    čtverec potvrďte klávesou <code>Enter</code> a pokračujte zadáním dalšího čtverce.
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default SquaresAutocomplete;
