import { FC, createContext, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { IProject } from '../schemas/interfaces';
import { useApi } from './apiContext';
import filterRuleByPeriod from '../lib/filterRuleByPeriod';
import filterRuleByTerritorialUnitId from '../lib/filterRuleByTerritorialUnitId';
import filterRuleByTaxonId from '../lib/filterRuleByTaxonId';
import { usePlaces } from './placesContext';
import { EProjectCategory } from '../schemas/enums';
import filterRuleBySlug from '../lib/filterRuleBySlug';

export interface IProjectsContext {
    projects?: IProject[];
    getActiveProjects?: (date: Date, territoryId: number, taxonId: number, projectsSlugs?: string[]) => IProject[];
}

export const ProjectsContext = createContext<IProjectsContext>({} as IProjectsContext);

export const useProjects = () => {
    return useContext(ProjectsContext);
};

export const ProjectsProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [projects, setProjects] = useState<IProject[]>();
    const [getActiveProjects, setGetActiveProjects] = useState<IProjectsContext['getActiveProjects']>();
    const { getProjects } = useApi();
    const { places } = usePlaces();

    useEffect(() => {
        if (!places) return;

        getProjects().then(({ projects }) => {
            setProjects(projects);

            setGetActiveProjects(
                () => (date: Date, territoryId: number, taxonId: number, projectsSlugs?: string[] | null) => {
                    const byTaxon = filterRuleByTaxonId(projects, taxonId);
                    const byTerritory = filterRuleByTerritorialUnitId(byTaxon, territoryId, places);
                    const byPeriod = filterRuleByPeriod(byTerritory, date);
                    const bySlug = filterRuleBySlug(byPeriod, projectsSlugs);

                    return bySlug;
                },
            );
        });
    }, [places]);

    return <ProjectsContext.Provider value={{ projects, getActiveProjects }}>{children}</ProjectsContext.Provider>;
};
