import { Typography } from '@mui/material';
import { FC } from 'react';

const UndefinedDictValue: FC<{ label?: string }> = ({ label }) => {
    return (
        <Typography component="span" variant="caption" fontStyle="italic" sx={{ opacity: 0.5 }}>
            {label ?? 'nezadáno'}
        </Typography>
    );
};

export default UndefinedDictValue;
