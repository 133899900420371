import { ContentPasteGo } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { FC } from 'react';
import { ICoordinates } from '../../schemas/interfaces';
import { convert } from 'geo-coordinates-parser';
import { enqueueSnackbar } from 'notistack';

const CoordinatesPaste: FC<{
    onPaste?: (coords: ICoordinates) => void;
    onError?: (e: any) => void;
    PasteButtonProps?: IconButtonProps;
}> = (props) => {
    const handlePasteFromClipboard = () => {
        if (!navigator || !navigator.clipboard || !navigator.clipboard.readText)
            enqueueSnackbar('Vložení ze schránky váš prohlížeč nepodporuje.', { variant: 'error' });

        navigator.clipboard.readText().then((text) => {
            try {
                const coordinates = convert(text);
                enqueueSnackbar('Souřadnice úspěšně vloženy.', { variant: 'success' });
                props.onPaste?.([parseFloat(coordinates.decimalLatitude), parseFloat(coordinates.decimalLongitude)]);
            } catch (e) {
                enqueueSnackbar('Vložený formát souřadnic nebyl rozpoznán. Zkuste souřadnice zkopírovat znovu.', {
                    variant: 'error',
                });
                props.onError?.(e);
            }
        });
    };

    return (
        <Tooltip title="Kliknutím vložíte GPS souřadnice ze schránky. Souřadnice dokážeme převést z mnoha formátů.">
            <IconButton onClick={handlePasteFromClipboard} {...props.PasteButtonProps}>
                <ContentPasteGo />
            </IconButton>
        </Tooltip>
    );
};

export default CoordinatesPaste;
