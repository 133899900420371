import { parse, addYears } from 'date-fns';
import { IProject } from '../schemas/interfaces';

export default function filterRuleByPeriod<RuleType extends { period: IProject['period'] }>(
    rules: RuleType[],
    targetDate: Date,
): RuleType[] {
    return rules.filter((subject) => {
        return subject.period.some((p) => {
            const from = parse(p.from, 'MM-dd', targetDate);
            const to = parse(p.to, 'MM-dd', targetDate);

            // fix for over-the-end-of-year periods
            // we need to check both the intervals that start in the previous year
            // and end in the current year and the intervals that start in the current year
            // and end in the next year
            // e.g. if we are adding observation on 30.12.2023 with a rule 12-01 to 01-31,
            // we need to check both the interval 12-01-2022 to 01-31-2023 and the interval
            // 12-01-2023 to 01-31-2024
            if (from > to) {
                const fromLastYear = addYears(from, -1);
                const toThisYear = to;
                const fromThisYear = from;
                const toNextYear = addYears(to, 1);

                return (
                    (fromLastYear <= targetDate && targetDate <= toThisYear) ||
                    (fromThisYear <= targetDate && targetDate <= toNextYear)
                );
            }

            return from <= targetDate && targetDate <= to;
        });
    });
}
