import { FC, useMemo, useState } from 'react';
import ImportFileListItem from './ImportFileListItem';
import { Accordion, Box } from '@mui/material';
import ImportFilePicker from './ImportFilePicker';
import { set } from 'lodash';
import { RsPostImport } from '../../../schemas/responses';

export interface ImportFile {
    file: File;
    status: 'pending' | 'loading' | 'success' | 'error' | 'failed';
    response?: RsPostImport;
    error?: Error;
}

const ImportFileList: FC<Record<string, never>> = () => {
    const [files, setFiles] = useState<ImportFile[]>([]);

    const addFile = useMemo(
        () => (newFile: File) => {
            setFiles((prevFiles) => {
                const newImportFile: ImportFile = {
                    file: newFile,
                    status: prevFiles.some((file) => file.status === 'loading') ? 'pending' : 'loading',
                };
                return [...prevFiles, newImportFile];
            });
        },
        [],
    );

    return (
        <Box className="ImportFileList-root">
            <Box className="ImportFileList-files" sx={{ pb: 5 }}>
                {files.map((file, index) => {
                    return (
                        <ImportFileListItem
                            key={index}
                            file={file}
                            onFileLoaded={(resultFile) => {
                                console.log();
                                setFiles((prevFiles) => {
                                    if (prevFiles[index].status !== 'loading') return prevFiles;

                                    const newFiles = [...prevFiles];
                                    newFiles[index] = resultFile;
                                    const firstPendingIndex = newFiles.findIndex((file) => file.status === 'pending');
                                    if (firstPendingIndex !== -1) {
                                        newFiles[firstPendingIndex] = {
                                            ...newFiles[firstPendingIndex],
                                            status: 'loading',
                                        };
                                    }
                                    return newFiles;
                                });
                            }}
                        />
                    );
                })}
            </Box>
            <ImportFilePicker
                onFile={(file) => {
                    addFile(file);
                }}
            />
        </Box>
    );
};

export default ImportFileList;
