import { useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { ObsListDetailsFormValues } from './ObsListDetailsForm';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ICoordinates } from '../../../schemas/interfaces';
import { useApi } from '../../../services/apiContext';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

const LocationFormGeocoding: FC<{
    onChange: (municipalityPartId: number) => void;
    onGeocodingRejected?: () => void;
}> = ({ onChange, onGeocodingRejected }) => {
    const formik = useFormikContext<ObsListDetailsFormValues>();
    const { geocode } = useApi();

    const coordinates = formik.values.coordinates;

    useDeepCompareEffect(() => {
        console.log('geocode', coordinates);
        if (!coordinates || !coordinates?.latitude || !coordinates?.longitude) {
            console.log('no coordinates or track, geocoding not possible');
            return;
        }

        const coords: ICoordinates | undefined =
            coordinates && coordinates.latitude && coordinates.longitude
                ? [coordinates.latitude, coordinates.longitude]
                : undefined;

        if (!coords) {
            console.error('no coordinates or track, geocoding not possible (after checks, should not happen)');
            return;
        }

        geocode(coords)
            .then((result) => {
                if (!result.success)
                    throw new Error(`Geocoding failed, but returned a 200 response of: ${JSON.stringify(result)}`);

                onChange(result.territorialUnit.municipalityPart.id);
                closeSnackbar();
            })
            .catch((error) => {
                console.error(error);

                if (error.status === 404 || error.status === 400)
                    return enqueueSnackbar(
                        'Zadané souřadnice neodpovídají žádné obci v\xa0České republice. Zkuste prosím zvolit jiné.',
                        {
                            variant: 'error',
                        },
                    );

                if (error.status === 503)
                    return enqueueSnackbar(
                        'Obec se nepodařilo dohledat kvůli chybě externí služby. Zkuste to později nebo vyberte obec ručně ze\xa0seznamu.',
                        {
                            variant: 'error',
                        },
                    );

                enqueueSnackbar(
                    'Obec se nepodařilo dohledat kvůli neznámé chybě. Zkuste to později nebo vyberte obec ručně ze\xa0seznamu.',
                    {
                        variant: 'error',
                    },
                );
            });
    }, [coordinates ?? {}]);

    return null;
};

export default LocationFormGeocoding;
