import { Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { theme } from '../../../theme';

const ImportFilePicker: FC<{
    onFile: (file: File) => void;
}> = ({ onFile }) => {
    const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        accept: { 'application/json': [], 'text/csv': [] },
    });

    useEffect(() => {
        acceptedFiles.forEach((file) => {
            onFile(file);
        });
    }, [acceptedFiles]);

    return (
        <Box className="ImportFilePicker">
            <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{
                    py: 10,
                    px: 3,
                    border: '2px dashed #000',
                    borderColor: 'primary.300',
                    bgcolor: isDragAccept ? '#4caf5050' : isDragReject ? '#ef535050' : '#ffffff50',
                }}
            >
                <input {...getInputProps()} />
                <Typography variant="body1" component="p" textAlign="center">
                    {!isDragReject && (
                        <>
                            Přetáhněte soubory sem, nebo klikněte pro výběr souborů z vašeho počítače. Podporované
                            formáty jsou: JSON, CSV.
                        </>
                    )}
                    {isDragReject && <b>Tento typ souboru není podporován. Podporované formáty jsou: JSON, CSV.</b>}
                </Typography>
            </Box>
        </Box>
    );
};

export default ImportFilePicker;
