import {
    enum_list_rights,
    enum_list_role,
    enum_sort_languages,
    enum_sort_orders,
    enum_list_type,
    enum_tags_categories,
    enum_listItem_tags,
    enum_rarity_categories,
    enum_sex_categories,
    enum_rarity,
    enum_secrecy_categories,
    enum_analytics_dateMode,
    enum_comment_rights,
    enum_list_observationMode,
    enum_activity_categories,
    enum_project_categories,
    enum_detection_categories,
} from './schemas';
import * as z from 'zod';

const schema_list_roles = z.enum(enum_list_role);
const schema_list_rights = z.enum(enum_list_rights);
const schema_list_types = z.enum(enum_list_type);
const schema_sort_languages = z.enum(enum_sort_languages);
const schema_sort_orders = z.enum(enum_sort_orders);
const schema_tag_category = z.enum(enum_tags_categories);
const schema_observation_rarity_level = z.enum(enum_rarity);
const schme_listItem_tags = z.enum(enum_listItem_tags);
const schema_rarity_category = z.enum(enum_rarity_categories);
const schema_sex_category = z.enum(enum_sex_categories);
const schema_secrecy_category = z.enum(enum_secrecy_categories);
const schema_activity_category = z.enum(enum_activity_categories);
const schema_analytics_dateMode = z.enum(enum_analytics_dateMode);
const schema_comment_right = z.enum(enum_comment_rights);
const schema_list_observationMode = z.enum(enum_list_observationMode);
const schema_projectCategory = z.enum(enum_project_categories);
const schema_detectionCategory = z.enum(enum_detection_categories);

export const ListRoles = schema_list_roles.enum;
export const ListRights = schema_list_rights.enum;
export const ListTypes = schema_list_types.enum;
export const SortLanguages = schema_sort_languages.enum;
export const SortOrders = schema_sort_orders.enum;
export const TagCategory = schema_tag_category.enum;
export const RarityLevel = schema_observation_rarity_level.enum;
export const ListItemTags = schme_listItem_tags.enum;
export const RarityCategory = schema_rarity_category.enum;
export const SexCategory = schema_sex_category.enum;
export const SecrecyCategory = schema_secrecy_category.enum;
export const ActivityCategory = schema_activity_category.enum;
export const AnalyticsDateMode = schema_analytics_dateMode.enum;
export const CommentRights = schema_comment_right.enum;
export const ListObservationMode = schema_list_observationMode.enum;
export const ProjectCategory = schema_projectCategory.enum;
export const DetectionCategory = schema_detectionCategory.enum;

export type EListRoles = z.infer<typeof schema_list_roles>;
export type EListRights = z.infer<typeof schema_list_rights>;
export type EListTypes = z.infer<typeof schema_list_types>;
export type ESortLanguages = z.infer<typeof schema_sort_languages>;
export type ESortOrders = z.infer<typeof schema_sort_orders>;
export type ETagCategory = z.infer<typeof schema_tag_category>;
export type ERarityLevel = z.infer<typeof schema_observation_rarity_level>;
export type EListItemTags = z.infer<typeof schme_listItem_tags>;
export type ERarityCategory = z.infer<typeof schema_rarity_category>;
export type ESexCategory = z.infer<typeof schema_sex_category>;
export type ESecrecyCategory = z.infer<typeof schema_secrecy_category>;
export type EActivityCategory = z.infer<typeof schema_activity_category>;
export type EExportMode = 'items' | 'lists';
export type EAnalyticsDateMode = z.infer<typeof schema_analytics_dateMode>;
export type ECommentRights = z.infer<typeof schema_comment_right>;
export type EListObservationMode = z.infer<typeof schema_list_observationMode>;
export type EProjectCategory = z.infer<typeof schema_projectCategory>;
export type EDetectionCategory = z.infer<typeof schema_detectionCategory>;

export enum ListItemAction {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}
