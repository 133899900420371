import { StickyNote2 } from '@mui/icons-material';
import { IconButton, Grid, TextField, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import PoppedControl from '../../../../../components/formControls/PoppedControl';
import { AvifAddStickyNote } from '../../../../../components/icons';
import translateErrorMessage from '../../../../../services/errorMessages';
import RingMarkDialog from '../submenu/RingMarkDialog';
import { useFormikContext } from 'formik';
import { ObservationItemFormValues } from '../../../ObservationFormApp';

const NoteDialog: FC<{ formId: string }> = ({ formId }) => {
    const { values, setFieldValue, errors, touched, handleBlur } = useFormikContext<ObservationItemFormValues>();

    return (
        <PoppedControl
            renderIcon={() => (
                <Tooltip title="Přidat poznámku">
                    <IconButton aria-label="Vložit poznámku" color={values.note ? 'secondary' : undefined}>
                        {values.note ? <StickyNote2 /> : <AvifAddStickyNote />}
                    </IconButton>
                </Tooltip>
            )}
            renderControl={() => (
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            autoFocus
                            minRows={3}
                            maxRows={6}
                            value={values.note || ''}
                            name="note"
                            onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue('note', value, false);
                            }}
                            onBlur={handleBlur}
                            label="Vložte poznámku k&nbsp;pozorování"
                            fullWidth
                            error={!!errors.note && !!touched.note}
                            helperText={!!errors.note && !!touched.note && translateErrorMessage(errors.note)}
                            inputProps={{ form: formId }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RingMarkDialog label="Zadejte číslo přečteného kroužku" controlTag />
                    </Grid>
                </Grid>
            )}
            title="Poznámka"
            confirmButtonLabel="Uložit"
            disableClickaway
            onConfirmed={() => {
                setFieldValue('note', values.note);
            }}
        />
    );
};

export default NoteDialog;
