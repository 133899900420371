import { Box, Tabs, Tab, Alert, Snackbar, useMediaQuery } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import TabPanel from '../../../components/TabPanel';
import { IObservationFilter } from '../../../schemas/interfaces';
import ObsItemsGallery from './tabs/ObsItemsGallery';
import ObsItemsMap from './tabs/ObsItemsMap';
import ObsItemsTable from './tabs/ObsItemsTable';
import ObsListsTable from './tabs/ObsListsTable';
import { CollectionsOutlined, ListAlt } from '@mui/icons-material';
import { AvifBinocularsIcon, AvifMapIcon } from '../../../components/icons';
import { useNavigate } from 'react-router-dom';
import { useJoyride } from '../../../services/joyrideContext';
import FloatingTabs from './tabs/FloatingTabs';

export interface IObsListsResultsProps {
    query?: IObservationFilter;
    tab?: TabIndex;
    fullScreen?: boolean;
}

export enum TabIndex {
    Items = 'items',
    Lists = 'lists',
    Gallery = 'gallery',
    Map = 'map',
    Species = 'species',
    Charts = 'charts',
}

export const ObsListsResults: React.FunctionComponent<IObsListsResultsProps> = (props: IObsListsResultsProps) => {
    const [value, setValue] = useState<TabIndex>(TabIndex.Items);
    const [error, setError] = useState<string | undefined>();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(pointer: coarse)');
    const { joyrideEvents } = useJoyride();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const queryParams = new URLSearchParams();
    queryParams.append('filter', JSON.stringify(props.query));

    useEffect(() => {
        props.tab && setValue(props.tab);
    }, [props.tab]);

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            console.log('subscribed', data);
            if (data.step.target === '.tour-search-4') handleChange(TabIndex.Items);
            if (data.step.target === '.tour-search-5') handleChange(TabIndex.Lists);
            if (data.step.target === '.tour-search-6') handleChange(TabIndex.Gallery);
            if (data.step.target === '.tour-search-8') handleChange(TabIndex.Map);
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents, queryParams]);

    const handleChange = (newValue: TabIndex) => {
        navigate(`/search/${newValue}?${queryParams.toString()}`);
        setValue(newValue);
    };

    const onFetchError = (error: any) => {
        console.error(error);
        setError('Vyhledávání selhalo. Zkuste to znovu, případně obnovte stránku.');
    };

    return (
        <Box className="ObsListsResults" ref={wrapperRef}>
            <Box
                sx={{
                    width: '100%',
                    borderRadius: 'var(--mui-shape-borderRadius)',
                    overflow: 'hidden',
                }}
            >
                {/* <Box sx={{ height: 300, overflow: 'auto', p: 3 }}>
                    <Box component="code" sx={{ width: '100%', height: 'auto', maxHeight: 'none' }}>
                        {props.query ? JSON.stringify(props.query) : 'No query.'}
                    </Box>
                </Box> */}
                {!props.fullScreen && (
                    <FloatingTabs contentElement={wrapperRef.current} includeHeader={[TabIndex.Items, TabIndex.Lists].includes(value)}>
                        <Box className="tour-search-3">
                            <Tabs
                                value={value}
                                onChange={(_, value) => handleChange(value)}
                                indicatorColor="secondary"
                                variant="fullWidth"
                                textColor="inherit"
                                sx={{
                                    bgcolor: 'primary.main',
                                    color: 'white',
                                    '& .MuiTab-root': {
                                        minHeight: '54px',
                                        minWidth: 'auto',
                                    },
                                    '& .MuiTab-root .MuiSvgIcon-root': {
                                        display: ['none', 'inline-block'],
                                    },
                                    borderRadius: 'var(--mui-shape-borderRadius) var(--mui-shape-borderRadius) 0 0',
                                    overflow: 'hidden',
                                }}
                            >
                                <Tab
                                    label="Pozorování"
                                    icon={<AvifBinocularsIcon />}
                                    iconPosition="start"
                                    value={TabIndex.Items}
                                    sx={{ flexGrow: isMobile ? 1.5 : undefined }}
                                    className="tour-search-4"
                                />
                                <Tab
                                    label="Vycházky"
                                    icon={<ListAlt />}
                                    iconPosition="start"
                                    value={TabIndex.Lists}
                                    className="tour-search-5"
                                />
                                <Tab
                                    label="Galerie"
                                    icon={<CollectionsOutlined />}
                                    iconPosition="start"
                                    value={TabIndex.Gallery}
                                    className="tour-search-6"
                                />
                                <Tab
                                    label="Mapa"
                                    icon={<AvifMapIcon fontSize="large" />}
                                    iconPosition="start"
                                    value={TabIndex.Map}
                                    className="tour-search-8"
                                />
                            </Tabs>
                        </Box>
                    </FloatingTabs>
                )}
                {props.query && (
                    <>
                        <TabPanel value={value} index={TabIndex.Items}>
                            <ObsItemsTable
                                query={props.query}
                                onFetchError={onFetchError}
                                fullScreen={props.fullScreen}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={TabIndex.Lists}>
                            <ObsListsTable
                                query={props.query}
                                onFetchError={onFetchError}
                                fullScreen={props.fullScreen}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={TabIndex.Gallery}>
                            <ObsItemsGallery
                                query={props.query}
                                onFetchError={onFetchError}
                                fullScreen={!!props.fullScreen}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={TabIndex.Map}>
                            <ObsItemsMap
                                query={props.query}
                                onFetchError={onFetchError}
                                fullScreen={!!props.fullScreen}
                            />
                        </TabPanel>
                    </>
                )}
            </Box>
            <Snackbar
                open={error !== undefined}
                autoHideDuration={6000}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') return;
                    setError(undefined);
                }}
            >
                <Alert severity="error">{error}</Alert>
            </Snackbar>
        </Box>
    );
};
