import { FC } from 'react';
import UndefinedDictValue from './UndefinedDictValue';
import { useDict } from '../../services/dictContext';

const ActivityFromDict: FC<{ activityId?: number | null }> = ({ activityId }) => {
    const { activity: activities } = useDict();

    if (!activityId) return <UndefinedDictValue />;

    if (!activities) return <>Loading...</>;

    const activity = activities.find((activity) => activity.id === activityId);

    if (!activity) return <>Unknown activity</>;

    return <>{activity.name.cs || 'Missing activity label'}</>;
};

export default ActivityFromDict;
