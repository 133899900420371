import { FC, useEffect, useState } from 'react';

const TableEditorDraggable: FC<Record<never, never>> = () => {
    const [isDrag, setIsDrag] = useState(false);
    const [element, setElement] = useState<Element>();

    useEffect(() => {
        const wrapper = document.querySelector('.TableEditor .MuiTableContainer-root');

        if (!wrapper) return;

        setElement(wrapper);
    }, []);

    useEffect(() => {
        const handlePointerdown = () => {
            setIsDrag(true);
        };
        element?.addEventListener('pointerdown', handlePointerdown);

        const handlePointerup = () => {
            setIsDrag(false);
        };
        addEventListener('pointerup', handlePointerup);

        return () => {
            element?.removeEventListener('pointerdown', handlePointerdown);
            removeEventListener('pointerup', handlePointerup);
        };
    }, [element]);

    useEffect(() => {
        const handlePointermove = (ev: PointerEvent) => {
            if (!isDrag || !element) return;

            element.scrollLeft -= ev.movementX;
        };

        addEventListener('pointermove', handlePointermove);

        return () => {
            removeEventListener('pointermove', handlePointermove);
        };
    }, [isDrag, element]);

    return null;
};

export default TableEditorDraggable;
