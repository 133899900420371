import { Box } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const AppLogo: FC<{ inverted?: boolean }> = ({ inverted }) => {
    return (
        <Link to="/">
            <Box
                className="tour-into-1"
                component="img"
                src="/img/logo.png"
                alt="avif.birds.cz"
                sx={{
                    display: 'block',
                    maxHeight: '50px',
                    filter: inverted ? 'invert(1)' : undefined,
                }}
            />
        </Link>
    );
};

export default AppLogo;
