import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import DictionarySelect, { DictionarySelectPublicProps } from '../../../../components/formControls/DictionarySelect';
import { IPredefinedFilter } from '../../../../schemas/interfaces';
import { useUser } from '../../../../services/userContext';
import UserFilterDelete from './UserFilterDelete';
import { SelectChangeEvent } from '@mui/material';

export const NEW_FILTER: IPredefinedFilter = {
    id: 'new',
    name: {
        cs: 'Nový vlastní filtr',
        en: 'New custom filter',
    },
    // bit ugly, but this way delete button won't show for new/custom/empty filter
    type: '' as 'userDefined',
    parameters: {},
};

/**
 * Implementation of DictionarySelect for project dictionary.
 */
const PredefinedFilterSelect: FunctionComponent<DictionarySelectPublicProps<string, IPredefinedFilter>> = (props) => {
    const [separatorBeforeIndex, setSeparatorBeforeIndex] = useState<number>();
    const [filters, setFilters] = useState<IPredefinedFilter[]>();
    const { filters: predefinedFilters } = useUser();

    useEffect(() => {
        if (!predefinedFilters?.items) return;

        // Sort filters by type, predefined filters first
        const filters = predefinedFilters.items.sort((a, b) =>
            a.type == b.type ? 0 : a.type == 'predefined' ? -1 : 1,
        );

        setFilters(filters);
        setSeparatorBeforeIndex(filters.findIndex((filter) => filter.type === 'userDefined'));
    }, [predefinedFilters]);

    return (
        <DictionarySelect<string, IPredefinedFilter>
            {...props}
            getDictOptions={
                new Promise<IPredefinedFilter[]>((resolve) => {
                    if (!filters) return resolve([]);

                    return resolve([
                        ...filters,
                        // Add option for a new filter
                        NEW_FILTER,
                    ]);
                })
            }
            getDictValues={(filters) => filters.map((filter: IPredefinedFilter) => filter.id)}
            getOptionLabel={(option, filters = [] as IPredefinedFilter[]) =>
                filters.find((filter) => filter.id === option)?.name.cs || `Unknown filter ${option}`
            }
            separatorBeforeIndex={separatorBeforeIndex}
            separatorText="Vlastní filtry"
            disableEmpty
            renderOptionSuffix={(option) => {
                const filter = filters?.find((filter) => filter.id === option);

                if (!filter || filter.type !== 'userDefined') return null;

                return (
                    <UserFilterDelete
                        filter={filter}
                        buttonProps={{ size: 'small', sx: { width: null, height: null } }}
                        onDeleted={(deletedFilter) => {
                            // Clear the value if the deleted filter is selected
                            if (deletedFilter.id === props.value) {
                                props.onChange?.(
                                    { target: { value: '' } } as SelectChangeEvent<string>,
                                    null as ReactNode,
                                );
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export default PredefinedFilterSelect;
