import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './services/router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Experimental_CssVarsProvider } from '@mui/material';
import { experimentalTheme } from './theme';
import { cs } from 'date-fns/locale/cs';
import { AuthProvider } from './services/authenticator';
import { ApiProvider } from './services/apiContext';
import { DictionaryProvider } from './services/dictContext';
import { JoyrideProvider } from './services/joyrideContext';
import { UserProvider } from './services/userContext';
import { SnackbarProvider } from 'notistack';
import CustomSnackbar from './components/CustomSnackbar';
import { TaxonLanguageProvider } from './services/taxonLanguageContext';

function App() {
    return (
        <Experimental_CssVarsProvider theme={experimentalTheme}>
            <AuthProvider>
                <ApiProvider>
                    <DictionaryProvider>
                        <UserProvider>
                            <JoyrideProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
                                    <SnackbarProvider
                                        preventDuplicate
                                        autoHideDuration={6000}
                                        Components={{
                                            default: CustomSnackbar,
                                            error: CustomSnackbar,
                                            info: CustomSnackbar,
                                            success: CustomSnackbar,
                                            warning: CustomSnackbar,
                                        }}
                                    >
                                        <TaxonLanguageProvider>
                                            <RouterProvider router={router} />
                                        </TaxonLanguageProvider>
                                    </SnackbarProvider>
                                </LocalizationProvider>
                            </JoyrideProvider>
                        </UserProvider>
                    </DictionaryProvider>
                </ApiProvider>
            </AuthProvider>
        </Experimental_CssVarsProvider>
    );
}

export default App;
