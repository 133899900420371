import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Tooltip,
    Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import ObsItemsFormSummary from './components/ObsItemsFormSummary';
import ObsListDetailsForm from './components/ObsListDetailsForm';
import ObsListDetailsSummary from './components/ObsListDetailsSummary';
import ObsListSummary from './components/ObsListSummary';
import { IList, IListCreate, IListItem, IListItemCreate, IMedia, IPlace } from '../../schemas/interfaces';
import { cloneDeep, debounce } from 'lodash';
import { schema_list_input } from '../../schemas/schemas';
import Loading from '../../components/Loading';
import { useApi } from '../../services/apiContext';
import { useAuth } from '../../services/authenticator';
import NotFound from '../../components/NotFound';
import { ListItemAction, ListRights } from '../../schemas/enums';
import PoppedControl from '../../components/formControls/PoppedControl';
import { ProjectsProvider } from '../../services/projectsContext';
import { PlacesProvider } from '../../services/placesContext';
import { useFullwidth } from './components/FullwidthContext';
import ObsItemsForm from './components/itemForm/ObsItemsForm';
import { useDict } from '../../services/dictContext';

interface ObservationFormAppProps {
    new: boolean;
    item?: boolean;
}

interface ObservationFormAppState {
    notFound?: boolean;
    list?: ObservationFormValues;
    originalList?: ObservationFormValues;
    activeStep: number;
    initialized: boolean;
}

export interface ObservationFormValues extends Omit<Partial<IListCreate>, 'items'> {
    // only available when form used for editing an existing list
    publicId?: string;
    items?: ObservationItemFormValues[];
    rights?: IList['rights'];
    _Municipality?: IPlace;
}

export interface ObservationItemFormValues extends Partial<IListItemCreate> {
    // only available when form used for editing an existing list
    id?: number;
    projects?: IListItem['projects'];
    _Media?: IMedia[];
    _Action?: ListItemAction;
    _ProjectsData?: {
        [key: string]: { projectId: number; data: { [key: string]: string | number | boolean | null | undefined } };
    };
}

export type ObservationFormStepComponent = FunctionComponent<{
    initialValues?: Partial<ObservationFormValues>;
    originalList?: ObservationFormValues;
    onNext?: (values: Partial<ObservationFormValues>) => Promise<void>;
    onBack?: (values?: Partial<ObservationFormValues>) => Promise<void>;
    onChange?: (values: Partial<ObservationFormValues>) => void;
    onSubmit?: (created: IList) => void;
    new: boolean;
    itemId?: number;
}>;

export type ObservationFormStepSummaryComponent = FunctionComponent<{
    data?: Partial<ObservationFormValues>;
    new: boolean;
    onBack?: () => Promise<void>;
}>;

export interface IObservationFormStep {
    component: ObservationFormStepComponent;
    summaryComponent?: ObservationFormStepSummaryComponent;
    label: string;
}

const formSteps: IObservationFormStep[] = [
    {
        component: ObsListDetailsForm,
        summaryComponent: ObsListDetailsSummary,
        label: 'Informace o vycházce',
    },
    {
        component: ObsItemsForm,
        summaryComponent: ObsItemsFormSummary,
        label: 'Pozorování',
    },
    {
        component: ObsListSummary,
        label: 'Odeslání pozorování',
    },
];

export const LOCAL_STORAGE_KEY = 'observationList';

const schema_list_input_partial = schema_list_input.extend({
    type: schema_list_input.shape['type'].nullish(),
    date: schema_list_input.shape['date'].nullish(),
    municipalityPartId: schema_list_input.shape['municipalityPartId'].nullish(),
    coordinates: schema_list_input.shape['coordinates'].nullish(),
    items: schema_list_input.shape['items'].nullish(),
});

const ObservationFormApp: FunctionComponent<ObservationFormAppProps> = (props) => {
    const { id } = useParams<{ id: string }>();
    const [state, setState] = useState<ObservationFormAppState>({ activeStep: 0, initialized: false });
    const { isLoggedIn } = useAuth();
    const api = useApi();
    const navigate = useNavigate();
    const { fullwidth, mapEnabled } = useFullwidth();
    const { places } = useDict();

    // when editing an existing list or item, hydrate from API
    useEffect(() => {
        if (!id || !!props.new) return;

        const getFromApi = props.item ? api.getListItem : api.getList;

        getFromApi(id)
            .then((response) => {
                if (!response.rights.includes(ListRights.editList))
                    return setState((state) => ({ ...state, notFound: true }));

                const items: ObservationItemFormValues[] = response.items.map((item): ObservationItemFormValues => {
                    const itemCreate: ObservationItemFormValues = { ...item, media: [] };
                    itemCreate._Media = [...item.media];
                    itemCreate.media = item.media.map((media) => media.id);

                    return itemCreate;
                });

                const list = {
                    ...response,
                    items,
                    municipalityPartId: response.location.territorialUnitId,
                    coordinates: response.location.coordinatesSetByUser ? response.location.coordinates : undefined,
                };

                setState((state) => ({
                    ...state,
                    list: list,
                    originalList: cloneDeep(list),
                    initialized: true,
                    activeStep: props.item ? 1 : state.activeStep,
                }));
            })
            .catch((error) => {
                console.error(error);
                setState((state) => ({ ...state, notFound: true }));
            });
    }, [id, props.new, props.item]);

    // when creating a new list, hydrate from local storage
    useEffect(() => {
        if (id || !props.new) return;

        const stored = getFromLocalStorage();

        // if (!stored.items || !stored.items.length) stored.items = [{}];

        hydrateMediaFromApi(stored).then((list) => {
            setState((state) => ({ ...state, list: list, initialized: true }));
        });
    }, [id, props.new]);

    useEffect(() => {
        setTimeout(() => {
            if (state.activeStep === 0) window.scrollTo({ top: 0, behavior: 'smooth' });
            else activeStepRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }, [state.activeStep]);

    const getFromLocalStorage = () => {
        if (!window.localStorage || !window.localStorage.getItem(LOCAL_STORAGE_KEY)) return {};

        const obj = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        const parsed = schema_list_input_partial.safeParse(obj);

        if (!parsed.success) {
            console.error('Failed to parse observation list from local storage', parsed.error);
            return {};
        }

        return parsed.data as ObservationFormValues;
    };

    const onChange = debounce((values: ObservationFormValues) => {
        if (!window.localStorage) return;
        console.log('persist', values);

        const observationList = { ...getFromLocalStorage(), ...values };
        observationList.items = observationList.items?.filter((item) => item.taxonId !== undefined);

        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(observationList));
    }, 300);

    const hydrateMediaFromApi = async (values: ObservationFormValues) => {
        const itemsWithMedia = await Promise.all(
            (values.items || []).map(async (item) => {
                if (!item.media) return item;

                const _Media = await Promise.all(
                    item.media.map(async (mediaId) => {
                        return await api.getMedia(mediaId);
                    }),
                );
                console.log(_Media);
                return { ...item, _Media };
            }),
        );

        if (values.municipalityPartId) {
            const place = places?.find((place) => place.id == values.municipalityPartId);

            if (place && place.latitude && place.longitude) values._Municipality = place;
        }

        return { ...values, items: itemsWithMedia };
    };

    const onSubmit = () => {
        window.localStorage && window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    const isLastStep = () => state.activeStep === formSteps.length - 1;
    const isFirstStep = () => state.activeStep === 0;
    const canGoNext = () => !isLastStep();
    const canGoBack = () => !isFirstStep();
    const prevStep = () => setState((state) => ({ ...state, activeStep: state.activeStep - 1 }));
    const nextStep = () => setState((state) => ({ ...state, activeStep: state.activeStep + 1 }));
    const ActiveStep = formSteps[state.activeStep];
    const activeStepRef = useRef<HTMLElement | null>(null);

    if (state.notFound || (!!id && !!props.new) || (!id && !props.new))
        return (
            <NotFound
                text="Zadaná vycházka nebo pozorování nebylo nalezeno nebo nemáte práva k&nbsp;jeho úpravě."
                className="ObservationFormApp"
            />
        );

    if (!state.initialized)
        return (
            <Box sx={{ py: 5.75 }}>
                <Loading fullPage />
            </Box>
        );

    if (isLoggedIn === false) return <Navigate to="/" />;

    return (
        <PlacesProvider>
            <ProjectsProvider>
                <Container
                    maxWidth={fullwidth && mapEnabled ? false : 'lg'}
                    className="ObservationFormApp"
                    sx={{
                        pt: 5.75,
                        pb: 6.75,
                        maxWidth: '100%',
                        width: '2100px',
                        pl: {
                            xs: 0.5,
                            md: 3,
                        },
                        pr: {
                            xs: 1,
                            md: 3,
                        },
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" component="h1" sx={{ pb: 1.75 }} className="tour-createlist-1">
                                {!props.new ? `Úprava vycházky #${id}` : 'Zadání nové vycházky'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                                gap: 1,
                            }}
                        >
                            {props.new && (
                                <Tooltip title="Importujte data z externího zdroje bez nutnosti ručního zadávání.">
                                    <Link to="/import">
                                        <Button variant="outlined" color="primary" className="tour-createlist-2">
                                            Import dat
                                        </Button>
                                    </Link>
                                </Tooltip>
                            )}
                            <PoppedControl
                                renderIcon={() => (
                                    <Box>
                                        <Tooltip
                                            title={
                                                props.new
                                                    ? 'Smaže rozpracovanou vycházku a pozorování a umožní vám začít zadávat od\xa0začátku.'
                                                    : 'Všechny změny ve\xa0vycházce budou zrušeny.'
                                            }
                                        >
                                            <Button variant="outlined" color="error" className="tour-createlist-2">
                                                {props.new ? 'Zahodit vycházku' : 'Zahodit úpravy'}
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                )}
                                renderControl={() => <></>}
                                title={
                                    props.new
                                        ? 'Opravdu chcete zahodit rozpracovanou vycházku?'
                                        : 'Opravdu chcete zahodit úpravy této vycházky?'
                                }
                                confirmButtonLabel="Ano, pokračovat"
                                cancelButtonLabel="Ne, vrátit se zpět"
                                disableClickaway
                                onConfirmed={() => {
                                    if (!props.new && id) return navigate(`/list/${id}`);

                                    window.localStorage && window.localStorage.removeItem(LOCAL_STORAGE_KEY);
                                    window.location.reload();
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Stepper
                        activeStep={state.activeStep}
                        orientation="vertical"
                        sx={{
                            '& .MuiStepConnector-root': {
                                ml: {
                                    xs: 0.5,
                                    md: 1.5,
                                },
                                '& .MuiStepConnector-line': {
                                    borderLeft: {
                                        xs: 'none',
                                        md: '1px solid var(--mui-palette-StepContent-border)',
                                    },
                                },
                            },
                        }}
                    >
                        {formSteps.map((step, index) => (
                            <Step key={index}>
                                <StepLabel>{step.label}</StepLabel>
                                {step.summaryComponent && state.activeStep > index && (
                                    <Box
                                        sx={{
                                            ml: {
                                                xs: 0.5,
                                                md: 1.5,
                                            },
                                            pl: {
                                                xs: 0,
                                                md: 2,
                                            },
                                            borderLeft: {
                                                xs: 'none',
                                                md: '1px solid var(--mui-palette-StepContent-border)',
                                            },
                                        }}
                                        className="MuiStepSummary-root"
                                    >
                                        <step.summaryComponent
                                            data={state.list}
                                            onBack={async () => {
                                                if (canGoBack()) prevStep();
                                            }}
                                            new={props.new}
                                        />
                                    </Box>
                                )}
                                <StepContent
                                    sx={{
                                        position: 'relative',
                                        pr: 0,
                                        ml: {
                                            xs: 0.5,
                                            md: 1.5,
                                        },
                                        pl: {
                                            xs: 0,
                                            md: 2,
                                        },
                                        borderLeft: {
                                            xs: 'none',
                                            md: '1px solid var(--mui-palette-StepContent-border)',
                                        },
                                    }}
                                >
                                    <Box
                                        component="span"
                                        ref={index === state.activeStep ? activeStepRef : undefined}
                                        sx={{ position: 'absolute', visibility: 'hidden', top: -120 }}
                                    />
                                    <Box>
                                        <step.component
                                            initialValues={state.list}
                                            onNext={async (values) => {
                                                console.log('next step', values);

                                                setState((state) => ({
                                                    ...state,
                                                    list: { ...state.list, ...values },
                                                }));

                                                if (canGoNext()) nextStep();
                                            }}
                                            onBack={async (values) => {
                                                console.log('prev step');

                                                if (values)
                                                    setState((state) => ({
                                                        ...state,
                                                        list: { ...state.list, ...values },
                                                    }));

                                                if (canGoBack()) prevStep();
                                            }}
                                            onChange={!props.new ? undefined : onChange}
                                            onSubmit={!props.new ? undefined : onSubmit}
                                            originalList={state.originalList}
                                            new={props.new}
                                            itemId={props.item && id ? parseInt(id) : undefined}
                                        />
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Container>
            </ProjectsProvider>
        </PlacesProvider>
    );
};

export default ObservationFormApp;
