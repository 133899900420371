import { Box, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { IChartsFilter, schema_chartsFilter } from '../../schemas/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseFilterToFormData } from '../../services/parsers';
import ChartFilter from './components/ChartFilter';
import ChartResult from './components/ChartResult';

const defaultQuery: IChartsFilter = {
    taxonId: [775, 823, 601],
    dateRange: {
        start: '2022-01-01',
        end: '2022-12-31',
    },
    dateMode: 'months',
};

export const ChartApp: React.FunctionComponent<Record<never, any>> = () => {
    const [query, setQuery] = useState<IChartsFilter>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // in case no filter is set in the URL, do nothing
        if (!params.has('filter')) {
            onFilterSubmit(defaultQuery);
            return;
        }

        const rawFilter = JSON.parse(params.get('filter') as string) as IChartsFilter;
        const filter = schema_chartsFilter.safeParse(rawFilter);

        if (!filter.success) return;

        setQuery(filter.data);
    }, [location]);

    const onFilterSubmit = (newQuery: IChartsFilter) => {
        const newQueryString = JSON.stringify(newQuery);

        if (newQueryString === JSON.stringify(query)) return;

        const params = new URLSearchParams();
        params.append('filter', newQueryString);

        navigate('?' + params.toString(), { replace: true });
    };

    return (
        <Box className="ChartsApp">
            <Box sx={{ py: 3, bgcolor: 'primary.300' }}>
                <Container maxWidth="lg">
                    <Typography
                        variant="h2"
                        component="h1"
                        textAlign="center"
                        sx={{ pb: 3, color: 'background.paper' }}
                    >
                        Vizualizace dat
                    </Typography>
                    <Paper elevation={2} sx={{ p: 2.5 }}>
                        <ChartFilter query={query ?? undefined} onSubmit={onFilterSubmit} />
                    </Paper>
                </Container>
            </Box>
            <Box sx={{ pt: 3, pb: 6.75 }}>
                <Container maxWidth="lg">
                    <Paper elevation={2}>
                        <ChartResult query={query} />
                    </Paper>
                </Container>
            </Box>
        </Box>
    );
};
