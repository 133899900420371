import { Marker as ComponentMarker } from '@adamscybot/react-leaflet-component-marker';
import { Typography } from '@mui/material';
import { FC, MutableRefObject, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Circle, Popup, Tooltip } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { ICoordinates } from '../../schemas/interfaces';
import { feedersSecondary, feedersPrimary } from '../../theme';
import TaxonFromDict from '../dicts/TaxonFromDict';
import CopyToClipboard from './CopyToClipboard';
import { parseCoordinate } from '../../services/parsers';

const ObservationMarker: FC<{
    coordinates: ICoordinates;
    taxonId: number;
    squareCode?: string;
    popup?: boolean;
    hovered?: boolean;
    onClick?: () => void;
    linkTo?: string;
    onMoved?: (coordinates: ICoordinates) => void;
    onMoving?: (coordinates: ICoordinates) => void;
}> = ({ coordinates, taxonId, squareCode, popup, hovered, onClick, linkTo, onMoved, onMoving }) => {
    const [position, setPosition] = useState<[number?, number?]>(coordinates || [undefined, undefined]);
    const markerRef = useRef<L.Marker>(null);

    useEffect(() => {
        setPosition(coordinates);
    }, [coordinates]);

    const handleDragFactory = (end?: boolean) => () => {
        const coords = markerToCoordinates(markerRef);

        if (!coords) return;

        onMoving?.(coords);
        end && onMoved?.(coords);
    };

    const eventHandlers = useMemo(
        () => ({
            click: () => {
                onClick?.();
            },
            drag: onMoved ? handleDragFactory() : undefined,
            dragend: onMoved ? handleDragFactory(true) : undefined,
        }),
        [onClick, onMoved, onMoving, markerRef.current],
    );

    // only renders when both latitude and longitude are numeric
    if (!position || !position[0] || !position[1]) return null;

    const icon = (
        <Typography
            variant="caption"
            component={'div'}
            sx={{
                bgcolor: '#fff',
                border: '2px solid',
                borderColor: hovered ? feedersSecondary[400] : feedersPrimary[400],
                padding: '3px 5px',
                textAlign: 'center',
                lineHeight: 1.2,
                '&:hover': {
                    opacity: 0.1,
                },
            }}
        >
            <TaxonFromDict taxonId={taxonId} disableTitle />
        </Typography>
    );

    return (
        <>
            <Circle
                center={position as ICoordinates}
                pathOptions={{ color: feedersPrimary[400], weight: 3 }}
                radius={2}
            />
            <ComponentMarker
                position={position as ICoordinates}
                ref={markerRef}
                icon={
                    linkTo ? (
                        <Link to={linkTo} style={{ color: 'inherit', textDecoration: 'none' }} target="_blank">
                            {icon}
                        </Link>
                    ) : (
                        icon
                    )
                }
                draggable={!!onMoved}
                eventHandlers={eventHandlers}
            >
                <Tooltip content={'Pozice pozorování'} direction="top" />
                {popup && (
                    <Popup>
                        <CopyToClipboard
                            label="Souřadnice"
                            value={`${Math.round(position[0] * 10000000) / 10000000}, ${
                                Math.round(position[1] * 10000000) / 10000000
                            }`}
                        />
                        <br />
                        {squareCode && <CopyToClipboard label="Kód kvadrantu" value={squareCode} />}
                    </Popup>
                )}
            </ComponentMarker>
        </>
    );
};

export default ObservationMarker;

const markerToCoordinates = (marker: RefObject<L.Marker>): ICoordinates | null => {
    if (!marker.current) return null;

    const markerLatLng = marker.current.getLatLng();
    const markerCoordinates: ICoordinates = [
        parseCoordinate(markerLatLng.lat) as number,
        parseCoordinate(markerLatLng.lng) as number,
    ];

    return markerCoordinates;
};
