import { Box, Chip, Menu, MenuItem, Tooltip } from '@mui/material';
import { FC, useMemo, useRef, useState } from 'react';
import { SupportedTaxonLanguages, useTaxonLanguage } from '../../services/taxonLanguageContext';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const TaxonLanguageSwitcherMobile: FC<Record<string, never>> = () => {
    const { currentLanguage, setLanguage } = useTaxonLanguage();
    const [menuOpen, setMenuOpen] = useState(false);
    const anchor = useRef<HTMLElement | null>();

    const handleClose = useMemo(() => () => setMenuOpen(false), [setMenuOpen]);

    return (
        <>
            <Box
                ref={anchor}
                sx={{
                    ml: 2,
                    display: 'inline-flex',
                }}
            >
                <Tooltip title="Kliknutím změníte jazyk názvů druhů." placement="top">
                    <Chip
                        onClick={() => setMenuOpen(true)}
                        variant="filled"
                        clickable={false}
                        sx={{
                            fontSize: '85%',
                            cursor: 'pointer',
                            '& .MuiChip-label': {
                                display: 'inline-flex',
                                alignItems: 'center',
                                height: '100%',
                                pl: 1.5,
                            },
                            '& .MuiSvgIcon-root': {
                                top: '0px !important',
                            },
                        }}
                        label={
                            <>
                                {currentLanguage?.toUpperCase()}
                                &nbsp;
                                {menuOpen ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                            </>
                        }
                        size="small"
                    />
                </Tooltip>
            </Box>

            <Menu open={menuOpen} id="taxon-language-switcher-menu" anchorEl={anchor.current} onClose={handleClose}>
                {SupportedTaxonLanguages.filter((lang) => lang !== currentLanguage).map((lang) => (
                    <MenuItem
                        onClick={() => {
                            setLanguage?.(lang);
                            handleClose();
                        }}
                        dense
                    >
                        {lang.toUpperCase()}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default TaxonLanguageSwitcherMobile;
