import { FC } from 'react';
import UndefinedDictValue from './UndefinedDictValue';
import { useDict } from '../../services/dictContext';

const AgeFromDict: FC<{ ageId?: number | null }> = ({ ageId }) => {
    const { age: ages } = useDict();

    if (!ageId) return <UndefinedDictValue />;

    if (!ages) return <>Loading...</>;

    const age = ages.find((age) => age.id === ageId);

    if (!age) return <>Unknown age</>;

    return <>{age.name.cs || 'Missing age label'}</>;
};

export default AgeFromDict;
