import L from 'leaflet';
import { ICoordinates } from '../schemas/interfaces';

export const distanceTwoPoints = (p1: ICoordinates, p2: ICoordinates) => {
    const m1 = new L.CircleMarker(p1).getLatLng();
    const m2 = new L.CircleMarker(p2).getLatLng();

    return m1.distanceTo(m2);
};

export const normalizeString = (input: string) => {
    return input
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};

export const normalizeObjectForComparison = (input: any) => {
    const output = { ...input };

    Object.keys(output).forEach((key) => {
        if (
            output[key] === null ||
            output[key] === undefined ||
            (Array.isArray(output[key]) && output[key].length === 0) ||
            key === '_Action'
        )
            delete output[key];
    });

    return output;
};
