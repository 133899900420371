import { FunctionComponent } from 'react';
import { IArea } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useUser } from '../../services/userContext';

/**
 * Implementation of DictionarySelect for predefined & user-defined areas
 */
const AreaSelect: FunctionComponent<DictionarySelectPublicProps<string, IArea>> = (props) => {
    const user = useUser();

    const sorted = user?.areas?.sort((a, b) => a.type.localeCompare(b.type));

    return (
        <DictionarySelect<string, IArea>
            color="secondary"
            {...props}
            getDictOptions={
                sorted && sorted.length
                    ? new Promise((resolve) => {
                          resolve(sorted);
                      })
                    : undefined
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.publicId)}
            getOptionLabel={(option, dicts = [] as IArea[]) =>
                dicts.find((dict) => dict.publicId === option)?.name.cs || `Unknown area ${option}`
            }
            separatorBeforeIndex={sorted?.findIndex((area) => area.type === 'userDefined') || undefined}
            separatorText="Vlastní oblasti"
        />
    );
};

export default AreaSelect;
