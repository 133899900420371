import { MoreVert, ContentCopy, DeleteOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { TableEditorItem } from '../../../../../components/tableEditor/TableEditor';
import { FormikProps } from 'formik';
import TagToggleMenuItem from './TagToggleMenuItem';
import { ITableEditorRowActions } from '../../../../../components/tableEditor/TableEditorRow';
import { ObservationItemFormValues } from '../../../ObservationFormApp';
import { useJoyride } from '../../../../../services/joyrideContext';
import DetectionMenuItem from './DetectionMenuItem';
import GroupsMenuItem from './GroupsMenuItem';

const Submenu: FC<{
    item: TableEditorItem<ObservationItemFormValues>;
    formikProps: FormikProps<ObservationItemFormValues>;
    formActions: ITableEditorRowActions;
    formId: string;
    existingGroups?: number[];
    groupsEnabled?: boolean;
    onGroupsEnabled?: () => void;
    onGroupChanged?: (group: number) => void;
}> = (props) => {
    const { item, formikProps, formId, existingGroups, formActions, groupsEnabled, onGroupsEnabled, onGroupChanged } =
        props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const submenuOpen = Boolean(anchorEl);
    const { joyrideEvents } = useJoyride();

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            if (
                data.step.target === '.tour-createitemdetails-2' ||
                data.step.target === '.tour-createitemdetails-3' ||
                data.step.target === '.tour-createitemdetails-4' ||
                data.step.target === '.tour-createitemdetails-5'
            )
                setAnchorEl(buttonRef.current);

            if (data.step.target === '.tour-createitemdetails-6') handleClose();
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents]);

    const handleOpen = () => {
        setAnchorEl(buttonRef.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (item.empty) return null;

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id={formId + 'moreOptionsButton'}
                    aria-label="Více možností"
                    aria-controls={submenuOpen ? formId + 'moreOptionsMenu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={submenuOpen ? 'true' : undefined}
                    onClick={handleOpen}
                    ref={buttonRef}
                >
                    <MoreVert />
                </IconButton>
            </Tooltip>
            <Menu
                id={formId + 'moreOptionsMenu'}
                anchorEl={anchorEl}
                open={submenuOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                MenuListProps={{
                    'aria-labelledby': formId + 'moreOptionsButton',
                    className:
                        'tour-createitemdetails-2 tour-createitemdetails-3 tour-createitemdetails-4 tour-createitemdetails-5',
                }}
            >
                <TagToggleMenuItem formikProps={formikProps} onClose={handleClose} />
                <GroupsMenuItem {...{ handleClose, groupsEnabled, existingGroups, onGroupsEnabled, onGroupChanged }} />
                <DetectionMenuItem handleClose={handleClose} />
                {!!formActions.duplicateItem && (
                    <MenuItem
                        onClick={() => {
                            formActions.duplicateItem?.();
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <ContentCopy fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Duplikovat pozorování</ListItemText>
                    </MenuItem>
                )}
                {formActions.deleteItem && (
                    <MenuItem
                        onClick={() => {
                            formActions.deleteItem?.();
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <DeleteOutlined fontSize="small" color="error" />
                        </ListItemIcon>
                        <ListItemText>Odebrat pozorování</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default Submenu;
