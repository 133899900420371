import { Launch } from '@mui/icons-material';
import { ListItem, ListItemIcon, Typography } from '@mui/material';
import { FC } from 'react';
import { ImportFile } from './ImportFileList';
import ImportListSuccess from './ImportListSuccess';
import ImportListError from './ImportListError';

type Results = NonNullable<ImportFile['response']>['results'];
export type ImportListResult = Results[0];

const ImportListResult: FC<{ listResult: ImportListResult; index: number }> = ({ listResult, index }) => {
    return (
        <ListItem>
            <ListItemIcon>
                <Typography
                    fontFamily={'Consolas'}
                    sx={{
                        fontWeight: '900',
                        fontSize: '1.2rem',
                        bgcolor: listResult.success ? 'success.main' : 'warning.main',
                        color: 'white',
                        width: '2rem',
                        height: '2rem',
                        lineHeight: '2rem',
                        textAlign: 'center',
                    }}
                >
                    {index}
                </Typography>
            </ListItemIcon>
            {listResult.success ? <ImportListSuccess result={listResult} /> : <ImportListError result={listResult} />}
        </ListItem>
    );
};

export default ImportListResult;
