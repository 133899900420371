import { Translate } from '@mui/icons-material';
import { AutocompleteProps, IconButton, TooltipProps } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { TooltipMobile } from '../Tooltip';
import {
    SupportedTaxonLanguages,
    SupportedTaxonLanguagesNames,
    TaxonLanguage,
    useTaxonLanguage,
} from '../../services/taxonLanguageContext';

const SpeciesLanguageSwitcher: FC<{
    buttonSize?: AutocompleteProps<any, boolean, boolean, false>['size'];
    tooltipProps?: Partial<TooltipProps>;
}> = ({ buttonSize, tooltipProps }) => {
    const { setLanguage, currentLanguage } = useTaxonLanguage();
    const [nextLanguage, setNextLanguage] = useState<TaxonLanguage>();
    const [nextLanguageName, setNextLanguageName] = useState<string>();

    const switchLanguage = useMemo(() => {
        return () => {
            if (!currentLanguage || !nextLanguage || !setLanguage) return;

            setLanguage(nextLanguage);
        };
    }, [currentLanguage, nextLanguage, setLanguage]);

    useEffect(() => {
        if (!currentLanguage) return;

        const nextLanguageIndex =
            (SupportedTaxonLanguages.indexOf(currentLanguage) + 1) % SupportedTaxonLanguages.length;
        setNextLanguage(SupportedTaxonLanguages[nextLanguageIndex]);
        setNextLanguageName(SupportedTaxonLanguagesNames[nextLanguageIndex]);
    }, [currentLanguage]);

    return (
        <TooltipMobile title={`Přepnout názvy druhů do ${nextLanguageName}`} {...tooltipProps}>
            <IconButton onClick={() => switchLanguage()} size={buttonSize || 'medium'}>
                <Translate fontSize="inherit" />
            </IconButton>
        </TooltipMobile>
    );
};

export default SpeciesLanguageSwitcher;
