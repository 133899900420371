import { FunctionComponent } from 'react';
import { IProject } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useDict } from '../../services/dictContext';

/**
 * Implementation of DictionarySelect for project dictionary.
 */
const ProjectSelect: FunctionComponent<DictionarySelectPublicProps<number, IProject>> = (props) => {
    const { projects } = useDict();

    return (
        <DictionarySelect<number, IProject>
            color="secondary"
            {...props}
            getDictOptions={
                projects ?
                new Promise((resolve) => {
                    resolve(projects);
                }) : undefined
            }
            getDictValues={(dicts) => dicts.map((dict: IProject) => dict.id)}
            getOptionLabel={(option, dicts = [] as IProject[]) =>
                dicts.find((dict) => dict.id === option)?.name.cs || `Unknown project ${option}`
            }
        />
    );
};

export default ProjectSelect;
