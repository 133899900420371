import { Box, TablePaginationProps } from '@mui/material';
import { GridPagination } from '@mui/x-data-grid';
import { FC } from 'react';

type ObsTablePaginationProps = Partial<TablePaginationProps> & {
    total?: number;
    loading?: boolean;
};

const ObsTablePagination: FC<ObsTablePaginationProps> = (props) => {
    const { total, loading, ...paginationProps } = props;

    const totalText = total === 999999 ? '' : ` z ${total}`;

    return (
        <GridPagination
            {...paginationProps}
            sx={{
                ...props.sx,
                ...(props.loading ? { '& .MuiTablePagination-actions': { opacity: 0.2, pointerEvents: 'none' } } : {}),
            }}
            labelDisplayedRows={(labelProps) => {
                return (
                    <Box component="span">
                        {props.total === 0 && `0 - 0 z 0`}
                        {!!props.total && (
                            <>
                                {labelProps.from} - {labelProps.to}
                                {totalText}
                            </>
                        )}
                    </Box>
                );
            }}
        />
    );
};

export default ObsTablePagination;
