import { IPlace, IProject } from '../schemas/interfaces';

export default function filterRuleByTerritorialUnitId<RuleType extends { territory: IProject['territory'] }>(
    rules: RuleType[],
    targetTerritorialUnitId: number,
    allPlaces: IPlace[],
): RuleType[] {
    const findParentPlaces: (placeId: number) => IPlace[] = (placeId) => {
        const place = allPlaces.find((p) => p.id === placeId);

        if (!place) return [];

        if (!place.parentId) return [place];

        return [place, ...findParentPlaces(place.parentId)];
    };

    const validTerritorialUnitIds = findParentPlaces(targetTerritorialUnitId).map((p) => p.id);

    return rules.filter((rule) => {
        return rule.territory.some((territoryId) => validTerritorialUnitIds.includes(territoryId));
    });
}
