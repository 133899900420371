import { FC, useEffect } from 'react';
import { ImportFile } from './ImportFileList';
import { Accordion, AccordionSummary, Box, CircularProgress, Typography } from '@mui/material';
import { CheckCircle, Error, ExpandMore } from '@mui/icons-material';
import { useApi } from '../../../services/apiContext';
import ImportSuccess from './ImportListSuccess';
import ImportFailed from './ImportListError';
import ImportFileResult from './ImportFileResult';

const ImportFileListItem: FC<{ file: ImportFile; onFileLoaded: (result: ImportFile) => void }> = ({
    file,
    onFileLoaded,
}) => {
    const { uploadImport } = useApi();

    useEffect(() => {
        if (file.status !== 'loading') return;

        uploadImport(file.file)
            .then((result) => {
                onFileLoaded({
                    ...file,
                    status: result.success ? 'success' : 'error',
                    response: result,
                });
            })
            .catch((error) => {
                console.log(typeof error, error);
                onFileLoaded({ ...file, status: 'failed', error });
            });
    }, [file.status]);

    const finished = ['success', 'error'].includes(file.status);
    const failed = file.status === 'failed';

    return (
        <Accordion className="ImportFileListItem-root">
            <AccordionSummary
                sx={{ lineHeight: '1.8rem', pointerEvents: finished ? 'auto' : 'none' }}
                expandIcon={<ExpandMore sx={{ visibility: finished ? 'visible' : 'hidden' }} />}
            >
                <Box className="ImportFileListItem-status" sx={{ fontSize: '1.8rem', display: 'inline-flex', pr: 2 }}>
                    {!finished && !failed && (
                        <CircularProgress
                            size={25}
                            thickness={6}
                            color="info"
                            variant="indeterminate"
                            sx={{ p: '2px' }}
                        />
                    )}
                    {file.status === 'success' && <CheckCircle color="success" fontSize="inherit" />}
                    {file.status === 'error' && <Error color="warning" fontSize="inherit" />}
                    {file.status === 'failed' && <Error color="error" fontSize="inherit" />}
                </Box>
                <Box
                    component="span"
                    sx={{
                        width: '40%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        pr: 1,
                    }}
                >
                    <b>{file.file.name}</b>
                    <Typography variant="caption" sx={{ pl: 0.5 }}>
                        ({(file.file.size / 1024).toFixed(0).toLocaleString()} KB)
                    </Typography>
                </Box>
                <Box component="span" sx={{ width: '40%', textOverflow: 'ellipsis' }}>
                    {file.status === 'pending' && 'Čeká na zpracování...'}
                    {file.status === 'loading' && 'Načítání...'}
                    {file.status === 'success' && 'Import proběhl úspěšně. Klikněte pro více informací.'}
                    {file.status === 'error' && 'Import selhal. Klikněte pro více informací.'}
                    {file.status === 'failed' && 'Soubor se nepodařilo nahrát. Zkuste to prosím později.'}
                </Box>
            </AccordionSummary>
            {finished && file.response && <ImportFileResult file={file} />}
        </Accordion>
    );
};

export default ImportFileListItem;
