import { FC } from 'react';
import AvifJoyride from './AvifJoyride';
import { Link } from 'react-router-dom';

const CreateListTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            continuous
            steps={[
                {
                    target: '.tour-createlist-1',
                    title: 'Zadání nové vycházky',
                    content: (
                        <>
                            Zadání nové vycházky je rozděleno do&nbsp;tří kroků. V&nbsp;prvním se definují údaje
                            o&nbsp;vycházce, v&nbsp;druhém údaje o&nbsp;jednotlivých pozorování. Ve&nbsp;třetím kroku je
                            nabídnut přehledný souhrn ke&nbsp;kontrole a následnému odeslání.
                        </>
                    ),
                },

                {
                    target: '.tour-createlist-2',
                    title: 'Ukládání rozpracované vycházky',
                    content: (
                        <>
                            Veškeré údaje, které zadáváte, jsou průběžně ukládany ve&nbsp;vašem prohlížeči. Pokud okno
                            prohlížeče zavřete a vrátíte se později, všechny údaje budou obnoveny. Pokud si přejete
                            uložené údaje zahodit, klikněte na&nbsp;toto tlačítko.
                        </>
                    ),
                },
                {
                    target: '.tour-createlist-3',
                    title: 'Datum a čas',
                    content: (
                        <>
                            <b>Datum pozorování a celkový čas</b> strávený na&nbsp;vycházce patří společně
                            s&nbsp;určením lokality k&nbsp;nejdůležitějším údajům.
                        </>
                    ),
                },
                {
                    target: '.tour-createlist-4',
                    title: 'Místo pozorování: oblast',
                    content: (
                        <>
                            <b>Místo pozorování</b> lze zadat třemi způsoby. Zadání ve&nbsp;formě <b>oblasti</b> můžete
                            využít, např. pokud navštívíte nějakou obec, ale nepamatujete si konkrétní ulice, kterými
                            jste během své vycházky procházeli.
                        </>
                    ),
                },
                {
                    target: '.tour-createlist-5',
                    title: 'Místo pozorování: bod',
                    content: (
                        <>
                            Zadání ve&nbsp;formě <b>bodu</b> využijete, pokud jste např. sledovali rybník
                            z&nbsp;jediného místa bez&nbsp;přemisťování.
                        </>
                    ),
                },
                {
                    target: '.tour-createlist-6',
                    title: 'Místo pozorování: linie',
                    content: (
                        <>
                            Zadání ve&nbsp;formě <b>linie</b> umožňuje zadat několik souřadnic vycházky. Takové zadání
                            místa vycházky je <b>nejpřesnější</b>, a tudíž i nejvíce cenné.
                        </>
                    ),
                },
                {
                    target: '.tour-createlist-7',
                    title: 'Pozorovatelé a jejich utajení',
                    content: (
                        <>
                            K&nbsp;vycházce lze přidat informace o&nbsp;celkovém počtu pozorovatelů a jejich jména.
                            Pokud si nepřejete, aby byla jména pozorovatelů vyobrazena v&nbsp;tabulkovém výpisu
                            pozorování, máte možnost zaškrtnout pole <b>"Utajit jména všech pozorovatelů"</b>.
                        </>
                    ),
                },
                {
                    target: '.tour-createlist-8',
                    title: 'Utajit',
                    content: (
                        <>
                            Kromě utajení jmen pozorovatelů je během zadávání vycházky možné utajit i lokalitu. Je to
                            proto, že některé druhy ptáků, jako např. čáp černý, jsou velmi citlivé na&nbsp;vyrušování a
                            zveřejnění konkrétní lokality by mohlo narušit jejich hnízdění.
                        </>
                    ),
                },
                {
                    target: '.tour-createlist-9',
                    title: 'Kompletní vs. částečný seznam',
                    content: (
                        <>
                            Důležitým krokem vkládání je definice toho, o&nbsp;jaký seznam pozorování se jedná.
                            <b>Kompletní seznam</b> zahrnuje úplně všechny viděné a slyšené ptáky během vycházky.{' '}
                            <b>Částečný seznam</b> naopak zahrnuje jen některé vybrané druhy. Pokud možno, zadávejte
                            prosím vždy ve&nbsp;formě kompletních seznamů. Jedině tak se můžeme dozvědět o&nbsp;změnách
                            početnosti běžně se vyskytujících druhů.
                        </>
                    ),
                },
            ]}
            tourId="createlist"
        />
    );
};

export default CreateListTour;
