import { Alert, AlertTitle, Box, Button, List, ListItem, Tooltip, Typography } from '@mui/material';
import { LOCAL_STORAGE_KEY, ObservationFormStepComponent } from '../ObservationFormApp';
import { schema_list_input, schema_listItem_input } from '../../../schemas/schemas';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useApi } from '../../../services/apiContext';
import { Link, useNavigate } from 'react-router-dom';
import { ListItemAction } from '../../../schemas/enums';
import { LoadingButton } from '@mui/lab';
import { SafeParseReturnType, ZodError } from 'zod';
import { IListCreate } from '../../../schemas/interfaces';
import CreateListSummaryTour from '../../../components/joyride/CreateListSummaryTour';
import { DownloadOutlined } from '@mui/icons-material';

const ObsListSummary: ObservationFormStepComponent = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | false>();
    const [success, setSuccess] = useState<boolean>();
    const [listId, setListId] = useState<string>();
    const [parsed, setParsed] = useState<SafeParseReturnType<any, IListCreate>>();
    const api = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        setParsed(schema_list_input.safeParse(props.initialValues));
    }, [props.initialValues]);

    const list = props.initialValues;
    const isValid = parsed && parsed.success;
    const errors = parsed && !parsed.success && parsed.error;
    const parsedData = parsed && parsed.success && parsed.data;

    const handleSubmitError = (error: Response | any) => {
        setLoading(false);

        if (error instanceof Response) {
            error.json().then((response: { error?: { cs?: string } }) => {
                console.error(response);
                setSubmitError(response.error?.cs || JSON.stringify(response));
            });
        } else {
            console.error(error);
            setSubmitError(JSON.stringify(error));
        }
    };

    const createList = () => {
        if (!parsedData || list?.publicId) throw new Error('Failed parsing data or list ID is present.');

        api.createList(parsedData)
            .then((res) => {
                setLoading(false);

                if (!res.success) throw new Error('Failed to create list: ' + res.error);

                props.onSubmit && props.onSubmit(res.data);
                setSubmitError(false);
                setListId(res.data.publicId);
            })
            .catch(handleSubmitError);
    };

    const updateList = () => {
        if (!parsedData || !list?.publicId) throw new Error('Failed parsing data or list ID is missing.');

        const { items: parsedItems, ...parsedList } = parsedData;

        setLoading(true);
        api.updateList(list?.publicId, parsedList)
            .then((listUpdateResponse) => {
                if (!listUpdateResponse.success) throw new Error('Failed to update list: ' + listUpdateResponse.error);

                return Promise.all(
                    (list?.items || [])
                        .filter((i) => i._Action)
                        .map((item) => {
                            if (!listUpdateResponse.data.publicId) return Promise.reject('List ID is missing');

                            const parsedItem = schema_listItem_input.parse(item);

                            if (item._Action === ListItemAction.Create)
                                return api.createListItem(listUpdateResponse.data.publicId, parsedItem);

                            if (item._Action === ListItemAction.Update && item.id)
                                return api.updateListItem(listUpdateResponse.data.publicId, item.id, parsedItem);

                            if (item._Action === ListItemAction.Delete && item.id)
                                return api.deleteListItem(listUpdateResponse.data.publicId, item.id);

                            return Promise.reject(`Unknown item action of ${item._Action}`);
                        }),
                ).then((itemActionResponses) => {
                    itemActionResponses.forEach((r) => {
                        if (!r.success) throw new Error('Failed to create/update/delete list item: ' + r.error);
                    });

                    setLoading(false);
                    setSubmitError(false);
                    setSuccess(true);
                    setListId(listUpdateResponse.data.publicId);
                });
            })
            .catch(handleSubmitError);
    };

    const onSubmit = () => {
        setLoading(true);
        console.log('submitting', parsedData);

        props.new && createList();
        !props.new && updateList();
    };

    const handleNextInsertClick: MouseEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        props.new ? window.location.reload() : navigate('/create');
    };

    return (
        <Box className="ObsListSummary">
            {(list?.items || []).filter((item) => item._Action !== 'delete').length < 1 && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    <AlertTitle>Upozornění</AlertTitle>
                    <Typography variant="body2" component="p">
                        {props.new && (
                            <>
                                Vycházka neobsahuje žádná pozorování. Jste si jist/a, že chcete takovou vycházku
                                vytvořit?
                            </>
                        )}
                        {!props.new && list?.publicId && (
                            <>
                                Vycházka neobsahuje žádná pozorování. Zvažte její kompletní smazání na&nbsp;
                                <Link to={`/list/${list?.publicId}`}>jejím detailu</Link>.
                            </>
                        )}
                    </Typography>
                </Alert>
            )}
            {!isValid && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    <AlertTitle>Chyba</AlertTitle>
                    <Typography variant="body2" component="div">
                        Formulář obsahuje chyby, které je potřeba opravit:
                        <List>
                            {((errors || { issues: [] }) as ZodError<any>).issues.map((issue) => (
                                <ListItem key={issue.path.join('.')}>
                                    {issue.path[0] === 'items' && (
                                        <span>
                                            <b>
                                                Chyba v řádku č. {parseInt(String(issue.path[1])) + 1}, pole{' '}
                                                {issue.path[2] ?? 'neznámé'}:
                                            </b>{' '}
                                            {issue.message}. Pokud vám chyba není jasná, zkuste daný řádek vymazat a
                                            vytvořit znovu.
                                        </span>
                                    )}
                                    {issue.path[0] !== 'items' && (
                                        <span>
                                            {' '}
                                            <b>Chyba v poli {issue.path.slice(1).join('.')}:</b> {issue.message}. Zkuste
                                            se ve formuláři vrátit a pole opravit.
                                        </span>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                        Pokud se vám chyby nedaří vyřešit, stáhněte si pomocí následujícího tlačítka chybový log a ten
                        nám společně se snímkem obrazovky pošlete na e-mail{' '}
                        <Link to="mailto:admin@birds.cz" style={{ color: 'inherit', fontWeight: 'bold' }}>
                            admin@birds.cz
                        </Link>
                        .
                    </Typography>
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DownloadOutlined />}
                        href={window.URL.createObjectURL(
                            new Blob(
                                [
                                    JSON.stringify(
                                        {
                                            timestamp: new Date(),
                                            error: errors,
                                            data: props.initialValues,
                                            localStorage: window?.localStorage?.getItem
                                                ? window.localStorage.getItem(LOCAL_STORAGE_KEY)
                                                : { error: 'localStorage not available' },
                                        },
                                        null,
                                        4,
                                    ),
                                ],
                                { type: 'text/json' },
                            ),
                        )}
                        sx={{ mt: 2 }}
                        download={`avif-error-log-${new Date().toISOString()}.json`}
                    >
                        Stáhnout chybový log
                    </Button>
                </Alert>
            )}
            {!!submitError && submitError !== undefined && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    <AlertTitle>Chyba</AlertTitle>
                    <Typography variant="body2" component="p">
                        Při&nbsp;odesílání došlo k&nbsp;chybě. Server vrátil chybu: {submitError}
                    </Typography>
                </Alert>
            )}
            {!submitError && submitError !== undefined && (
                <Alert severity="success">
                    <AlertTitle>Hotovo!</AlertTitle>
                    <Typography variant="body2" component="p">
                        Vycházka byla úspěšně odeslána!
                    </Typography>
                </Alert>
            )}
            {!submitError && submitError !== undefined && listId && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', pt: 2 }}>
                    <Link to={`/list/${listId}`} style={{ textDecoration: 'none' }}>
                        <Button variant="contained" color="primary">
                            Zobrazit vycházku
                        </Button>
                    </Link>
                    <Link to={`/create`} style={{ textDecoration: 'none' }} onClick={handleNextInsertClick}>
                        <Button variant="outlined" color="primary">
                            Vložit další vycházku
                        </Button>
                    </Link>
                </Box>
            )}
            {submitError !== false && (
                <>
                    <Typography
                        variant="body1"
                        component="div"
                        sx={{
                            p: 1.75,
                            bgcolor: 'background.paper',
                        }}
                    >
                        Před&nbsp;odesláním zkontrolujte údaje o&nbsp;vycházce a pozorováních, případně se
                        ve&nbsp;formuláři vraťte a upravte je.
                        <Typography variant="caption" component="p" sx={{ pt: 2, color: 'text.secondary' }}>
                            Vložením dat a fotografií do&nbsp;této databáze souhlasíte s&nbsp;jejich vytěžováním nebo
                            zužitkováním Českou společností ornitologickou v&nbsp;souladu s&nbsp;§ 3 odst. 9 stanov ČSO
                            podle&nbsp;
                            <Link to="http://jpsp.birds.cz/misc/Zasady_nakladani_s_daty_CSO.pdf" target="_blank">
                                Zásad nakládání s&nbsp;daty ČSO
                            </Link>
                            . Tím nejsou dotčena práva vyplývající z&nbsp;předpisů o&nbsp;ochraně práv duševního
                            vlastnictví.
                        </Typography>
                    </Typography>
                    <Box
                        sx={{ display: 'inline-flex', flexWrap: 'wrap', gap: '1rem', pt: 2 }}
                        className="tour-createlistsummary-1 tour-createlistsummary-2"
                    >
                        <Tooltip
                            title={
                                props.new
                                    ? 'Zpět na\xa0úpravu pozorování.'
                                    : 'Zpět na\xa0úpravu pozorování. Smazaná pozorování budou znovu zobrazena.'
                            }
                        >
                            <Button
                                onClick={() => {
                                    props.onBack && props.onBack();
                                }}
                                variant="outlined"
                                sx={{ bgcolor: 'primary.50' }}
                            >
                                Zpět
                            </Button>
                        </Tooltip>
                        <LoadingButton onClick={onSubmit} variant="contained" color="secondary" loading={loading}>
                            {props.new && 'Odeslat vycházku do\xa0databáze'}
                            {!props.new && 'Uložit vycházku a pozorování'}
                        </LoadingButton>
                    </Box>
                    <CreateListSummaryTour />
                </>
            )}
        </Box>
    );
};

export default ObsListSummary;
