import { FunctionComponent } from 'react';
import { ISecrecy } from '../../schemas/interfaces';
import DictionarySelect, { DictionarySelectPublicProps } from './DictionarySelect';
import { useDict } from '../../services/dictContext';

const SecrecySelect: FunctionComponent<DictionarySelectPublicProps<number, ISecrecy>> = (props) => {
    const { secrecy } = useDict();

    return (
        <DictionarySelect<number, ISecrecy>
            color="secondary"
            {...props}
            getDictOptions={
                secrecy
                    ? new Promise((resolve) => {
                          resolve(secrecy);
                      })
                    : undefined
            }
            getDictValues={(dicts) => dicts.map((dict) => dict.level)}
            getOptionLabel={(option, dicts = [] as ISecrecy[]) =>
                dicts.find((dict) => dict.level === option)?.name.cs || `Unknown attribute ${option}`
            }
            disableEmpty
        />
    );
};

export default SecrecySelect;
