import React, { useMemo } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

const MapControlsDisabler: React.FC = () => {
    const map = useMap();

    const disableTabIndex = useMemo(
        () => (map: HTMLElement) => {
            setTimeout(() => {
                map.querySelectorAll(
                    '.leaflet-control-zoom-in, \
                .leaflet-control-zoom-out, \
                .leaflet-control-attribution > a, \
                .leaflet-control-layers-toggle, \
                .leaflet-control-scale-line, \
                .leaflet-control-geocoder-icon, \
                .leaflet-control-fullscreen-button, \
                .leaflet-bar a, \
                .leaflet-popup-content-wrapper, \
                .leaflet-popup-tip, \
                .leaflet-draw-toolbar a, \
                .leaflet-draw-actions a, \
                .leaflet-control-locate a, \
                .leaflet-marker-icon, \
                .MuiSvgIcon-root, \
                .MuiButtonBase-root, \
                .MuiIconButton-root',
                ).forEach((el) => {
                    el.setAttribute('tabindex', '-1');
                });
            });
        },
        [],
    );

    useMapEvents({
        baselayerchange: () => {
            disableTabIndex(map.getContainer());
        },
    });

    return null;
};

export default MapControlsDisabler;
