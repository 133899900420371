import { FC, useEffect, useState } from 'react';
import { IProject } from '../../../../../schemas/interfaces';
import { Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { ProjectFormValues } from '../ProjectForm';
import { TooltipMobile } from '../../../../../components/Tooltip';
import { ValueSetter } from 'date-fns/parse/_lib/Setter';
import { CustomProjectFormProps } from '../CustomProjectForm';
import SpeciesAutocomplete from '../../../../../components/formControls/SpeciesAutocomplete';
import translateErrorMessage from '../../../../../services/errorMessages';

enum Territoriality {
    Territorial = 'territorial',
    Nest = 'nest',
    Family = 'family',
    Local = 'local',
    Other = 'other',
    FlownOver = 'flownOver',
}

enum Detection {
    Visual = '1',
    Acoustic = '2',
    Singing = 'singing',
}

enum FlownOver {
    Yes = '1',
    No = '0',
}

enum CountUnit {
    Individual = 'individual',
    Nest = 'nest',
}

const LsdTransForm: FC<CustomProjectFormProps> = ({ project, baseFormContext }) => {
    const { values, setFieldValue } = useFormikContext<ProjectFormValues>();
    const [visibleDetection, setVisibleDetection] = useState<Detection>();

    useEffect(() => {
        if (values.data['territoriality'] !== Territoriality.Territorial && visibleDetection === Detection.Singing) {
            setAcoustic();
        }
    }, [values.data['territoriality']]);

    useEffect(() => {
        if (values.data['detection'] === Detection.Acoustic && baseFormContext.values.activityId === 2)
            return setSinging();

        setVisibleDetection((values.data['detection'] as Detection) ?? undefined);
    }, [values.data['detection'], values.data['flownOver']]);

    const handleDetectionChange = (e: React.MouseEvent<HTMLElement> | undefined, detection: Detection) => {
        switch (detection) {
            case Detection.Visual:
                setVisual();
                break;
            case Detection.Acoustic:
                setAcoustic();
                break;
            case Detection.Singing:
                setSinging();
                break;
        }
    };

    const setVisual = () => {
        setFieldValue('data.detection', Detection.Visual);
        setVisibleDetection(Detection.Visual);
        baseFormContext.setFieldValue('activityId', null);
    };
    const setAcoustic = () => {
        setFieldValue('data.detection', Detection.Acoustic);
        setVisibleDetection(Detection.Acoustic);
        baseFormContext.setFieldValue('activityId', null);
    };
    const setSinging = () => {
        setFieldValue('data.detection', Detection.Acoustic);

        setTerritorial();
        baseFormContext.setFieldValue('activityId', 2);

        // run this in next tick, so change of detection in data is already done
        setTimeout(() => {
            setVisibleDetection(Detection.Singing);
        });
    };

    const handleTerritorialityChange = (
        e: React.MouseEvent<HTMLElement> | undefined,
        territoriality: Territoriality,
    ) => {
        switch (territoriality) {
            case Territoriality.Territorial:
                setTerritorial();
                break;
            case Territoriality.Nest:
                setNest();
                break;
            case Territoriality.Family:
                setFamily();
                break;
            case Territoriality.Local:
                break;
            case Territoriality.Other:
                setOther();
                break;
            case Territoriality.FlownOver:
                setflownOver();
                break;
        }
    };

    const setTerritorial = () => {
        setFieldValue('data.territoriality', Territoriality.Territorial);
        setFieldValue('data.flownOver', FlownOver.No);
        setFieldValue('data.countUnit', CountUnit.Individual);
    };
    const setNest = () => {
        setFieldValue('data.territoriality', Territoriality.Nest);
        setFieldValue('data.flownOver', FlownOver.No);
        setFieldValue('data.countUnit', CountUnit.Nest);
    };
    const setFamily = () => {
        setFieldValue('data.territoriality', Territoriality.Family);
        setFieldValue('data.flownOver', FlownOver.No);
        setFieldValue('data.countUnit', CountUnit.Individual);
    };
    const setOther = () => {
        setFieldValue('data.territoriality', Territoriality.Other);
        setFieldValue('data.flownOver', FlownOver.No);
        setFieldValue('data.countUnit', CountUnit.Individual);
    };
    const setflownOver = () => {
        setFieldValue('data.territoriality', Territoriality.Other);
        setFieldValue('data.flownOver', FlownOver.Yes);
        setFieldValue('data.countUnit', CountUnit.Individual);
    };

    return (
        <Grid container>
            <Grid item xs={12} sx={{ pb: 1 }}>
                <Typography variant="subtitle2" textTransform="uppercase" fontWeight="bold">
                    Druh:
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
                <SpeciesAutocomplete
                    // for empty item the formId changes on submit so we use it here for the key
                    // so the species autocomplete is rerendered when species is selected
                    value={baseFormContext.values.taxonId ?? null}
                    onChange={(value) => {
                        baseFormContext.setFieldValue('taxonId', value);
                    }}
                    optionsFilter={(taxons) => taxons.filter((taxon) => taxon.genusId || taxon.id === 0)}
                    onBlur={() => baseFormContext.setFieldTouched('taxonId', true)}
                    name="taxonId"
                    label=""
                    AutocompleteProps={{
                        disableClearable: true,
                    }}
                    TextFieldProps={{
                        // only show validation error for table rows, not the add new row form
                        error: !!baseFormContext.errors.taxonId && !!baseFormContext.touched.taxonId,
                        helperText:
                            !!baseFormContext.errors.taxonId &&
                            !!baseFormContext.touched.taxonId &&
                            translateErrorMessage(baseFormContext.errors.taxonId),
                    }}
                    enableLanguageSwitch
                />
            </Grid>
            <Grid item xs={12} sx={{ pb: 1 }}>
                <Typography variant="subtitle2" textTransform="uppercase" fontWeight="bold">
                    Způsob pozorování:
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
                <ToggleButtonGroup
                    value={'nothing'}
                    onChange={handleDetectionChange}
                    id="detection"
                    exclusive
                    fullWidth
                    size="small"
                >
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton value={Detection.Visual} selected={visibleDetection == Detection.Visual}>
                            Viděný
                        </ToggleButton>
                    </TooltipMobile>
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton
                            value={Detection.Acoustic}
                            selected={visibleDetection == Detection.Acoustic || visibleDetection == Detection.Singing}
                        >
                            Slyšený
                        </ToggleButton>
                    </TooltipMobile>
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton value={Detection.Singing} selected={visibleDetection == Detection.Singing}>
                            Zpěv
                        </ToggleButton>
                    </TooltipMobile>
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} sx={{ pb: 1 }}>
                <Typography variant="subtitle2" textTransform="uppercase" fontWeight="bold">
                    Chování:
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
                <ToggleButtonGroup
                    value={'nothing'}
                    onChange={handleTerritorialityChange}
                    id="territoriality"
                    exclusive
                    fullWidth
                    size="small"
                >
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton
                            value={Territoriality.Territorial}
                            selected={values.data['territoriality'] == Territoriality.Territorial}
                        >
                            Teritoriální
                        </ToggleButton>
                    </TooltipMobile>
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton
                            value={Territoriality.Nest}
                            selected={values.data['territoriality'] == Territoriality.Nest}
                        >
                            Hnízdo
                        </ToggleButton>
                    </TooltipMobile>
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton
                            value={Territoriality.Family}
                            selected={values.data['territoriality'] == Territoriality.Family}
                        >
                            Rodinka
                        </ToggleButton>
                    </TooltipMobile>
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton
                            value={Territoriality.Other}
                            selected={values.data['territoriality'] == Territoriality.Other}
                        >
                            Ostatní
                        </ToggleButton>
                    </TooltipMobile>
                    <TooltipMobile title="Popisek na vysvětlení">
                        <ToggleButton value={Territoriality.FlownOver} selected={values.data['flownOver'] === '1'}>
                            Přelet
                        </ToggleButton>
                    </TooltipMobile>
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} sx={{ pb: 1 }}>
                <Typography variant="subtitle2" textTransform="uppercase" fontWeight="bold">
                    Počet:
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
                <TextField
                    value={baseFormContext.values.count || ''}
                    name="count"
                    onChange={(e) => {
                        const value = e.target.value;
                        baseFormContext.setFieldValue('count', value || null, false);
                    }}
                    onBlur={baseFormContext.handleBlur}
                    // label="Počet"
                    fullWidth
                    error={!!baseFormContext.errors.count && !!baseFormContext.touched.count}
                    helperText={
                        !!baseFormContext.errors.count &&
                        !!baseFormContext.touched.count &&
                        translateErrorMessage(baseFormContext.errors.count)
                    }
                />
            </Grid>
        </Grid>
    );
};

export default LsdTransForm;
