import { Box } from '@mui/material';
import { FunctionComponent } from 'react';

interface FullScreenImageBackgroundProps {
    imageUrl: string;
    children: React.ReactNode;
}

const FullScreenImageBackground: FunctionComponent<FullScreenImageBackgroundProps> = ({ imageUrl, children }) => {
    return (
        <Box
            sx={{
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)', md: 'calc(100vh - 64px)' },
                marginTop: '-1rem',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
            }}
            className="FullScreenImageBackground"
        >
            {children}
        </Box>
    );
};

export default FullScreenImageBackground;
