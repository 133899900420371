import { Box, Fab, FormHelperText, Grid, IconButton, IconButtonProps, Tooltip, useMediaQuery } from '@mui/material';
import { FormikProps } from 'formik';
import OpenStreetMap, { MapCategory, OSMOnClickLocationMarker, customIconDimmed } from './OpenStreetMap';
import { parseCoordinate } from '../../services/parsers';
import { ClearRounded } from '@mui/icons-material';
import translateErrorMessage from '../../services/errorMessages';
import { ICoordinates } from '../../schemas/interfaces';
import { Marker, Polyline } from 'react-leaflet';
import NumberField from '../formControls/NumberField';
import Geolocation from './Geolocation'; // Import Geolocation component
import CoordinatesPaste from './CoordinatesPaste';
import { ClearableTextFieldProps } from '../formControls/ClearableTextField';

export interface CoordinatesMapValues extends CoordinatesMapGen<number> {}

export interface CoordinatesMapTouched extends CoordinatesMapGen<boolean> {}

export interface CoordinatesMapGen<T> {
    latitude?: T;
    longitude?: T;
    radius?: T;
}

interface CoordinatesMapProps {
    formikProps: FormikProps<any>;
    disableRadius?: boolean;
    coordsValues: CoordinatesMapValues;
    inputNamePrefix: string;
    height?: string;
    minHeight?: string;
    TextFieldProps?: ClearableTextFieldProps;
    PasteButtonProps?: IconButtonProps;
    required?: boolean;
    defaultRadius?: number;
    highlightWhenFilled?: boolean;
    center?: ICoordinates;
    zoom?: number;
    backgroundCoords?: ICoordinates[];
    requiredZoom?: number;
    mapCategory?: MapCategory;
    onFieldChange?: (fieldName: string, value?: any) => void;
}

const CoordinatesMap: React.FunctionComponent<CoordinatesMapProps> = (props) => {
    const { handleBlur, setFieldValue: fSetFieldValue, setTouched, setValues } = props.formikProps;
    const values = props.coordsValues;
    const iPr = props.inputNamePrefix || '';
    const isMobile = useMediaQuery('(pointer: coarse)');

    const errorsFromFormik = props.formikProps.errors[iPr.substring(0, iPr.length - 1)] as any;
    const errors: { latitude?: string; longitude?: string; radius?: string; coordinates?: string } = {
        ...(typeof errorsFromFormik === 'string'
            ? { [iPr.substring(0, iPr.length - 1)]: errorsFromFormik }
            : errorsFromFormik),
    };
    const touchedFromFormik = props.formikProps.touched[iPr.substring(0, iPr.length - 1)] as any;
    const touched: { latitude?: boolean; longitude?: boolean; radius?: boolean; coordinates?: boolean } = {
        ...(typeof touchedFromFormik === 'boolean'
            ? { [iPr.substring(0, iPr.length - 1)]: touchedFromFormik }
            : touchedFromFormik),
    };

    // since the coordinates properties are set inside of the coordinates object which is nullable
    // and we set the defaultValue to null, Formik is not aware of the properies and doesn't
    // set them to touched when the user submits form which makes the error messages disappear
    // thus we check whether the coordinates object is touched and if so, we display the error messages too
    const touchedAny = !!props.formikProps.touched[iPr.substring(0, iPr.length - 1)];

    // custom handler that sets the changed value to the formik field
    // and also resets the whole coordinates object to undefined if all
    // values are empty
    const setFieldValue = (fieldName: string, value?: number, submitOnChange = true) => {
        if (!value && value !== 0 && Object.entries(values).filter(([, v]) => v).length <= 1) {
            const updateFieldName = iPr.substring(0, iPr.length - 1);
            // if current value is empty (user deletes input) and the values object has
            // only one value set (at most), reset the whole coordinates object
            // this is needed because the values object is not updated yey as the current
            // change event has not been applied to it yet
            fSetFieldValue(updateFieldName, null, submitOnChange);
            props.onFieldChange?.(updateFieldName, null);
        } else {
            const updateFieldName = iPr + fieldName;
            // otherwise just update the value of the current field
            fSetFieldValue(updateFieldName, value, submitOnChange);
            props.onFieldChange?.(updateFieldName, value);
        }
    };

    const setCoordinate = (fieldName: string, value?: number | string | null, submitOnChange = true) => {
        const coordinate = value || value === 0 || value === '' ? parseCoordinate(value) : undefined;
        setFieldValue(fieldName, coordinate, submitOnChange);
    };

    const setRadius = (value?: number | string | null, submitOnChange = true) => {
        const radius = parseFloat(value as string);
        setFieldValue('radius', isNaN(radius) ? undefined : radius, submitOnChange);
    };

    const setCoordinates = (coordinates: ICoordinates | undefined, radius?: number) => {
        const newValues = { ...props.formikProps.values };
        const newCoordinates = coordinates
            ? {
                  latitude: parseCoordinate(coordinates[0]),
                  longitude: parseCoordinate(coordinates[1]),
                  radius: !props.disableRadius && radius ? radius : undefined,
              }
            : null;
        newValues[iPr.substring(0, iPr.length - 1)] = newCoordinates;

        setValues(newValues);
        props.onFieldChange?.(iPr.substring(0, iPr.length - 1), newCoordinates);

        setTouched({
            ...props.formikProps.touched,
            [iPr.substring(0, iPr.length - 1)]: {
                latitude: true,
                longitude: true,
                radius: true,
            },
        });
    };

    return (
        <Grid container spacing={1.75}>
            <Grid item xs={12}>
                <Box sx={{ position: 'relative', borderRadius: 'var(--mui-shape-borderRadius)', overflow: 'hidden' }}>
                    <OpenStreetMap
                        height={props.height ?? '225px'}
                        minHeight={props.minHeight ?? '100px'}
                        center={props.center}
                        defaultZoom={props.zoom}
                        mapCategory={props.mapCategory}
                    >
                        {props.backgroundCoords && props.backgroundCoords.length && (
                            <>
                                {props.backgroundCoords.map((coordinate, index) => (
                                    <Marker position={coordinate} icon={customIconDimmed} key={index}></Marker>
                                ))}
                                <Polyline
                                    positions={props.backgroundCoords}
                                    pathOptions={{ color: '#5E7694' }}
                                    className="dimmed"
                                />
                            </>
                        )}
                        <OSMOnClickLocationMarker
                            onClick={async (latitude, longitude) => {
                                setCoordinates([latitude, longitude], values.radius ?? props.defaultRadius);
                            }}
                            latitude={values.latitude}
                            longitude={values.longitude}
                            radius={values.radius}
                            requiredZoom={props.requiredZoom}
                            draggable
                        />
                        {!!isMobile && (
                            <Geolocation
                                onChange={({ latitude, longitude }) => {
                                    setCoordinates([latitude, longitude], values.radius ?? props.defaultRadius);
                                }}
                            />
                        )}
                    </OpenStreetMap>
                    {!!values.latitude && !!values.longitude && (
                        <Tooltip title="Odstranit bod">
                            <Fab
                                onClick={async () => {
                                    // using the default setFieldValue handler here because we don't need to
                                    // prepend it with the prefix value
                                    const updateFieldName = iPr.substring(0, iPr.length - 1);
                                    props.formikProps.setFieldValue(updateFieldName, null);
                                    props.onFieldChange?.(updateFieldName, null);
                                }}
                                tabIndex={-1}
                                size="small"
                                sx={{ position: 'absolute', right: '1em', bottom: '1em' }}
                                color="primary"
                            >
                                <ClearRounded />
                            </Fab>
                        </Tooltip>
                    )}
                </Box>
            </Grid>
            {!props.disableRadius && (
                <Grid item xs={12} md={3}>
                    <NumberField
                        fullWidth
                        id={`${iPr}radius`}
                        name={`${iPr}radius`}
                        className={props.highlightWhenFilled && values.radius ? 'nonEmpty' : undefined}
                        value={values.radius ?? ''}
                        onChange={(e) => setRadius(e.target.value)}
                        onBlur={handleBlur}
                        label="Okolí (km)"
                        error={!!errors.radius && (!!touched.radius || touchedAny)}
                        helperText={
                            !!errors.radius &&
                            (!!touched.radius || touchedAny) &&
                            translateErrorMessage(errors.radius as string)
                        }
                        min={0}
                        step={0.1}
                        required={!!props.required}
                        {...props.TextFieldProps}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={props.disableRadius ? 5.5 : 4}>
                <Tooltip title="Souřadnice můžete vybrat také kliknutím do&nbsp;mapy.">
                    <Box>
                        <NumberField
                            fullWidth
                            id={`${iPr}latitude`}
                            name={`${iPr}latitude`}
                            className={props.highlightWhenFilled && values.latitude ? 'nonEmpty' : undefined}
                            value={values.latitude ?? ''}
                            onChange={(e) => {
                                setCoordinate('latitude', e.target.value);
                            }}
                            onBlur={handleBlur}
                            label="Zem. šířka"
                            error={!!errors.latitude && (!!touched.latitude || touchedAny)}
                            helperText={
                                !!errors.latitude &&
                                !!touched.latitude &&
                                translateErrorMessage(errors.latitude as string)
                            }
                            sx={{
                                '& input[type=number]::-webkit-inner-spin-button, \
                            & input[type=number]::-webkit-outer-spin-button': {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            }}
                            min={0}
                            step={0.1}
                            required={!!props.required}
                            {...props.TextFieldProps}
                        />
                    </Box>
                </Tooltip>
            </Grid>
            <Grid item xs={10} md={props.disableRadius ? 5.5 : 4}>
                <Tooltip title="Souřadnice můžete vybrat také kliknutím do&nbsp;mapy.">
                    {/* Box is used here because functional components do not support ref, so Tooltip was throwing an error */}
                    <Box>
                        <NumberField
                            fullWidth
                            id={`${iPr}longitude`}
                            name={`${iPr}longitude`}
                            className={props.highlightWhenFilled && values.longitude ? 'nonEmpty' : undefined}
                            value={values.longitude ?? ''}
                            onChange={(e) => {
                                setCoordinate('longitude', e.target.value);
                            }}
                            onBlur={handleBlur}
                            label="Zem. délka"
                            error={!!errors.longitude && (!!touched.longitude || touchedAny)}
                            helperText={
                                !!errors.longitude &&
                                !!touched.longitude &&
                                translateErrorMessage(errors.longitude as string)
                            }
                            sx={{
                                '& input[type=number]::-webkit-inner-spin-button, \
                            & input[type=number]::-webkit-outer-spin-button': {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            }}
                            min={0}
                            step={0.1}
                            max={100}
                            required={!!props.required}
                            {...props.TextFieldProps}
                        />
                    </Box>
                </Tooltip>
            </Grid>
            <Grid
                item
                xs={2}
                md={1}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    // paddingTop: '22px !important',
                }}
            >
                <CoordinatesPaste
                    onPaste={(coords) => setCoordinates(coords, values.radius ?? props.defaultRadius)}
                    PasteButtonProps={props.PasteButtonProps}
                />
            </Grid>
            {/* <Grid item xs={12}>
                <code>{JSON.stringify(errors)}</code>
            </Grid> */}
            {!!errors.coordinates && (
                <Grid item xs={12}>
                    <FormHelperText error>
                        {translateErrorMessage((errors as any)[iPr.substring(0, iPr.length - 1)] as string)}
                    </FormHelperText>
                </Grid>
            )}
        </Grid>
    );
};

export default CoordinatesMap;
