import { FunctionComponent } from 'react';
import { IMapType } from '../../../../../schemas/interfaces';
import { useUser } from '../../../../../services/userContext';
import DictionarySelect, { DictionarySelectPublicProps } from '../../../../../components/formControls/DictionarySelect';
import PredefinedFilterSelect from '../../search/PredefinedFilterSelect';

/**
 * Implementation of DictionarySelect for map types.
 */
const MapTypeSelect: FunctionComponent<DictionarySelectPublicProps<string, IMapType>> = (props) => {
    const { search } = useUser();

    return (
        <DictionarySelect<string, IMapType>
            {...props}
            getDictOptions={
                new Promise<IMapType[]>((resolve) => {
                    if (!search?.mapTypes) return resolve([]);

                    return resolve([...search.mapTypes.items]);
                })
            }
            getDictValues={(mapTypes: IMapType[]) => mapTypes.map((type) => type.id)}
            getOptionLabel={(option, mapTypes = []) =>
                mapTypes.find((filter) => filter.id === option)?.name.cs || `Unknown mapType ${option}`
            }
            value={props.value || search?.mapTypes?.default}
            label="Typ mapového zobrazení"
            disableEmpty
        />
    );
};

export default MapTypeSelect;
