import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { ChangeEvent, ChangeEventHandler } from 'react';

export interface ClearableTextFieldProps extends StandardTextFieldProps {
    disableClear?: boolean;
}

const ClearableTextField: React.FC<ClearableTextFieldProps> = ({ disableClear, ...props }) => {
    return (
        <TextField
            {...props}
            className={[props.className || '', [props.value ? 'hasValue' : '']].join(' ')}
            sx={{
                '& .clearButton': {
                    display: 'none',
                },
                '&:hover .clearButton, \
                & .Mui-focused .clearButton': {
                    display: 'flex',
                },
                ...props.sx,
            }}
            InputProps={{
                endAdornment: !disableClear && !!props.value && (
                    <InputAdornment position="end">
                        <IconButton
                            className="clearButton"
                            onClick={() =>
                                props.onChange?.({
                                    target: {
                                        value: '',
                                        name: props.name as string,
                                    } as unknown as ChangeEvent<HTMLInputElement>['target'],
                                } as ChangeEvent<HTMLInputElement>)
                            }
                        >
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default ClearableTextField;
