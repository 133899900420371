import { TableCell } from '@mui/material';
import { FC } from 'react';
import ActivityFromDict from '../../../../components/dicts/ActivityFromDict';
import AgeFromDict from '../../../../components/dicts/AgeFromDict';
import SexFromDict from '../../../../components/dicts/SexFromDict';
import TaxonFromDict from '../../../../components/dicts/TaxonFromDict';
import { TableEditorItem } from '../../../../components/tableEditor/TableEditor';
import TableIcons from '../../../../components/TableIcons';
import { ObservationItemFormValues } from '../../ObservationFormApp';

const ItemView: FC<{ item: TableEditorItem<ObservationItemFormValues> }> = ({ item }) => {
    //for some reason when switching from p2 to p3, the data can be undefined which causes an exception
    if (!item.data) return <></>;

    return (
        <>
            <TableCell sx={{ minWidth: '200px', width: '30%', boxSizing: 'border-box' }}>
                <TaxonFromDict taxonId={item.data.taxonId} />
            </TableCell>
            <TableCell sx={{ minWidth: '100px', width: '10%', boxSizing: 'border-box' }}>
                {item.data.count ?? <em>nezadáno</em>}
            </TableCell>
            <TableCell sx={{ minWidth: '100px', width: '12.5%', boxSizing: 'border-box' }}>
                <SexFromDict sexId={item.data.sexId} />
            </TableCell>
            <TableCell sx={{ minWidth: '100px', width: '10%', boxSizing: 'border-box' }}>
                <AgeFromDict ageId={item.data.ageId} />
            </TableCell>
            <TableCell sx={{ minWidth: '100px', width: '17.5%', boxSizing: 'border-box' }}>
                <ActivityFromDict activityId={item.data.activityId} />
            </TableCell>
            <TableCell sx={{ minWidth: '50px', width: '5%', boxSizing: 'border-box' }}>
                <TableIcons note={item.data.note} align="center" popupsDisabled />
            </TableCell>
            <TableCell sx={{ minWidth: '50px', width: '5%', boxSizing: 'border-box' }}>
                <TableIcons media={item.data._Media} align="center" popupsDisabled />
            </TableCell>
            <TableCell sx={{ minWidth: '50px', width: '5%', boxSizing: 'border-box' }}>
                <TableIcons coordinates={item.data.coordinates} align="center" popupsDisabled />
            </TableCell>
        </>
    );
};

export default ItemView;
