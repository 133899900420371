import { Box } from '@mui/material';
import { FC, MouseEventHandler, PropsWithChildren, useState } from 'react';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';

const ZoomableImage: FC<{
    url: string;
    altText?: string | null;
    onBoxClick?: () => void;
}> = ({ url, altText, onBoxClick }) => {
    return (
        <TransformWrapper key={url}>
            <TransformComponent>
                <Box
                    sx={{
                        width: { xs: '100vw', sm: 'calc(100vw - 64px)' },
                        height: 'calc(100vh - 132px)',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'transparent',
                        position: 'relative',
                        textAlign: 'center',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onBoxClick?.();
                    }}
                >
                    <ZoomResetOnClick>
                        <img
                            src={url}
                            alt={altText || ''}
                            style={{
                                display: 'inline-block',
                                width: 'auto',
                                maxWidth: '100vw',
                                maxHeight: '100vh',
                                margin: '0 auto',
                                // pointerEvents: 'all !important' as 'all',
                            }}
                        />
                    </ZoomResetOnClick>
                </Box>
            </TransformComponent>
        </TransformWrapper>
    );
};

const ZoomResetOnClick: FC<PropsWithChildren> = ({ children }) => {
    const controls = useControls();
    const [willReset, setWillReset] = useState<NodeJS.Timeout>();

    const onClick: MouseEventHandler = (e) => {
        e.stopPropagation();

        if (willReset) {
            clearTimeout(willReset);
            setWillReset(undefined);
        } else {
            const timeout = setTimeout(() => {
                controls.resetTransform();
                setWillReset(undefined);
            }, 500);
            setWillReset(timeout);
        }
    };

    return (
        <Box sx={{ display: 'inline' }} onClick={onClick}>
            {children}
        </Box>
    );
};

export default ZoomableImage;
