import { Box, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface FullHeightContentProps extends PropsWithChildren {
    className?: string;
    boxSx?: SxProps;
}

const FullHeightContent: FC<FullHeightContentProps> = ({ className, boxSx, children }) => {
    return (
        <Box
            className={`LongLoadingOverlay ${className}`}
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255,255,255,0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 5,
                minHeight: '35vh',
                boxSizing: 'border-box',
                ...boxSx,
            }}
        >
            <Box
                className="LongLoadingOverlay-content"
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    padding: 1,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default FullHeightContent;
