import { FC } from 'react';
import { Polyline } from 'react-leaflet';
import { ICoordinates } from '../../schemas/interfaces';
import { feedersPrimary } from '../../theme';

const ObserverObservationLine: FC<{ observer: ICoordinates; observation: ICoordinates }> = ({
    observer,
    observation,
}) => (
    <>
        {/* <Polyline
            positions={[observer, observation]}
            pathOptions={{
                color: '#fff',
                weight: 3,
            }}
        /> */}
        <Polyline
            positions={[observer, observation]}
            pathOptions={{
                dashArray: '5, 5',
                dashOffset: '0',
                color: feedersPrimary[400],
                weight: 3,
            }}
        />
    </>
);

export default ObserverObservationLine;
