import { Badge } from '@mui/material';
import { parseISO } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useApi } from '../services/apiContext';
import { useLocation } from 'react-router-dom';

export const NEWS_LAST_VISIT_LS_KEY = 'avifNewsLastVisit';

const AppHeaderNews: FC<Record<string, never>> = () => {
    const [visible, setVisible] = useState(false);
    const [badge, setBadge] = useState<number>();
    const { getAllNewsArticles } = useApi();
    const location = useLocation();

    useEffect(() => {
        const lastVisit = window.localStorage.getItem(NEWS_LAST_VISIT_LS_KEY);

        // in case the user never visited the news app
        // let's consider the current visit as the first visit of news, so
        // next time a new badge will be shown
        if (!lastVisit || !parseISO(lastVisit)) {
            window.localStorage.setItem(NEWS_LAST_VISIT_LS_KEY, new Date().toISOString());
            return;
        }

        const lastVisitDate = parseISO(lastVisit);

        getAllNewsArticles()
            .then((news) => {
                const newNews = news.filter((n) => n.date > lastVisitDate);
                setBadge(newNews.length);
                setVisible(newNews.length > 0);
            })
            .catch((e) => {
                console.error(e);
            });
    }, [location.pathname]);

    return (
        <Badge
            badgeContent={badge}
            color="error"
            invisible={!visible}
            max={9}
            sx={{
                '& .MuiBadge-badge': {
                    right: '-8px',
                },
            }}
        >
            Novinky
        </Badge>
    );
};

export default AppHeaderNews;
