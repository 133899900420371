import { Menu as MenuIcon } from '@mui/icons-material';
import {
    AppBar,
    Container,
    Toolbar,
    Typography,
    Box,
    IconButton,
    Button,
    Drawer,
    ListItem,
    ListItemButton,
    List,
    ListItemText,
    useMediaQuery,
} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../services/authenticator';
import AppHeaderUser from './AppHeaderUser';
import AppLogo from './AppLogo';
import { useJoyride } from '../services/joyrideContext';
import MenuTour from './joyride/MenuTour';
import AppHeaderNews from './AppHeaderNews';

const AppHeader: FC = () => {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const menuAnchorRef = useRef<HTMLButtonElement>(null);
    const { isLoggedIn, actions } = useAuth();
    const { joyrideEvents } = useJoyride();
    const isMobile = useMediaQuery('(pointer: coarse)');

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            if (
                ['.tour-intro-3', '.tour-intro-4', '.tour-intro-5', '.tour-intro-6', '.tour-intro-7'].includes(
                    data.step.target as string,
                )
            )
                handleOpenNavMenu();

            if (['.tour-intro-1', '.tour-intro-2', '.tour-intro-8'].includes(data.step.target as string))
                handleCloseNavMenu();
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents, menuAnchorRef.current]);

    const pages: { title: string | JSX.Element; path: string; disabled?: boolean; className?: string }[] = [
        { title: <AppHeaderNews />, path: '/news' },
        { title: 'Vyhledávání', path: '/search', className: 'tour-menu-2' },
        { title: 'Vložit', path: '/create', disabled: !isLoggedIn, className: 'tour-menu-3' },
        { title: 'Vizualizace dat', path: '/charts' },
        { title: 'Nápověda', path: '/help' },
        { title: 'Projekty', path: '/projects' },
    ];

    const handleOpenNavMenu = () => {
        setAnchorElNav(menuAnchorRef.current);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <MenuTour />
            <AppBar className="AppHeader">
                <Container maxWidth="lg">
                    <Toolbar
                        disableGutters
                        sx={{
                            justifyContent: {
                                xs: 'space-between',
                                md: 'flex-start',
                            },
                        }}
                    >
                        {/* Mobile menu */}
                        <Box component="nav" sx={{ display: 'none' }}>
                            <Drawer
                                container={window !== undefined ? () => window.document.body : undefined}
                                variant="temporary"
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '70vw' },
                                    '& a': {
                                        color: 'primary.main',
                                        bgcolor: 'background.paper',
                                        boxShadow: 'none',
                                        webkitTapHighlightColor: 'transparent',
                                    },
                                    '& .MuiListItem-root': {
                                        bgcolor: 'background.paper',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '56px',
                                    }}
                                >
                                    <AppLogo inverted />
                                </Box>
                                <List
                                    sx={{
                                        '& a.active .MuiListItemButton-root': {
                                            bgcolor: 'action.selected',
                                            color: 'secondary.main',
                                        },
                                    }}
                                >
                                    {pages.map((page, index) => (
                                        <NavLink
                                            to={page.path}
                                            style={{
                                                textDecoration: 'none',
                                                pointerEvents: page.disabled ? 'none' : 'all',
                                            }}
                                            key={index}
                                            className={isMobile ? page.className : undefined}
                                        >
                                            <ListItem
                                                onClick={handleCloseNavMenu}
                                                key={index}
                                                disabled={page.disabled}
                                                color="primary"
                                            >
                                                <ListItemButton color="primary">
                                                    <ListItemText>
                                                        <Typography variant="body1" component="span" fontWeight="bold">
                                                            {page.title}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        </NavLink>
                                    ))}
                                </List>
                            </Drawer>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                ref={menuAnchorRef}
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>

                        {/* Logo */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }} className="tour-intro-1 tour-intro-2">
                            <AppLogo />
                        </Box>

                        {/* Desktop menu */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', md: 'flex' },
                                px: 3,
                                alignSelf: 'stretch',
                                '& a.active .MuiButtonBase-root:after': {
                                    display: 'block',
                                },
                            }}
                        >
                            {pages.map((page, index) => (
                                <NavLink
                                    to={page.path}
                                    style={{
                                        textDecoration: 'none',
                                        pointerEvents: page.disabled ? 'none' : 'all',
                                    }}
                                    key={index}
                                >
                                    <Button
                                        key={index}
                                        onClick={handleCloseNavMenu}
                                        disabled={page.disabled}
                                        className={!isMobile ? page.className : undefined}
                                        sx={{
                                            m: 0,
                                            height: '100%',
                                            color: 'white',
                                            display: 'block',
                                            borderRadius: 0,
                                            px: 2,
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '6px',
                                                bgcolor: 'secondary.main',
                                                display: 'none',
                                            },
                                            '&.Mui-disabled': {
                                                color: '#fff',
                                                opacity: 0.5,
                                                pointerEvents: 'none',
                                                cursor: 'default',
                                            },
                                        }}
                                    >
                                        {page.title}
                                    </Button>
                                </NavLink>
                            ))}
                        </Box>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }} className="tour-menu-1">
                            {isLoggedIn ? (
                                <>
                                    {/* <NotificationsCenter /> */}
                                    <AppHeaderUser />
                                </>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => actions?.openLogin && actions.openLogin()}
                                >
                                    Přihlásit se
                                </Button>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

export default AppHeader;
