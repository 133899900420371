import { FC, useState } from 'react';
import AvifJoyride from './AvifJoyride';
import { EVENTS, ACTIONS } from 'react-joyride';
import { useJoyride } from '../../services/joyrideContext';
import ExampleTour from './ExampleTour';

const IntroTour: FC<Record<never, unknown>> = () => {
    const { disableTour } = useJoyride();
    const [disableBeacon, setDisableBeacon] = useState<boolean>(true);
    const [step, setStep] = useState<number>(0);
    const [exampleTour, setExampleTour] = useState<boolean>(false);

    return (
        <AvifJoyride
            tourId="intro"
            continuous
            floaterProps={{
                styles: {
                    wrapper: {
                        zIndex: 1100,
                    },
                },
            }}
            stepIndex={step}
            steps={[
                {
                    target: '.tour-intro-1',
                    disableBeacon: disableBeacon,
                    placement: 'bottom',
                    title: 'Vítejte!',
                    showProgress: false,
                    content: (
                        <>
                            Vítejte v&nbsp;interaktivní nápovědě nové databáze Avif. Nápověda se vám zobrazí vždy, když
                            kdekoliv kliknete na&nbsp;tento symbol. Nápovědu můžete vypnout hned pro&nbsp;celou databázi
                            kliknutím na&nbsp;tlačítko "Vypnout nápovědu". Pokud byste nápovědu znovu potřebovali,
                            najdete ji v&nbsp;sekci "Nápověda" pod&nbsp;možností "Spustit interaktivní nápovědu".
                            {exampleTour && <ExampleTour />}
                        </>
                    ),
                },
                {
                    target: '.tour-intro-2',
                    placement: 'bottom',
                    content: '',
                },
            ]}
            locale={{
                skip: 'Vypnout nápovědu',
                next: <>Vstoupit do&nbsp;databáze</>,
            }}
            callback={(data) => {
                if (data.type === EVENTS.TOOLTIP) {
                    setExampleTour(true);
                } else if (data.action === ACTIONS.CLOSE) {
                    setDisableBeacon(false);
                    setStep(0);
                    setExampleTour(false);
                } else if (data.type === EVENTS.STEP_BEFORE && data.step.target === '.tour-intro-2' && disableTour) {
                    disableTour('intro');
                }
            }}
        />
    );
};

export default IntroTour;
