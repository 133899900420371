import { FC, useEffect, useMemo, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import FitBounds from '../../apps/ObsListsApp/components/tabs/FitBounds';
import { ICoordinates } from '../../schemas/interfaces';
import { feedersSecondary, feedersPrimary } from '../../theme';
import ObserverMarker from './ObserverMarker';
import ObserverObservationLine from './ObserverObservationLine';
import ObservationMarker from './ObservationMarker';
import { LeafletEventHandlerFnMap } from 'leaflet';

const ObservationWithObserver: FC<{
    observer?: ICoordinates;
    observation?: ICoordinates;
    taxonId?: number | null;
    observationId?: number;
    fitBounds?: boolean;
    observationPopup?: boolean;
    observerPopup?: boolean;
    observationLink?: boolean;
    squareCode?: string;
    enableHoverHighlight?: boolean;
    highlighted?: boolean;
    onClick?: () => void;
    onObservationMoved?: (coordinates: ICoordinates) => void;
}> = ({
    observer,
    observation,
    taxonId,
    observationId,
    fitBounds,
    observationPopup,
    observerPopup,
    observationLink,
    squareCode,
    enableHoverHighlight,
    highlighted,
    onClick,
    onObservationMoved,
}) => {
    const [observationCoordinates, setObservationCoordinates] = useState<ICoordinates | undefined>(observation);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        if (highlighted) setHovered(true);
        else setHovered(false);
    }, [highlighted]);

    useEffect(() => {
        setObservationCoordinates(observation);
    }, [observation]);

    const eventHandlers: LeafletEventHandlerFnMap = useMemo(
        () => ({
            click: () => {
                onClick?.();
            },
            mouseover: () => {
                enableHoverHighlight && !highlighted && setHovered(true);
            },
            mouseout: () => {
                enableHoverHighlight && !highlighted && setHovered(false);
            },
        }),
        [onClick, enableHoverHighlight, highlighted],
    );

    const handleObservationMoving = (coordinates: ICoordinates) => {
        setObservationCoordinates(coordinates);
    };

    if (!observation) return null;

    return (
        <>
            <FeatureGroup
                pathOptions={{ color: hovered ? feedersSecondary[400] : feedersPrimary[400] }}
                eventHandlers={eventHandlers}
            >
                {!!observationCoordinates && !!observer && (
                    <ObserverObservationLine observer={observer} observation={observationCoordinates} />
                )}
                {!!observationCoordinates && taxonId != undefined && (
                    <ObservationMarker
                        coordinates={observationCoordinates}
                        taxonId={taxonId}
                        popup={observationPopup}
                        squareCode={squareCode}
                        hovered={hovered}
                        linkTo={observationLink ? `/item/${observationId}` : undefined}
                        onMoved={highlighted ? onObservationMoved : undefined}
                        onMoving={highlighted ? handleObservationMoving : undefined}
                    />
                )}
                {!!observer && <ObserverMarker position={observer} popup={observerPopup} squareCode={squareCode} />}
            </FeatureGroup>
            {!!fitBounds && !!observationCoordinates && !!observer && (
                <FitBounds bounds={[observationCoordinates, observer]} />
            )}
        </>
    );
};

export default ObservationWithObserver;
