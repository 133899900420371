import { FunctionComponent, useEffect, useState } from 'react';
import { IMedia } from '../../schemas/interfaces';
import { AppBar, Container, Dialog, DialogContent, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, CloseRounded } from '@mui/icons-material';
import ZoomableImage from './ZoomableImage';
import AppLogo from '../AppLogo';

export interface LightboxProps {
    images: IMedia[];
    open?: boolean;
    onClose?: () => void;
    openOnImage?: number;
    hideCaptionIcons?: boolean;
    captions?: (JSX.Element | string)[];
}

const Lightbox: FunctionComponent<LightboxProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<number>(0);
    const isMobile = useMediaQuery('(pointer:coarse)');

    useEffect(() => {
        setOpen(props.open || false);
        setCurrentImage(props.openOnImage || 0);
    }, [props.open, props.openOnImage]);

    if (!open || props.images.length === 0) return null;

    const switchImage = (change: number) => {
        const newImage = currentImage + change;

        if (newImage < 0 || newImage >= props.images.length) return;

        setCurrentImage(newImage);
    };
    const nextImage = () => {
        switchImage(1);
    };
    const prevImage = () => {
        switchImage(-1);
    };

    const closeLightbox = () => {
        setOpen(false);
        if (props.onClose) props.onClose();
    };

    const image = props.images[currentImage];

    return (
        <Dialog
            open={open}
            onClose={closeLightbox}
            maxWidth={false}
            onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') prevImage();
                if (e.key === 'ArrowRight') nextImage();
            }}
            sx={{
                '& .MuiDialog-paper': {
                    background: 'transparent',
                    boxShadow: 'none',
                    maxWidth: { xs: 'calc(100% - 20px)' },
                    mx: { xs: '10px' },
                },
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.9)',
                },
                '& .MuiIconButton-root': {
                    color: '#fff',
                    '&.Mui-disabled': {
                        opacity: 0.1,
                    },
                },
                '& .MuiDialog-container': {
                    alignItems: 'flex-end',
                },
            }}
        >
            <AppBar className="AppHeader" sx={{ py: 1, px: 2, paddingRight: '16px !important' }}>
                <Container maxWidth={false} sx={{ p: 0 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={6} md={3}>
                            <AppLogo />
                        </Grid>
                        <Grid xs={12} md={6} order={isMobile ? 1 : undefined}>
                            <Typography variant="body1" textAlign="center">
                                {props.captions && props.captions[currentImage]}
                            </Typography>
                        </Grid>
                        <Grid xs={6} md={3} sx={{ textAlign: 'right' }}>
                            <IconButton onClick={() => prevImage()} size="medium" disabled={currentImage == 0}>
                                <ArrowBackIos fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                onClick={() => nextImage()}
                                size="medium"
                                disabled={currentImage == props.images.length - 1}
                            >
                                <ArrowForwardIos fontSize="inherit" />
                            </IconButton>
                            <IconButton size="medium" onClick={closeLightbox} className="close">
                                <CloseRounded fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Container>
            </AppBar>
            <DialogContent
                sx={{
                    padding: 0,
                    position: 'relative',
                }}
            >
                {open && <ZoomableImage url={image.url} altText={image.note} onBoxClick={() => closeLightbox()} />}
            </DialogContent>
        </Dialog>
    );
};

export default Lightbox;
