import { Box, Chip, ChipProps, Tooltip, useMediaQuery } from '@mui/material';
import { FC, Fragment } from 'react';
import { SupportedTaxonLanguages, getLanguageName, useTaxonLanguage } from '../../services/taxonLanguageContext';
import TaxonLanguageSwitcherMobile from './TaxonLanguageSwitcherMobile';

const TaxonLanguageSwitcher: FC<{ chipProps?: Partial<ChipProps> }> = ({ chipProps }) => {
    const { currentLanguage, setLanguage } = useTaxonLanguage();
    const isMobile = useMediaQuery('(pointer: coarse) and (max-width: 500px)');

    if (isMobile) return <TaxonLanguageSwitcherMobile />;

    return (
        <Chip
            variant="filled"
            size="small"
            clickable={false}
            {...chipProps}
            sx={{
                ml: 2,
                fontSize: '80%',
                cursor: 'pointer',
                '& .MuiChip-label': {
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '100%',
                    pl: 1.5,
                },
                ...chipProps?.sx,
            }}
            label={
                <>
                    {SupportedTaxonLanguages.map((lang, index) => (
                        <Fragment key={lang}>
                            <Tooltip title={`Přepnout názvy druhů do\xa0${getLanguageName(lang)}`}>
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        cursor: lang === currentLanguage ? 'default' : 'pointer',
                                        fontWeight: lang === currentLanguage ? 'bold' : 'normal',
                                        '&:hover': {
                                            textDecoration: lang === currentLanguage ? 'none' : 'underline',
                                        },
                                        px: 0.5,
                                        mx: 0.5,
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setLanguage?.(lang);
                                    }}
                                >
                                    {lang.toUpperCase()}
                                </Box>
                            </Tooltip>
                            {index < SupportedTaxonLanguages.length - 1 && (
                                <Box sx={{ display: 'inline-block', fontSize: '70%' }}>|</Box>
                            )}
                        </Fragment>
                    ))}
                </>
            }
        />
    );
};

export default TaxonLanguageSwitcher;
