import { Container, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import ImportFileList from './components/ImportFileList';
import { useAuth } from '../../services/authenticator';
import { Navigate } from 'react-router-dom';

const ImportApp: FC<Record<string, never>> = () => {
    const { isLoggedIn } = useAuth();

    if (isLoggedIn === false) return <Navigate to="/" />;

    return (
        <Container
            className="ImportApp"
            sx={{
                pt: 5.75,
                pb: 6.75,
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h1" sx={{ pb: 1.75 }}>
                        Import pozorování
                    </Typography>
                    <Typography variant="body1" component="p" sx={{ pb: 3 }}>
                        Importujte svá pozorování z jiných aplikací nebo záznamů. Stačí nahrát soubor ve formátu CSV
                        nebo JSON a my se postaráme o zbytek.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ImportFileList />
                </Grid>
            </Grid>
        </Container>
    );
};

export default ImportApp;
