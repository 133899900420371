import { Alert, Button, Container, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import FullScreenImageBackground from '../components/FullScreenImageBackground';
import Loading from '../components/Loading';
import { useApi } from '../services/apiContext';
import { LoadingButton } from '@mui/lab';

interface ResetPasswordAppState {
    validToken?: boolean;
    loading?: boolean;
    success?: boolean;
}

const ResetPasswordApp: FunctionComponent = () => {
    const [state, setState] = useState<ResetPasswordAppState>({});
    const token = useParams<{ token: string }>().token;
    const api = useApi();

    useEffect(() => {
        if (!token) return setState({ validToken: false });

        api._mock_validatePasswordToken(token).then((validToken) => setState({ validToken }));
    }, []);

    const onSubmit = () => {
        setState({ ...state, loading: true });

        if (!token) return setState({ ...state, loading: false, validToken: false });

        api._mock_resetPassword(token, 'heslo').then((response) => {
            setState({ ...state, loading: false, success: response });
        });
    };

    return (
        <FullScreenImageBackground imageUrl="https://picsum.photos/1920/1080">
            <Container
                maxWidth="sm"
                sx={{
                    bgcolor: 'background.paper',
                    padding: '2rem',
                    borderRadius: 1,
                    opacity: 0.9,
                }}
                className="ForgottenPasswordApp"
            >
                <Grid container spacing={2}>
                    {state.validToken === undefined && (
                        <Grid item xs={12}>
                            <Loading fullPage />
                        </Grid>
                    )}
                    {state.validToken === false && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h1">
                                    Neplatný odkaz
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Platnost tohoto odkazu pro obnovení hesla již vypršela. Požádejte o obnovu hesla
                                    znovu.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button component={RouterLink} to="/forgotten-password" variant="contained">
                                    Obnovit heslo
                                </Button>
                            </Grid>
                        </>
                    )}
                    {state.validToken === true && state.success !== true && (
                        <>
                            <Grid item xs={12} sx={{ pb: 3 }}>
                                <Typography variant="h4" component="h1">
                                    Obnovte svoje heslo
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Vyplňte dvakrát svoje nové heslo.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Váš e-mail"
                                    id="email"
                                    type="email"
                                    fullWidth
                                    value={'uzivatel@birds.cz'}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Vaše uživatelské jméno"
                                    id="username"
                                    fullWidth
                                    value={'example'}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Nové heslo" id="password" type="password" fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Zopakujte heslo"
                                    id="repeat-password"
                                    type="password"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmit}
                                    loading={state.loading}
                                >
                                    Změnit heslo
                                </LoadingButton>
                            </Grid>
                        </>
                    )}
                    {state.success === true && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h1">
                                    Heslo bylo úspěšně změněno
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Nyní se můžete přihlásit pomocí nového hesla.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button component={RouterLink} to="/login" variant="contained">
                                    Přihlásit se
                                </Button>
                            </Grid>
                        </>
                    )}
                    {state.success === false && (
                        <Snackbar
                            open={state.success === false}
                            autoHideDuration={6000}
                            onClose={(event, reason) => {
                                if (reason === 'clickaway') return;

                                setState({ ...state, success: undefined });
                            }}
                        >
                            <Alert severity="error">Nepodařilo se změnit heslo. Zkuste to prosím znovu později.</Alert>
                        </Snackbar>
                    )}
                </Grid>
            </Container>
        </FullScreenImageBackground>
    );
};

export default ResetPasswordApp;
