import { FC } from 'react';
import { RarityLevel, ERarityLevel } from '../../schemas/enums';
import { StarRounded, DiamondRounded, PushPinRounded } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { TooltipMobile } from '../Tooltip';

const ItemRarityIcon: FC<{
    item: {
        rarity: ERarityLevel;
    };
}> = ({ item }) => {
    return (
        <Box
            sx={{
                color: 'action.active',
                fontSize: '1.15rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {item.rarity == RarityLevel.remarkable && (
                <TooltipMobile title="Zajímavé pozorování" placement="bottom">
                    <PushPinRounded fontSize="inherit" />
                </TooltipMobile>
            )}
            {item.rarity == RarityLevel.uncommon && (
                <TooltipMobile title="Neobvyklé pozorování" placement="bottom">
                    <StarRounded fontSize="inherit" />
                </TooltipMobile>
            )}
            {item.rarity == RarityLevel.rare && (
                <TooltipMobile title="Vzácné pozorování" placement="bottom">
                    <DiamondRounded fontSize="inherit" />
                </TooltipMobile>
            )}
        </Box>
    );
};

export default ItemRarityIcon;
