import { CopyAllRounded, DownloadRounded, ExitToAppRounded } from '@mui/icons-material';
import {
    Button,
    Grid,
    TextField,
    Box,
    Alert,
    Snackbar,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormControl,
    FormHelperText,
    AlertTitle,
    Tooltip,
    Typography,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { FC, useEffect, useState } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import PoppedControl from '../../../components/formControls/PoppedControl';
import { IExport, IObservationFilter, schema_export_form } from '../../../schemas/interfaces';
import { useApi } from '../../../services/apiContext';
import Loading from '../../../components/Loading';
import { useAuth } from '../../../services/authenticator';
import translateErrorMessage from '../../../services/errorMessages';
import { EExportMode } from '../../../schemas/enums';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const formInitialValues: IExport & { consent: boolean } = {
    email: '',
    format: 'csv',
    purpose: '' as any,
    purposeDescription: '' as any,
    consent: false,
};

const Export: FC<{ mode: EExportMode; filter: IObservationFilter }> = (props) => {
    const { mode, filter } = props;
    const [state, setState] = useState<{ success?: boolean; loading: boolean; url?: string; tooltipOpen: boolean }>({
        success: undefined,
        loading: false,
        url: undefined,
        tooltipOpen: false,
    });
    const api = useApi();
    const auth = useAuth();

    useEffect(() => {
        setState({ success: undefined, loading: false, url: undefined, tooltipOpen: false });
    }, [props]);

    const handleFormSubmit = (values: IExport) => {
        setState({ ...state, loading: true });

        api.getExport(
            {
                ...values,
                filter,
                language: 'cs',
            },
            mode,
        )
            .then((response) => {
                setState({ ...state, success: response.success, loading: false, url: response.url });
            })
            .catch((err) => {
                console.error(err);
                setState({ ...state, success: false, loading: false });
            });
    };

    return (
        <Box className="Export">
            <PoppedControl
                renderIcon={() => (
                    <Button variant="text">
                        <ExitToAppRounded />
                        &nbsp;Export
                    </Button>
                )}
                renderControl={(handleClose) => (
                    <>
                        {state.url === undefined && (
                            <>
                                <Typography variant="body1" component="p">
                                    ČSO umožňuje neomezený přístup k&nbsp;datům (s&nbsp;výjimkou dat utajených
                                    uživatelem). Pro&nbsp;účely evidence vyplňte prosím následující údaje:
                                </Typography>
                                <Formik<IExport & { consent: boolean }>
                                    onSubmit={(values) => handleFormSubmit(values)}
                                    initialValues={{
                                        ...formInitialValues,
                                        email: auth.identity?.email || '',
                                    }}
                                    validationSchema={toFormikValidationSchema(schema_export_form)}
                                >
                                    {({ values, handleBlur, handleChange, touched, errors }) => {
                                        return (
                                            <Form noValidate>
                                                <Grid container spacing={1} sx={{ pt: 1.75 }}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            maxRows={1}
                                                            value={values.email}
                                                            label="E-mail"
                                                            name="email"
                                                            type="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!errors.email && touched.email}
                                                            helperText={
                                                                !!touched.email &&
                                                                !!errors.email &&
                                                                translateErrorMessage(errors.email)
                                                            }
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="purpose"
                                                            label="Účel exportu"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.purpose}
                                                            error={!!errors.purpose && touched.purpose}
                                                            helperText={
                                                                !!touched.purpose &&
                                                                !!errors.purpose &&
                                                                translateErrorMessage(errors.purpose)
                                                            }
                                                            fullWidth
                                                            select
                                                            required
                                                        >
                                                            {[
                                                                'Vyhodnocení dat, odborný článek (nejsem profesionální ornitolog)',
                                                                'Vyhodnocení dat, odborný článek (jsem profesionální ornitolog)',
                                                                'Zařazení do\xa0jiné databáze',
                                                                'Osobní použití, archivace',
                                                                'Komerční studie',
                                                                'Jiné',
                                                            ].map((purpose) => (
                                                                <MenuItem key={purpose} value={purpose}>
                                                                    {purpose}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            multiline
                                                            minRows={3}
                                                            maxRows={6}
                                                            value={values.purposeDescription}
                                                            name="purposeDescription"
                                                            label="Popis účelu exportu"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={
                                                                !!errors.purposeDescription &&
                                                                touched.purposeDescription
                                                            }
                                                            helperText={
                                                                !!touched.purposeDescription &&
                                                                !!errors.purposeDescription &&
                                                                translateErrorMessage(errors.purposeDescription)
                                                            }
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="format"
                                                            label="Formát souboru exportu"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.format}
                                                            error={!!errors.format && touched.format}
                                                            helperText={
                                                                !!touched.format &&
                                                                !!errors.format &&
                                                                translateErrorMessage(errors.format)
                                                            }
                                                            fullWidth
                                                            select
                                                            required
                                                        >
                                                            <MenuItem key="csv" value="csv">
                                                                csv
                                                            </MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl error={!!errors.consent && touched.consent}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="consent"
                                                                        value={values.consent}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        required
                                                                    />
                                                                }
                                                                label="Prohlašuji, že budu s&nbsp;daty nakládat v&nbsp;souladu s&nbsp;pravidly projektu a že budu dodržovat zásady ochrany osobních údajů.*"
                                                            />
                                                            {!!errors.consent && touched.consent && (
                                                                <FormHelperText>
                                                                    {translateErrorMessage(errors.consent)}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'flex-end',
                                                            gap: '1rem',
                                                        }}
                                                    >
                                                        <Button onClick={() => handleClose()}>Zrušit</Button>
                                                        <LoadingButton
                                                            variant={state.loading ? 'outlined' : 'contained'}
                                                            type="submit"
                                                            color="secondary"
                                                            loading={state.loading}
                                                        >
                                                            Export
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </>
                        )}
                        {state.url !== undefined && (
                            <>
                                <Alert severity="success">
                                    <AlertTitle>Export úspěšný!</AlertTitle>
                                    Exportovaná data jsou připravena na&nbsp;následujícím odkazu. Odkaz je platný 24
                                    hodin.
                                    <TextField
                                        fullWidth
                                        defaultValue={state.url}
                                        label="Odkaz ke&nbsp;stažení exportu"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        '& .MuiButton-root': {
                                                            minWidth: 'auto',
                                                        },
                                                    }}
                                                >
                                                    <Tooltip
                                                        title="Zkopírovat odkaz"
                                                        placement="top"
                                                        open={state.tooltipOpen ? false : undefined}
                                                    >
                                                        <Box>
                                                            <Tooltip
                                                                title="Zkopírováno do&nbsp;schránky!"
                                                                placement="top"
                                                                onClose={() =>
                                                                    setState((state) => ({
                                                                        ...state,
                                                                        tooltipOpen: false,
                                                                    }))
                                                                }
                                                                open={state.tooltipOpen}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                            >
                                                                <IconButton
                                                                    sx={{ ml: 1 }}
                                                                    onClick={() => {
                                                                        if (
                                                                            !navigator ||
                                                                            !navigator.clipboard ||
                                                                            !state.url
                                                                        )
                                                                            return;

                                                                        navigator.clipboard.writeText(state.url);
                                                                        setState((state) => ({
                                                                            ...state,
                                                                            tooltipOpen: true,
                                                                        }));
                                                                        setTimeout(() => {
                                                                            setState((state) => ({
                                                                                ...state,
                                                                                tooltipOpen: false,
                                                                            }));
                                                                        }, 2000);
                                                                    }}
                                                                >
                                                                    <CopyAllRounded />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip title="Stáhnout soubor" placement="top">
                                                        <IconButton
                                                            sx={{ ml: 1 }}
                                                            href={state.url}
                                                            download
                                                            target="_blank"
                                                        >
                                                            <DownloadRounded />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ mt: '1rem' }}
                                    />
                                    <Box sx={{ pt: 1 }}>
                                        Vysvětlení všech hodnot najdete v&nbsp;
                                        <Link
                                            to="https://avif.cso.cz/files/export-napoveda-help.pdf"
                                            target="_blank"
                                            style={{ color: 'inherit' }}
                                        >
                                            dokumentaci
                                        </Link>
                                        .
                                    </Box>
                                </Alert>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-end',
                                        gap: '1rem',
                                        pt: 2,
                                    }}
                                >
                                    <Button onClick={() => handleClose()} variant="contained">
                                        Zavřít
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </>
                )}
                disableButtons
                title={`Export dat - výpis ${mode === 'items' ? 'pozorování' : 'vycházek'}`}
            />
            {state.success === false && (
                <Snackbar
                    open={state.success === false}
                    autoHideDuration={6000}
                    onClose={(_, reason) => {
                        if (reason === 'clickaway') return;
                        setState({ ...state, success: undefined });
                    }}
                >
                    <Alert severity="error">
                        Chyba při&nbsp;vytváření exportu. Zkuste to prosím znovu, případně kontaktuje správce.
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default Export;
