import { Button, Container, Alert, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import FullScreenImageBackground from '../components/FullScreenImageBackground';
import Loading from '../components/Loading';
import { useApi } from '../services/apiContext';
import { LoadingButton } from '@mui/lab';

interface ForgottenPasswordAppState {
    loading: boolean;
    success?: boolean;
}

const ForgottenPasswordApp: FunctionComponent = () => {
    const [state, setState] = useState<ForgottenPasswordAppState>({ loading: false });
    const api = useApi();

    const onSubmit = () => {
        setState({ ...state, loading: true });

        api._mock_requestPasswordReset('email').then((response) => {
            setState({ ...state, loading: false, success: response });
        });
    };

    return (
        <FullScreenImageBackground imageUrl="https://picsum.photos/1920/1080">
            <Container
                maxWidth="sm"
                sx={{ bgcolor: 'background.paper', padding: '2rem', borderRadius: 1, opacity: 0.9 }}
                className="ForgottenPasswordApp"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ pb: 3 }}>
                        <Typography variant="h4" component="h1">
                            Zapomněli jste heslo nebo přihlašovací jméno?
                        </Typography>
                        <Typography variant="body1" component="p">
                            Zadejte svůj email a my vám pošleme vaše přihlašovací jméno včetně instrukcí pro obnovení
                            hesla.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Email" id="email" fullWidth required />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <LoadingButton variant="contained" color="primary" onClick={onSubmit} loading={state.loading}>
                            Odeslat
                        </LoadingButton>
                    </Grid>
                </Grid>
                {state.success === true && (
                    <Snackbar
                        open={state.success === true}
                        autoHideDuration={6000}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') return;
                            setState({ ...state, success: undefined });
                        }}
                    >
                        <Alert severity="success">Na&nbsp;váš email byl odeslán odkaz pro obnovení hesla.</Alert>
                    </Snackbar>
                )}
                {state.success === false && (
                    <Snackbar
                        open={state.success === false}
                        autoHideDuration={6000}
                        onClose={(event, reason) => {
                            if (reason === 'clickaway') return;
                            setState({ ...state, success: undefined });
                        }}
                    >
                        <Alert severity="error">
                            Odkaz na&nbsp;obnovení hesla se nepodařilo odeslat. Zkontrolujte správnost e-mailové adresy.
                        </Alert>
                    </Snackbar>
                )}
            </Container>
        </FullScreenImageBackground>
    );
};

export default ForgottenPasswordApp;
