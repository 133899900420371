import { Navigate, createBrowserRouter } from 'react-router-dom';
import ForgottenPasswordApp from '../apps/ForgottenPasswordApp';
import HomeApp from '../apps/HomeApp';
import ObservationFormApp from '../apps/ObservationFormApp/ObservationFormApp';
import { ObsItemViewApp } from '../apps/ObsItemViewApp/ObsItemViewApp';
import { ListViewApp } from '../apps/ListViewApp/ListViewApp';
import { ObsListsApp } from '../apps/ObsListsApp/ObsListsApp';
import { ProfileApp } from '../apps/ProfileApp/ProfileApp';
import RegisterApp from '../apps/RegisterApp/RegisterApp';
import ResetPasswordApp from '../apps/ResetPasswordApp';
import NewsApp from '../apps/NewsApp/NewsApp';
import NewsViewApp from '../apps/NewsViewApp/NewsViewApp';
import HelpApp from '../apps/HelpApp/HelpApp';
import AboutApp from '../apps/AboutApp/AboutApp';
import ProjectsApp from '../apps/ProjectsApp/ProjectsApp';
import { LogoutApp } from '../apps/LogoutApp/LogoutApp';
import AppContent from '../components/AppContent';
import StatisticsApp from '../apps/StatisticsApp/StatisticsApp';
import { TabIndex } from '../apps/ObsListsApp/components/ObsListsResults';
import NotFound from '../components/NotFound';
import { ChartApp } from '../apps/ChartApp/ChartApp';
import { Box } from '@mui/material';
import { FullwidthProvider } from '../apps/ObservationFormApp/components/FullwidthContext';
import ImportApp from '../apps/ImportApp/ImportApp';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppContent />,
        children: [
            {
                path: '/',
                element: <Navigate to="/search" replace />,
            },
            {
                path: '/login',
                element: <HomeApp login />,
            },
            {
                path: '/logout',
                element: <LogoutApp />,
            },
            {
                path: '/register',
                element: <RegisterApp />,
            },
            {
                path: '/forgotten-password',
                element: <ForgottenPasswordApp />,
            },
            {
                path: '/reset-password/:token?',
                element: <ResetPasswordApp />,
            },
            {
                path: '/search',
                element: <Navigate to={`/search/${TabIndex.Items}`} replace />,
            },
            {
                path: `/search/${TabIndex.Items}`,
                element: <ObsListsApp tab={TabIndex.Items} />,
            },
            {
                path: `/search/${TabIndex.Lists}`,
                element: <ObsListsApp tab={TabIndex.Lists} />,
            },
            {
                path: `/search/${TabIndex.Gallery}`,
                element: <ObsListsApp tab={TabIndex.Gallery} />,
            },
            {
                path: `/search/${TabIndex.Map}`,
                element: <ObsListsApp tab={TabIndex.Map} />,
            },
            {
                path: `/iframe/items`,
                element: <ObsListsApp tab={TabIndex.Items} fullScreen />,
            },
            {
                path: '/list/:id',
                element: <ListViewApp />,
            },
            {
                path: '/list/:id/edit',
                element: (
                    <Box>
                        <FullwidthProvider>
                            <ObservationFormApp new={false} />
                        </FullwidthProvider>
                    </Box>
                ),
            },
            {
                path: '/create/:projectSlugs?',
                element: (
                    <FullwidthProvider>
                        <ObservationFormApp new={true} />
                    </FullwidthProvider>
                ),
            },
            {
                path: '/item/:id',
                element: <ObsItemViewApp />,
            },
            {
                path: '/item/:id/edit',
                element: (
                    <Box>
                        <FullwidthProvider>
                            <ObservationFormApp new={false} item />
                        </FullwidthProvider>
                    </Box>
                ),
            },
            {
                path: '/import',
                element: <ImportApp />,
            },
            {
                path: '/profile/:id',
                element: <ProfileApp />,
            },
            {
                path: '/profile',
                element: <ProfileApp />,
            },
            {
                path: '/news',
                element: <NewsApp />,
            },
            {
                path: '/news/:language/:id',
                element: <NewsViewApp />,
            },
            {
                path: '/help',
                element: <HelpApp />,
            },
            {
                path: '/about',
                element: <AboutApp />,
            },
            {
                path: '/projects',
                element: <ProjectsApp />,
            },
            {
                path: '/charts',
                element: <ChartApp />,
            },
            {
                path: '*',
                element: <NotFound text="Zadaná stránka nebyla nalezena." className="pageNotFound" reportText />,
            },
        ],
    },
]);

export default router;
