import { FormikProps } from 'formik';
import { FC, useState } from 'react';
import {
    Alert,
    Button,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Snackbar,
    TextField,
    Tooltip,
} from '@mui/material';
import { AddAPhotoRounded, Delete, PhotoCameraRounded, UploadFile } from '@mui/icons-material';
import PoppedControl from '../../../../components/formControls/PoppedControl';
import { useApi } from '../../../../services/apiContext';
import Loading from '../../../../components/Loading';
import { ObservationItemFormValues } from '../../ObservationFormApp';

const MediaUpload: FC<{ formikProps: FormikProps<ObservationItemFormValues>; formId: string }> = (props) => {
    const formId = props.formId;
    const { values, setFieldValue, handleBlur } = props.formikProps;
    const [uploading, setUploading] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<string>();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [note, setNote] = useState<string>();
    const [filesError, setFilesError] = useState<boolean>(false);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>();
    const [deleteSuccess, setDeleteSuccess] = useState<boolean>();

    const api = useApi();

    const clearTempValues = () => {
        setUploading(false);
        setSelectedFiles([]);
        setNote(undefined);
    };

    return (
        <>
            <PoppedControl
                renderIcon={() => (
                    <Tooltip title="Přidat fotografie">
                        <IconButton
                            aria-label="Vložit fotografii"
                            color={values.media && values.media.length ? 'secondary' : undefined}
                        >
                            {values.media && values.media.length ? (
                                <PhotoCameraRounded />
                            ) : (
                                <AddAPhotoRounded sx={{ transform: 'scale(-1,1)' }} />
                            )}
                        </IconButton>
                    </Tooltip>
                )}
                renderControl={() => (
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                        {values._Media !== undefined && values._Media.length > 0 && (
                            <Grid item xs={12}>
                                <ImageList cols={3}>
                                    {values._Media.map((media) => (
                                        <ImageListItem key={media.id}>
                                            <img src={media.url} />
                                            <Tooltip title={media.note || undefined}>
                                                {/* The empty element here is to surpress a warning from MUI that a child of a Tooltip has a title attribute. */}
                                                <>
                                                    <ImageListItemBar
                                                        actionIcon={
                                                            deleting === media.id ? (
                                                                <Loading
                                                                    inline
                                                                    progressProps={{ sx: { color: 'white' } }}
                                                                />
                                                            ) : (
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setDeleting(media.id);
                                                                        api.deleteMedia(media.id)
                                                                            .then(() => {
                                                                                setFieldValue(
                                                                                    '_Media',
                                                                                    values._Media?.filter(
                                                                                        (x) => x.id !== media.id,
                                                                                    ),
                                                                                    false,
                                                                                );
                                                                                setFieldValue(
                                                                                    'media',
                                                                                    values.media?.filter(
                                                                                        (x) => x !== media.id,
                                                                                    ),
                                                                                );
                                                                                setDeleting(undefined);
                                                                                setDeleteSuccess(true);
                                                                            })
                                                                            .catch(() => {
                                                                                setDeleting(undefined);
                                                                                setDeleteSuccess(false);
                                                                            });
                                                                    }}
                                                                    sx={{ color: 'white' }}
                                                                >
                                                                    <Delete color="inherit" />
                                                                </IconButton>
                                                            )
                                                        }
                                                        title={media.note || ''}
                                                    />
                                                </>
                                            </Tooltip>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Grid>
                        )}
                        {values.media && values.media.length > 0 && values.media.length < 3 && (
                            <Grid item xs={12}>
                                <Divider sx={{ mb: 2 }} />
                            </Grid>
                        )}
                        {values.media && values.media.length < 3 && (
                            <>
                                <Grid item xs={12}>
                                    <Button
                                        variant={selectedFiles.length ? 'contained' : 'outlined'}
                                        component="label"
                                        endIcon={<UploadFile />}
                                        fullWidth
                                    >
                                        {!selectedFiles.length && 'Vyberte soubor'}
                                        {!!selectedFiles.length && selectedFiles.map((f) => f.name).join(', ')}
                                        <input
                                            hidden
                                            accept="image/jpeg, image/jpg"
                                            type="file"
                                            name="selectedFile"
                                            value={''}
                                            onChange={(event) => {
                                                if (!event.target.files) return;

                                                if (event.target.files.length + (values.media?.length || 0) > 3)
                                                    return setFilesError(true);

                                                const fileList = event.target.files;
                                                let fileArray: File[] = [];

                                                for (let i = 0; i < fileList.length; i++) {
                                                    const file = fileList.item(i);
                                                    if (!file) continue;
                                                    fileArray = [...fileArray, file];
                                                }

                                                setSelectedFiles(fileArray || []);
                                            }}
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="note"
                                        value={note || ''}
                                        onChange={(e) => setNote(e.target.value || undefined)}
                                        onBlur={handleBlur}
                                        label="Popis fotografie"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        component="label"
                                        endIcon={
                                            !uploading ? (
                                                <UploadFile />
                                            ) : (
                                                <Loading inline progressProps={{ sx: { color: 'white' } }} />
                                            )
                                        }
                                        disabled={!selectedFiles.length}
                                        fullWidth
                                        onClick={() => {
                                            if (!selectedFiles.length) {
                                                console.error('no file to upload');
                                                return;
                                            }
                                            setUploading(true);
                                            api.uploadMedia(selectedFiles[0], note || '')
                                                .then((res: any) => {
                                                    setFieldValue(
                                                        '_Media',
                                                        [...(values._Media || []), res.data],
                                                        false,
                                                    );
                                                    setFieldValue('media', [...(values.media || []), res.data.id]);
                                                    setUploadSuccess(true);
                                                    clearTempValues();
                                                })
                                                .catch(() => {
                                                    setUploadSuccess(false);
                                                    setUploading(false);
                                                });
                                        }}
                                    >
                                        Nahrát soubor
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
                title="Fotografie pozorování"
                description="Ke&nbsp;každému pozorování můžete přidat až 3 fotografie ve&nbsp;formátu JPEG. Vyberte soubor, který chcete nahrát, přidejte k&nbsp;němu popis a nahrajte jej."
                confirmButtonLabel="Uložit"
                confirmButtonDisabled={!!selectedFiles.length}
                cancelButtonLabel="Zrušit"
                disableClickaway
                onConfirmed={clearTempValues}
                onCanceled={clearTempValues}
            />
            {filesError && (
                <Snackbar
                    open
                    autoHideDuration={6000}
                    onClose={(_, reason) => {
                        reason !== 'clickaway' && setFilesError(false);
                    }}
                >
                    <Alert severity="error">
                        Výběr souborů selhal. K&nbsp;jednomu pozorování můžete nahrát maximálně 3 fotografie.
                    </Alert>
                </Snackbar>
            )}
            {uploadSuccess !== undefined && (
                <Snackbar
                    open
                    autoHideDuration={6000}
                    onClose={(_, reason) => {
                        reason !== 'clickaway' && setUploadSuccess(undefined);
                    }}
                >
                    <Alert severity={uploadSuccess ? 'success' : 'error'}>
                        {uploadSuccess === true && 'Fotografie byla v\xa0pořádku nahrána.'}
                        {uploadSuccess === false && 'Nahrávání selhalo. Zkuste to prosím znovu.'}
                    </Alert>
                </Snackbar>
            )}
            {deleteSuccess !== undefined && (
                <Snackbar
                    open
                    autoHideDuration={6000}
                    onClose={(_, reason) => {
                        reason !== 'clickaway' && setDeleteSuccess(undefined);
                    }}
                >
                    <Alert severity={deleteSuccess ? 'success' : 'error'}>
                        {deleteSuccess === true && 'Fotografie byla v\xa0pořádku smazána.'}
                        {deleteSuccess === false && 'Smazání fotografie selhalo. Zkuste to prosím znovu.'}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default MediaUpload;
