import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    Alert,
    Snackbar,
    SnackbarProps,
    Link,
    Typography,
    Box,
    Grid,
} from '@mui/material';
import { FC, useState } from 'react';
import { useAuth } from '../../services/authenticator';
import { Formik, Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import translateErrorMessage from '../../services/errorMessages';

interface LoginAppProps {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    onFail?: () => void;
}

interface IFormValues {
    username: string;
    password: string;
}

const defaultValues: IFormValues = {
    username: '',
    password: '',
};

const loginSchema = z.object({
    username: z.string({ required_error: 'errors.login.username.empty' }),
    password: z.string({ required_error: 'errors.login.password.empty' }),
});

const LoginApp: FC<LoginAppProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState<boolean>();
    const { actions } = useAuth();

    const handleClose = () => {
        if (props.onClose) props.onClose();
    };

    const handleLogin = (values: IFormValues) => {
        setLoading(true);

        if (!actions?.login) {
            console.log('login not available');
            setSuccess(false);
            setLoading(false);
            return;
        }

        return actions
            .login(values.username, values.password)
            .then((result) => {
                console.log(result);
                setSuccess(true);
                setLoading(false);
                props.onSuccess && props.onSuccess();
            })
            .catch((error) => {
                console.log(error);
                setSuccess(false);
                setLoading(false);
                props.onFail && props.onFail();
            });
    };

    const onSnackbarClose: SnackbarProps['onClose'] = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccess(undefined);
    };

    return (
        <>
            <Formik<IFormValues>
                initialValues={defaultValues}
                onSubmit={handleLogin}
                validationSchema={toFormikValidationSchema(loginSchema)}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Dialog open={props.open} onClose={props.onClose} className="LoginApp">
                        <Form onSubmit={handleSubmit} id="loginForm" noValidate>
                            <DialogTitle>Přihlásit se</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1.75}>
                                    <Grid item xs={12}>
                                        <DialogContentText>
                                            Přihlašte se ke&nbsp;svému účtu pomocí vašeho uživatelského jména a hesla.
                                        </DialogContentText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="username"
                                            name="username"
                                            value={values.username}
                                            label="Uživatelské jméno"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.username && !!touched.username}
                                            helperText={
                                                !!touched.username &&
                                                !!errors.username &&
                                                translateErrorMessage(errors.username)
                                            }
                                            required
                                            fullWidth
                                            inputProps={{ form: 'loginForm' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="password"
                                            name="password"
                                            value={values.password}
                                            label="Heslo"
                                            type="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.password && !!touched.password}
                                            helperText={
                                                !!touched.password &&
                                                !!errors.password &&
                                                translateErrorMessage(errors.password)
                                            }
                                            required
                                            fullWidth
                                            inputProps={{ form: 'loginForm' }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions sx={{ p: 3, pt: 0 }}>
                                <Typography variant="caption" component="div" sx={{ marginRight: 'auto' }}>
                                    <Link href="https://birds.cz/registrace" color="inherit">
                                        Zaregistrovat se
                                    </Link>
                                    <br />
                                    <Link href="https://birds.cz/registrace/ztracene_heslo" color="inherit">
                                        Zapomenuté přihlašovací údaje
                                    </Link>
                                </Typography>
                                <Button onClick={() => handleClose()}>Zrušit</Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    // onClick={() => handleSubmit()}
                                    disabled={isSubmitting}
                                    loading={loading}
                                    color="secondary"
                                >
                                    Přihlásit se
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    </Dialog>
                )}
            </Formik>

            {success === true && (
                <Snackbar open autoHideDuration={6000} onClose={onSnackbarClose}>
                    <Alert severity="success">Přihlášení proběhlo úspěšně.</Alert>
                </Snackbar>
            )}
            {success === false && (
                <Snackbar open autoHideDuration={6000} onClose={onSnackbarClose}>
                    <Alert severity="error">Přihlášení selhalo. Zkontrolujte přihlašovací údaje.</Alert>
                </Snackbar>
            )}
        </>
    );
};

export default LoginApp;
