import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { INewsArticleSummary } from '../../schemas/interfaces';

const NewsCard: FC<{ article: INewsArticleSummary }> = ({ article }) => {
    return (
        <Card
            sx={{
                height: '100%',
            }}
            variant="outlined"
        >
            <Link to={`/news/cs/${article.id}`}>
                <CardMedia image={article.thumbnail} title={article.title} sx={{ height: 200, width: '100%' }} />
            </Link>
            <CardContent sx={{ flexGrow: 1 }}>
                <Link to={`/news/cs/${article.id}`} style={{ textDecoration: 'none' }}>
                    <Typography variant="h3" component="h2" sx={{ pb: 1 }} color="primary">
                        {article.title}
                    </Typography>
                </Link>
                <Typography variant="body2" component="p" sx={{ pb: 1 }}>
                    Publikováno {format(article.date, 'd. M. yyyy')}
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                    }}
                >
                    {article.sample}
                </Typography>
                <Link to={`/news/cs/${article.id}`} style={{ textDecoration: 'none' }}>
                    <Button
                        color="secondary"
                        sx={{
                            textDecoration: 'underline',
                            textTransform: 'none',
                            pl: 0,
                        }}
                    >
                        Přejít na&nbsp;článek
                    </Button>
                </Link>
            </CardContent>
        </Card>
    );
};

export default NewsCard;
