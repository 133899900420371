import { FC, createContext, useContext, PropsWithChildren, useState, useEffect, useMemo } from 'react';
import { ITaxon } from '../schemas/interfaces';

const TAXON_LANGUAGE_KEY = 'avifTaxonLanguage';

export type TaxonLanguage = 'cs' | 'en' | 'la';

export const SupportedTaxonLanguages: TaxonLanguage[] = ['cs', 'en', 'la'];
export const SupportedTaxonLanguagesNames = ['češtiny', 'angličtiny', 'latiny'];

export const getLanguageName = (lang: TaxonLanguage) => {
    return SupportedTaxonLanguagesNames[SupportedTaxonLanguages.indexOf(lang)];
};

export const translateTaxonName = (taxon: ITaxon, lang?: TaxonLanguage) => {
    if (!lang) return 'Initializing...';
    if (!taxon || !taxon.name) return 'Unrecognized taxon';

    switch (lang) {
        case 'en':
            return taxon.name.en || `Missing english ${taxon.id}`;
        case 'la':
            return taxon.name.la || `Missing latin ${taxon.id}`;
        case 'cs':
        default:
            return taxon.name.cs || `Missing czech ${taxon.id}`;
    }
};

export interface ITaxonLanguageContext {
    currentLanguage?: TaxonLanguage;
    setLanguage?: (lang: TaxonLanguage) => void;
}

export const TaxonLanguageContext = createContext<ITaxonLanguageContext>({} as ITaxonLanguageContext);

export const useTaxonLanguage = () => {
    return useContext(TaxonLanguageContext);
};

export const TaxonLanguageProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState<TaxonLanguage>();

    useEffect(() => {
        if (!window || !window.localStorage) {
            console.error('Browser does not support localStorage, persising language settings will not work.');
            setCurrentLanguage(SupportedTaxonLanguages[0]);
            return;
        }

        const storedLang = localStorage.getItem(TAXON_LANGUAGE_KEY);

        if (storedLang && SupportedTaxonLanguages.includes(storedLang as TaxonLanguage)) {
            setCurrentLanguage(storedLang as TaxonLanguage);
        } else {
            setCurrentLanguage(SupportedTaxonLanguages[0]);
        }
    }, []);

    const setLanguage = useMemo(() => {
        return (lang: TaxonLanguage) => {
            if (!SupportedTaxonLanguages.includes(lang)) return;

            localStorage.setItem(TAXON_LANGUAGE_KEY, lang);
            setCurrentLanguage(lang);
        };
    }, [setCurrentLanguage]);

    return (
        <TaxonLanguageContext.Provider value={{ currentLanguage, setLanguage }}>
            {children}
        </TaxonLanguageContext.Provider>
    );
};
