import { FC } from 'react';
import { Circle, Popup, Tooltip } from 'react-leaflet';
import { ICoordinates } from '../../schemas/interfaces';
import { feedersPrimary } from '../../theme';
import CopyToClipboard from './CopyToClipboard';

const ObserverMarker: FC<{ position: ICoordinates; squareCode?: string; popup?: boolean }> = ({
    position,
    squareCode,
    popup,
}) => (
    <Circle
        center={position}
        pathOptions={{ color: feedersPrimary[400], weight: 3 }}
        radius={5}
        // eventHandlers={{
        //     mouseover: (e) => {
        //         e.target.setStyle({ color: 'red' });
        //     },
        // }}
    >
        <Tooltip content={'Pozice pozorovatele'} direction="top" />
        {!!popup && (
            <Popup>
                <CopyToClipboard
                    label="Souřadnice"
                    value={`${Math.round(position[0] * 10000000) / 10000000}, ${
                        Math.round(position[1] * 10000000) / 10000000
                    }`}
                />
                <br />
                {squareCode && <CopyToClipboard label="Kód kvadrantu" value={squareCode} />}
            </Popup>
        )}
    </Circle>
);

export default ObserverMarker;
