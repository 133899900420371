import { FC } from 'react';
import AvifJoyride from './AvifJoyride';

const ExampleTour: FC<Record<never, unknown>> = () => {
    return (
        <AvifJoyride
            continuous
            floaterProps={{
                styles: {
                    wrapper: {
                        zIndex: 1100,
                    },
                },
            }}
            steps={[
                {
                    target: '.tour-intro-1',
                    title: 'Výběr pozorovaných druhů',
                    content:
                        'Druhým krokem vkládání vycházky jsou údaje o tom, co jsme na vycházce viděli. Nejprve zaznamenáme, \
                        o jaký se jednalo druh – toto je současně jediný povinný údaj k vyplnění v této části zadávání. To, \
                        zda vyplníte další detaily je zcela na vás.',
                },
            ]}
            tourId="example"
            styles={{
                beacon: {
                    pointerEvents: 'none',
                    cursor: 'default',
                },
            }}
        />
    );
};

export default ExampleTour;
