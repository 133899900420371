import { SxProps } from '@mui/material';

const doubleRowStyle: any = {
    '& .MuiDataGrid-virtualScroller': {
        overflow: 'hidden',
        background: 'none',
    },
    '& .MuiDataGrid-virtualScrollerContent, \
    & .MuiDataGrid-virtualScrollerRenderZone': {
        width: '100% !important',
    },
    '& .MuiDataGrid-columnHeaders': {
        maxHeight: 'none !important',
        minHeight: '0px !important',
        lineHeight: '30px !important',
        width: '100% !important',
    },
    '& .MuiDataGrid-row, \
    & .MuiDataGrid-columnHeaders > div[role=row]': {
        width: '100% !important',
        flexWrap: 'wrap',
        maxHeight: 'none !important',
        minHeight: '0px !important',
        borderBottom: '1px solid #ddd',
        lineHeight: '30px !important',
    },
    '& .MuiDataGrid-cell, \
    & .MuiDataGrid-columnHeader': {
        width: 'auto !important',
        minWidth: '0px !important',
        maxWidth: '100% !important',
        minHeight: '0px !important',
        maxHeight: 'none !important',
        lineHeight: '30px !important',
        border: 'none',
        paddingTop: '0px',
        height: 'auto !important',
    },
    '& .MuiDataGrid-columnSeparator': { minHeight: '0px !important' },
    '& .MuiDataGrid-scrollbar': {
        display: 'none',
    },
};

export default doubleRowStyle;
