import { ToggleButton, ToggleButtonProps, TooltipProps } from '@mui/material';
import { FC } from 'react';
import { TooltipMobile } from './Tooltip';

export interface TooltipToggleButtonProps extends ToggleButtonProps {
    TooltipProps: Partial<TooltipProps> & Pick<TooltipProps, 'title'>;
}

const TooltipToggleButton: FC<TooltipToggleButtonProps> = ({ TooltipProps, ...otherProps }) => {
    return (
        <TooltipMobile {...TooltipProps}>
            <ToggleButton {...otherProps} />
        </TooltipMobile>
    );
};

export default TooltipToggleButton;
